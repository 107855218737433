import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import {
	EditContainerContentDirective,
	UserRightsMessageComponent,
	YunoAdminButtonsModule,
	YunoAdminCodeEditorModule,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { JsonFormsModule } from '@yuno/angular-json-forms';
import {
	YunoFormsCheckboxComponent,
	YunoFormsCheckboxTemplateComponent,
	YunoFormsCodeEditorComponent,
	YunoFormsCodeEditorTemplateComponent,
	YunoFormsColorComponent,
	YunoFormsColorTemplateComponent,
	YunoFormsDateComponent,
	YunoFormsDateTemplateComponent,
	YunoFormsDefaultComponent,
	YunoFormsDefaultTemplateComponent,
	YunoFormsDividerComponent,
	YunoFormsEmailComponent,
	YunoFormsEmailTemplateComponent,
	YunoFormsFileComponent,
	YunoFormsFileTemplateComponent,
	YunoFormsFormControlSelectorComponent,
	YunoFormsHtmlTextComponent,
	YunoFormsHtmlTextTemplateComponent,
	YunoFormsLabelComponent,
	YunoFormsNumberComponent,
	YunoFormsNumberTemplateComponent,
	YunoFormsPasswordComponent,
	YunoFormsPasswordTemplateComponent,
	YunoFormsRadioComponent,
	YunoFormsRadioTemplateComponent,
	YunoFormsRangeComponent,
	YunoFormsRangeTemplateComponent,
	YunoFormsSelectComponent,
	YunoFormsSelectTemplateComponent,
	YunoFormsSpanComponent,
	YunoFormsTelephoneComponent,
	YunoFormsTelephoneTemplateComponent,
	YunoFormsTextComponent,
	YunoFormsTextTemplateComponent,
	YunoFormsTextareaComponent,
	YunoFormsTextareaTemplateComponent,
	YunoFormsTitleComponent,
	YunoFormsToggleComponent,
	YunoFormsToggleTemplateComponent,
	YunoFormsUrlComponent,
	YunoFormsUrlTemplateComponent,
	YunoFormsValidatorMessagesComponent,
	YunoFormsValidatorMessagesTemplateComponent,
	YunoLanguageIconsComponent
} from '@yuno/angular/forms/components';

import { ObjectEditorComponent } from './object-editor.component';

@NgModule({
	declarations: [ObjectEditorComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		YunoFormsDividerComponent,
		YunoFormsTitleComponent,
		YunoFormsTextComponent,
		YunoFormsSpanComponent,
		YunoFormsValidatorMessagesComponent,
		YunoFormsCheckboxComponent,
		YunoFormsCheckboxTemplateComponent,
		YunoFormsCodeEditorComponent,
		YunoFormsCodeEditorTemplateComponent,
		YunoFormsColorComponent,
		YunoFormsColorTemplateComponent,
		YunoFormsDateComponent,
		YunoFormsDateTemplateComponent,
		YunoFormsDefaultComponent,
		YunoFormsDefaultTemplateComponent,
		YunoFormsEmailComponent,
		YunoFormsEmailTemplateComponent,
		YunoFormsFileComponent,
		YunoFormsFileTemplateComponent,
		YunoFormsFormControlSelectorComponent,
		YunoFormsHtmlTextComponent,
		YunoFormsHtmlTextTemplateComponent,
		YunoFormsLabelComponent,
		YunoFormsNumberComponent,
		YunoFormsNumberTemplateComponent,
		YunoFormsPasswordComponent,
		YunoFormsPasswordTemplateComponent,
		YunoFormsRadioComponent,
		YunoFormsRadioTemplateComponent,
		YunoFormsRangeComponent,
		YunoFormsRangeTemplateComponent,
		YunoFormsSelectComponent,
		YunoFormsSelectTemplateComponent,
		YunoFormsTelephoneComponent,
		YunoFormsTelephoneTemplateComponent,
		YunoFormsTextareaComponent,
		YunoFormsTextareaTemplateComponent,
		YunoFormsTextTemplateComponent,
		YunoFormsToggleComponent,
		YunoFormsToggleTemplateComponent,
		YunoFormsUrlComponent,
		YunoFormsUrlTemplateComponent,
		YunoFormsValidatorMessagesTemplateComponent,
		YunoLanguageIconsComponent,
		JsonFormsModule,
		YunoEditContainerModule,
		YunoAdminButtonsModule,
		YunoAdminCodeEditorModule,
		UserRightsMessageComponent,
		EditContainerContentDirective
	]
})
export class ObjectEditorModule {}
