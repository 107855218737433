import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { combineLatest } from 'rxjs';

import { YunoFormsImageComponent } from '@yuno/angular/forms/admin-components';
import { YunoFormsTextComponent } from '@yuno/angular/forms/components';

import { PredefinedEditorService } from '../predefined-editor.service';
import { PredefinedDefaultEditorComponent } from './_default-editor.component';

@Component({
	selector: 'yuno-admin-predefined-file-editor',
	imports: [ReactiveFormsModule, YunoFormsTextComponent, YunoFormsImageComponent],
	template: `
		<form [formGroup]="form">
			<div formGroupName="options" class="flex flex-col gap-4">
				<section formGroupName="title">
					@for (lang of languages; track lang) {
						@if (lang === language) {
							<yuno-forms-text
								[formControlName]="lang"
								[language]="lang"
								placeholder="Add title text here"
								label="File title" />
						}
					}
				</section>
				<yuno-forms-image
					[formValue]="'object'"
					formControlName="file"
					label="File"
					placeholder="select file" />
			</div>
		</form>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PredefinedFileEditorComponent
	extends PredefinedDefaultEditorComponent
	implements OnInit
{
	readonly editor = inject(PredefinedEditorService);

	data$ = combineLatest({
		files: this.editor.files$
	});

	ngOnInit() {
		this.editor.getFiles();
	}
}
