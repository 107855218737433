import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SafeHtml } from '@angular/platform-browser';
import { tap } from 'rxjs';

import { EmbedVideoService } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import {
	YunoFormsDividerComponent,
	YunoFormsSelectComponent,
	YunoFormsSpanComponent,
	YunoFormsTextComponent,
	YunoFormsTextareaComponent,
	YunoFormsTitleComponent
} from '@yuno/angular/forms/components';

import { TextEditorsService } from '../text-editors.service';

@Component({
	selector: 'yuno-admin-video-editor',
	imports: [
		ReactiveFormsModule,
		YunoFormsDividerComponent,
		YunoFormsTitleComponent,
		YunoFormsTextComponent,
		YunoFormsSpanComponent,
		YunoFormsSelectComponent,
		YunoFormsTextareaComponent,
		AsyncPipe
	],
	templateUrl: './video-editor.component.html',
	styleUrls: ['./video-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	private readonly embedService = inject(EmbedVideoService);
	readonly editService = inject(TextEditorsService);

	html: SafeHtml;
	attr = { height: '360', width: '720' };

	service$ = this.editService.getActiveService().pipe(
		tap(service => {
			if (service) {
				const type = service.component?.get('type');
				const id = service.component?.get('videoId');

				if (this.editService.isPageEditorService(service)) {
					this.attr = { height: '240', width: '480' };
				} else {
					this.attr = { height: '360', width: '720' };
				}

				if (id) {
					if (type && type.value === 'vimeo') {
						this.updateVimeo(id.value);
					}
					if (type && type.value === 'youtube') {
						this.updateYoutube(id.value);
					}
				}
			}
		})
	);

	updateVimeo(id: string) {
		this.html = this.embedService.embed_vimeo(id, {
			attr: this.attr
		});
	}

	updateYoutube(id: string) {
		this.html = this.embedService.embed_youtube(id, {
			attr: this.attr
		});
	}

	ngOnInit() {
		this.languageSelector();
	}

	ngOnDestroy() {
		this.languageService.destroyLanguageSelector();
	}
}
