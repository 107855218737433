import { Injectable, inject, signal } from '@angular/core';

import { ENVIRONMENT } from '@yuno/admin/core';
import { MessageService, ToastItem } from '@yuno/angular/notifications';
import { VariantsInterface } from '@yuno/api/interface';

@Injectable({
	providedIn: 'root'
})
export class UrlGeneratorService {
	private readonly message = inject(MessageService);
	private readonly environment = inject(ENVIRONMENT);

	$variants = signal<VariantsInterface[]>([]);
	$variantRoutes = signal<string[]>([]);

	clientUrl: string;
	appName: string;
	appUrl: string;

	noReferrerValues = [
		'no-referrer',
		'no-referrer-when-downgrade',
		'origin',
		'origin-when-cross-origin',
		'same-origin',
		'strict-origin-when-cross-origin',
		'unsafe-url'
	];
	noReferrerDisplay = [
		'no-referrer',
		'no-referrer-when-downgrade (default)',
		'origin',
		'origin-when-cross-origin',
		'same-origin',
		'strict-origin-when-cross-origin',
		'unsafe-url'
	];

	embedCode = true;
	lazyLoading = true;
	embedTrue = false;
	allowfullscreen = true;
	noReferer = 'no-referrer-when-downgrade';

	url = '';
	iframe = '';

	variant = 'public';
	variantRoute = 'page/home';
	variants: string[] = [];

	selectVariant(variant: string | null): void {
		if (!variant) {
			return;
		}

		const variantData = this.findVariantRoutes(variant);
		if (!variantData) {
			return;
		}

		const routes = variantData.routes.map(r => r.id.link).filter(e => !!e) as string[];

		this.$variantRoutes.set(routes);
		this.variantRoute = routes[0] || '';

		this.updateUrl();
	}

	findVariantRoutes(variant: string): VariantsInterface | undefined {
		return this.$variants().find(v => v.id === variant);
	}

	copyToClipboard(): void {
		navigator.clipboard.writeText(this.embedCode ? this.iframe : this.url).then(() => {
			const data: ToastItem = {
				message: `copied to clipboard`
			};
			this.message.showToast(data, 'success');
		});
	}

	updateUrl(): void {
		const baseUrl = `https://${this.clientUrl}.${this.environment['yuno-main']}/${this.appUrl}`;
		const variantData = this.variant !== 'public' ? this.findVariantRoutes(this.variant) : null;

		if (this.variant !== 'public' && !variantData) {
			return;
		}

		const variantPath = variantData ? `/v/${variantData.url}` : '';

		this.url = `${baseUrl}${variantPath}/${this.variantRoute}`;

		if (this.embedTrue) {
			this.url = this.url + '?embed=true';
		}
		this.iframe = this.generateIframeCode();
	}

	private generateIframeCode(): string {
		const attributes: string[] = [`title="${this.appName}"`, `src="${this.url}"`];

		if (this.allowfullscreen) {
			attributes.push('allowfullscreen', 'allow="fullscreen"');
		}

		if (this.noReferer) {
			attributes.push(`referrerpolicy="${this.noReferer}"`);
		}

		if (this.lazyLoading) {
			attributes.push('loading="lazy"');
		}

		return `<iframe ${attributes.join(' ')}></iframe>`;
	}
}
