<yuno-edit-container [col]="6" [pos]="1" [span]="6">
	<ng-container buttons>
		<button yuno-admin-close-button (click)="onClosePage()"></button>
	</ng-container>
	<div class="flex flex-col gap-2 md:grid md:h-full">
		<div edit-container-content>
			<form *ngIf="service.form" [formGroup]="service.form" class="mt-4">
				<div class="flex flex-row items-start justify-between">
					<yuno-forms-title>Rule Editor</yuno-forms-title>
					<button yuno-admin-button color="primary" (click)="addRule()">Add Rule</button>
				</div>
				<div formGroupName="layout" class="mt-4">
					<div formArrayName="options" class="col-span-1 grid gap-2">
						<ng-container [ngSwitch]="service.getStyleRuleType()">
							<div
								*ngFor="let rule of service.layoutOptions.controls; let i = index"
								[formGroupName]="i"
								class="flex flex-row items-center justify-center gap-2 rounded-md border border-gray-300 bg-gray-200 p-2">
								<ng-container *ngSwitchCase="'string'">
									<div class="grid flex-1 grid-cols-2 gap-2">
										<yuno-forms-text
											[formControlName]="0"
											placeholder="value to compare against..."></yuno-forms-text>
										<yuno-forms-select
											[formControlName]="1"
											[display]="service.stylesKeys"
											[selectValues]="service.stylesKeys"></yuno-forms-select>
									</div>
								</ng-container>
								<ng-container *ngSwitchCase="'number'">
									<div class="grid flex-1 grid-cols-3 gap-2">
										<ng-container [formArrayName]="0">
											<yuno-forms-select
												class="flex-1"
												[formControlName]="0"
												[display]="service.optionsOperator"
												[selectValues]="service.optionsOperator"></yuno-forms-select>
											<yuno-forms-number [formControlName]="1"></yuno-forms-number>
										</ng-container>
										<yuno-forms-select
											class="flex-1"
											[formControlName]="1"
											[display]="service.stylesKeys"
											[selectValues]="service.stylesKeys"></yuno-forms-select>
									</div>
								</ng-container>
								<ng-container *ngSwitchCase="'date'">
									<div class="grid flex-1 grid-cols-4 gap-2">
										<ng-container [formArrayName]="0">
											<yuno-forms-select
												class="flex-1"
												[formControlName]="0"
												[display]="service.optionsOperator"
												[selectValues]="service.optionsOperator"></yuno-forms-select>
											<yuno-forms-number [formControlName]="1"></yuno-forms-number>
											<yuno-forms-select
												class="flex-1"
												[formControlName]="2"
												[display]="service.optionsDateOptions"
												[selectValues]="service.optionsDateOptions"></yuno-forms-select>
										</ng-container>
										<yuno-forms-select
											class="flex-1"
											[formControlName]="1"
											[display]="service.stylesKeys"
											[selectValues]="service.stylesKeys"></yuno-forms-select>
									</div>
								</ng-container>
								<ng-container *ngSwitchDefault>Something went wrong</ng-container>
								<button yuno-admin-button color="danger" (click)="removeRule(i)">Remove</button>
							</div>
						</ng-container>
					</div>
				</div>
			</form>
		</div>
	</div>
</yuno-edit-container>
