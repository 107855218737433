import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, map } from 'rxjs';

import { LegendFacade } from '@yuno/admin/features/legends/data-access';
import { YunoAdminButtonsModule, YunoAdminTableComponent } from '@yuno/admin/ui';
import { Legend } from '@yuno/api/interface';

import { LegendsRoutingModule } from './legends-routing.module';

@Component({
	selector: 'yuno-admin-legends',
	imports: [YunoAdminTableComponent, LegendsRoutingModule, YunoAdminButtonsModule, AsyncPipe],
	templateUrl: './legends.component.html',
	styleUrls: ['./legends.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegendsComponent implements OnInit {
	private readonly legendFacade = inject(LegendFacade);
	private readonly router = inject(Router);
	private readonly route = inject(ActivatedRoute);

	hoveredItem: Partial<Legend>;

	data$ = combineLatest({
		legends: this.legendFacade.legends$.pipe(
			map(data => data as unknown as { [key: string]: unknown }[])
		),
		selected: this.legendFacade.selectedLegends$
	});

	ngOnInit(): void {
		this.legendFacade.getLegendsByAppId();
	}

	onSelect(row: Partial<Legend>): void {
		this.legendFacade.select(row._id as string);

		this.router.navigate(['edit', row._id], {
			relativeTo: this.route
		});
	}

	onDuplicate(row: Partial<Legend>): void {
		this.legendFacade.duplicate(row._id as string);
	}

	onDelete(row: Partial<Legend>): void {
		this.legendFacade.delete(row._id as string);
	}

	onCreate(): void {
		this.legendFacade.clearSelect();
		this.router.navigate(['create'], {
			relativeTo: this.route
		});
		this.hoveredItem = { _id: '' };
	}
}
