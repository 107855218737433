import { AsyncPipe, KeyValuePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { combineLatest, startWith, tap } from 'rxjs';

import { AnnotationComponent, YunoAdminButtonsModule } from '@yuno/admin/ui';
import { ControlsOf } from '@yuno/angular/forms';
import {
	YunoFormsDividerComponent,
	YunoFormsSelectComponent,
	YunoFormsSpanComponent,
	YunoFormsTextComponent,
	YunoFormsTitleComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';
import { SetFeatureState } from '@yuno/api/interface';

import { EventsDefaultFormItemComponent } from '../_default/default-form-item.component';

@Component({
	selector: 'yuno-admin-set-feature-state',
	imports: [
		FormsModule,
		ReactiveFormsModule,
		YunoAdminButtonsModule,
		YunoFormsDividerComponent,
		YunoFormsTitleComponent,
		YunoFormsTextComponent,
		YunoFormsSpanComponent,
		YunoFormsSelectComponent,
		YunoFormsToggleComponent,
		AnnotationComponent,
		KeyValuePipe,
		AsyncPipe
	],
	templateUrl: './set-feature-state.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetFeatureStateComponent extends EventsDefaultFormItemComponent implements OnInit {
	newKeyValue: string | null;

	get controls() {
		return (this.form.controls.options.controls as ControlsOf<SetFeatureState>).state;
	}

	layers$ = this.service.sourceLayers$.pipe(
		tap(data => {
			if (!data) {
				console.log('NO DATA');
			}
		})
	);

	srcData$ = combineLatest({
		sources: this.service.sources$.pipe(
			tap(data => {
				if (data.length >= 1) {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					const src: string = (this.form.getRawValue().options as any).source as string;
					if (src) {
						this.service.getSourceLayers(src);
					}
				}
			})
		),
		sourceLayers: this.service.sourceLayers$.pipe(
			startWith([]),
			tap(data => {
				if (!data) {
					console.log('NO DATA');
				}
			})
		)
	});

	ngOnInit(): void {
		this.service.getSources();
	}

	getSourceLayers(event: string | null): void {
		if (event) {
			this.service.getSourceLayers(event);
		}
		this.form.controls.options.get('sourceLayer')?.reset();
	}

	createNewValue(): void {
		if (!this.controls) {
			console.warn('NO CONTROLS FOUND!');
			return;
		}

		const control = new FormControl<string | undefined>('', {
			nonNullable: true
		});

		if (this.newKeyValue === 'hover') {
			return;
		}
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		this.controls.addControl(this.newKeyValue as string, control);
		this.newKeyValue = null;
	}

	removeControl(item: string): void {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		this.controls?.removeControl(item);
	}
}
