import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCommonModule } from '@angular/material/core';

export type YunoSharedUiButtonColors =
	| 'primary'
	| 'secondary'
	| 'secondary-dark'
	| 'warning'
	| 'danger'
	| 'success'
	| 'muted'
	| 'disabled';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[yuno-shared-ui-button]',
    imports: [],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '[attr.disabled]': 'disabled || null'
    },
    template: ` <span
		#button
		class="button-container block transition-colors duration-200 ease-in-out">
		@if (add) {
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="-mt-1 mr-2 inline-block h-4 w-4"
				viewBox="0 0 16 16">
				<circle cx="8" cy="8" r="8" style="fill: #3a4558" />
				<path
					d="M12.3 6.9H9.1V3.7c0-.4-.3-.7-.7-.7h-.8c-.4 0-.7.3-.7.7v3.2H3.7c-.4 0-.7.3-.7.7v.7c0 .4.3.7.7.7h3.2v3.2c0 .4.3.7.7.7h.7c.4 0 .7-.3.7-.7V9.1h3.2c.4 0 .7-.3.7-.7v-.8c.1-.4-.2-.7-.6-.7z"
					style="fill: #e9ecee" />
			</svg>
		}
		<ng-content></ng-content>
	</span>`,
    styleUrls: [`./button.component.scss`],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoSharedUiButtonComponent {
	@Input() add = false;

	@HostBinding('class.disabled') @Input() disabled = false;

	_color?: YunoSharedUiButtonColors = 'primary';
	@HostBinding('class') @Input() set color(color: YunoSharedUiButtonColors | undefined) {
		this._color = color || 'primary';
	}

	get color(): YunoSharedUiButtonColors | undefined {
		return this._color;
	}
}

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[yuno-shared-ui-button-table], span[yuno-shared-ui-button-table]',
    imports: [MatCommonModule, CommonModule, MatButtonModule],
    template: `
		<span #button class="text-sm">
			@if (add) {
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="mr-1.5 inline-block h-4 w-4"
					viewBox="0 0 16 16">
					<circle cx="8" cy="8" r="8" style="fill: #3a4558" />
					<path
						d="M12.3 6.9H9.1V3.7c0-.4-.3-.7-.7-.7h-.8c-.4 0-.7.3-.7.7v3.2H3.7c-.4 0-.7.3-.7.7v.7c0 .4.3.7.7.7h3.2v3.2c0 .4.3.7.7.7h.7c.4 0 .7-.3.7-.7V9.1h3.2c.4 0 .7-.3.7-.7v-.8c.1-.4-.2-.7-.6-.7z"
						style="fill: #e9ecee" />
				</svg>
			}
			<ng-content></ng-content>
		</span>
	`,
    styleUrls: ['./button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoSharedUiButtonTableComponent {
	@HostBinding('class.disabled') @Input() disabled = false;
	@HostBinding('attr.data-type') table = 'table';

	_color?: YunoSharedUiButtonColors = 'primary';
	@HostBinding('class') @Input() set color(color: YunoSharedUiButtonColors | undefined) {
		this._color = color || 'primary';
	}

	get color(): YunoSharedUiButtonColors | undefined {
		return this._color;
	}

	@Input() add = false;
}
