import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { sortBy } from 'lodash';
import { combineLatest, map, tap } from 'rxjs';

import { MarkerCategoriesFacade } from '@yuno/admin/features/place-markers/data-access';
import { ChooseTemplateComponent } from '@yuno/admin/features/templates';
import {
	YunoAdminButtonComponent,
	YunoAdminCardComponent,
	YunoAdminDeleteAdminButtonComponent,
	YunoAdminDuplicateButtonComponent,
	YunoAdminEditButtonComponent,
	YunoAdminTableComponent
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { DialogItem, MessageDialogComponent } from '@yuno/angular/notifications';
import { MarkerCategory } from '@yuno/api/interface';

import { PlaceMarkerTemplateViewComponent } from '../templates/template-view/template-view.component';
import { PlaceMarkersRoutingModule } from './place-markers.routing.module';

@Component({
	selector: 'yuno-admin-place-markers-internal-view',
	imports: [
		PlaceMarkersRoutingModule,
		YunoAdminTableComponent,
		AsyncPipe,
		ChooseTemplateComponent,
		YunoAdminButtonComponent,
		YunoAdminDuplicateButtonComponent,
		YunoAdminEditButtonComponent,
		YunoAdminCardComponent,
		PlaceMarkerTemplateViewComponent,
		YunoAdminDeleteAdminButtonComponent
	],
	templateUrl: './place-markers.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlaceMarkersComponent extends AppDataComponent {
	private readonly facade = inject(MarkerCategoriesFacade);
	private readonly dialog = inject(MatDialog);
	private dialogRef: MatDialogRef<MessageDialogComponent>;

	toggleTemplates = false;
	tabValue = 'Editor';

	data$ = combineLatest({
		categories: this.facade.markerCategories$.pipe(
			map(data => {
				const list: { [key: string]: unknown }[] = data.map(category => ({
					_id: category?._id || '',
					id: category?.id || '',
					order: category.order,
					public: category.public,
					style: category?.type,
					inputs: category.inputs?.length,
					events:
						category.events?.onClick?.length +
						' | ' +
						category.events?.onMouseMove?.length,
					intern: category.intern,
					cluster: category.cluster?.active ? 'yes' : ''
				}));
				return sortBy(list, data => {
					return data['order'] || 1;
				});
			})
		),
		selected: this.facade.selectedMarkerCategory$.pipe(
			tap(data => {
				if (data?._id && data.id === 'new category') {
					this.router.navigate(['./', 'view', data._id, 'map', 'edit'], {
						relativeTo: this.route
					});
				}
			})
		)
	});

	onView(row: Partial<MarkerCategory>): void {
		this.router.navigate(['view', row?._id as string], {
			relativeTo: this.route
		});
	}

	onCreate(templateId: string | undefined) {
		if (templateId) {
			this.facade.createFromTemplate(templateId);
		} else {
			this.facade.createNew();
		}
		// this.router.navigate(['view', 'create', 'map', 'edit'], {
		// 	relativeTo: this.route
		// });
		this.toggleTemplates = false;
	}

	onEdit(row: Partial<MarkerCategory>): void {
		this.router.navigate(['view', row?._id as string, 'map', 'edit'], {
			relativeTo: this.route
		});
	}

	onDuplicate(row: Partial<MarkerCategory>): void {
		this.facade.duplicate(row?._id as string);
	}

	onDelete(row: Partial<MarkerCategory>): void {
		this.facade.delete(row?._id as string);
	}

	onToggleDelete(row: Partial<MarkerCategory>) {
		const data: DialogItem = {
			title: 'Confirm deleting marker category',
			message:
				'Are you sure you want to delete this marker category? <br> All markers attached to this category will be deleted as well.',
			buttons: [
				{
					key: 'Delete',
					type: 'danger',
					confirm: true
				},
				{
					key: 'Cancel',
					type: 'muted'
				}
			],
			confirm: 'Confirmed'
		};

		this.dialogRef = this.dialog.open(MessageDialogComponent, {
			data: data
		});

		this.dialogRef.afterClosed().subscribe((confirmed: boolean) => {
			if (confirmed) {
				this.onDelete(row);
			}
		});
	}

	onSelectTemplate(bool: boolean) {
		if (this.templateApp) {
			this.onCreate(undefined);
		} else {
			this.toggleTemplates = bool;
		}
	}
}
