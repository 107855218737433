import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { YunoFormsDefaultComponent } from '../_default/default.component';
import { YunoFormsCheckboxComponent } from '../checkbox/checkbox.component';
import { YunoFormsColorComponent } from '../color/color.component';
import { YunoFormsEmailComponent } from '../email/email.component';
import { YunoFormsFileComponent } from '../file/file.component';
import { YunoFormsHtmlTextComponent } from '../html/html.component';
import { YunoFormsNumberComponent } from '../number/number.component';
import { YunoFormsPasswordComponent } from '../password/password.component';
import { YunoFormsRadioComponent } from '../radio/radio.component';
import { YunoFormsRangeComponent } from '../range/range.component';
import { YunoFormsSelectComponent } from '../select/select.component';
import { YunoFormsTelephoneComponent } from '../telephone/telephone.component';
import { YunoFormsTextComponent } from '../text/text.component';
import { YunoFormsTextareaComponent } from '../textarea/textarea.component';
import { YunoFormsToggleComponent } from '../toggle/toggle.component';

@Component({
    selector: 'yuno-form-control-selector',
    templateUrl: './form-control-selector.component.html',
    styleUrls: ['./form-control-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        YunoFormsEmailComponent,
        YunoFormsTextComponent,
        YunoFormsPasswordComponent,
        YunoFormsTelephoneComponent,
        YunoFormsNumberComponent,
        YunoFormsRangeComponent,
        YunoFormsSelectComponent,
        YunoFormsTextareaComponent,
        YunoFormsCheckboxComponent,
        YunoFormsRadioComponent,
        YunoFormsToggleComponent,
        YunoFormsFileComponent,
        YunoFormsColorComponent,
        YunoFormsHtmlTextComponent
    ]
})
export class YunoFormsFormControlSelectorComponent extends YunoFormsDefaultComponent {
	@Input() formGroup: FormGroup;

	@Input() key?:
		| 'text'
		| 'email'
		| 'password'
		| 'tel'
		| 'url'
		| 'number'
		| 'range'
		| 'select'
		| 'textarea'
		| 'checkbox'
		| 'radio'
		| 'toggle'
		| 'file'
		| 'image'
		| 'color'
		| 'html'
		| string;

	@Input() display?: string[];
	@Input() selectValues?: string[];
}
