import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { AnnotationComponent } from '@yuno/admin/ui';
import {
	YunoFormsSelectComponent,
	YunoFormsSpanComponent,
	YunoFormsTextComponent,
	YunoFormsTitleComponent
} from '@yuno/angular/forms/components';

import { TextEditorsService } from '../text-editors.service';

@Component({
	selector: 'yuno-image-button-editor',
	imports: [
		ReactiveFormsModule,
		YunoFormsTitleComponent,
		YunoFormsTextComponent,
		YunoFormsSpanComponent,
		YunoFormsSelectComponent,
		AsyncPipe,
		AnnotationComponent
	],
	templateUrl: './image-button-editor.component.html',
	styles: ``,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageButtonEditorComponent implements OnInit, OnDestroy {
	readonly editService = inject(TextEditorsService);

	service$ = this.editService.getActiveService();
	data$ = this.editService.data$;

	onType(form: FormGroup, type: string | null) {
		if (type === 'url') {
			form.get('link')?.reset();
		} else {
			form.get('url')?.reset();
		}
	}

	ngOnInit() {
		this.editService.onInitImageButtons();
		this.editService.onInitNav();
		this.editService.onInitPages();
	}

	ngOnDestroy() {
		this.editService.onClearImageButtons();
	}
}
