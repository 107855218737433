import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { YunoFormsTextComponent, YunoFormsTextareaComponent } from '@yuno/angular/forms/components';

import { PredefinedDefaultEditorComponent } from './_default-editor.component';

@Component({
	selector: 'yuno-admin-predefined-paragraph-editor',
	imports: [
		ReactiveFormsModule,
		YunoFormsTextComponent,
		YunoFormsTextareaComponent,
		NgTemplateOutlet
	],
	template: `
		<form [formGroup]="form">
			<div formGroupName="options" class="flex flex-col gap-4">
				<section formGroupName="title">
					@for (lang of languages; track lang) {
						@if (lang === language) {
							<yuno-forms-text
								[formControlName]="lang"
								[language]="lang"
								placeholder="Add title text here"
								label="Paragraph title (optional)" />
						}
					}
				</section>
				<section formGroupName="content">
					@for (lang of languages; track lang) {
						@if (lang === language) {
							<yuno-forms-textarea
								[formControlName]="lang"
								[language]="lang"
								[rows]="3"
								placeholder="Placeholder text"
								label="Paragraph content" />
						}
					}
				</section>
				<ng-container [ngTemplateOutlet]="template" />
			</div>
		</form>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PredefinedParagraphEditorComponent extends PredefinedDefaultEditorComponent {}
