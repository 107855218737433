@if (service$ | async; as service) {
	@if (service.component) {
		@if (editor.getSelector(service.component) | async; as selector) {
			<form [formGroup]="service.component" class="flex flex-col gap-4">
				<section>
					<yuno-forms-title>{{ editor.getTitle(selector) }} Editor</yuno-forms-title>
					<yuno-forms-span>You can edit the text below.</yuno-forms-span>
				</section>
				@if (data$ | async; as data) {
					<div formGroupName="options" class="flex flex-col gap-4">
						@switch (selector) {
							@case ('h1') {
								<yuno-admin-predefined-heading-editor
									[form]="service.component"
									[language]="data.language"
									[template]="htmlMessage" />
							}
							@case ('h2') {
								<yuno-admin-predefined-heading-editor
									[form]="service.component"
									[language]="data.language"
									[template]="htmlMessage" />
							}
							@case ('h3') {
								<yuno-admin-predefined-heading-editor
									[form]="service.component"
									[language]="data.language"
									[template]="htmlMessage" />
							}
							@case ('paragraph') {
								<yuno-admin-predefined-paragraph-editor
									[form]="service.component"
									[language]="data.language"
									[template]="htmlMessage" />
							}
							@case ('summary') {
								<yuno-admin-predefined-summary-editor
									[form]="service.component"
									[language]="data.language"
									[template]="htmlMessage" />
							}
							@case ('dateSummary') {
								<yuno-admin-predefined-summary-editor
									[form]="service.component"
									[language]="data.language"
									[template]="htmlMessage" />
							}
							@case ('blockquote') {
								<yuno-admin-predefined-blockquote-editor
									[form]="service.component"
									[language]="data.language"
									[template]="htmlMessage" />
							}
							@case ('divider') {
								<yuno-admin-predefined-divider-editor
									[form]="service.component"
									[language]="data.language"
									[template]="htmlMessage" />
							}
							@case ('file') {
								<yuno-admin-predefined-file-editor
									[form]="service.component"
									[language]="data.language"
									[template]="htmlMessage" />
							}
						}
					</div>
				}
			</form>
		}
	}
}

<ng-template #htmlMessage>
	@if (minimalRole(roles.ADMIN)) {
		<yuno-admin-annotation>
			<p class="m-0">
				{{ 'properties.key' | curly }} is accepted; however,
				<a
					href="https://angular.dev/best-practices/security#preventing-cross-site-scripting-xss"
					class="inline underline"
					rel="noopener">
					it will not support rendering HTML or TinyMCE content </a
				>.
			</p>
		</yuno-admin-annotation>
	}
</ng-template>
