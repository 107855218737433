import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, map } from 'rxjs';

import { ImageButtonFacade } from '@yuno/admin/features/media/data-access';
import { YunoAdminButtonsModule, YunoAdminTableComponent } from '@yuno/admin/ui';
import { MediaImageButton } from '@yuno/api/interface';

import { ImageButtonsRoutingModule } from './image-buttons-routing.module';

@Component({
	selector: 'yuno-admin-image-buttons',
	imports: [
		ImageButtonsRoutingModule,
		YunoAdminTableComponent,
		YunoAdminButtonsModule,
		AsyncPipe
	],
	templateUrl: './image-buttons.component.html',
	styleUrls: ['./image-buttons.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageButtonsComponent implements OnInit {
	private readonly imageButtonFacade = inject(ImageButtonFacade);
	private readonly router = inject(Router);
	private readonly route = inject(ActivatedRoute);

	hoveredItem: Partial<MediaImageButton>;
	data$ = combineLatest({
		imageButtons: this.imageButtonFacade.imageButtons$.pipe(
			map(data => data as { [key: string]: unknown }[])
		),
		selected: this.imageButtonFacade.selectedImageButton$
	});

	ngOnInit(): void {
		this.imageButtonFacade.getImageButtons();
	}

	getData(data: Partial<MediaImageButton>[] | null | undefined) {
		return data as { [key: string]: unknown }[];
	}

	onSelect(row: Partial<MediaImageButton>) {
		this.router.navigate(['edit', row._id], {
			relativeTo: this.route
		});
	}

	onDelete(row: Partial<MediaImageButton>) {
		this.imageButtonFacade.delete(row._id as string);
	}
}
