import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import {
	YunoFormsDividerComponent,
	YunoFormsNumberComponent,
	YunoFormsSpanComponent
} from '@yuno/angular/forms/components';

import { EventsDefaultFormItemComponent } from '../_default/default-form-item.component';

@Component({
	selector: 'yuno-admin-zoom-to-feature',
	imports: [
		ReactiveFormsModule,
		YunoFormsDividerComponent,
		YunoFormsSpanComponent,
		YunoFormsNumberComponent
	],
	templateUrl: './zoom-to-feature.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZoomToFeatureComponent extends EventsDefaultFormItemComponent {}
