module.exports = {
  A: {
    A: {
      "1": "A B",
      "2": "K D E F iC"
    },
    B: {
      "1": "C L M G N O P",
      "516": "6 7 8 9 Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I"
    },
    C: {
      "132": "mB nB oB pB qB rB sB JC tB KC uB vB wB",
      "164": "0 1 2 3 4 5 jC IC J MB K D E F A B C L M G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mC nC",
      "516": "xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a",
      "1028": "6 7 8 9 b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC kC lC"
    },
    D: {
      "420": "0 1 2 3 4 5 J MB K D E F A B C L M G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB",
      "516": "6 7 8 9 kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC"
    },
    E: {
      "1": "A B C L M G QC CC DC tC uC vC RC SC EC wC FC TC UC VC WC XC xC GC YC ZC aC bC cC yC HC dC eC fC zC",
      "132": "F sC",
      "164": "D E rC",
      "420": "J MB K oC PC pC qC"
    },
    F: {
      "1": "C CC gC 4C DC",
      "2": "F B 0C 1C 2C 3C",
      "420": "0 1 2 3 4 5 G N O P NB y z OB PB QB RB SB TB UB VB WB",
      "516": "XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x"
    },
    G: {
      "1": "CD DD ED FD GD HD ID JD KD LD MD ND OD RC SC EC PD FC TC UC VC WC XC QD GC YC ZC aC bC cC RD HC dC eC fC",
      "132": "AD BD",
      "164": "E 8C 9C",
      "420": "PC 5C hC 6C 7C"
    },
    H: {
      "1": "SD"
    },
    I: {
      "420": "IC J TD UD VD WD hC XD YD",
      "516": "I"
    },
    J: {
      "420": "D A"
    },
    K: {
      "1": "C CC gC DC",
      "2": "A B",
      "516": "H"
    },
    L: {
      "516": "I"
    },
    M: {
      "1028": "BC"
    },
    N: {
      "1": "A B"
    },
    O: {
      "516": "EC"
    },
    P: {
      "420": "J",
      "516": "0 1 2 3 4 5 y z ZD aD bD cD dD QC eD fD gD hD iD FC GC HC jD"
    },
    Q: {
      "516": "kD"
    },
    R: {
      "516": "lD"
    },
    S: {
      "164": "mD nD"
    }
  },
  B: 4,
  C: "CSS3 Multiple column layout",
  D: true
};