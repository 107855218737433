import { computed, inject } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { HotToastService } from '@ngneat/hot-toast';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { Types } from 'mongoose';
import { switchMap, tap } from 'rxjs';

import { ApiService } from '@yuno/angular/api';
import { RoutesVariantsDto } from '@yuno/api/dto';
import {
	Event,
	MediaImageButton,
	RoutesInterface,
	RoutesItemDropdown,
	VariantsInterface,
	generateLanguageObject
} from '@yuno/api/interface';

type VariantRouteState = {
	routes: RoutesInterface[];
	variants: VariantsInterface[];
	isLoading: boolean;
	selectVariant: string | undefined;
	selectRoute: string | undefined;
	selectRouteDropdown: number | undefined;
};

const initialState: VariantRouteState = {
	routes: [],
	variants: [],
	isLoading: false,
	selectVariant: undefined,
	selectRoute: undefined,
	selectRouteDropdown: undefined
};

const newRoute = (appId: string, order: number): RoutesInterface => {
	return {
		_id: new Types.ObjectId().toString(),
		appId,
		order,
		public: false,
		display: generateLanguageObject(`route-${order + 1}`),
		dropdown: [],
		link: undefined,
		theme: undefined,
		events: []
	};
};

const newVariant = (appId: string, order: number): VariantsInterface => {
	return {
		_id: new Types.ObjectId().toString(),
		appId,
		id: `variant ${order + 1}`,
		url: `variant-${order + 1}`,
		routeSecurityIcons: false,
		passwordProtected: false,
		nonPublicRoutes: false,
		nonPublicContent: false,
		routes: []
	};
};

const newDropdownRoute = (): RoutesItemDropdown => {
	return {
		_id: new Types.ObjectId().toString(),
		public: true,
		display: generateLanguageObject('new dropdown route'),
		item: undefined,
		color: undefined,
		link: undefined,
		text: undefined,
		theme: undefined, // Partial<Theme>,
		events: []
	};
};

type PossibleTypes = RoutesInterface | VariantsInterface;

/**
 * Updates a specific key-value pair in an array of objects.
 *
 * @template T - The type of objects in the data array, which must extend PossibleTypes.
 * @param {T[]} data - The array of objects to be updated.
 * @param {string} selected - The _id of the object to update.
 * @param {string} key - The key of the object that needs to be updated.
 * @param {string | boolean} value - The new value to assign to the specified key.
 * @returns {(T[] | undefined)} A new array with the updated object if the object with the specified _id is found, or undefined if no such object is found.
 *
 * @example
 * const data = [
 *   { _id: '1', name: 'Route 1', value: 'A' },
 *   { _id: '2', name: 'Route 2', value: 'B' }
 * ];
 * const updatedData = updateValueInArray(data, '1', 'value', 'C');
 * // updatedData will be:
 * // [
 * //   { _id: '1', name: 'Route 1', value: 'C' },
 * //   { _id: '2', name: 'Route 2', value: 'B' }
 * // ]
 */
const updateValueInArray = <T extends PossibleTypes>(
	data: T[],
	selected: string,
	key: string,
	value: string | boolean
): T[] | undefined => {
	const foundIndex = data.findIndex(v => v._id === selected);
	if (foundIndex === -1) return;

	const updated = { ...data[foundIndex], [key]: value } as T;
	return [...data.slice(0, foundIndex), updated, ...data.slice(foundIndex + 1)];
};

const updateDisplayInRoute = (
	data: RoutesInterface[],
	selected: string,
	value: string,
	language: string
): RoutesInterface[] | undefined => {
	const foundIndex = data.findIndex(v => v._id === selected);
	if (foundIndex === -1) return;

	const updated = {
		...data[foundIndex],
		display: {
			...data[foundIndex].display,
			[language]: value
		}
	};
	return [...data.slice(0, foundIndex), updated, ...data.slice(foundIndex + 1)];
};

/**
 * Updates a dropdown item in a route object with new data.
 *
 * @param {RoutesInterface[]} data - The array of route objects to be updated.
 * @param {string} [selected] - The _id of the route object to update.
 * @param {number} [index] - The index of the dropdown item within the selected route object.
 * @param {{ [key: string]: unknown }} [newData] - An object containing the new key-value pairs to update in the dropdown item.
 * @returns {(RoutesInterface[] | undefined)} A new array with the updated route object if the object with the specified _id is found, or undefined if no such object or dropdown item is found.
 *
 * @example
 * const data = [
 *   { _id: '1', name: 'Route 1', dropdown: [{ link: 'someLink', theme: 'dark' }] },
 *   { _id: '2', name: 'Route 2', dropdown: [{ link: 'anotherLink', theme: 'light' }] }
 * ];
 * const newData = { link: 'updatedLink', theme: 'updatedTheme' };
 * const updatedData = updateValueInDropdownArray(data, '1', 0, newData);
 * // updatedData will be:
 * // [
 * //   { _id: '1', name: 'Route 1', dropdown: [{ link: 'updatedLink', theme: 'updatedTheme' }] },
 * //   { _id: '2', name: 'Route 2', dropdown: [{ link: 'anotherLink', theme: 'light' }] }
 * // ]
 */
const updateValueInDropdownArray = (
	data: RoutesInterface[],
	selected?: string,
	index?: number,
	newData?: { [key: string]: unknown }
): RoutesInterface[] | undefined => {
	if (!selected || index === undefined || !newData) return;

	const foundIndex = data.findIndex(v => v._id === selected);
	if (foundIndex === -1) return;

	const route = data[foundIndex].dropdown;
	if (!route) return;

	const dropdownItem = route[index];
	const updated: RoutesItemDropdown = { ...dropdownItem, ...newData };

	const updatedRoute = {
		...data[foundIndex],
		dropdown: [...route.slice(0, index), updated, ...route.slice(index + 1)]
	};
	return [...data.slice(0, foundIndex), updatedRoute, ...data.slice(foundIndex + 1)];
};

const updateDisplayInRouteDropdown = (
	data: RoutesInterface[],
	selected: string,
	index: number,
	value: string,
	language: string
): RoutesInterface[] | undefined => {
	if (!selected || index === undefined) return;

	const foundIndex = data.findIndex(v => v._id === selected);
	if (foundIndex === -1) return;

	const route = data[foundIndex].dropdown;
	if (!route) return;

	const dropdownItem = route[index];
	const updated: RoutesItemDropdown = {
		...dropdownItem,
		display: {
			...dropdownItem.display,
			[language]: value
		}
	};

	const updatedRoute = {
		...data[foundIndex],
		dropdown: [...route.slice(0, index), updated, ...route.slice(index + 1)]
	};

	return [...data.slice(0, foundIndex), updatedRoute, ...data.slice(foundIndex + 1)];
};

/**
 * Clears a specific key in a route object and sets another key to a specific value.
 *
 * @param {RoutesInterface[]} data - The array of route objects to be updated.
 * @param {string} selected - The _id of the route object to update.
 * @param {string} key - The key of the route object that needs to be cleared.
 * @param {string} set - The key of the route object that needs to be set to a specific value ('link' or any other value).
 * @returns {(RoutesInterface[] | undefined)} A new array with the updated route object if the object with the specified _id is found, or undefined if no such object is found.
 *
 * @example
 * const data = [
 *   { _id: '1', name: 'Route 1', link: 'someLink', theme: 'dark' },
 *   { _id: '2', name: 'Route 2', link: 'anotherLink', theme: 'light' }
 * ];
 * const updatedData = clearValueInRoute(data, '1', 'link', 'theme');
 * // updatedData will be:
 * // [
 * //   { _id: '1', name: 'Route 1', link: undefined, theme: 'page/' },
 * //   { _id: '2', name: 'Route 2', link: 'anotherLink', theme: 'light' }
 * // ]
 */
const clearValueInRoute = (
	data: RoutesInterface[],
	selected: string,
	key: string,
	set: string
): RoutesInterface[] | undefined => {
	const foundIndex = data.findIndex(v => v._id === selected);
	if (foundIndex === -1) return;

	const updated: RoutesInterface = {
		...data[foundIndex],
		[key]: undefined,
		[set]: set === 'link' ? 'page/' : set === 'theme' ? null : 'empty'
	};

	return [...data.slice(0, foundIndex), updated, ...data.slice(foundIndex + 1)];
};

/**
 * Clears a specific key in a dropdown item of a route object and sets another key to a specific value.
 *
 * @param {RoutesInterface[]} data - The array of route objects to be updated.
 * @param {string} selected - The _id of the route object to update.
 * @param {number} index - The index of the dropdown item within the selected route object.
 * @param {string} key - The key of the dropdown item that needs to be cleared.
 * @param {string} set - The key of the dropdown item that needs to be set to a specific value ('link' or any other value).
 * @returns {(RoutesInterface[] | undefined)} A new array with the updated route object if the object with the specified _id is found, or undefined if no such object or dropdown item is found.
 *
 * @example
 * const data = [
 *   { _id: '1', name: 'Route 1', dropdown: [{ link: 'someLink', theme: 'dark' }] },
 *   { _id: '2', name: 'Route 2', dropdown: [{ link: 'anotherLink', theme: 'light' }] }
 * ];
 * const updatedData = clearValueInDropdownArray(data, '1', 0, 'link', 'theme');
 * // updatedData will be:
 * // [
 * //   { _id: '1', name: 'Route 1', dropdown: [{ link: undefined, theme: 'page/' }] },
 * //   { _id: '2', name: 'Route 2', dropdown: [{ link: 'anotherLink', theme: 'light' }] }
 * // ]
 */
const clearValueInDropdownArray = (
	data: RoutesInterface[],
	selected: string,
	index: number,
	key: string,
	set: string
): RoutesInterface[] | undefined => {
	const foundIndex = data.findIndex(v => v._id === selected);
	if (foundIndex === -1) return;

	const route = data[foundIndex].dropdown;
	if (!route) return;

	const dropdownItem = route[index];

	const updated = {
		...dropdownItem,
		[key]: undefined,
		[set]: set === 'link' ? 'page/' : set === 'theme' ? null : 'empty'
	};

	const updatedRoute = {
		...data[foundIndex],
		dropdown: [...route.slice(0, index), updated, ...route.slice(index + 1)]
	};
	return [...data.slice(0, foundIndex), updatedRoute, ...data.slice(foundIndex + 1)];
};

/**
 * Retrieves a specific dropdown item from a route object.
 *
 * @param {RoutesInterface[]} data - The array of route objects to search through.
 * @param {string} [selected] - The _id of the route object to search in.
 * @param {number} [index] - The index of the dropdown item within the selected route object.
 * @returns {(RoutesItemDropdown | undefined)} The dropdown item if found, or undefined if not found.
 *
 * @example
 * const data = [
 *   { _id: '1', name: 'Route 1', dropdown: [{ link: 'someLink', theme: 'dark' }] },
 *   { _id: '2', name: 'Route 2', dropdown: [{ link: 'anotherLink', theme: 'light' }] }
 * ];
 * const dropdownItem = getDropdownItem(data, '1', 0);
 * // dropdownItem will be:
 * // { link: 'someLink', theme: 'dark' }
 */
const getDropdownItem = (
	data: RoutesInterface[],
	selected?: string,
	index?: number
): RoutesItemDropdown | undefined => {
	if (!selected || index === undefined) return;

	const foundIndex = data.findIndex(v => v._id === selected);
	if (foundIndex === -1) return;

	const route = data[foundIndex].dropdown;
	if (!route) return;

	return route[index];
};

export const VariantsStore = signalStore(
	{ providedIn: 'root' },
	withState(initialState),
	withComputed(state => ({
		editVariant: computed(() => state.variants().find(v => v._id === state.selectVariant())),
		editRoute: computed(() => state.routes().find(v => v._id === state.selectRoute())),
		editRouteDropdown: computed(() => {
			const selected = state.selectRoute();
			const index = state.selectRouteDropdown();
			if (index === undefined) {
				return;
			}
			const route = state.routes().find(v => v._id === selected);
			return route?.dropdown?.[index];
		}),
		currentImageButton: computed(() => {
			const data = state.routes();
			const selected = state.selectRoute();
			const index = state.selectRouteDropdown();
			return getDropdownItem(data, selected, index)?.item;
		}),
		variantList: computed(() => {
			const variants = state.variants();
			return variants.map(variant => ({
				display: variant.id as string,
				value: variant._id as string
			}));
		})
	})),
	withMethods((state, api = inject(ApiService), toast = inject(HotToastService)) => ({
		reset: () => patchState(state, initialState),
		get: rxMethod<string>(appId =>
			appId.pipe(
				tap(() => patchState(state, { isLoading: true })),
				switchMap(appId =>
					api.getObservable<RoutesVariantsDto>(`routes/variants/${appId}`).pipe(
						tapResponse({
							next: data => {
								patchState(state, {
									routes: data.routes,
									variants: data.variants,
									isLoading: false
								});
							},
							error: () => {
								patchState(state, { ...initialState, isLoading: false });
							}
						})
					)
				)
			)
		),
		save: rxMethod<{ appId: string; data: RoutesVariantsDto }>(dto =>
			dto.pipe(
				switchMap(dto =>
					api
						.postObservable<
							RoutesVariantsDto,
							RoutesVariantsDto
						>(`routes/variants/${dto.appId}`, dto.data)
						.pipe(
							toast.observe({
								loading: 'Saving...',
								success: 'Successfully Saved!',
								error: 'Error Saving'
							}),
							tapResponse({
								next: data => {
									patchState(state, {
										routes: data.routes,
										variants: data.variants,
										isLoading: false
									});
								},
								error: () => {
									patchState(state, { isLoading: false });
								}
							})
						)
				)
			)
		),
		removeRoute: rxMethod<{ appId: string; id: string }>(dto =>
			dto.pipe(
				switchMap(dto =>
					api
						.deleteObservable<RoutesVariantsDto>(
							`routes/variants/${dto.appId}/route/${dto.id}`
						)
						.pipe(
							toast.observe({
								loading: 'Deleting route...',
								success: 'Successfully deleted!',
								error: 'Error deleting'
							}),
							tapResponse({
								next: () => {
									const variants = state.variants();
									for (const variant of variants) {
										variant.routes = variant.routes.filter(
											v => v.id._id !== dto.id
										);
									}

									patchState(state, {
										routes: state.routes().filter(v => v._id !== dto.id),
										variants: variants,
										isLoading: false
									});
								},
								error: () => {
									patchState(state, { isLoading: false });
								}
							})
						)
				)
			)
		),
		removeVariant: rxMethod<{ appId: string; id: string }>(dto =>
			dto.pipe(
				switchMap(dto =>
					api
						.deleteObservable<RoutesVariantsDto>(
							`routes/variants/${dto.appId}/variant/${dto.id}`
						)
						.pipe(
							toast.observe({
								loading: 'Deleting variant...',
								success: 'Successfully deleted!',
								error: 'Error deleting'
							}),
							tapResponse({
								next: () => {
									patchState(state, {
										variants: state.variants().filter(v => v._id !== dto.id),
										isLoading: false
									});
								},
								error: () => {
									patchState(state, { isLoading: false });
								}
							})
						)
				)
			)
		),
		removeDropdownRoute: (routeId: string, id: string) => {
			const data = state.routes();
			const variants = state.variants();
			//
			const foundIndex = data.findIndex(v => v._id === routeId);
			if (foundIndex === -1) {
				return;
			}

			const updated: RoutesInterface = { ...data[foundIndex] };
			if (!updated.dropdown) {
				updated.dropdown = [];
			}

			const index = updated.dropdown.findIndex(item => item._id === id);
			if (index !== -1) {
				updated.dropdown.splice(index, 1); // Removes 1 item at the specified index
			}

			for (const variant of variants) {
				for (const route of variant.routes) {
					const routeIndex = route.routes.findIndex(item => item === id);
					if (routeIndex !== -1) {
						route.routes.splice(routeIndex, 1); // Removes 1 item at the specified index
					}
				}
			}

			patchState(state, {
				variants,
				routes: [...data.slice(0, foundIndex), updated, ...data.slice(foundIndex + 1)]
			});
		},
		addNewRoute: (appId: string) => {
			const data = state.routes();
			data.push(newRoute(appId, data.length));

			patchState(state, { routes: data });
		},
		addNewVariant: (appId: string) => {
			const data = state.variants();
			data.push(newVariant(appId, data.length));

			patchState(state, { variants: data });
		},
		setEditRoute: (_id: string | undefined) => patchState(state, { selectRoute: _id }),
		setEditRouteDropdown: (index: number | undefined) =>
			patchState(state, { selectRouteDropdown: index }),
		setEditVariant: (_id: string | undefined) => patchState(state, { selectVariant: _id }),
		clearRoute: (type: 'theme' | 'page') => {
			const data = state.routes();
			const selected = state.selectRoute();
			if (!selected) return;

			const key = type === 'page' ? 'link' : 'theme';
			const set = type === 'page' ? 'theme' : 'link';
			const updatedArr = clearValueInRoute(data, selected, key, set);
			if (updatedArr) {
				patchState(state, { routes: updatedArr });
			}
		},
		addDropdownRoute: () => {
			const data = state.routes();
			const selected = state.selectRoute();
			if (!selected) return;

			const foundIndex = data.findIndex(v => v._id === selected);
			if (foundIndex === -1) return;

			const updated: RoutesInterface = { ...data[foundIndex] };

			updated.dropdown = [
				...(updated.dropdown || []),
				{
					...newDropdownRoute(),
					link: `new-dropdown-${(updated.dropdown?.length || 1) + 1}`,
				}
			];

			patchState(state, {
				routes: [...data.slice(0, foundIndex), updated, ...data.slice(foundIndex + 1)]
			});
		},
		patchRoute: (key: string, value: string) => {
			const data = state.routes();
			const selected = state.selectRoute();
			if (!selected) {
				return;
			}

			const updatedArr = updateValueInArray(data, selected, key, value);
			if (updatedArr) {
				patchState(state, { routes: updatedArr });
			}
		},
		patchRouteDisplay: (value: string, language: string) => {
			const data = state.routes();
			const selected = state.selectRoute();
			if (!selected) {
				return;
			}

			const updatedArr = updateDisplayInRoute(data, selected, value, language);
			if (updatedArr) {
				patchState(state, { routes: updatedArr });
			}
		},
		patchRouteDropdown: (key: string, value: string) => {
			const routes = updateValueInDropdownArray(
				state.routes(),
				state.selectRoute(),
				state.selectRouteDropdown(),
				{ [key]: value }
			);

			if (routes) {
				patchState(state, { routes });
			}
		},
		patchRouteDropdownDisplay: (value: string, language: string) => {
			const index = state.selectRouteDropdown();
			const selected = state.selectRoute();
			if (index === undefined || !selected) {
				return;
			}

			const updatedArr = updateDisplayInRouteDropdown(
				state.routes(),
				selected,
				index,
				value,
				language
			);

			if (updatedArr) {
				patchState(state, { routes: updatedArr });
			}
		},
		patchRouteEvents: (events: Event[]) => {
			const data = state.routes();
			const selected = state.selectRoute();
			if (!selected) return;

			const foundIndex = data.findIndex(v => v._id === selected);
			if (foundIndex === -1) return;

			const updated = { ...data[foundIndex], events };
			patchState(state, {
				routes: [...data.slice(0, foundIndex), updated, ...data.slice(foundIndex + 1)]
			});
		},
		patchRouteDropdownEvents: (events: Event[]) => {
			const routes = updateValueInDropdownArray(
				state.routes(),
				state.selectRoute(),
				state.selectRouteDropdown(),
				{ events: events }
			);
			if (!routes) {
				return;
			}

			patchState(state, { routes });
		},
		addImageButtonToDropdown: (btn: MediaImageButton) => {
			const routes = updateValueInDropdownArray(
				state.routes(),
				state.selectRoute(),
				state.selectRouteDropdown(),
				{ item: btn }
			);
			if (!routes) {
				return;
			}

			patchState(state, { routes });
		},
		patchVariant: (key: string, value: string | boolean) => {
			const data = state.variants();
			const selected = state.selectVariant();
			if (!selected) {
				return;
			}
			const updatedArr = updateValueInArray(data, selected, key, value);
			if (updatedArr) patchState(state, { variants: updatedArr });
		},
		updateRoutes: (routes: RoutesInterface[]) => {
			routes = routes.map((item, index) => ({ ...item, order: index }));
			patchState(state, { routes });
		},
		updateVariants: (variants: VariantsInterface[]) => patchState(state, { variants }),
		addRouteToVariant: (
			event: MatCheckboxChange,
			variant: VariantsInterface,
			route: RoutesInterface
		) => {
			const variants = state.variants();
			const foundIndex = variants.findIndex(v => v._id === variant._id);

			if (foundIndex === -1) return;

			// Clone the found variant
			const updatedVariant = { ...variants[foundIndex] };

			if (!event.checked) {
				updatedVariant.routes = updatedVariant.routes.filter(v => v.id._id !== route._id);
				patchState(state, { variants: [...variants.slice(0, foundIndex), updatedVariant, ...variants.slice(foundIndex + 1)] });
				return;
			}

			if (!Array.isArray(updatedVariant.routes)) {
				updatedVariant.routes = []; // Ensure it's an array
			}

			if (updatedVariant.routes.find(r => r.id._id === route._id)) return;

			// Clone the routes array immutably
			updatedVariant.routes = [...updatedVariant.routes, { id: route, routes: [] }];

			// Update state immutably
			patchState(state, { variants: [...variants.slice(0, foundIndex), updatedVariant, ...variants.slice(foundIndex + 1)] });
		},
		addSubRouteToVariant: (
			event: MatCheckboxChange,
			variant: VariantsInterface,
			route: RoutesInterface,
			subRoute: RoutesItemDropdown
		) => {
			const variants = state.variants();
			const foundIndex = variants.findIndex(v => v._id === variant._id);
			if (foundIndex === -1 || !subRoute._id) return;

			// Clone the variant
			const updatedVariant = { ...variants[foundIndex] };

			// Find the route in the cloned variant
			const routeIndex = updatedVariant.routes.findIndex(r => r.id._id === route._id);
			if (routeIndex === -1) return;

			// Clone the found route
			const updatedRoute = { ...updatedVariant.routes[routeIndex] };

			// Ensure routes array exists and is mutable
			updatedRoute.routes = [...updatedRoute.routes];

			if (!event.checked) {
				// Remove subRoute immutably
				updatedRoute.routes = updatedRoute.routes.filter(item => item !== subRoute._id);
			} else {
				// Add subRoute if not already present
				if (!updatedRoute.routes.includes(subRoute._id)) {
					updatedRoute.routes.push(subRoute._id);
				}
			}

			// Rebuild the variant with updated routes
			updatedVariant.routes = [
				...updatedVariant.routes.slice(0, routeIndex),
				updatedRoute,
				...updatedVariant.routes.slice(routeIndex + 1),
			];

			// Update the state immutably
			patchState(state, {
				variants: [
					...variants.slice(0, foundIndex),
					updatedVariant,
					...variants.slice(foundIndex + 1),
				],
			});
		},
		clearRouteDropdown: (type: 'theme' | 'page') => {
			const data = state.routes();
			const selected = state.selectRoute();
			const index = state.selectRouteDropdown();
			if (!selected || index === undefined) {
				return;
			}
			const key = type === 'page' ? 'link' : 'theme';
			const set = type === 'page' ? 'theme' : 'link';
			const routes: RoutesInterface[] | undefined = clearValueInDropdownArray(
				data,
				selected,
				index,
				key,
				set
			);

			if (!routes) {
				return;
			}

			patchState(state, { routes });
		}
	}))
);
