import { DatePipe } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	Input,
	forwardRef,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { YunoFormsLabelRequired } from '../../pipes/label-required/label-required.pipe';
import {
	YunoFormsDefaultComponent,
	YunoFormsDefaultTemplateComponent
} from '../_default/default.component';
import { YunoFormsValidatorMessagesComponent } from '../_validator-messages/validator-messages.component';

@Component({
    selector: 'yuno-forms-date',
    template: `
		<label class="yuno-form">
			@if (label) {
				<span class="select-none" [innerHtml]="label | labelRequired: required"> </span>
			}
			<!-- Date plus time -->
			@if (dateTime) {
				<input
					class="truncate"
					type="datetime-local"
					[min]="min || '2010-01-01'"
					[max]="max || '2100-01-01'"
					[disabled]="ngControl.disabled"
					[id]="ngControl.name"
					[name]="ngControl.name"
					[placeholder]="placeholder"
					(change)="updateFormControl($event)"
					[value]="getFormControl().value | date: 'yyyy-MM-ddThh:mm'" />
			}

			<!-- Date Only -->
			@if (!dateTime) {
				<input
					class="truncate"
					type="date"
					[min]="min || '2010-01-01'"
					[max]="max || '2100-01-01'"
					[disabled]="ngControl.disabled"
					[id]="ngControl.name"
					[name]="ngControl.name"
					[placeholder]="placeholder"
					(change)="updateFormControl($event)"
					[valueAsDate]="getDateValue()" />
			}

			<yuno-validator-messages [control]="ngControl.control">
				<ng-content></ng-content>
			</yuno-validator-messages>
		</label>
	`,
    providers: [DatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [YunoFormsValidatorMessagesComponent, DatePipe, YunoFormsLabelRequired]
})
export class YunoFormsDateComponent extends YunoFormsDefaultComponent implements AfterViewInit {
	private readonly destroyRef = inject(DestroyRef);
	private readonly datePipe = inject(DatePipe);

	private _dateTime = false;
	@Input() set dateTime(bool: boolean) {
		this._dateTime = bool;
	}

	get dateTime(): boolean {
		return this._dateTime;
	}

	@Input() min: string;
	@Input() max: string;

	// Subscribes to changes of the formControl
	// to update the current View
	ngAfterViewInit(): void {
		// Seems counter intuitive, but we need to patch the value
		// even if it already has a value
		this.getFormControl().patchValue(this.getDateValue());
		this.cdr.detectChanges();

		this.getFormControl()
			.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.cdr.markForCheck();
			});
	}

	// Updates the Original formControl
	updateFormControl(event: Event): void {
		const e = event.target as HTMLInputElement;
		this.getFormControl().patchValue(e.value);
	}

	// Returns a Date value with yyyy-MM-dd format
	// so we can use it inside the Date formControl
	getDateValue(): Date {
		if (!this.getFormControl().value) {
			return new Date();
		}

		// Transforms the Date to a value readable by the Date form input
		const parsedDate = Date.parse(this.getFormControl().value);
		const date = this.datePipe.transform(parsedDate, 'yyyy-MM-dd');

		// If somehow the date is wrong, we return the current date
		if (!date) {
			return new Date();
		}

		// else we return now
		return new Date(date);
	}
}

@Component({
    selector: 'yuno-forms-template-date',
    template: `
		<label class="yuno-form">
			@if (label) {
				<span class="select-none" [innerHtml]="label | labelRequired: required"> </span>
			}
			<!-- Date plus time -->
			@if (dateTime) {
				<input
					class="truncate"
					type="datetime-local"
					[min]="min || '2010-01-01'"
					[max]="max || '2100-01-01'"
					[disabled]="isDisabled"
					[ngModel]="value"
					(ngModelChange)="valueChange($event)"
					[placeholder]="placeholder"
					[value]="value | date: 'yyyy-MM-ddThh:mm'" />
			}

			<!-- Date Only -->
			@if (!dateTime) {
				<input
					class="truncate"
					type="date"
					[min]="min || '2010-01-01'"
					[max]="max || '2100-01-01'"
					[disabled]="isDisabled"
					[ngModel]="value"
					(ngModelChange)="valueChange($event)"
					[placeholder]="placeholder"
					[valueAsDate]="getDateValue()" />
			}
		</label>
	`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [FormsModule, DatePipe, YunoFormsLabelRequired],
    providers: [
        DatePipe,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => YunoFormsDateTemplateComponent),
            multi: true
        }
    ]
})
export class YunoFormsDateTemplateComponent
	extends YunoFormsDefaultTemplateComponent
	implements AfterViewInit
{
	private readonly datePipe = inject(DatePipe);
	private readonly cdr = inject(ChangeDetectorRef);

	private _dateTime = false;
	@Input() set dateTime(bool: boolean) {
		this._dateTime = bool;
	}

	get dateTime(): boolean {
		return this._dateTime;
	}

	@Input() min: string;
	@Input() max: string;

	// Subscribes to changes of the formControl
	// to update the current View
	ngAfterViewInit(): void {
		// Seems counter intuitive, but we need to patch the value
		// even if it already has a value
		this.valueChange(this.getDateValue());
		this.cdr.detectChanges();
	}

	// Returns a Date value with yyyy-MM-dd format
	// so we can use it inside the Date formControl
	getDateValue(): Date {
		if (!this.value || typeof this.value !== 'string') {
			return new Date();
		}

		// Transforms the Date to a value readable by the Date form input
		const parsedDate = Date.parse(this.value);
		const date = this.datePipe.transform(parsedDate, 'yyyy-MM-dd');

		// If somehow the date is wrong, we return the current date
		if (!date) {
			return new Date();
		}

		// else we return now
		return new Date(date);
	}
}
