import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';


@Component({
    selector: 'yuno-navbar-item',
    templateUrl: './navbar-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NavbarItemComponent {
	@HostBinding('class') className = 'flex flex-row items-center justify-center gap-4';

	@Input({ required: true }) link: string[];
	@Input({ required: true }) display: string;
	@Input() active = false;
	@Input() first = false;
	@Input() last = false;

	private _exact = true;
	@Input() set exact(bool: boolean | undefined) {
		this._exact = bool === undefined ? true : bool;
	}

	get exact(): boolean {
		return this._exact;
	}
}
