module.exports = {
  A: {
    A: {
      "2": "K D E F A B iC"
    },
    B: {
      "1": "6 7 8 9 Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I",
      "2": "C L M G N O P"
    },
    C: {
      "1": "6 7 8 9 a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC kC lC",
      "2": "jC IC mC nC",
      "33": "nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z",
      "164": "0 1 2 3 4 5 J MB K D E F A B C L M G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB"
    },
    D: {
      "1": "6 7 8 9 cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC",
      "2": "J MB K D E F A B C L M G N O P NB y",
      "132": "0 1 2 3 4 5 z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB"
    },
    E: {
      "1": "M G tC uC vC RC SC EC wC FC TC UC VC WC XC xC GC YC ZC aC bC cC yC HC dC eC fC zC",
      "2": "J MB K oC PC pC",
      "132": "D E F A B C L qC rC sC QC CC DC"
    },
    F: {
      "1": "PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x",
      "2": "F 0C 1C 2C",
      "132": "0 1 2 3 4 5 G N O P NB y z OB",
      "164": "B C 3C CC gC 4C DC"
    },
    G: {
      "1": "LD MD ND OD RC SC EC PD FC TC UC VC WC XC QD GC YC ZC aC bC cC RD HC dC eC fC",
      "2": "PC 5C hC 6C 7C",
      "132": "E 8C 9C AD BD CD DD ED FD GD HD ID JD KD"
    },
    H: {
      "164": "SD"
    },
    I: {
      "1": "I",
      "2": "IC J TD UD VD WD hC",
      "132": "XD YD"
    },
    J: {
      "132": "D A"
    },
    K: {
      "1": "H",
      "2": "A",
      "164": "B C CC gC DC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "EC"
    },
    P: {
      "1": "0 1 2 3 4 5 J y z ZD aD bD cD dD QC eD fD gD hD iD FC GC HC jD"
    },
    Q: {
      "1": "kD"
    },
    R: {
      "1": "lD"
    },
    S: {
      "164": "mD nD"
    }
  },
  B: 4,
  C: "CSS3 tab-size",
  D: true
};