import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SpritesheetModule } from '@yuno/admin/features/spritesheets/feature/module/spritesheet.module';
import { AppDataComponent } from '@yuno/admin/utils';

import { SpritesheetsRoutingModule } from './spritesheets-routing.module';

@Component({
	selector: 'yuno-admin-spritesheets',
	imports: [SpritesheetModule, SpritesheetsRoutingModule],
	templateUrl: './spritesheets.component.html',
	styleUrls: ['./spritesheets.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpritesheetsComponent extends AppDataComponent {
	onAddSprite() {
		this.router.navigate(['create'], {
			relativeTo: this.route
		});
	}
}
