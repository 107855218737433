@if (service$ | async; as service) {
	@if (service.component) {
		<form [formGroup]="service.component" class="flex flex-col gap-4">
			<section>
				<yuno-forms-title>Custom Text Editor</yuno-forms-title>
				<yuno-forms-span>You can edit the selected custom text below.</yuno-forms-span>
			</section>
			<yuno-forms-text
				class="flex-1"
				formControlName="name"
				placeholder="Custom text name"
				label="Custom text name"></yuno-forms-text>
			<yuno-forms-divider></yuno-forms-divider>
			<section class="flex justify-start">
				<label class="yuno-form">
					<span class="select-none">Content Editors</span>
					<div
						class="pointer-events-auto flex h-[52px] flex-row gap-2 rounded-full border border-gray-300 bg-gray-50 p-1 font-semibold">
						<div
							(click)="codeEditorType = 'code'"
							class="flex cursor-pointer select-none flex-row items-center justify-center rounded-full px-4 py-5 text-sm transition-colors ease-in-out hover:bg-gray-300"
							[ngClass]="{
								'pointer-events-none bg-yuno-blue text-white':
									codeEditorType === 'code'
							}">
							<div class="flex flex-row gap-2">Code</div>
						</div>
						<div
							(click)="codeEditorType = 'visual'"
							class="flex cursor-pointer select-none flex-row items-center justify-center rounded-full px-4 py-5 text-sm transition-colors ease-in-out hover:bg-gray-300"
							[ngClass]="{
								'pointer-events-none bg-yuno-blue text-white':
									codeEditorType === 'visual'
							}">
							<div class="flex flex-row gap-2">Visual Editor</div>
						</div>
						<div
							(click)="codeEditorType = 'data'"
							class="flex cursor-pointer select-none flex-row items-center justify-center rounded-full px-4 py-5 text-sm transition-colors ease-in-out hover:bg-gray-300"
							[ngClass]="{
								'pointer-events-none bg-yuno-blue text-white':
									codeEditorType === 'data'
							}">
							<div class="flex flex-row gap-2">Custom Data</div>
						</div>
					</div>
				</label>
			</section>
			<yuno-admin-annotation type="warning" class="text-sm">
				The Visual Editor can and probably will result in corruption of the data. Use at own
				risk!
			</yuno-admin-annotation>
			<yuno-admin-annotation class="text-sm">
				<div>
					When you want to use a property that includes a language, you can add
					<span class="inline font-semibold">| language</span>
					to the selector. Like this:
				</div>
				<code ngNonBindable class="text-xs">
					{{ properties.properties.display | language }}
				</code>
			</yuno-admin-annotation>
			<yuno-forms-span>
				This is an HTML editor with Nunjucks support. For all Nunjucks templating
				possibilities take a look at the
				<a
					class="text-yuno-blue underline"
					href="https://mozilla.github.io/nunjucks/templating.html"
					rel="noopener"
					target="_blank">
					documentation
				</a>
			</yuno-forms-span>
			@if (codeEditorType === 'code') {
				<yuno-code-editor
					[validate]="true"
					[control]="contentControl"
					[language]="'html'"
					[height]="600"
					(changed)="
						patchCodeContent(service.component.get('content'), $event)
					"></yuno-code-editor>
				<div>
					<span>Preview:</span>
					<div
						class="rounded-md border border-gray-400 p-4"
						[innerHtml]="contentControl.value | renderString: customDataJson"></div>
				</div>
			}
			@if (codeEditorType === 'visual') {
				<div class="flex-1">
					<yuno-forms-html-image-library
						formControlName="content"></yuno-forms-html-image-library>
				</div>
			}
			@if (codeEditorType === 'data') {
				<yuno-code-editor
					[validate]="true"
					[control]="dataControl"
					[height]="600"
					(changed)="
						patchCustomData(service.component.get('customData'), $event)
					"></yuno-code-editor>
			}
		</form>
	}
}
