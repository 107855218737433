import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SpritesheetModule } from '@yuno/admin/features/spritesheets/feature/module/spritesheet.module';
import { AppDataComponent } from '@yuno/admin/utils';

import { SpritesheetsRoutingGlobalModule } from './spritesheets-routing.global.module';

@Component({
	selector: 'yuno-admin-spritesheets-global',
	imports: [SpritesheetModule, SpritesheetsRoutingGlobalModule],
	templateUrl: './spritesheets.global.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpritesheetsGlobalComponent extends AppDataComponent {
	onAddSprite() {
		this.router.navigate(['create'], {
			relativeTo: this.route
		});
	}
}
