import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';

import { MarkerCategoriesFacade } from '@yuno/admin/features/place-markers/data-access';
import { YunoAdminButtonComponent, YunoAdminButtonTableComponent } from '@yuno/admin/ui';
import { JsonFormsModule } from '@yuno/angular-json-forms';
import {
	YunoFormsDividerComponent,
	YunoFormsSelectComponent,
	YunoFormsSpanComponent,
	YunoFormsTitleComponent
} from '@yuno/angular/forms/components';

import { CategoryEditorService } from '../../../category-editor.service';

@Component({
	selector: 'yuno-admin-list-category-style',
	imports: [
		CommonModule,
		YunoFormsDividerComponent,
		YunoFormsTitleComponent,
		YunoFormsSpanComponent,
		YunoFormsSelectComponent,
		ReactiveFormsModule,
		JsonFormsModule,
		YunoAdminButtonComponent,
		YunoAdminButtonTableComponent
	],
	templateUrl: './list-category-style.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListCategoryStyleComponent implements OnInit {
	private readonly destroyRef = inject(DestroyRef);
	private readonly cdr = inject(ChangeDetectorRef);
	private readonly categoryFacade = inject(MarkerCategoriesFacade);

	service = inject(CategoryEditorService);

	@Output() openStyleEditor = new EventEmitter<boolean>();
	@Output() openRulesEditor = new EventEmitter<boolean>();

	hover: number | null;

	@Input() language: string | undefined = 'nl';

	ngOnInit(): void {
		this.onChanges();
	}

	onChanges(): void {
		this.service.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
			this.cdr.markForCheck();
		});
	}

	onAddStyle() {
		this.service.addStyle();
		this.categoryFacade.selectStyle(this.service.styles.controls.length - 1);
		this.openStyleEditor.next(true);
	}

	onSelectStyle(index: number) {
		this.categoryFacade.selectStyle(index);
		this.openStyleEditor.next(true);
	}

	onSetRules() {
		this.openRulesEditor.next(true);
	}

	onRemoveStyle(index: number) {
		this.service.styles.removeAt(index);
	}
}
