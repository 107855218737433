import { NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	HostBinding,
	Input
} from '@angular/core';
import {
	AbstractControl,
	FormsModule,
	ReactiveFormsModule,
	UntypedFormArray,
	UntypedFormControl,
	UntypedFormGroup
} from '@angular/forms';
import { cloneDeep } from 'lodash';
import { ReplaySubject, delay, first } from 'rxjs';

import { columns } from '../../../helpers';
import {
	JsonArrayForm,
	JsonButtonForm,
	JsonFormArrayControl,
	JsonFormControl,
	JsonFormKeyType,
	JsonGroupForm,
	JsonLabel
} from '../../../json-forms.models';
import { FormCreatorService } from '../../../services/form-creator.service';
import { ControlArrayComponent } from '../array-control/array-control.component';
import { ButtonComponent } from '../button/button.component';
import { ControlComponent } from '../control/control.component';
import { DividerComponent } from '../divider/divider.component';
import { GroupComponent } from '../group/group.component';
import { LabelComponent } from '../label/label.component';

@Component({
    selector: 'yuno-json-forms-types-array',
    templateUrl: './array.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgClass,
        ButtonComponent,
        LabelComponent,
        DividerComponent,
        GroupComponent,
        ControlComponent,
        ControlArrayComponent
    ]
})
export class ArrayComponent {
	private item$: ReplaySubject<JsonArrayForm> = new ReplaySubject(1);

	_item: JsonArrayForm;
	_control: UntypedFormArray | undefined;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	_formValue: any[];

	@Input() form: UntypedFormGroup;
	@Input()
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	set formValue(formValue: any[]) {
		if (!formValue) {
			return;
		}

		this._formValue = formValue;

		this.item$.pipe(first(), delay(0)).subscribe(() => {
			for (const value of formValue) {
				this.addItem(value);
			}
		});
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	get formValue(): any[] {
		return this._formValue;
	}

	@Input()
	set item(control: JsonArrayForm) {
		this._item = control;
		// the observable is used by the patcher. To make sure the item object is populated
		this.item$.next(this._item);
		this.className = this.colspan();
	}
	get item(): JsonArrayForm {
		return this._item;
	}

	@Input()
	set control(control: UntypedFormArray | undefined) {
		this._control = control;
	}
	get control(): UntypedFormArray | undefined {
		return this._control;
	}

	@HostBinding('className') hostClass = 'col-span-full';
	className: string;

	formControlPath: string[] = [];
	formControlName: string;

	controls: JsonFormKeyType[][] = [];

	constructor(
		private cdr: ChangeDetectorRef,
		private formCreator: FormCreatorService
	) {}

	colspan(): string {
		return columns(this.item.columns);
	}

	asGroup(obj: JsonFormKeyType): JsonGroupForm {
		return obj as JsonGroupForm;
	}

	asButton(obj: JsonFormKeyType): JsonButtonForm {
		return obj as JsonButtonForm;
	}

	asLabel(obj: JsonFormKeyType): JsonLabel {
		return obj as JsonLabel;
	}

	asArray(obj: JsonFormKeyType): JsonArrayForm {
		return obj as JsonArrayForm;
	}

	asFormControl(obj: JsonFormKeyType): JsonFormControl {
		return obj as JsonFormControl;
	}

	asFormArrayControl(obj: JsonFormKeyType): JsonFormArrayControl {
		return obj as JsonFormArrayControl;
	}

	asFormGroup(obj?: AbstractControl): UntypedFormGroup {
		return obj as UntypedFormGroup;
	}

	addItem(value?: unknown): void {
		const controls = cloneDeep(this.item.controls);

		this.controls.push(controls);
		if (!this.item.options?.fixed) {
			if (!this.control) {
				return;
			}

			for (const control of controls) {
				this.formCreator.createJsonFormArrayControls(control, this.control, value);
			}

			this.cdr.detectChanges();
		}
	}

	removeItemFromArray(index: string): void {
		if (!this.control) {
			return;
		}

		const indexNum = parseInt(index);
		if (isNaN(indexNum)) {
			console.warn(`index is not a valid number: ${indexNum}`);
			return;
		}

		this.control.removeAt(indexNum);
	}

	getFormControl(index: number, key: string): UntypedFormControl {
		return this.control?.controls[index].get(key) as UntypedFormControl;
	}
	getFormArray(index: number, key: string): UntypedFormArray {
		return this.control?.controls[index].get(key) as UntypedFormArray;
	}
	getFormGroup(index: number, key: string): UntypedFormGroup {
		return this.control?.controls[index].get(key) as UntypedFormGroup;
	}
}
