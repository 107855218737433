import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { YunoFormsToggleComponent } from '@yuno/angular/forms/components';

import { PredefinedDefaultEditorComponent } from './_default-editor.component';

@Component({
	selector: 'yuno-admin-predefined-divider-editor',
	imports: [ReactiveFormsModule, YunoFormsToggleComponent],
	template: `
		<form [formGroup]="form">
			<div formGroupName="options" class="flex flex-col gap-4">
				<yuno-forms-toggle class="flex-1" formControlName="line" label="Show line" />
			</div>
		</form>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PredefinedDividerEditorComponent extends PredefinedDefaultEditorComponent {}
