import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	inject
} from '@angular/core';
import { isEqual } from 'lodash';

import { ContentKeys } from '@yuno/admin/features/textfield-pages/feature/content/types';
import { YunoAdminButtonsModule, YunoAdminCardDraggableComponent } from '@yuno/admin/ui';
import { AngularPipesModule } from '@yuno/angular/pipes';
import { TextfieldDataInject, TextfieldDataService } from '@yuno/angular/textfield';
import { TextfieldComponentsTypes } from '@yuno/api/interface';

export interface DuplicatePageComponent {
	type: ContentKeys;
	component: TextfieldComponentsTypes;
}

@Component({
	selector: 'yuno-admin-pages-dynamic',
	imports: [
		CommonModule,
		YunoAdminCardDraggableComponent,
		AngularPipesModule,
		YunoAdminButtonsModule
	],
	templateUrl: './pages-dynamic.component.html',
	styleUrls: ['./pages-dynamic.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PagesDynamicComponent {
	private readonly textfieldDataService = inject(TextfieldDataService);

	_item: TextfieldDataInject;
	_addList = false;

	@Input() set item(data: TextfieldDataInject) {
		if (isEqual(data.data, this._item?.data)) {
			return;
		}

		this._item = data;
		this.componentTitle = data.key;

		this.updatedBy = this.getUpdatedBy();
		this.updatedAt = this.getUpdatedAt();
	}

	get item(): TextfieldDataInject {
		return this._item;
	}

	@Input() active = false;
	@Input() componentTitle: string;

	@Input() set addList(bool: boolean) {
		this._addList = bool;
	}

	get addList(): boolean {
		return this._addList;
	}

	@Input() deleteDisabled = false;
	@Input() duplicateDisabled = false;

	@Output() add = new EventEmitter();
	@Output() clicked = new EventEmitter();
	@Output() duplicate = new EventEmitter<DuplicatePageComponent>();
	@Output() removed = new EventEmitter<boolean>();

	updatedAt: Date;
	updatedBy: string;

	getUpdatedBy() {
		return this.textfieldDataService.getUpdatedBy(this.item);
	}

	getUpdatedAt() {
		return this.textfieldDataService.getUpdatedAt(this.item);
	}

	onDuplicate(event: Event): void {
		event.stopPropagation();

		this.duplicate.emit({
			type: this.item.key,
			component: this.item.data
		});
	}

	onClick(): void {
		this.clicked.emit(true);
	}

	onAdd(): void {
		this.add.emit(true);
	}

	onRemove(event: Event): void {
		event.stopPropagation();

		this.removed.next(true);
	}
}
