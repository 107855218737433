import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	HostBinding,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
	combineLatest,
	debounceTime,
	distinctUntilChanged,
	filter,
	map,
	startWith,
	take,
	tap
} from 'rxjs';

import { DatasetsFacade } from '@yuno/admin/features/datasets';
import { EventFormType, EventsFacade } from '@yuno/admin/features/events';
import { EventsFormsComponent } from '@yuno/admin/features/events/feature/events.forms.component';
import {
	UserRightsMessageComponent,
	YunoAdminButtonComponent,
	YunoAdminCloseButtonComponent,
	YunoAdminSaveButtonComponent,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { EditContainerBreadcrumbsComponent } from '@yuno/admin/ui/edit-container/edit-container-breadcrumbs.component';
import { AppDataComponent, getIdFromRouter, redirectTo } from '@yuno/admin/utils';
import { JsonFormsModule } from '@yuno/angular-json-forms';
import { getFormValidationErrors } from '@yuno/angular/forms';
import {
	YunoFormsColorComponent,
	YunoFormsDividerComponent,
	YunoFormsNumberComponent,
	YunoFormsSelectComponent,
	YunoFormsTextComponent,
	YunoFormsTitleComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';
import { DialogItem, MessageDialogComponent, MessageService } from '@yuno/angular/notifications';
import { Event, LanguageAll, LanguageType, Marker } from '@yuno/api/interface';
import { waitFor } from '@yuno/shared/helpers';

import { MarkerEditorService, MarkersFacade } from '../../../index';
import { MarkersMapViewerService } from '../internal-view/components/map-viewer/map-viewer.service';

@Component({
	selector: 'yuno-admin-marker-map-editor',
	imports: [
		CommonModule,
		YunoFormsDividerComponent,
		YunoFormsTitleComponent,
		YunoFormsTextComponent,
		YunoFormsColorComponent,
		YunoFormsNumberComponent,
		YunoFormsSelectComponent,
		YunoFormsToggleComponent,
		JsonFormsModule,
		FormsModule,
		ReactiveFormsModule,
		YunoEditContainerModule,
		YunoAdminSaveButtonComponent,
		YunoAdminCloseButtonComponent,
		YunoAdminButtonComponent,
		UserRightsMessageComponent,
		EventsFormsComponent,
		EditContainerBreadcrumbsComponent
	],
	animations: [
		trigger('slideIn', [
			state(
				'deactive',
				style({
					transform: 'translateX(100%) translateX(40px)'
				})
			),
			state('active', style({ transform: 'translateX(0)' })),
			transition('deactive => active', [
				animate(`300ms cubic-bezier(0.4, 0, 0.2, 1)`, style({ transform: 'translateX(0)' }))
			]),
			transition('active => deactive', [
				animate(
					`300ms cubic-bezier(0.4, 0, 0.2, 1)`,
					style({ transform: 'translateX(100%)' })
				)
			]),
			transition('* => void', [
				animate(
					`300ms cubic-bezier(0.4, 0, 0.2, 1)`,
					style({ transform: 'translateX(100%)' })
				)
			])
		])
	],
	styleUrls: ['./marker-editor.component.scss'],
	templateUrl: './marker-editor.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkerEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	@HostBinding('class.fixed') isPopup = false;

	private readonly dialog = inject(MatDialog);
	private dialogRef: MatDialogRef<MessageDialogComponent>;

	private message = inject(MessageService);

	private readonly cdr = inject(ChangeDetectorRef);
	private readonly destroyRef = inject(DestroyRef);
	private readonly markerFacade = inject(MarkersFacade);
	private readonly eventsFacade = inject(EventsFacade);
	private readonly datasetFacade = inject(DatasetsFacade);
	private readonly mapService = inject(MarkersMapViewerService);
	readonly service = inject(MarkerEditorService);

	currentLanguage: LanguageType = 'nl';
	languages = LanguageAll;
	readonly = !this.minimalAppRole(this.userRoles.ADMIN);
	currentRoute: 'map' | 'list' = 'map';

	private _activeState: 'active' | 'deactive' = 'deactive';

	get slideInToggle(): string {
		return this._activeState || 'deactive';
	}

	// Stores a original copy of the Marker
	originalData: Partial<Marker>;
	changesMade = 0;
	active: EventFormType;

	// Toggles
	toggleLocation = false;
	disabled = true;

	data$ = combineLatest({
		dragEnd: this.mapService.dragEnd$.pipe(
			startWith(undefined),
			tap(lngLat => {
				lngLat && this.service.coordinates.patchValue(lngLat.toArray());
			})
		),
		selected: this.markerFacade.selectedMarkers$.pipe(
			filter(e => !!e),
			map(e => e as Partial<Marker>),
			distinctUntilChanged((prev, curr) => {
				// When we first have no id, then we have
				// we where in creation mode
				// therefore we can route to the newly created marker
				if (!prev._id && curr._id) {
					this.markerFacade.removeEmptyMarker();
					redirectTo(this.route, this.router, ['edit', `${curr._id}`]);
					return false;
				}

				return prev._id === curr._id;
			}),
			tap(data => {
				this.service.resetDefaultValues();
				this.originalData = data as Marker;

				this.editContainerBreadcrumbsService.addRoute('data', data.properties?.id);

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				this.service.form.patchValue(this.originalData as any);
				this.service.addEvents(data.events);

				// resets changes made
				this.changesMade = -1;

				setTimeout(() => {
					this._activeState = 'active';
					this.cdr.detectChanges();
				});
			})
		),
		toggle: this.eventsFacade.toggle$.pipe(startWith(false)),
		parent: this.datasetFacade.parent$
	});

	async ngOnInit(): Promise<void> {
		this.service.createFormGroup();
		if (this.router.url.includes('create')) {
			await this.createNewMarker();
		} else {
			this.markerFacade.select(await getIdFromRouter(this.route));
		}

		if (this.router.url.includes('list')) {
			this.currentRoute = 'list';
		}

		this.onChanges();
		this.onRouting();
		this.checkAccess();

		await this.mapService.setPadding({ right: 480, top: 30, bottom: 30, left: 30 });
	}

	checkAccess(): void {
		if (this.minimalAppRole(this.userRoles.EDITOR)) {
			this.disabled = false;
			this.service.form.enable();
		} else {
			// When no Editor rights disable the form
			this.disabled = true;
			this.service.form.disable();
		}
	}

	onChanges(): void {
		this.service.form.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
			.subscribe(() => {
				this.markerFacade.updateSelect(this.service.form.getRawValue() as Marker);
				this.changesMade++;
				if (this.isPopup) {
					this.datasetFacade.getParentRoute(this.allRouteParams);
				}
			});
	}

	onRouting(): void {
		this.route.data.pipe(take(1)).subscribe(data => {
			this.isPopup = data['popup'] || false;
		});
	}

	onSave() {
		this.service.form.markAllAsTouched();
		if (this.service.form.valid) {
			this.markerFacade.save();
		} else {
			console.warn('Form Errors:', getFormValidationErrors(this.service.form));
			this.message.sendToast('One or more of the required inputs are missing!', 'error');
		}
	}

	onReset(): void {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		this.service.form.patchValue(this.originalData as any);
		// resets changes made
		this.changesMade = -1;
	}

	/* 	navigates back to the Dataset page */
	async onClose(): Promise<void> {
		if (this.changesMade < 1) {
			await this.doClose();
			return;
		}

		const data: DialogItem = {
			title: 'Confirm',
			message: 'Changes are made, want to Close without Saving?',
			buttons: [
				{
					key: 'Yes',
					type: 'danger',
					confirm: true
				},
				{
					key: 'Cancel',
					type: 'secondary'
				}
			],
			confirm: 'Confirmed'
		};

		this.dialogRef = this.dialog.open(MessageDialogComponent, {
			data: data
		});

		this.dialogRef
			.afterClosed()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((confirmed: boolean) => {
				if (confirmed) {
					this.doClose();
				}
			});
	}

	private async doClose(): Promise<void> {
		this._activeState = 'deactive';

		this.toggleLocation = false;
		this.mapService.setDraggable(false);

		await waitFor(300);

		if (this.isPopup) {
			redirectTo(this.route, this.router);
		} else {
			const routerLink = this.route?.parent?.snapshot.pathFromRoot
				.map(s => s.url)
				.reduce((a, e) => {
					if (a.length + e.length !== this.route?.parent?.snapshot.pathFromRoot.length) {
						return a.concat(e);
					}
					return a;
				})
				.map(s => s.path);

			this.router.navigate(routerLink as string[]);
		}

		this.markerFacade.clearSelect();
	}

	async createNewMarker(): Promise<void> {
		this._activeState = 'active';
		this.service.coordinates.patchValue(await this.mapService.getCenter());
		this.toggleDraggable(true);
	}

	toggleDraggable(bool: boolean): void {
		this.toggleLocation = bool;
		this.mapService.setDraggable(bool);
	}

	onToggleEvents(key: 'onClick' | 'onMouseMove'): void {
		let events: Event[];
		if (key === 'onClick') {
			events = this.service.onClickEvents.getRawValue() as Event[];
		} else {
			events = this.service.onMouseMoveEvents.getRawValue() as Event[];
		}
		this.active = key;
		this.eventsFacade.toggleEvents(true);
		this.eventsFacade.updateEvents(events);
		this.eventsFacade.setKey(key);
	}

	ngOnDestroy() {
		this.markerFacade.clearSelect();
		this.editContainerBreadcrumbsService.removeRouteName(this.originalData?.properties?.id);
	}
}
