import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { AsyncSubject, combineLatest, map, startWith } from 'rxjs';

import { ChooseTemplateComponent } from '@yuno/admin/features/templates';
import { YunoAdminButtonsModule, YunoAdminTableComponent } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { Textfield, TextfieldComponents } from '@yuno/api/interface';

import { TextfieldFacade } from '../../../data-access';

@Component({
	imports: [
		YunoAdminButtonsModule,
		YunoAdminTableComponent,
		RouterModule,
		AsyncPipe,
		ChooseTemplateComponent
	],
	selector: 'yuno-admin-textfield-client',
	templateUrl: './textfields.component.html',
	styleUrls: ['./textfields.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextfieldsClientComponent extends AppDataComponent implements OnInit {
	private readonly textfieldFacade = inject(TextfieldFacade);
	$presets = toSignal(this.textfieldFacade.getPresets());

	/* 	an observerable that checks when legends are collected 	*/
	private _textfieldsChecked = new AsyncSubject<boolean>();

	data$ = combineLatest({
		textfields: this.textfieldFacade.allTextfields$.pipe(
			map(data => {
				let textfields = data;
				if (data) {
					textfields = data
						.filter(text => text.public)
						.map(item => {
							return item;
						}) as { [key: string]: unknown }[];
				}
				return textfields as { [key: string]: unknown }[];
			})
		),
		selected: this.textfieldFacade.selectedTextfield$,
		checked: this._textfieldsChecked.asObservable().pipe(startWith(false))
	});

	texts: { [key: string]: unknown }[];
	selected: Textfield | undefined;
	masterToggle: 'true' | 'indeterminate' | 'false';
	toggleTemplates = false;

	ngOnInit(): void {
		this.textfieldFacade.get();
	}

	onSelectTemplate(bool: boolean) {
		this.toggleTemplates = bool;
	}

	onCreate(components?: TextfieldComponents[]) {
		this.textfieldFacade.clearSelect();
		this.router.navigate(['create'], {
			relativeTo: this.route
		});
		this.textfieldFacade.setTemplate(components);
		this.toggleTemplates = false;
	}

	onCreateFromPreset(id?: string) {
		this.textfieldFacade.clearSelect();
		this.router.navigate(['create'], {
			relativeTo: this.route
		});
		this.textfieldFacade.setPreset(id);
		this.toggleTemplates = false;
	}

	onSelectTextfield(row: Partial<Textfield>): void {
		this.router.navigate(['edit', row._id], {
			relativeTo: this.route
		});
	}
}
