import { Injectable, NgZone, inject } from '@angular/core';
import MapboxDraw, {
	DrawCreateEvent,
	DrawDeleteEvent,
	DrawSelectionChangeEvent,
	DrawUpdateEvent
} from '@mapbox/mapbox-gl-draw';
import { Feature, GeoJsonProperties, Geometry, Position } from 'geojson';
import { CircleMode, DirectMode, DragCircleMode, SimpleSelectMode } from 'mapbox-gl-draw-circle';
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';
import { Map } from 'maplibre-gl';
import { ReplaySubject } from 'rxjs';

export type CustomDrawMode =
	| 'draw_line_string'
	| 'draw_polygon'
	| 'draw_point'
	| 'simple_select'
	| 'direct_select'
	| 'static'
	| 'draw_rectangle'
	| 'draw_circle';

@Injectable({
	providedIn: 'root'
})
export class DrawMapService {
	private zone = inject(NgZone);

	private map?: Map;

	private _drawMode = new ReplaySubject<CustomDrawMode | null>(1);
	private _drawCreate = new ReplaySubject<DrawCreateEvent | null>(1);
	private _drawDelete = new ReplaySubject<DrawDeleteEvent | null>(1);
	private _drawUpdate = new ReplaySubject<DrawUpdateEvent | null>(1);
	private _drawSelect = new ReplaySubject<DrawSelectionChangeEvent | null>(1);

	drawMode$ = this._drawMode.asObservable();
	drawCreate$ = this._drawCreate.asObservable();
	drawDelete$ = this._drawDelete.asObservable();
	drawUpdate$ = this._drawUpdate.asObservable();
	drawSelect$ = this._drawSelect.asObservable();

	draw?: MapboxDraw;

	addDrawFeatures(map: Map): void {
		this.map = map;

		/*
			Considering that MapLibre is an open - source fork of the Mapbox rendering library, many of the Mapbox plugins can be used in conjunction with MapLibre without any issue in JavaScript.
			However, since MapLibre is a TypeScript library in the same spirit, if you wish to use some of the popular Mapbox plugins in your TypeScript application you will likely run into the TypeScript compiler throwing a lot of type inference errors.
			This is because these plugins were developed with Mapbox solely in mind.
		*/
		// @ts-ignore - see explanation above
		if (this.draw && this.map?.hasControl(this.draw)) {
			return;
		}

		this.draw = new MapboxDraw({
			displayControlsDefault: true,
			modes: {
				...MapboxDraw.modes,
				draw_rectangle: DrawRectangle,
				draw_circle: CircleMode,
				drag_circle: DragCircleMode,
				direct_select: DirectMode,
				simple_select: SimpleSelectMode
			}
		});

		// @ts-ignore - see explanation above
		this.map?.addControl(this.draw);
		this.map?.on('draw.create', e => this.zone.run(() => this.drawCreate(e)));
		this.map?.on('draw.update', e => this.zone.run(() => this.drawUpdate(e)));
		this.map?.on('draw.delete', e => this.zone.run(() => this.drawDelete(e)));
		this.map?.on('draw.selectionchange', e => this.zone.run(() => this.drawSelect(e)));
		this.map?.on('draw.modechange', e => this.zone.run(() => this.drawMode(e)));
	}

	// Create separate functions to be able to disable them when the map unloads
	drawCreate(e: DrawCreateEvent | null): void {
		this._drawCreate.next(e);
	}

	// Create separate functions to be able to disable them when the map unloads
	drawUpdate(e: DrawUpdateEvent | null): void {
		this._drawUpdate.next(e);
	}

	// Create separate functions to be able to disable them when the map unloads
	drawDelete(e: DrawDeleteEvent | null): void {
		this._drawDelete.next(e);
	}

	// Create separate functions to be able to disable them when the map unloads
	drawSelect(e: DrawSelectionChangeEvent | null): void {
		this._drawSelect.next(e);
	}

	// Create separate functions to be able to disable them when the map unloads
	drawMode(e: CustomDrawMode | null): void {
		this._drawMode.next(e);
	}

	addPolygon(id: string, polygon: Position[]): void {
		if (!this.draw) {
			return;
		}

		const feature: Feature<Geometry, GeoJsonProperties> = {
			id: id,
			type: 'Feature',
			properties: {
				'fill-color': '#3bb2d0'
			},
			geometry: {
				coordinates: [polygon],
				type: 'Polygon'
			}
		};

		this.draw?.deleteAll();
		this.draw?.add(feature);
	}

	/**
	 * We need to destroy the draw instance when we remove a Map from the view
	 * we also reset all the observables to Null
	 */
	deleteDrawFunctions(): void {
		this.draw = undefined;
		this._drawMode.next(null);
		this._drawCreate.next(null);
		this._drawDelete.next(null);
		this._drawUpdate.next(null);
		this._drawSelect.next(null);
		if (this.map) {
			this.map?.off('draw.create', e => this.zone.run(() => this.drawCreate(e)));
			this.map?.off('draw.update', e => this.zone.run(() => this.drawUpdate(e)));
			this.map?.off('draw.delete', e => this.zone.run(() => this.drawDelete(e)));
			this.map?.off('draw.selectionchange', e => this.zone.run(() => this.drawSelect(e)));
			this.map?.off('draw.modechange', e => this.zone.run(() => this.drawMode(e)));

			// remove map as last to not casue conflicts
			this.map = undefined;
		}
	}

	/**
	 * Removes all current drawings from the map
	 * @returns
	 */
	removeDrawFeatures(): void {
		/*
			Considering that MapLibre is an open - source fork of the Mapbox rendering library, many of the Mapbox plugins can be used in conjunction with MapLibre without any issue in JavaScript.
			However, since MapLibre is a TypeScript library in the same spirit, if you wish to use some of the popular Mapbox plugins in your TypeScript application you will likely run into the TypeScript compiler throwing a lot of type inference errors.
			This is because these plugins were developed with Mapbox solely in mind.
		*/
		// @ts-ignore - see explanation above
		if (!this.draw || !this.map || !this.map?.hasControl(this.draw)) {
			return;
		}
		this.draw?.deleteAll();
		this._drawMode.next(null);
		this._drawCreate.next(null);
		this._drawDelete.next(null);
		this._drawUpdate.next(null);
		this._drawSelect.next(null);

		// remove map as last to not casue conflicts
		this.map = undefined;
	}

	updateDrawColor(color: string): void {
		// @ts-ignore - see explanation above
		if (!this.draw || !this.map || !this.map?.hasControl(this.draw)) {
			return;
		}

		const fillCold = this.map?.getLayer('gl-draw-polygon-fill-inactive.cold');
		const fillHot = this.map?.getLayer('gl-draw-polygon-fill-inactive.hot');
		const strokeCold = this.map?.getLayer('gl-draw-polygon-stroke-inactive.cold');
		const strokeHot = this.map?.getLayer('gl-draw-polygon-stroke-inactive.hot');

		if (fillCold) {
			this.map?.setPaintProperty('gl-draw-polygon-fill-inactive.cold', 'fill-color', color);
		}
		if (fillHot) {
			this.map?.setPaintProperty('gl-draw-polygon-fill-inactive.hot', 'fill-color', color);
		}
		if (strokeCold) {
			this.map?.setPaintProperty('gl-draw-polygon-stroke-inactive.cold', 'line-color', color);
		}
		if (strokeHot) {
			this.map?.setPaintProperty('gl-draw-polygon-stroke-inactive.hot', 'line-color', color);
		}
	}

	updateDrawOpacity(opacity: number): void {
		// @ts-ignore - see explanation above
		if (!this.draw || !this.map || !this.map?.hasControl(this.draw)) {
			return;
		}

		const fillCold = this.map?.getLayer('gl-draw-polygon-fill-inactive.cold');
		const fillHot = this.map?.getLayer('gl-draw-polygon-fill-inactive.hot');

		if (fillCold) {
			this.map?.setPaintProperty(
				'gl-draw-polygon-fill-inactive.cold',
				'fill-opacity',
				opacity
			);
		}
		if (fillHot) {
			this.map?.setPaintProperty(
				'gl-draw-polygon-fill-inactive.hot',
				'fill-opacity',
				opacity
			);
		}
	}
}
