module.exports = {
  A: {
    A: {
      "1": "A B",
      "2": "K D E F iC"
    },
    B: {
      "1": "6 7 8 9 C L M G N O P Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I"
    },
    C: {
      "1": "6 7 8 9 WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC kC lC",
      "2": "jC IC mC",
      "260": "0 1 2 3 4 5 N O P NB y z OB PB QB RB SB TB UB VB",
      "292": "J MB K D E F A B C L M G nC"
    },
    D: {
      "1": "4 5 6 7 8 9 OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC",
      "33": "0 1 2 3 A B C L M G N O P NB y z",
      "548": "J MB K D E F"
    },
    E: {
      "1": "SC EC wC FC TC UC VC WC XC xC GC YC ZC aC bC cC yC HC dC eC fC zC",
      "2": "oC PC",
      "260": "D E F A B C L M G qC rC sC QC CC DC tC uC vC RC",
      "292": "K pC",
      "804": "J MB"
    },
    F: {
      "1": "0 1 2 3 4 5 G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x DC",
      "2": "F B 0C 1C 2C 3C",
      "33": "C 4C",
      "164": "CC gC"
    },
    G: {
      "1": "SC EC PD FC TC UC VC WC XC QD GC YC ZC aC bC cC RD HC dC eC fC",
      "260": "E 8C 9C AD BD CD DD ED FD GD HD ID JD KD LD MD ND OD RC",
      "292": "6C 7C",
      "804": "PC 5C hC"
    },
    H: {
      "2": "SD"
    },
    I: {
      "1": "I XD YD",
      "33": "J WD hC",
      "548": "IC TD UD VD"
    },
    J: {
      "1": "A",
      "548": "D"
    },
    K: {
      "1": "H DC",
      "2": "A B",
      "33": "C",
      "164": "CC gC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "1": "A B"
    },
    O: {
      "1": "EC"
    },
    P: {
      "1": "0 1 2 3 4 5 J y z ZD aD bD cD dD QC eD fD gD hD iD FC GC HC jD"
    },
    Q: {
      "1": "kD"
    },
    R: {
      "1": "lD"
    },
    S: {
      "1": "mD nD"
    }
  },
  B: 4,
  C: "CSS Gradients",
  D: true
};