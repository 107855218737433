import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';

import { LayoutFacade } from '@yuno/admin/features/layout';

import { SideOptionsMenuComponent } from './side-options-menu.component';

@Component({
	selector: 'yuno-admin-side-options-container',
	template: `
		<div class="block h-full w-full overflow-hidden">
			<div class="flex h-full flex-row overflow-hidden">
				<section
					class="flex h-full w-full flex-row justify-center overflow-x-auto p-4 shadow-sm">
					<div
						class="w-full max-w-screen-xl flex-1"
						[ngClass]="{
							'py-14': listOnly
						}">
						<ng-content />
					</div>
				</section>
				@if ($sideoptions(); as sideOptions) {
					@if (sideOptions && sideOptions.visible) {
						<yuno-admin-side-options-menu [(opened)]="sideOptions.open" />
					}
				}
			</div>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgClass, SideOptionsMenuComponent],
	styleUrls: ['./side-options-menu.component.scss']
})
export class SideOptionsContainerComponent {
	@Input() listOnly = false;

	private readonly service = inject(LayoutFacade);
	$sideoptions = this.service.$sideoptions;
}
