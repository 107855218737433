import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	Input,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';

import { YunoSelectFieldModule } from '@yuno/admin/ui';
import { YunoFormsSpanComponent, YunoFormsTitleComponent } from '@yuno/angular/forms/components';
import { LanguagePipe } from '@yuno/angular/pipes';
import {
	MarkerCategory,
	MarkerCategoryInputs,
	MarkerCustomOptions,
	MarkerPresetOptions
} from '@yuno/api/interface';

import { CategoryEditorService } from '../../../category-editor.service';

@Component({
	selector: 'yuno-admin-category-input-editor',
	imports: [
		YunoSelectFieldModule,
		YunoFormsTitleComponent,
		YunoFormsSpanComponent,
		ReactiveFormsModule,
		LanguagePipe
	],
	templateUrl: './category-input-editor.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryInputEditorComponent implements OnInit {
	private readonly destroyRef = inject(DestroyRef);
	private readonly cdr = inject(ChangeDetectorRef);
	private readonly service = inject(CategoryEditorService);
	private inputs: string[];
	private customInputs: string[];
	@Input() language: string | undefined = 'nl';

	inputFields: Partial<MarkerPresetOptions>[] = [
		{
			type: 'title',
			label: {
				nl: 'Titel',
				'nl-BE': 'Titel',
				de: 'Titel',
				en: 'Title',
				fr: 'Titre',
				es: 'Título',
				it: 'Titolo',
				da: 'Titel'
			},
			placeholder: {
				nl: 'voeg een titel toe',
				'nl-BE': 'voeg een titel toe',
				de: 'einen Titel hinzufügen',
				en: 'add a title',
				fr: 'ajouter un titre',
				es: 'agregar un título',
				it: 'aggiungi un titolo',
				da: 'tilføj en titel'
			}
		},
		{
			type: 'status',
			label: {
				nl: 'Status',
				'nl-BE': 'Status',
				de: 'Status',
				en: 'Status',
				fr: 'Statut',
				es: 'Estado',
				it: 'Stato',
				da: 'Status'
			},
			placeholder: {
				nl: 'selecteer een status',
				'nl-BE': 'selecteer een status',
				de: 'Status auswählen',
				en: 'select a status',
				fr: 'sélectionner un statut',
				es: 'seleccionar un estado',
				it: 'seleziona uno stato',
				da: 'vælg en status'
			},
			options: { list: [] }
		},
		{
			type: 'startDate',
			label: {
				nl: 'Startdatum',
				'nl-BE': 'Startdatum',
				de: 'Startdatum',
				en: 'Start Date',
				fr: 'Date de début',
				es: 'Fecha de inicio',
				it: 'Data di inizio',
				da: 'Startdato'
			},
			placeholder: {
				nl: 'kies een datum',
				'nl-BE': 'kies een datum',
				de: 'Datum wählen',
				en: 'pick a date',
				fr: 'choisir une date',
				es: 'elegir una fecha',
				it: 'scegli una data',
				da: 'vælg en dato'
			}
		},
		{
			type: 'endDate',
			label: {
				nl: 'Einddatum',
				'nl-BE': 'Einddatum',
				de: 'Enddatum',
				en: 'End Date',
				fr: 'Date de fin',
				es: 'Fecha de finalización',
				it: 'Data di fine',
				da: 'Slutdato'
			},
			placeholder: {
				nl: 'kies een datum',
				'nl-BE': 'kies een datum',
				de: 'Datum wählen',
				en: 'pick a date',
				fr: 'choisir une date',
				es: 'elegir una fecha',
				it: 'scegli una data',
				da: 'vælg en dato'
			}
		},
		{
			type: 'location',
			label: {
				nl: 'Locatie',
				'nl-BE': 'Locatie',
				de: 'Standort',
				en: 'Location',
				fr: 'Lieu',
				es: 'Ubicación',
				it: 'Posizione',
				da: 'Placering'
			},
			placeholder: {
				nl: 'kies een locatie',
				'nl-BE': 'kies een locatie',
				de: 'Standort wählen',
				en: 'pick a location',
				fr: 'choisir un lieu',
				es: 'elegir una ubicación',
				it: 'scegli una posizione',
				da: 'vælg en placering'
			}
		},
		{
			type: 'number',
			label: {
				nl: 'Nummer',
				'nl-BE': 'Nummer',
				de: 'Nummer',
				en: 'Number',
				fr: 'Numéro',
				es: 'Número',
				it: 'Numero',
				da: 'Nummer'
			},
			placeholder: {
				nl: 'kies een nummer',
				'nl-BE': 'kies een nummer',
				de: 'eine Nummer wählen',
				en: 'pick a number',
				fr: 'choisir un numéro',
				es: 'elegir un número',
				it: 'scegli un numero',
				da: 'vælg et nummer'
			}
		},
		{
			type: 'theme',
			label: {
				nl: 'Thema',
				'nl-BE': 'Thema',
				de: 'Thema',
				en: 'Theme',
				fr: 'Thème',
				es: 'Tema',
				it: 'Tema',
				da: 'Tema'
			},
			placeholder: {
				nl: 'voeg een thema toe',
				'nl-BE': 'voeg een thema toe',
				de: 'ein Thema hinzufügen',
				en: 'add a theme',
				fr: 'ajouter un thème',
				es: 'agregar un tema',
				it: 'aggiungi un tema',
				da: 'tilføj et tema'
			},
			options: { list: [] }
		},
		{
			type: 'type',
			label: {
				nl: 'Type',
				'nl-BE': 'Type',
				de: 'Typ',
				en: 'Type',
				fr: 'Type',
				es: 'Tipo',
				it: 'Tipo',
				da: 'Type'
			},
			placeholder: {
				nl: 'selecteer type',
				'nl-BE': 'selecteer type',
				de: 'Typ auswählen',
				en: 'select type',
				fr: 'sélectionner le type',
				es: 'seleccionar tipo',
				it: 'seleziona il tipo',
				da: 'vælg type'
			},
			options: { list: [] }
		},
		{
			type: 'minZoom',
			label: {
				nl: 'Min. Zoom',
				'nl-BE': 'Min. Zoom',
				de: 'Min. Zoom',
				en: 'Min. Zoom',
				fr: 'Min. Zoom',
				es: 'el Zoom mínimo',
				it: 'Zoom Min.',
				da: 'Min. Zoom'
			},
			placeholder: {
				nl: 'stel Min. Zoom in',
				'nl-BE': 'stel Min. Zoom in',
				de: 'Min. Zoom einstellen',
				en: 'set Min. Zoom',
				fr: 'définir Min. Zoom',
				es: 'establecer el Zoom mínimo',
				it: 'imposta Zoom Min.',
				da: 'indstil Min. Zoom'
			}
		},
		{
			type: 'maxZoom',
			label: {
				nl: 'Max. Zoom',
				'nl-BE': 'Max. Zoom',
				de: 'Max. Zoom',
				en: 'Max. Zoom',
				fr: 'Max. Zoom',
				es: 'el Zoom máximo',
				it: 'Zoom Max.',
				da: 'Max. Zoom'
			},
			placeholder: {
				nl: 'stel Max. Zoom in',
				'nl-BE': 'stel Max. Zoom in',
				de: 'Max. Zoom einstellen',
				en: 'set Max. Zoom',
				fr: 'définir Max. Zoom',
				es: 'establecer el Zoom máximo',
				it: 'imposta Zoom Max.',
				da: 'indstil Max. Zoom'
			}
		},
		{
			type: 'alignment',
			label: {
				nl: 'Uitlijning',
				'nl-BE': 'Uitlijning',
				de: 'Ausrichtung',
				en: 'Alignment',
				fr: "l'Alignement",
				es: 'Alineación',
				it: "l'Allineamento",
				da: 'Justering'
			},
			placeholder: {
				nl: 'stel uitlijning in',
				'nl-BE': 'stel uitlijning in',
				de: 'Ausrichtung festlegen',
				en: 'set alignment',
				fr: "définir l'alignement",
				es: 'establecer alineación',
				it: "imposta l'allineamento",
				da: 'indstil justering'
			}
		},
		{
			type: 'rotation',
			label: {
				nl: 'Icoonrotatie',
				'nl-BE': 'Icoonrotatie',
				de: 'Icon-Rotation',
				en: 'Icon Rotation',
				fr: "Rotation de l'icône",
				es: 'Rotación de ícono',
				it: "Rotazione dell'icona",
				da: 'Ikonrotation'
			},
			placeholder: {
				nl: '0',
				'nl-BE': '0',
				de: '0',
				en: '0',
				fr: '0',
				es: '0',
				it: '0',
				da: '0'
			}
		},
		{
			type: 'category',
			label: {
				nl: 'Categorie',
				'nl-BE': 'Categorie',
				de: 'Kategorie',
				en: 'Category',
				fr: 'Catégorie',
				es: 'Categoría',
				it: 'Categoria',
				da: 'Kategori'
			},
			placeholder: {
				nl: 'selecteer categorie',
				'nl-BE': 'selecteer categorie',
				de: 'Kategorie auswählen',
				en: 'select category',
				fr: 'sélectionner une catégorie',
				es: 'seleccionar categoría',
				it: 'seleziona categoria',
				da: 'vælg kategori'
			},
			options: { list: [] }
		},
		{
			type: 'impact',
			label: {
				nl: 'Impact',
				'nl-BE': 'Impact',
				de: 'Auswirkung',
				en: 'Impact',
				fr: "l'Impact",
				es: 'Impacto',
				it: 'Impatto',
				da: 'Påvirkning'
			},
			placeholder: {
				nl: 'selecteer impact',
				'nl-BE': 'selecteer impact',
				de: 'Auswirkung auswählen',
				en: 'select impact',
				fr: "sélectionner l'impact",
				es: 'seleccionar impacto',
				it: 'seleziona impatto',
				da: 'vælg påvirkning'
			},
			options: { list: [] }
		},
		{
			type: 'size',
			label: {
				nl: 'Grootte',
				'nl-BE': 'Grootte',
				de: 'Größe',
				en: 'Size',
				fr: 'la Taille',
				es: 'Tamaño',
				it: 'Dimensione',
				da: 'Størrelse'
			},
			placeholder: {
				nl: 'selecteer grootte',
				'nl-BE': 'selecteer grootte',
				de: 'Größe auswählen',
				en: 'select size',
				fr: 'sélectionner la taille',
				es: 'seleccionar tamaño',
				it: 'seleziona dimensione',
				da: 'vælg størrelse'
			},
			options: { list: [] }
		}
	];

	customInputFields: Partial<MarkerCustomOptions>[] = [
		{
			type: 'tinymce',
			label: {
				nl: 'Visuele Teksteditor',
				'nl-BE': 'Visuele Teksteditor',
				de: 'Visueller Texteditor',
				en: 'Visual Text editor',
				fr: 'Éditeur de Texte Visuel',
				es: 'Editor de Texto Visual',
				it: 'Editor di Testo Visivo',
				da: 'Visuel Teksteditor'
			},
			placeholder: {
				nl: 'tekst toevoegen',
				'nl-BE': 'tekst toevoegen',
				de: 'Text hinzufügen',
				en: 'add text',
				fr: 'ajouter du texte',
				es: 'agregar texto',
				it: 'aggiungi testo',
				da: 'tilføj tekst'
			}
		},
		{
			type: 'textline',
			label: {
				nl: 'Tekstregel',
				'nl-BE': 'Tekstregel',
				de: 'Textzeile',
				en: 'Text line',
				fr: 'Ligne de Texte',
				es: 'Línea de Texto',
				it: 'Linea di Testo',
				da: 'Tekstrække'
			},
			placeholder: {
				nl: 'tekst toevoegen',
				'nl-BE': 'tekst toevoegen',
				de: 'Text hinzufügen',
				en: 'add text',
				fr: 'ajouter du texte',
				es: 'agregar texto',
				it: 'aggiungi testo',
				da: 'tilføj tekst'
			}
		},
		{
			type: 'textareashort',
			label: {
				nl: 'Paragraaf (kort)',
				'nl-BE': 'Paragraaf (kort)',
				de: 'Absatz (kurz)',
				en: 'Paragraph (short)',
				fr: 'Paragraphe (court)',
				es: 'Párrafo (corto)',
				it: 'Paragrafo (breve)',
				da: 'Afsnit (kort)'
			},
			placeholder: {
				nl: 'tekst toevoegen',
				'nl-BE': 'tekst toevoegen',
				de: 'Text hinzufügen',
				en: 'add text',
				fr: 'ajouter du texte',
				es: 'agregar texto',
				it: 'aggiungi testo',
				da: 'tilføj tekst'
			}
		},
		{
			type: 'textarea',
			label: {
				nl: 'Paragraaf (lang)',
				'nl-BE': 'Paragraaf (lang)',
				de: 'Absatz (lang)',
				en: 'Paragraph (long)',
				fr: 'Paragraphe (long)',
				es: 'Párrafo (largo)',
				it: 'Paragrafo (lungo)',
				da: 'Afsnit (langt)'
			},
			placeholder: {
				nl: 'tekst toevoegen',
				'nl-BE': 'tekst toevoegen',
				de: 'Text hinzufügen',
				en: 'add text',
				fr: 'ajouter du texte',
				es: 'agregar texto',
				it: 'aggiungi testo',
				da: 'tilføj tekst'
			}
		},
		{
			type: 'image',
			label: {
				nl: 'Afbeelding',
				'nl-BE': 'Afbeelding',
				de: 'Bild',
				en: 'Image',
				fr: 'Image',
				es: 'Imagen',
				it: 'Immagine',
				da: 'Billede'
			},
			placeholder: {
				nl: 'selecteer afbeelding',
				'nl-BE': 'selecteer afbeelding',
				de: 'Bild auswählen',
				en: 'select image',
				fr: 'sélectionner une image',
				es: 'seleccionar imagen',
				it: 'seleziona immagine',
				da: 'vælg billede'
			}
		},
		{
			type: 'video',
			label: {
				nl: 'Video',
				'nl-BE': 'Video',
				de: 'Video',
				en: 'Video',
				fr: 'Vidéo',
				es: 'Video',
				it: 'Video',
				da: 'Video'
			},
			placeholder: {
				nl: 'video toevoegen',
				'nl-BE': 'video toevoegen',
				de: 'ein Video hinzufügen',
				en: 'add a video',
				fr: 'ajouter une vidéo',
				es: 'agregar un video',
				it: 'aggiungi un video',
				da: 'tilføj en video'
			}
		},
		{
			type: 'fence',
			label: {
				nl: 'Gebied',
				'nl-BE': 'Gebied',
				de: 'Fläche',
				en: 'Area',
				fr: 'Zone',
				es: 'Área',
				it: "un'Area",
				da: 'Område'
			},
			placeholder: {
				nl: 'tekengebied toevoegen',
				'nl-BE': 'tekengebied toevoegen',
				de: 'Zeichenfläche hinzufügen',
				en: 'add a draw area',
				fr: 'ajouter une zone de dessin',
				es: 'agregar un área de dibujo',
				it: "aggiungi un'area di disegno",
				da: 'tilføj et tegneområde'
			}
		},
		{
			type: 'dataset',
			label: {
				nl: 'Dataset',
				'nl-BE': 'Dataset',
				de: 'Datensatz',
				en: 'Dataset',
				fr: 'Ensemble de Données',
				es: 'Conjunto de Datos',
				it: 'Set di Dati',
				da: 'Datasæt'
			},
			placeholder: {
				nl: 'dataset toevoegen',
				'nl-BE': 'dataset toevoegen',
				de: 'Datensatz hinzufügen',
				en: 'add a dataset',
				fr: 'ajouter un ensemble de données',
				es: 'agregar un conjunto de datos',
				it: 'aggiungi un set di dati',
				da: 'tilføj et datasæt'
			}
		},
		{
			type: 'textfield',
			label: {
				nl: 'Tekstveld',
				'nl-BE': 'Tekstveld',
				de: 'Textfeld',
				en: 'Textfield',
				fr: 'Champ de Texte',
				es: 'Campo de Texto',
				it: 'Campo di Testo',
				da: 'Tekstfelt'
			},
			placeholder: {
				nl: 'tekstveld toevoegen',
				'nl-BE': 'tekstveld toevoegen',
				de: 'Textfeld hinzufügen',
				en: 'add a textfield',
				fr: 'ajouter un champ de texte',
				es: 'agregar un campo de texto',
				it: 'aggiungi un campo di testo',
				da: 'tilføj et tekstfelt'
			}
		},
		{
			type: 'disclaimer',
			label: {
				nl: 'Disclaimer',
				'nl-BE': 'Disclaimer',
				de: 'Haftungsausschluss',
				en: 'Disclaimer',
				fr: 'Avertissement',
				es: 'Advertencia',
				it: 'Dichiarazione di non Responsabilità',
				da: 'Ansvarsfraskrivelse'
			},
			placeholder: {
				nl: 'disclaimer toevoegen',
				'nl-BE': 'disclaimer toevoegen',
				de: 'Haftungsausschluss hinzufügen',
				en: 'add a disclaimer',
				fr: 'ajouter un avertissement',
				es: 'agregar una advertencia',
				it: 'aggiungi una dichiarazione di non responsabilità',
				da: 'tilføj en ansvarsfraskrivelse'
			}
		}
	];

	ngOnInit(): void {
		this.updateInputs(this.service.form.getRawValue() as Partial<MarkerCategory>);
		this.onChanges();
	}

	onChanges(): void {
		this.service.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
			this.updateInputs(this.service.form.getRawValue() as Partial<MarkerCategory>);
			this.cdr.markForCheck();
		});
	}

	updateInputs(data?: Partial<MarkerCategory>): void {
		if (!data) {
			return;
		}

		this.inputs = (data.inputs || [])
			.map(data => data.preset?.type)
			.filter(type => type !== undefined) as string[];
		this.customInputs = (data?.inputs || [])
			.map(data => data.custom?.type)
			.filter(type => type !== undefined) as string[];
	}

	getOccurence(val: string): number {
		if (this.customInputs && this.customInputs.length >= 1) {
			return this.customInputs.reduce((n, x) => n + Number(x === val), 0);
		}

		return 0;
	}

	compareInput(val: string | undefined): boolean {
		if (!val) {
			return false;
		}

		const bool = this.inputs.includes(val);
		return bool;
	}

	onSelect(item: Partial<MarkerPresetOptions>) {
		if (!item.type) {
			return;
		}

		const input: MarkerCategoryInputs = {
			preset: {
				listView: true,
				required: true,
				type: item.type,
				label: item?.label || '',
				placeholder: item?.placeholder || '',
				...item
			}
		};
		this.service.addPresetInput(input);
	}

	onSelectCustom(item: Partial<MarkerCustomOptions>) {
		if (!item.type) {
			return;
		}

		const input: MarkerCategoryInputs = {
			custom: {
				listView: true,
				required: true,
				key: `${item.type}${this.getOccurence(item.type)}`,
				type: item.type,
				label: item?.label || '',
				placeholder: item?.placeholder || '',
				...item
			}
		};
		this.service.addCustomInput(input);
	}
}
