import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { YunoFormsLabelRequired } from '../../pipes/label-required/label-required.pipe';
import {
	YunoFormsDefaultComponent,
	YunoFormsDefaultTemplateComponent
} from '../_default/default.component';

@Component({
    selector: 'yuno-forms-toggle',
    template: `
		<div class="flex" [ngClass]="{ 'pointer-events-none': ngControl.disabled }">
			<label
				class="yuno-form toggle h-full"
				[ngClass]="{
					'flex-col': labelPos !== 'side',
					'mt-2 !flex-row-reverse gap-4': labelPos === 'side',
					'mt- z2': !label
				}">
				@if (label) {
					<span class="select-none" [innerHtml]="label | labelRequired: required"> </span>
				}
				<input
					class="toggle my-auto block truncate"
					type="checkbox"
					[id]="ngControl.name"
					[name]="ngControl.name"
					[placeholder]="placeholder"
					[formControl]="getFormControl()" />
			</label>
			<div class="flex-1"></div>
		</div>
	`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, FormsModule, ReactiveFormsModule, YunoFormsLabelRequired]
})
export class YunoFormsToggleComponent extends YunoFormsDefaultComponent {
	@Input() labelPos: 'side' | 'top' = 'top';
}

@Component({
    selector: 'yuno-forms-template-toggle',
    template: `
		<div class="flex" [ngClass]="{ 'pointer-events-none': isDisabled }">
			<label
				class="yuno-form toggle h-full"
				[ngClass]="{
					'flex-col': labelPos !== 'side',
					'mt-2 !flex-row-reverse gap-4': labelPos === 'side',
					'mt- z2': !label
				}">
				@if (label) {
					<span class="select-none" [innerHtml]="label | labelRequired: required"> </span>
				}
				<input
					class="toggle my-auto block truncate"
					type="checkbox"
					[disabled]="isDisabled"
					[ngModel]="value"
					(ngModelChange)="valueChange($event)"
					[placeholder]="placeholder"
					[required]="required" />
			</label>
			<div class="flex-1"></div>
		</div>
	`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, FormsModule, YunoFormsLabelRequired],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => YunoFormsToggleTemplateComponent),
            multi: true
        }
    ]
})
export class YunoFormsToggleTemplateComponent extends YunoFormsDefaultTemplateComponent {
	@Input() labelPos: 'side' | 'top' = 'top';
}
