import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
	TemplateRef,
	inject
} from '@angular/core';
import { combineLatest, startWith, tap } from 'rxjs';

import { SideOptionsMenuService } from './side-options-menu.service';

@Component({
	selector: 'yuno-admin-side-options-menu',
	templateUrl: './side-options-menu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgClass, AsyncPipe, NgTemplateOutlet],
	styleUrls: ['./side-options-menu.component.scss']
})
export class SideOptionsMenuComponent implements OnInit {
	private _opened = true;
	private service = inject(SideOptionsMenuService);

	/* eslint-disable @typescript-eslint/no-explicit-any */
	sideOptionsTemplate: TemplateRef<any> | null = null;

	data$ = combineLatest({
		template: this.service.sideOptionsTemplate$.pipe(
			startWith(null),
			tap(template => {
				this.setSideOptionsTemplate(template);
			})
		)
	});

	@Output() openedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input()
	set opened(bool: boolean) {
		this._opened = bool;
	}

	get opened(): boolean {
		return this._opened;
	}

	public innerWidth: number;

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.innerWidth = window.innerWidth;
		this.innerWidth <= 768 && (this._opened = false);
	}

	setSideOptionsTemplate(template: TemplateRef<any> | null) {
		this.sideOptionsTemplate = template;
	}

	toggleOpened(): void {
		this.opened = !this.opened;
		this.openedChange.emit(this.opened);
	}

	ngOnInit(): void {
		this.innerWidth = window.innerWidth;
		this.innerWidth <= 768 && (this._opened = false);
	}
}
