import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { combineLatest, startWith, take, tap } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import { HtmlEditorImageLibraryComponent } from '@yuno/admin/features/html-form';
import { PagesFacade } from '@yuno/admin/features/textfield-pages';
import {
	EditContainerContentDirective,
	YunoAdminButtonsModule,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { AppDataComponent, redirectTo } from '@yuno/admin/utils';
import {
	YunoFormsDividerComponent,
	YunoFormsSelectComponent,
	YunoFormsTextComponent,
	YunoFormsTitleComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';
import { DashboardHelpItem, LanguageAll } from '@yuno/api/interface';

import { DashboardDataAccessModule, DashboardFacade } from '../../data-access';
import { DashboardEditorService } from './dashboard-editor.service';

@Component({
	selector: 'yuno-admin-dashboard-editor',
	imports: [
		ReactiveFormsModule,
		YunoFormsDividerComponent,
		YunoFormsTitleComponent,
		YunoFormsTextComponent,
		YunoFormsSelectComponent,
		YunoFormsToggleComponent,
		YunoEditContainerModule,
		YunoAdminButtonsModule,
		DashboardDataAccessModule,
		HtmlEditorImageLibraryComponent,
		AsyncPipe,
		EditContainerContentDirective
	],
	providers: [DashboardEditorService],
	templateUrl: './dashboard-editor.component.html',
	styleUrls: ['./dashboard-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	private readonly dashboardFacade = inject(DashboardFacade);
	private readonly pageFacade = inject(PagesFacade);
	private readonly appFacade = inject(AppFacade);
	private readonly cdr = inject(ChangeDetectorRef);
	private readonly destroyRef = inject(DestroyRef);
	readonly service = inject(DashboardEditorService);

	originalData: Partial<DashboardHelpItem>;
	languages = LanguageAll;

	data$ = combineLatest({
		item: this.dashboardFacade.selected$.pipe(
			tap(data => {
				if (this.originalData) {
					return;
				}
				this.originalData = data as DashboardHelpItem;
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				this.service.dashboardForm.patchValue(this.originalData as any);
				this.cdr.markForCheck();
			})
		),
		dashboard: this.dashboardFacade.dashboard$.pipe(
			tap(data => {
				if (data) {
					/* 	Detects current url and select dataset from url	*/
					this.route.paramMap.pipe(take(1)).subscribe(data => {
						const id = data.get('id');
						this.dashboardFacade.getSelected(Number(id));
					});
				}
			})
		),
		pages: this.pageFacade.pages$.pipe(
			tap(data => {
				this.service.linkValues = data.map(icon => icon.id) as string[];
				this.service.linkDisplay = data.map(icon => icon.id) as string[];
			})
		),
		language: this.appFacade.language$.pipe(startWith('nl'))
	});

	ngOnInit(): void {
		this.pageFacade.get();
		this.service.createFormGroup();
		this.onChanges();

		this.languageSelector();
	}

	onChanges(): void {
		this.service.dashboardForm.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.dashboardFacade.updateSelect(
					this.service.dashboardForm.getRawValue() as DashboardHelpItem
				);
			});
	}

	onSave() {
		this.dashboardFacade.update();
		this.dashboardFacade.save();
	}

	/* 	navigates back to the Dashboard page */
	onClose(): void {
		redirectTo(this.route, this.router);
		this.dashboardFacade.clearSelect();
	}

	ngOnDestroy() {
		this.languageService.destroyLanguageSelector();
	}
}
