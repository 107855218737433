import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';

@Component({
	selector: 'yuno-admin-item-list',
	templateUrl: './item-list.component.html',
	imports: [CdkOverlayOrigin, CdkConnectedOverlay, NgTemplateOutlet],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminItemListComponent {
	@Input() buttonEllipsis = false;
	@Input() disableAutoCloseButtons = false;
	@Input() index: number | null = null;

	/* eslint-disable @typescript-eslint/no-explicit-any */
	@Input() buttons: TemplateRef<any>;
	activeButtons = false;

	disableEllipsisButton(): void {
		this.activeButtons = false;
	}

	handleEllipsisClick() {
		if (this.disableAutoCloseButtons) {
			return;
		}
		this.activeButtons = false;
	}
}
