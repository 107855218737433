// eslint-disable-next-line @angular-eslint/component-selector

/* eslint-disable @angular-eslint/component-selector */

/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";



import { DefaultButtonsComponent, YunoAdminButtonComponent } from "./button.component";


/**
 * minimal AppRole = EDITOR
 */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[yuno-admin-add-button]',
    imports: [YunoAdminButtonComponent],
    template: `
		<span yuno-admin-button [disabled]="disabled" color="primary" [add]="true">
			<span class="peer empty:hidden">
				<ng-content></ng-content>
			</span>
			<!-- if no ng-content -->
			<span class="hidden peer-empty:!inline-block">Add</span>
		</span>
	`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminAddButtonComponent extends DefaultButtonsComponent implements OnInit {
	ngOnInit(): void {
		this.hidden = !this.minimalAppRole(this.userRoles.EDITOR);
	}
}

/**
 * minimal AppRole = EDITOR
 */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'yuno-admin-add-muted-button, button[yuno-admin-add-muted-button]',
    imports: [YunoAdminButtonComponent],
    template: `
		<span yuno-admin-button [disabled]="disabled" color="muted" [add]="true">
			<span class="peer empty:hidden">
				<ng-content></ng-content>
			</span>
			<!-- if no ng-content -->
			<span class="hidden peer-empty:!inline-block">Add</span>
		</span>
	`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminAddMutedButtonComponent extends DefaultButtonsComponent implements OnInit {
	ngOnInit(): void {
		this.hidden = !this.minimalAppRole(this.userRoles.EDITOR);
	}
}

/**
 * minimal AppRole = EDITOR
 */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[yuno-admin-save-button]',
    imports: [YunoAdminButtonComponent],
    template: `
		<span yuno-admin-button [disabled]="disabled" color="success">
			<span class="peer empty:hidden">
				<ng-content></ng-content>
			</span>
			<!-- if no ng-content -->
			<span class="hidden peer-empty:!inline-block">Save</span>
		</span>
	`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminSaveButtonComponent extends DefaultButtonsComponent implements OnInit {
	ngOnInit(): void {
		this.hidden = !this.minimalAppRole(this.userRoles.EDITOR);
	}
}

/**
 * minimal AppRole = EDITOR
 */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[yuno-admin-cancel-button]',
    imports: [YunoAdminButtonComponent],
    template: `
		<span yuno-admin-button [disabled]="disabled" color="muted">
			<span class="peer empty:hidden">
				<ng-content></ng-content>
			</span>
			<!-- if no ng-content -->
			<span class="hidden peer-empty:!inline-block">Cancel</span>
		</span>
	`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminCancelButtonComponent extends DefaultButtonsComponent implements OnInit {
	ngOnInit(): void {
		this.hidden = !this.minimalAppRole(this.userRoles.EDITOR);
	}
}

/**
 * Has NO minimal AppRole
 */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[yuno-admin-close-button]',
    imports: [YunoAdminButtonComponent],
    template: `
		<span yuno-admin-button color="muted">
			<span class="peer empty:hidden">
				<ng-content></ng-content>
			</span>
			<!-- if no ng-content -->
			<span class="hidden peer-empty:!inline-block">Close</span>
		</span>
	`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminCloseButtonComponent {}
