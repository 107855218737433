import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { combineLatest, tap } from 'rxjs';

import { FencesFacade } from '@yuno/admin/features/fences';
import { GeoPhotoCategoryFacade } from '@yuno/admin/features/geophotos';
import { LayersFacade } from '@yuno/admin/features/layers';
import { MarkersFacade } from '@yuno/admin/features/markers';
import { PanoramasFacade } from '@yuno/admin/features/panoramas';
import { MarkerCategoriesFacade } from '@yuno/admin/features/place-markers/data-access';
import { YunoAdminAddButtonComponent, YunoAdminDeleteAdminButtonComponent } from '@yuno/admin/ui';
import {
	YunoFormsSelectComponent,
	YunoFormsTitleComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';
import { LoadersModule } from '@yuno/project-atlas/ui';

import { DatasetEditorService, DatasetStatesForm, StateSelectors } from '../dataset-editor.service';

@Component({
	selector: 'yuno-admin-state-settings-editor',
	imports: [
		CommonModule,
		RouterModule,
		ReactiveFormsModule,
		YunoFormsTitleComponent,
		YunoFormsSelectComponent,
		YunoFormsToggleComponent,
		YunoAdminAddButtonComponent,
		LoadersModule,
		YunoAdminDeleteAdminButtonComponent
	],
	templateUrl: './state-settings-editor.component.html',
	styleUrls: ['./state-settings-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StateSettingsEditorComponent implements OnInit {
	private readonly destroyRef = inject(DestroyRef);
	private readonly cdr = inject(ChangeDetectorRef);
	private readonly route = inject(ActivatedRoute);
	private readonly fenceFacade = inject(FencesFacade);
	private readonly layerFacade = inject(LayersFacade);
	private readonly markerFacade = inject(MarkersFacade);
	private readonly categoryFacade = inject(MarkerCategoriesFacade);
	private readonly panoramaFacade = inject(PanoramasFacade);
	private readonly geoPhotosFacade = inject(GeoPhotoCategoryFacade);

	readonly service = inject(DatasetEditorService);

	stateIndex = 0;

	data$ = combineLatest({
		changes: this.service.rawForm$.pipe(
			takeUntilDestroyed(this.destroyRef)
			// map(data => data.data?.participates?.map(data => data.participate as TableRow) || [])
		),
		fences: this.fenceFacade.fences$.pipe(
			tap(data => {
				if (data && data.length >= 1) {
					this.service.fenceDisplay = data.map(object => object.id) as string[];
					this.service.fenceValues = data.map(object => object._id) as string[];
				}
			})
		),
		layers: this.layerFacade.layers$.pipe(
			tap(data => {
				if (data && data.length >= 1) {
					this.service.layerDisplay = data.map(object => object.id) as string[];
					this.service.layerValues = data.map(object => object._id) as string[];
				}
			})
		),
		markers: this.markerFacade.markers$.pipe(
			tap(data => {
				if (data && data.length >= 1) {
					this.service.markerDisplay = data.map(
						object => object.properties?.id
					) as string[];
					this.service.markerValues = data.map(object => object._id) as string[];
				}
			})
		),
		categories: this.categoryFacade.markerCategories$.pipe(
			tap(data => {
				if (data && data.length >= 1) {
					this.service.categoryDisplay = data.map(object => object.id) as string[];
					this.service.categoryValues = data.map(object => object._id) as string[];
				}
			})
		),
		panoramas: this.panoramaFacade.panoramas$.pipe(
			tap(data => {
				if (data && data.length >= 1) {
					this.service.panoramaDisplay = data.map(object => object.id) as string[];
					this.service.panoramaValues = data.map(object => object._id) as string[];
				}
			})
		),
		geoPhotos: this.geoPhotosFacade.data$.pipe(
			tap(data => {
				if (data && data.length >= 1) {
					this.service.photoCategoriesDisplay = data.map(
						category => category.id
					) as string[];
					this.service.photoCategoriesValues = data.map(
						category => category._id
					) as string[];
				}
			})
		)
	});

	get currentState(): FormGroup<DatasetStatesForm> | undefined {
		return this.stateIndex >= 0
			? (this.service.states.controls[this.stateIndex] as FormGroup<DatasetStatesForm>)
			: undefined;
	}

	ngOnInit(): void {
		this.fenceFacade.get();
		this.layerFacade.get();
		this.markerFacade.get();
		this.categoryFacade.get();
		this.panoramaFacade.get();
		this.geoPhotosFacade.get();

		// detect route param changes
		// to update the current view of selected state
		this.route.paramMap.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(params => {
			const index = Number(params.get('index'));
			if (index >= 0) {
				this.stateIndex = index;
				this.cdr.detectChanges();
			}
		});
	}

	addStateItem(type: StateSelectors) {
		this.service.addStateItem(type, this.stateIndex);
	}

	removeStateItem(type: StateSelectors, i: number) {
		this.service.removeStateItem(type, this.stateIndex, i);
	}
}
