import { NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Input,
	OnInit,
	inject
} from '@angular/core';

import { LanguageObjectModel, PageItemImgBtn } from '@yuno/api/interface';
import { ButtonCardModule } from '@yuno/project-atlas/ui';

import { EventsService } from '../../services/events.service';
import { TextfieldToken } from '../../textfield.injection.token';

@Component({
    selector: 'yuno-text-image-button',
    templateUrl: './text-image-button.component.html',
    styleUrls: ['./text-image-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ButtonCardModule, NgClass]
})
export class TextImageButtonComponent implements OnInit {
	@HostBinding('class.yuno__textfield__styling') private textStyling = true;

	private readonly injectValue = inject(TextfieldToken);
	private events = inject(EventsService);

	@Input() disableInjection = false;
	@Input() language?: string;

	_data: PageItemImgBtn;
	@Input() set data(data: PageItemImgBtn) {
		this._data = data;
	}

	get data(): PageItemImgBtn {
		return this._data;
	}

	ngOnInit(): void {
		!this.disableInjection &&
			this.injectValue?.data &&
			this.handleInjectionData(
				this.injectValue.data as PageItemImgBtn,
				this.injectValue.language
			);
	}

	handleInjectionData(data: PageItemImgBtn, language?: string): void {
		this.data = data;
		this.language = language;
	}

	combineLink(data: PageItemImgBtn, language?: string): string {
		const lang = language || 'nl';
		// Combine data.link and the result of data.url | languageSelect: language
		return data.link || (data.url as LanguageObjectModel)[lang] || ''; // Ensure a string is always returned
	}

	navigateTo(str?: string): void {
		if (!str) {
			return;
		}

		this.events.sendEvent([
			{
				type: 'navigateTo',
				options: {
					url: str
				}
			}
		]);
	}
}
