import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'yuno-admin-side-options-button',
	template: `
		<div
			class="flex h-8 w-full flex-1 select-none items-center gap-2 truncate rounded-full leading-8 transition-colors duration-500 ease-in-out hover:cursor-pointer hover:bg-yuno-blue-gray-200 hover:text-yuno-blue-gray-800"
			[ngClass]="{
				'!pointer-events-none bg-yuno-portal-blue text-white': active && !disabled,
				'pointer-events-none opacity-30': disabled,
				'px-1.5': !extraPadding,
				'px-4': extraPadding
			}">
			<ng-content></ng-content>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgClass],
	styleUrls: ['./side-options-button.component.scss']
})
export class SideOptionsButtonComponent {
	@Input() active = false;
	@Input() disabled = false;
	@Input() extraPadding = false;
}
