import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Injector,
	Input,
	OnInit,
	forwardRef,
	inject
} from '@angular/core';
import { combineLatest, tap } from 'rxjs';

import { EmbedTextfield, Textfield } from '@yuno/api/interface';

import { EventsService } from '../../services/events.service';
import { TextfieldToken } from '../../textfield.injection.token';
import { TextfieldInjectorComponent } from '../textfield-injector/textfield-injector.component';

@Component({
    selector: 'yuno-textfield-embed',
    template: `
		@if (data$ | async) {
			@if (data && data.textfield && language) {
				<yuno-textfield-injector
					class="my-4"
					[data]="data.textfield"
					[language]="language"
					[injector]="injector"></yuno-textfield-injector>
			}
		}
	`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [AsyncPipe, forwardRef(() => TextfieldInjectorComponent)]
})
export class TextEmbedComponent implements OnInit {
	injector = inject(Injector);
	private readonly injectValue = inject(TextfieldToken);
	private events = inject(EventsService);

	_data?: EmbedTextfield;

	@Input() language?: string = 'nl';
	@Input() disableInjection = false;
	@Input() containerId: string;
	@Input() content?: Textfield;
	@Input()
	set data(data: EmbedTextfield | undefined) {
		if (!data || data.clear) {
			this._data = undefined;
			this.content = undefined;
			return;
		}

		this._data = data;
	}
	get data(): EmbedTextfield | undefined {
		return this._data;
	}

	data$ = combineLatest({
		embed: this.events.embedText$.pipe(
			tap(data => {
				if (!data) {
					return;
				}

				if (this.containerId in data) {
					this.data = data[this.containerId];
				} else {
					this.data = undefined;
				}
			})
		)
	});

	ngOnInit(): void {
		!this.disableInjection &&
			this.injectValue?.data &&
			this.handleInjectionData(this.injectValue.data as string, this.injectValue.language);
	}

	handleInjectionData(containerId: string, language?: string) {
		this.language = language;
		this.containerId = containerId;
	}
}
