module.exports = {
  A: {
    A: {
      "2": "K D E F iC",
      "1028": "B",
      "1316": "A"
    },
    B: {
      "1": "6 7 8 9 C L M G N O P Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I"
    },
    C: {
      "1": "6 7 8 9 OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC kC lC",
      "164": "jC IC J MB K D E F A B C L M G N O P NB y z mC nC",
      "516": "0 1 2 3 4 5"
    },
    D: {
      "1": "6 7 8 9 PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC",
      "33": "0 1 2 3 4 5 z OB",
      "164": "J MB K D E F A B C L M G N O P NB y"
    },
    E: {
      "1": "F A B C L M G sC QC CC DC tC uC vC RC SC EC wC FC TC UC VC WC XC xC GC YC ZC aC bC cC yC HC dC eC fC zC",
      "33": "D E qC rC",
      "164": "J MB K oC PC pC"
    },
    F: {
      "1": "0 1 2 3 4 5 O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x DC",
      "2": "F B C 0C 1C 2C 3C CC gC 4C",
      "33": "G N"
    },
    G: {
      "1": "AD BD CD DD ED FD GD HD ID JD KD LD MD ND OD RC SC EC PD FC TC UC VC WC XC QD GC YC ZC aC bC cC RD HC dC eC fC",
      "33": "E 8C 9C",
      "164": "PC 5C hC 6C 7C"
    },
    H: {
      "1": "SD"
    },
    I: {
      "1": "I XD YD",
      "164": "IC J TD UD VD WD hC"
    },
    J: {
      "1": "A",
      "164": "D"
    },
    K: {
      "1": "H DC",
      "2": "A B C CC gC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "1": "B",
      "292": "A"
    },
    O: {
      "1": "EC"
    },
    P: {
      "1": "0 1 2 3 4 5 J y z ZD aD bD cD dD QC eD fD gD hD iD FC GC HC jD"
    },
    Q: {
      "1": "kD"
    },
    R: {
      "1": "lD"
    },
    S: {
      "1": "mD nD"
    }
  },
  B: 4,
  C: "CSS Flexible Box Layout Module",
  D: true
};