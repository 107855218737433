module.exports = {
  A: {
    A: {
      "2": "K D E F A B iC"
    },
    B: {
      "1": "G N O P",
      "33": "C L M",
      "129": "BB CB DB EB FB GB HB IB JB KB LB I",
      "161": "6 7 8 9 Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB"
    },
    C: {
      "1": "6 7 8 9 jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC kC lC",
      "2": "0 1 2 3 4 5 jC IC J MB K D E F A B C L M G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB mC nC"
    },
    D: {
      "129": "BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC",
      "161": "0 1 2 3 4 5 6 7 8 9 J MB K D E F A B C L M G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB"
    },
    E: {
      "2": "oC",
      "129": "EC wC FC TC UC VC WC XC xC GC YC ZC aC bC cC yC HC dC eC fC zC",
      "388": "MB K D E F A B C L M G pC qC rC sC QC CC DC tC uC vC RC SC",
      "420": "J PC"
    },
    F: {
      "2": "F B C 0C 1C 2C 3C CC gC 4C DC",
      "129": "p q r s t u v w x",
      "161": "0 1 2 3 4 5 G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o"
    },
    G: {
      "129": "EC PD FC TC UC VC WC XC QD GC YC ZC aC bC cC RD HC dC eC fC",
      "388": "E PC 5C hC 6C 7C 8C 9C AD BD CD DD ED FD GD HD ID JD KD LD MD ND OD RC SC"
    },
    H: {
      "2": "SD"
    },
    I: {
      "16": "IC TD UD VD",
      "129": "I",
      "161": "J WD hC XD YD"
    },
    J: {
      "161": "D A"
    },
    K: {
      "16": "A B C CC gC DC",
      "129": "H"
    },
    L: {
      "129": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "2": "A B"
    },
    O: {
      "161": "EC"
    },
    P: {
      "1": "3 4 5",
      "161": "0 1 2 J y z ZD aD bD cD dD QC eD fD gD hD iD FC GC HC jD"
    },
    Q: {
      "161": "kD"
    },
    R: {
      "161": "lD"
    },
    S: {
      "1": "mD nD"
    }
  },
  B: 7,
  C: "Background-clip: text",
  D: true
};