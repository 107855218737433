import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogModule,
	MatDialogRef
} from '@angular/material/dialog';
import { map } from 'rxjs';

import { UsersFacade } from '@yuno/admin/features/users';
import { YunoAdminButtonComponent } from '@yuno/admin/ui';
import { SafeUser } from '@yuno/api/interface';

@Component({
	selector: 'yuno-admin-user-reset-password',
	imports: [YunoAdminButtonComponent, MatDialogModule],
	template: `
		<h2 mat-dialog-title>Reset Password</h2>
		<mat-dialog-content class="mat-typography">
			<p>Are you sure you want to send a reset-password mail to this user?</p>
		</mat-dialog-content>
		<mat-dialog-actions align="end">
			<div class="flex gap-2">
				<button
					yuno-admin-button
					color="primary"
					[mat-dialog-close]="true"
					(click)="sendResetPassword()">
					Send
				</button>
				<button yuno-admin-button color="muted" [mat-dialog-close]="true">Cancel</button>
			</div>
		</mat-dialog-actions>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDataResetPasswordComponent {
	private readonly userFacade = inject(UsersFacade);
	readonly data = inject<Partial<SafeUser>>(MAT_DIALOG_DATA);

	sendResetPassword(): void {
		if (this.data && this.data.email) {
			this.userFacade.resetPassword(this.data.email);
		}
	}
}

@Component({
	selector: 'yuno-admin-user-resend-invite',
	imports: [YunoAdminButtonComponent, MatDialogModule],
	template: `
		<h2 mat-dialog-title>Resend Invite</h2>
		<mat-dialog-content class="mat-typography">
			<p>Are you sure you want to resend the invitation mail to this user?</p>
		</mat-dialog-content>
		<mat-dialog-actions align="end">
			<div class="flex gap-2">
				<button
					yuno-admin-button
					color="primary"
					[mat-dialog-close]="true"
					(click)="resendInvite()">
					Send
				</button>
				<button yuno-admin-button color="muted" [mat-dialog-close]="true">Cancel</button>
			</div>
		</mat-dialog-actions>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDataResendInviteComponent {
	private readonly userFacade = inject(UsersFacade);
	readonly data = inject<Partial<SafeUser>>(MAT_DIALOG_DATA);

	resendInvite(): void {
		if (this.data && this.data.email) {
			this.userFacade.resendInvite(this.data.email);
		}
	}
}

@Component({
	selector: 'yuno-admin-user-data',
	templateUrl: './user-data.component.html',
	styleUrls: ['./user-data.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false
})
export class UserDataComponent implements OnInit {
	private readonly dialog = inject(MatDialog);
	private readonly userFacade = inject(UsersFacade);
	readonly data = inject<Partial<SafeUser>>(MAT_DIALOG_DATA);

	@Input() userId: string;
	userData$ = this.userFacade.getUserApps$.pipe(
		map(data => data as { [key: string]: unknown }[])
	);

	dialogResendInvite: MatDialogRef<UserDataResendInviteComponent>;
	dialogResetPassword: MatDialogRef<UserDataResetPasswordComponent>;

	ngOnInit(): void {
		if (this.data && this.data.email) {
			this.userFacade.userData(this.data.email);
		}
	}

	resetPassword(): void {
		this.dialogResetPassword = this.dialog.open(UserDataResetPasswordComponent, {
			data: this.data
		});
	}

	resendInvite(): void {
		this.dialogResendInvite = this.dialog.open(UserDataResendInviteComponent, {
			data: this.data
		});
	}

	removeUserData(): void {
		this.userFacade.removeUserData();
	}
}
