import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { map } from 'rxjs';

import { AppDataComponent } from '@yuno/admin/utils';
import {
	YunoFormsSelectComponent,
	YunoFormsSpanComponent,
	YunoFormsTextComponent,
	YunoFormsTitleComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';

import { TextEditorsService } from '../text-editors.service';

@Component({
	selector: 'yuno-admin-category-editor',
	imports: [
		ReactiveFormsModule,
		YunoFormsTitleComponent,
		YunoFormsTextComponent,
		YunoFormsSpanComponent,
		YunoFormsSelectComponent,
		YunoFormsToggleComponent,
		AsyncPipe
	],
	templateUrl: './category-editor.component.html',
	styleUrls: ['./category-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	readonly editService = inject(TextEditorsService);

	service$ = this.editService.getActiveService().pipe(
		map(service => {
			return this.editService.returnTextfieldService(service);
		})
	);
	data$ = this.editService.data$;

	ngOnInit() {
		this.editService.onInitCategories();
		this.languageSelector();
	}

	ngOnDestroy() {
		this.editService.onClearCategory();
		this.languageService.destroyLanguageSelector();
	}
}
