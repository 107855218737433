import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import {
	EditContainerComponent,
	EditContainerContentDirective,
	YunoAdminCloseButtonComponent
} from '@yuno/admin/ui';

import { FileLibraryComponent } from '../file-library/file-library.component';
import { FileLibraryService } from '../file-library/file-library.service';

@Component({
	selector: 'yuno-file-library-edit-container',
	imports: [
		EditContainerComponent,
		FileLibraryComponent,
		YunoAdminCloseButtonComponent,
		EditContainerContentDirective
	],
	templateUrl: './edit-container.component.html',
	styleUrls: ['./edit-container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileLibraryEditContainerComponent {
	private readonly service = inject(FileLibraryService);

	onClose(): void {
		this.service.onClose();
	}
}
