import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { combineLatest, map, tap } from 'rxjs';

import { ChooseTemplateComponent } from '@yuno/admin/features/templates';
import {
	YunoAdminAddButtonComponent,
	YunoAdminDeleteAdminButtonComponent,
	YunoAdminDuplicateButtonComponent,
	YunoAdminEditButtonComponent,
	YunoAdminTableComponent,
	YunoCardModule
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { Layer } from '@yuno/api/interface';

import { LayersFacade } from '../../data-access';
import { LayerTemplateViewComponent } from '../template-view/template-view.component';
import { LayersRoutingModule } from './layers-routing.module';

@Component({
	imports: [
		YunoCardModule,
		LayersRoutingModule,
		AsyncPipe,
		ChooseTemplateComponent,
		LayerTemplateViewComponent,
		YunoAdminTableComponent,
		YunoAdminDuplicateButtonComponent,
		YunoAdminDeleteAdminButtonComponent,
		YunoAdminEditButtonComponent,
		YunoAdminAddButtonComponent
	],
	selector: 'yuno-admin-layers',
	templateUrl: './layers.component.html',
	styleUrls: ['./layers.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayersComponent extends AppDataComponent implements OnInit {
	private readonly layerFacade = inject(LayersFacade);

	private activeLayerId: string | null;
	toggleTemplates = false;
	tabValue = 'Editor';

	data$ = combineLatest({
		layers: this.layerFacade.layers$.pipe(map(data => data as { [key: string]: unknown }[])),
		select: this.layerFacade.selectedLayer$.pipe(
			tap(data => {
				if (data) {
					this.onSelect(data);
				}
			})
		)
	});

	ngOnInit(): void {
		this.layerFacade.get();
	}

	onSelect(row: Partial<Layer>) {
		this.router.navigate(['edit', row._id], {
			relativeTo: this.route
		});
	}

	onDuplicate(row: Partial<Layer>) {
		this.layerFacade.duplicate(row._id as string);
	}

	onDelete(row: Partial<Layer>) {
		this.layerFacade.delete(row._id as string);
	}

	onCreate(layerIds: string[], layerName: string) {
		this.layerFacade.clearSelect();
		if (layerIds.length < 1) {
			this.router.navigate(['create'], {
				relativeTo: this.route
			});
		}
		for (const id of layerIds) {
			this.layerFacade.saveFromTemplate(id, layerName);
		}
		this.toggleTemplates = false;
	}

	onSelectTemplate(bool: boolean) {
		if (this.templateApp) {
			this.onCreate([], '');
		} else {
			this.toggleTemplates = bool;
		}
	}
}
