import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { combineLatest, startWith, tap } from 'rxjs';

import { LayersFacade } from '@yuno/admin/features/layers';
import { YunoAdminButtonComponent } from '@yuno/admin/ui';
import {
	YunoFormsDividerComponent,
	YunoFormsSelectComponent,
	YunoFormsSpanComponent,
	YunoFormsTitleComponent
} from '@yuno/angular/forms/components';

import { ContentForm, LegendEditorService, legendList } from '../legend-editor.service';

@Component({
	selector: 'yuno-admin-legend-active-layer-editor',
	imports: [
		ReactiveFormsModule,
		YunoAdminButtonComponent,
		YunoFormsDividerComponent,
		YunoFormsTitleComponent,
		YunoFormsSpanComponent,
		YunoFormsSelectComponent,
		AsyncPipe
	],
	templateUrl: './legend-active-layer-editor.component.html',
	styleUrls: ['./legend-active-layer-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegendActiveLayerEditorComponent {
	private _form: FormGroup<ContentForm> | FormGroup<legendList>;
	activeForm = this.service.activeLayers;

	data$ = combineLatest({
		layers: this.layerFacade.layers$.pipe(
			startWith([]),
			tap(data => {
				this.service.layerDisplay = data.map(layer => layer.id) as string[];
				this.service.layerValues = data.map(layer => layer._id) as string[];
			})
		)
	});

	@Input() set form(content: FormGroup<ContentForm> | FormGroup<legendList>) {
		this._form = content;
		if (content instanceof FormGroup && content.controls) {
			if ('list' in content.controls) {
				this.activeForm = this.service.activeLayers;
			} else {
				this.activeForm = this.service.activeListLayers;
			}
		}
	}

	get form(): FormGroup<ContentForm> | FormGroup<legendList> {
		return this._form;
	}

	constructor(
		public service: LegendEditorService,
		private layerFacade: LayersFacade
	) {
		this.layerFacade.get();
	}

	removeLayerAt(index: number) {
		this.service.removeLayer(this.activeForm, index);
	}

	addLayer() {
		this.service.addLayer(this.activeForm);
	}
}
