import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AnnotationComponent } from '@yuno/admin/ui';
import {
	YunoFormsSelectComponent,
	YunoFormsSpanComponent,
	YunoFormsTextComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';

import { EventsDefaultFormItemComponent } from '../_default/default-form-item.component';

@Component({
	selector: 'yuno-admin-embed-text',
	imports: [
		ReactiveFormsModule,
		YunoFormsTextComponent,
		YunoFormsSpanComponent,
		YunoFormsSelectComponent,
		YunoFormsToggleComponent,
		AnnotationComponent,
		AsyncPipe
	],
	templateUrl: './embed-text.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbedTextComponent extends EventsDefaultFormItemComponent implements OnInit {
	ngOnInit(): void {
		this.service.getTextfields();
	}
}
