import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';

import { TableRow } from '@yuno/admin/ui';
import { ArrayValidators, CustomValidators } from '@yuno/angular/forms';
import { Template } from '@yuno/api/interface';

export interface LayerTemplateForm {
	_id: FormControl<string>;
	id: FormControl<string>;
	img: FormControl<string>;
	selector: FormControl<'layer'>;
	options: FormGroup<{
		layers: FormArray<FormControl<string>>;
		layerType: FormControl<string>;
	}>;
	tags: FormArray<FormControl<string>>;
}

@Injectable()
export class LayerTemplateEditorService {
	form: FormGroup<LayerTemplateForm>;
	rawForm$ = new ReplaySubject<Partial<Template>>(1);

	layerTypes = ['Basic', 'Background', 'Line', 'Point', 'Multi layer', 'Symbol', 'Fill', 'Fill extrude'];

	get layers(): FormArray {
		return (this.form.get('options') as FormGroup).get('layers') as FormArray;
	}

	get tags(): FormArray {
		return this.form.get('tags') as FormArray;
	}

	createFormGroup(): void {
		if (this.form) {
			this.form.reset();
		}
		this.form = new FormGroup<LayerTemplateForm>({
			_id: new FormControl({ value: '', disabled: true }, { nonNullable: true }),
			id: new FormControl('', { nonNullable: true, validators: Validators.required }),
			img: new FormControl('', { nonNullable: true }),
			selector: new FormControl('layer', {
				nonNullable: true,
				validators: Validators.required
			}),
			options: new FormGroup({
				layers: new FormArray<FormControl<string>>([]),
				layerType: new FormControl('Basic', { nonNullable: true })
			}),
			tags: new FormArray<FormControl<string>>([], ArrayValidators.minLength(1))
		});
	}

	add(data: TableRow): void {
		const layerId = data['_id'] as string;
		const control = new FormControl<string>(layerId, {
			nonNullable: true
		});

		this.layers.push(control);
	}

	remove(data: TableRow): void {
		const foundIndex = this.layers.getRawValue().findIndex(layer => layer === data['_id']);
		if (foundIndex < 0) {
			return;
		}

		this.layers.removeAt(foundIndex);
	}

	addTag(tag?: string): void {
		const control = new FormControl<string>(tag || '', {
			nonNullable: true,
			validators: [
				Validators.required,
				Validators.minLength(3),
				CustomValidators.noSpacesLowerCase()
			]
		});
		this.tags.push(control);
	}

	removeTag(index: number): void {
		this.tags.removeAt(index);
	}

	selectAll(data: string[]): void {
		if (data.length < 1) {
			this.layers.clear();
			return;
		}

		const count = data.length - this.layers.value.length;
		for (let i = 0; i < count; i++) {
			const control = new FormControl<string>(data[i], {
				nonNullable: true
			});

			this.layers.push(control);
		}
	}
}
