import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AdminSearchBarComponent, SideOptionsButtonComponent, TableRow, YunoAdminTableComponent } from '@yuno/admin/ui';
import { Template } from '@yuno/api/interface';

@Component({
	imports: [CommonModule, YunoAdminTableComponent, AdminSearchBarComponent, SideOptionsButtonComponent, FormsModule],
	selector: 'yuno-admin-template-table',
	template: `
		@if (templates) {
			<div class="mb-4 flex flex-row-reverse justify-between gap-4 p-1">
				<yuno-admin-search-bar
					[debounceTime]="100"
					[minimumLength]="0"
					(searches)="filter.emit($event)"></yuno-admin-search-bar>
				<section class="flex flex-row gap-2 p-1 items-center justify-center" >
					<span class="font-semibold select-none">Preview</span>
					@for (pill of previewPills; track pill) {
						<yuno-admin-side-options-button
							[extraPadding]="true"
							(click)="setPreview(pill)"
							[active]="preview === pill">
							{{ pill }}
						</yuno-admin-side-options-button>
					}
				</section>
			</div>
			<section class="grid max-h-[512px] overflow-y-auto">
				@switch (preview === 'No') {
					@case (true) {
						<yuno-admin-table
							[selectable]="false"
							[draggable]="false"
							[filterable]="false"
							[sortable]="true"
							[pagination]="false"
							[tableHeader]="false"
							[pageOptions]="{
								pageSize: 50,
								pageSizeOptions: [10, 25, 50, 100],
								hidePageSize: false
							}"
							[defaultColumn]="preview === 'No' ? 'id' : 'img'"
							[data]="templates"
							[customTemplate]="tableImage"
							[columns]="[
								{ key: 'id', label: 'Name' },
								{ key: 'updatedAt', type: 'date', label: 'last modified', width: 10 }
							]"
							(clicked)="onClick($event)">
						</yuno-admin-table>
					}
					@default {
						<yuno-admin-table
							[selectable]="false"
							[draggable]="false"
							[filterable]="false"
							[sortable]="true"
							[pagination]="false"
							[tableHeader]="false"
							[pageOptions]="{
								pageSize: 50,
								pageSizeOptions: [10, 25, 50, 100],
								hidePageSize: false
							}"
							[defaultColumn]="preview === 'No' ? 'id' : 'img'"
							[data]="templates"
							[customTemplate]="tableImage"
							[columns]="[
								{
									key: 'img',
									label: 'Image',
									type: 'custom',
									hidden: preview === 'No',
									sticky: true,
									maxWidth: 17,
									sortable: false
								},
								{ key: 'id', label: 'Name' },
								{ key: 'updatedAt', type: 'date', label: 'last modified', width: 10 }
							]"
							(clicked)="onClick($event)">
						</yuno-admin-table>
					}
				}
				<ng-template #tableImage let-row>
					<div
						class="bg-stripes-gray my-2 flex aspect-video w-48 items-center justify-center overflow-hidden rounded md:w-60">
						@if (row.img; as img) {
							<img
								class="max-h-full object-cover"
								[src]="img"
								loading="lazy"
								[alt]="row.data?.id" />
						}
					</div>
				</ng-template>
			</section>
		}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateTableComponent {
	@Input() templates: Partial<Template>[] | undefined;
	@Input() selector = 'textfield';

	@Output() clicked = new EventEmitter<Template>();
	@Output() filter = new EventEmitter<string>();

	previewPills: string[] = ['No', 'Yes'];
	preview = 'Yes';

	layerTypes = ['Basic', 'Background', 'Line', 'Point', 'Multi layer', 'Symbol', 'Fill', 'Fill extrude'];

	onClick(e?: TableRow) {
		const template = e as Template;
		this.clicked.emit(template);
	}

	onLayerType(value: Event) {
		const layerType = (value.target as HTMLSelectElement).value;
		this.filter.emit(layerType);
	}

	setPreview(pill: string) {
		this.preview = pill;
		// this.cdr.detectChanges();
	}

}
