import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	imports: [CommonModule],
	selector: 'yuno-admin-template-item',
	template: ` <div
		(click)="onClick()"
		class="col-span-1 h-64 w-56 cursor-pointer rounded-md bg-yuno-blue-gray-200 p-4">
		<img [src]="img" />
	</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateItemComponent {
	@Input() img: string | undefined;
	@Output() clicked = new EventEmitter<boolean>();

	onClick() {
		this.clicked.emit(true);
	}
}
