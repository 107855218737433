import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { AppFacade } from '@yuno/admin/features/apps';
import {
	DragRowComponent,
	YunoAdminButtonComponent,
	YunoAdminDeleteAdminButtonComponent,
	YunoAdminEditButtonComponent,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { YunoFormsTitleComponent } from '@yuno/angular/forms/components';
import { LanguagePipe } from '@yuno/angular/pipes';
import { ParticipationPages } from '@yuno/api/interface';

import { ParticipationModelFacade } from '../../../../../data-access';
import {
	ParticipationModelForm,
	ParticipationSettingsEditorService
} from '../../settings-editor.service';
import { StepsPageEditorComponent } from './steps-page-editor/steps-page-editor.component';

@Component({
	selector: 'yuno-admin-participation-model-steps',
	imports: [
		YunoFormsTitleComponent,
		ReactiveFormsModule,
		YunoAdminButtonComponent,
		DragDropModule,
		YunoEditContainerModule,
		DragRowComponent,
		StepsPageEditorComponent,
		AsyncPipe,
		LanguagePipe,
		YunoAdminEditButtonComponent,
		YunoAdminDeleteAdminButtonComponent
	],
	templateUrl: './steps.component.html',
	styleUrls: ['./cdk-drag-settings.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipationModelEditorStepsComponent extends AppDataComponent {
	@Input() form: FormGroup<ParticipationModelForm>;

	private facade = inject(ParticipationModelFacade);
	private appFacade = inject(AppFacade);
	service = inject(ParticipationSettingsEditorService);

	data$ = this.facade.selected$;
	language$ = this.appFacade.language$;

	selectedPageEditor = false;
	selectedPages = 0;

	drop(event: CdkDragDrop<ParticipationPages[]>, data: ParticipationPages[]): void {
		this.facade.changesSelectedModelPagesOrder(data, event.previousIndex, event.currentIndex);
	}

	onSelect(index: number): void {
		this.selectedPageEditor = true;
		this.selectedPages = index;
		this.languageSelector();
	}

	onAdd(): void {
		this.service.addNewPage();
	}

	onRemove(index: number): void {
		this.facade.removeSelectedModelPage(index);
	}

	onClosePage(): void {
		this.selectedPageEditor = false;
		this.selectedPages = 0;
		this.languageService.destroyLanguageSelector();
	}

	onSavePage(): void {
		this.selectedPageEditor = false;
		this.selectedPages = 0;
		this.languageService.destroyLanguageSelector();
	}
}
