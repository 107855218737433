import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FloatingListComponent } from '@yuno/yuno/features/ui';

import { SidebarGroupComponent } from './sidebar-group/sidebar-group.component';
import { SidebarItemComponent } from './sidebar-item/sidebar-item.component';
import { SidebarUserComponent } from './sidebar-user/sidebar-user.component';
import { SidemenuComponent } from './sidemenu.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		CdkConnectedOverlay,
		FloatingListComponent,
		CdkOverlayOrigin,
		SidebarUserComponent
	],
	declarations: [SidemenuComponent, SidebarGroupComponent, SidebarItemComponent],
	exports: [SidemenuComponent, SidebarGroupComponent, SidebarItemComponent]
})
export class YunoSidemenuModule {}
