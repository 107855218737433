@if (group) {
	<form [formGroup]="group">
		<yuno-forms-title>Event Editor</yuno-forms-title>
		<div class="mt-4">
			<yuno-forms-select
				formControlName="type"
				[selectValuesGroup]="eventGroupSelect"
				[displayGroup]="eventGroupDisplay"
				(changes)="generateOptions($event)">
			</yuno-forms-select>
		</div>
		<div class="mt-8">
			@switch (group.controls.type.value) {
				<!--		Content		-->
				@case ('loadDataset') {
					<yuno-admin-load-dataset [form]="group" />
				}
				@case ('unloadDataset') {
					<yuno-admin-load-dataset [form]="group" [unload]="true" />
				}
				@case ('openImage') {
					<yuno-admin-open-image [form]="group" />
				}
				@case ('openPano') {
					<yuno-admin-load-pano [form]="group" />
				}
				@case ('loadPano') {
					<yuno-admin-load-pano [form]="group" />
				}
				@case ('openUrl') {
					<yuno-admin-open-url [form]="group" />
				}
				@case ('clickMarker') {
					<yuno-admin-click-marker [form]="group" />
				}
				@case ('setFeatureState') {
					<yuno-admin-set-feature-state [form]="group" />
				}
				@case ('setLayerStyle') {
					<yuno-admin-event-set-layer-style [form]="group" />
				}

				<!--		Text		-->
				@case ('customDataPopup') {
					<yuno-admin-popup-custom-data [form]="group" />
				}
				@case ('customDataTextPopup') {
					<yuno-admin-popup-custom-data [form]="group" />
				}
				@case ('popupCustomText') {
					<yuno-admin-popup-custom-text [form]="group" />
				}
				@case ('popupHoverCustomText') {
					<yuno-admin-popup-custom-text [form]="group" />
				}
				@case ('hoverPopup') {
					<yuno-admin-map-popup [form]="group" />
				}
				@case ('embedText') {
					<yuno-admin-embed-text [form]="group" />
				}
				@case ('embedTextfield') {
					<yuno-admin-embed-text [form]="group" />
				}
				@case ('mapPopup') {
					<yuno-admin-map-popup [form]="group" />
				}
				@case ('popupText') {
					<yuno-admin-map-popup [form]="group" />
				}
				@case ('openText') {
					<yuno-admin-open-text [form]="group" />
				}
				@case ('loadText') {
					<yuno-admin-open-text [form]="group" />
				}
				@case ('openTextPopup') {
					<yuno-admin-open-text-popup [form]="group" />
				}
				@case ('loadTextPopup') {
					<yuno-admin-open-text-popup [form]="group" />
				}
				@case ('openTextfield') {
					<yuno-admin-open-text-popup [form]="group" />
				}
				@case ('closeTextfieldOverlay') {
					<div>This does not have any inputs.</div>
				}

				<!--		Zoom		-->
				@case ('zoomTo') {
					<yuno-admin-zoom-to [form]="group" />
				}
				@case ('zoomToPoint') {
					<yuno-admin-zoom-to [form]="group" />
				}
				@case ('zoomToMarker') {
					<yuno-admin-zoom-to-marker [form]="group" />
				}
				@case ('zoomToFence') {
					<yuno-admin-zoom-to-fence [form]="group" />
				}
				@case ('zoomToExtend') {
					<yuno-admin-zoom-to-extend [form]="group" />
				}
				@case ('zoomToBounds') {
					<yuno-admin-zoom-to-bounds [form]="group" />
				}
				@case ('zoomToPolygon') {
					<yuno-admin-zoom-to-polygon [form]="group" />
				}
				@case ('zoomToFeature') {
					<yuno-admin-zoom-to-feature [form]="group" />
				}
				@default {
					Ah oh something went wrong! Either we did not implement this Event or the event
					type,
					<strong>{{ group.controls.type.value }}</strong> , is wrong
				}
			}
		</div>
	</form>
}
