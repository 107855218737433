import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	HostListener,
	Input,
	Output,
	QueryList,
	ViewChild,
	ViewChildren,
	inject
} from '@angular/core';
import { ButtonGroupItemComponent } from 'libs/project-atlas/ui/src/lib/button-group/button-group-item/button-group-item.component';
import {
	ButtonGroupSliderComponent,
	SliderElementSize
} from 'libs/project-atlas/ui/src/lib/button-group/button-group-slider/button-group-slider.component';
import { isEqual } from 'lodash';

import { YunoDirectivesModule } from '@yuno/angular/directives';
import { AngularPipesModule } from '@yuno/angular/pipes';
import { Event, LanguageStringsModel, LanguageType, LanguageTypeAll } from '@yuno/api/interface';

export interface YunoAtlasButtonGroupItem {
	id: string;
	display: LanguageStringsModel;
	events?: Event[];
}

export const DebounceTime = 333;

@Component({
    selector: 'yuno-atlas-button-group',
    imports: [
        CommonModule,
        AngularPipesModule,
        YunoDirectivesModule,
        OverlayModule,
        ButtonGroupItemComponent,
        ButtonGroupSliderComponent
    ],
    templateUrl: './button-group.component.html',
    styleUrls: ['./button-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonGroupComponent implements AfterViewInit {
	private cdr = inject(ChangeDetectorRef);
	transition = false;

	@ViewChildren('buttonGroupItems', { read: ElementRef })
	buttonGroupItems: QueryList<ElementRef>;

	@ViewChild('collapseElement', { read: ElementRef })
	collapseElement: ElementRef;

	@ViewChild('container', { read: ElementRef })
	container: ElementRef;

	_language: LanguageTypeAll = 'nl';
	@Input() set language(lang: LanguageTypeAll) {
		this._language = lang;
		this.calcSize();
	}

	get language(): LanguageType {
		return this._language;
	}

	@Input() collapseType: 'dropup' | 'dropdown' = 'dropup';

	_maxWidth = 0;
	@Input() set maxWidth(num: number) {
		this._maxWidth = num;
		this.calcSize();
	}

	get maxWidth(): number {
		return this._maxWidth;
	}

	@Input()
	@HostBinding('class')
	style: 'square' | 'rounded' | 'round' = 'round';

	private _activeColor = '#fff';
	@Input() set activeColor(color: string | undefined) {
		this._activeColor = color || '#fff';
	}

	get activeColor(): string {
		return this._activeColor;
	}

	private _activeBackgroundColor = '#3498DB';
	@Input() set activeBackgroundColor(color: string | undefined) {
		this._activeBackgroundColor = color || '#3498DB';
	}

	get activeBackgroundColor(): string {
		return this._activeBackgroundColor;
	}

	private _options: YunoAtlasButtonGroupItem[] = [];
	@Input() set options(options: YunoAtlasButtonGroupItem[]) {
		if (isEqual(options, this.options)) {
			return;
		}

		this._options = options || [];
		this.setSizing(undefined);

		this.checkActive();
	}

	get options(): YunoAtlasButtonGroupItem[] {
		return this._options;
	}

	// Keep track of the current active Value
	private _value: string;
	@Input()
	set value(val: string | undefined) {
		if (!val) {
			return;
		}

		this._value = val;
		this.checkActive();
	}

	get value(): string {
		return this._value;
	}

	@Output() valueChange = new EventEmitter<string>();

	@HostListener('window:resize') onResize() {
		this.setSizing(this.targetElement);
	}

	collapse = false;
	targetElement: HTMLElement | undefined;
	activeElementSizing: SliderElementSize = {
		offsetTop: 0,
		offsetLeft: 0,
		clientWidth: 0,
		clientHeight: 0
	};
	activeValue: YunoAtlasButtonGroupItem;
	buttonGroupCollapseContainerBottom = 0;
	collapseContainerActive = false;

	// When the value has been set from the Parent Component
	// wait for the component to have been initialised
	// so we can read all children ButtonGroupItems
	ngAfterViewInit(): void {
		this.checkActive();
	}

	// used to track the items in the NgFor container
	// reducing the risk of rerendering those items
	trackBy(index: number, item: YunoAtlasButtonGroupItem): string {
		return item.id;
	}

	// Checks if any of the ButtonGroupItems attr.data-id
	// matches the current 'value'
	// then simulates a click on the element to activate the default MouseEvent action
	checkActive(): void {
		if (!this.buttonGroupItems) {
			return;
		}

		setTimeout(() => {
			const htmlElements = Array.from(this.buttonGroupItems);
			if (!htmlElements) {
				return;
			}

			const selectedItem = htmlElements.find(
				(el: ElementRef) => el.nativeElement.getAttribute('data-id') === this.value
			);
			const val = this.options.find(e => e.id === this.value);

			if (!selectedItem || !val) {
				return;
			}

			this.setSizing(selectedItem.nativeElement);
			this.activeValue = val;

			this.cdr.detectChanges();
		});
	}

	setActiveVal(): void {
		const val = this.options.find(e => e.id === this.value);
		if (!val) {
			return;
		}

		this.activeValue = val;
	}

	// Stores a reference to the clicked HTMLElement
	// changes the current value and emits it
	//
	// Skip make sure the element wont change when clicked in dropup
	optionClicked(event: MouseEvent, option: YunoAtlasButtonGroupItem, skip?: boolean): void {
		this.value = option.id;
		this.valueChange.emit(this.value);

		this.activeValue = option;

		if (skip) {
			this.toggleCollapseContainer();
			return;
		}

		const htmlElement = event.target || event.currentTarget;
		if (htmlElement instanceof HTMLElement || !htmlElement) {
			this.setSizing(htmlElement || undefined);
		}
	}

	toggleCollapseContainer(): void {
		this.collapseContainerActive = !this.collapseContainerActive;
		this.cdr.markForCheck();
	}

	resizeCollapsed(): void {
		this.setSizing(undefined);

		this.cdr.detectChanges();

		// then we activate it as element
		this.activateCollapseElement();
	}

	calcSize(): void {
		this.collapse = false;
		this.setSizing(undefined);

		this.cdr.detectChanges();

		setTimeout(() => {
			// because we unset the the Collapse value
			// we need a timeout to give Angular
			// time to "render" the full width again
			// const now = this.el.nativeElement.offsetWidth;
			const now = this.container.nativeElement.scrollWidth;

			// When we should show the Dropper
			// we remove a small portion on the maxWidth set
			// to allow some whitespace
			if (!this.maxWidth || this.maxWidth - 16 < now) {
				// Disables the default buttons
				// then checks the DOM for changes
				this.collapse = true;
				this.cdr.detectChanges();

				this.setActiveVal();

				// With a small delay we do check
				// if the collapseElement is indeed active
				if (!this.collapseElement) {
					return;
				}

				// then we activate it as element
				this.activateCollapseElement();
				return;
			}

			// Update the Slider
			this.checkActive();
		}, DebounceTime);
	}

	activateCollapseElement(): void {
		this.setSizing(this.collapseElement.nativeElement);

		this.buttonGroupCollapseContainerBottom =
			this.collapseElement.nativeElement.offsetHeight + 12;

		this.cdr.detectChanges();
	}

	/**
	 * Creates the Slider Size
	 */
	setSizing(targetElement: HTMLElement | undefined): void {
		this.targetElement = targetElement;
		this.transition = !!targetElement;

		if (
			this.activeElementSizing.offsetTop === 0 ||
			this.activeElementSizing.offsetLeft === 0 ||
			this.activeElementSizing.clientWidth === 0 ||
			this.activeElementSizing.clientHeight === 0
		) {
			this.transition = false;
		}

		this.activeElementSizing = {
			offsetTop: targetElement?.offsetTop || 0,
			offsetLeft: targetElement?.offsetLeft || 0,
			clientWidth: targetElement?.clientWidth || 0,
			clientHeight: targetElement?.clientHeight || 0
		};

		this.cdr.detectChanges();
	}
}
