import { NgClass, NgStyle } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	Input,
	OnDestroy,
	forwardRef,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import Color from 'color';
import { ColorPickerModule } from 'ngx-color-picker';
import { debounceTime } from 'rxjs';

import { YunoSharedUiButtonComponent } from '@yuno/shared/ui';

import { YunoFormsLabelRequired } from '../../pipes/label-required/label-required.pipe';
import {
	YunoFormsDefaultComponent,
	YunoFormsDefaultTemplateComponent
} from '../_default/default.component';
import { YunoFormsValidatorMessagesComponent } from '../_validator-messages/validator-messages.component';

@Component({
	selector: 'yuno-forms-color',
	template: `
		<label class="yuno-form">
			@if (label) {
				<span class="select-none" [innerHtml]="label | labelRequired: required"> </span>
			}
			<!--
			  gap cannot be used, due to the Color Picker
			  that will add a element between the input and the button
			  -->
			<section
				class="justify-center-center flex w-full"
				[ngClass]="{
					'flex-row items-center': clearDirection === 'right',
					'flex-col items-end': clearDirection === 'bottom'
				}">
				<input
					class="flex-1 truncate"
					[(colorPicker)]="colorValue"
					(colorPickerChange)="getFormControl().setValue($event)"
					[id]="ngControl.name"
					[name]="ngControl.name"
					[ngStyle]="{
						backgroundColor: getFormControl().value || '#fff',
						color: getTextColor(getFormControl().value) || '#000'
					}"
					[cpPosition]="colorDirection"
					[type]="'text'"
					[placeholder]="placeholder"
					[formControl]="getFormControl()" />

				@if (canClear) {
					<button
						yuno-shared-ui-button
						class="ml-3 mt-1"
						color="secondary"
						(click)="onClearValue()">
						Clear
					</button>
				}
			</section>
			<yuno-validator-messages [control]="ngControl.control">
				<ng-content></ng-content>
			</yuno-validator-messages>
		</label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		NgClass,
		FormsModule,
		ColorPickerModule,
		NgStyle,
		ReactiveFormsModule,
		YunoSharedUiButtonComponent,
		YunoFormsValidatorMessagesComponent,
		YunoFormsLabelRequired
	]
})
export class YunoFormsColorComponent
	extends YunoFormsDefaultComponent
	implements AfterViewInit, OnDestroy
{
	@Input() disableBgColor = false;
	@Input() colorDirection:
		| 'auto'
		| 'top'
		| 'bottom'
		| 'left'
		| 'right'
		| 'top-left'
		| 'top-right'
		| 'bottom-left'
		| 'bottom-right' = 'auto';
	colorValue: string;
	@Input() canClear = false;
	@Input() clearValue = '';
	@Input() clearDirection: 'right' | 'bottom' = 'right';

	private readonly destroyRef = inject(DestroyRef);

	ngAfterViewInit(): void {
		this.colorValue = this.getFormControl().value;
		this.onChanges();
	}

	onChanges(): void {
		this.getFormControl()
			.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
			.subscribe(val => {
				this.colorValue = val;
				this.cdr.markForCheck();
			});
	}

	onClearValue(): void {
		this.colorValue = this.clearValue;
		this.getFormControl().setValue(this.clearValue);
	}

	getTextColor(col: string): string {
		if (!col || this.disableBgColor) {
			return '#000';
		}
		try {
			const color = Color(col);
			const dark = color.isDark();
			return dark ? '#fff' : '#000';
		} catch (err) {
			return '#000';
		}
	}

	ngOnDestroy() {
		this.colorValue = this.clearValue;
	}
}

@Component({
	selector: 'yuno-forms-template-color',
	template: `
		<label class="yuno-form">
			@if (label) {
				<span class="select-none" [innerHtml]="label | labelRequired: required"> </span>
			}
			<!--
			  gap cannot be used, due to the Color Picker
			  that will add a element between the input and the button
			  -->
			<section
				class="justify-center-center flex w-full"
				[ngClass]="{
					'flex-row items-center': clearDirection === 'right',
					'flex-col items-end': clearDirection === 'bottom'
				}">
				<input
					class="flex-1 truncate"
					[colorPicker]="value"
					(colorPickerChange)="valueChange($event)"
					[ngStyle]="{
						backgroundColor: value || '#fff',
						color: getTextColor(value) || '#000'
					}"
					[cpPosition]="colorDirection"
					[type]="'text'"
					[placeholder]="placeholder" />

				@if (canClear) {
					<button
						yuno-shared-ui-button
						class="ml-3 mt-1"
						color="secondary"
						(click)="onClearValue()">
						Clear
					</button>
				}
			</section>
		</label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		NgClass,
		FormsModule,
		ColorPickerModule,
		NgStyle,
		YunoSharedUiButtonComponent,
		YunoFormsLabelRequired
	],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => YunoFormsColorTemplateComponent),
			multi: true
		}
	]
})
export class YunoFormsColorTemplateComponent
	extends YunoFormsDefaultTemplateComponent
	implements OnDestroy
{
	@Input() disableBgColor = false;
	@Input() colorDirection:
		| 'auto'
		| 'top'
		| 'bottom'
		| 'left'
		| 'right'
		| 'top-left'
		| 'top-right'
		| 'bottom-left'
		| 'bottom-right' = 'auto';

	@Input() canClear = false;
	@Input() clearValue = '';
	@Input() clearDirection: 'right' | 'bottom' = 'right';

	private readonly destroyRef = inject(DestroyRef);
	override value = '';

	onClearValue(): void {
		this.valueChange(this.clearValue);
	}

	getTextColor(col: string): string {
		if (!col || this.disableBgColor) {
			return '#000';
		}
		try {
			const color = Color(col);
			const dark = color.isDark();
			return dark ? '#fff' : '#000';
		} catch (err) {
			return '#000';
		}
	}

	ngOnDestroy() {
		this.valueChange(this.clearValue);
	}
}
