module.exports = {
  A: {
    A: {
      "1": "A B",
      "2": "K D E F iC"
    },
    B: {
      "1": "6 7 8 9 C L M G N O P Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC kC lC",
      "2": "jC IC mC nC",
      "33": "MB K D E F A B C L M G",
      "164": "J"
    },
    D: {
      "1": "4 5 6 7 8 9 OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC",
      "33": "0 1 2 3 J MB K D E F A B C L M G N O P NB y z"
    },
    E: {
      "1": "D E F A B C L M G qC rC sC QC CC DC tC uC vC RC SC EC wC FC TC UC VC WC XC xC GC YC ZC aC bC cC yC HC dC eC fC zC",
      "33": "K pC",
      "164": "J MB oC PC"
    },
    F: {
      "1": "0 1 2 3 4 5 G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x DC",
      "2": "F 0C 1C",
      "33": "C",
      "164": "B 2C 3C CC gC 4C"
    },
    G: {
      "1": "E 8C 9C AD BD CD DD ED FD GD HD ID JD KD LD MD ND OD RC SC EC PD FC TC UC VC WC XC QD GC YC ZC aC bC cC RD HC dC eC fC",
      "33": "7C",
      "164": "PC 5C hC 6C"
    },
    H: {
      "2": "SD"
    },
    I: {
      "1": "I XD YD",
      "33": "IC J TD UD VD WD hC"
    },
    J: {
      "1": "A",
      "33": "D"
    },
    K: {
      "1": "H DC",
      "33": "C",
      "164": "A B CC gC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "1": "A B"
    },
    O: {
      "1": "EC"
    },
    P: {
      "1": "0 1 2 3 4 5 J y z ZD aD bD cD dD QC eD fD gD hD iD FC GC HC jD"
    },
    Q: {
      "1": "kD"
    },
    R: {
      "1": "lD"
    },
    S: {
      "1": "mD nD"
    }
  },
  B: 5,
  C: "CSS3 Transitions",
  D: true
};