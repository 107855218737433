import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	inject
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { AppFacade } from '@yuno/admin/features/apps';
import {
	AnnotationComponent,
	YunoAdminButtonComponent,
	YunoAdminButtonTableComponent
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { JsonFormsModule } from '@yuno/angular-json-forms';
import {
	YunoFormsSelectComponent,
	YunoFormsSpanComponent,
	YunoFormsTitleComponent
} from '@yuno/angular/forms/components';

import { ParticipationModelFacade } from '../../../../../data-access';
import {
	ParticipationModelForm,
	ParticipationSettingsEditorService
} from '../../settings-editor.service';

@Component({
	selector: 'yuno-admin-participation-style-list',
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		JsonFormsModule,
		YunoAdminButtonComponent,
		YunoAdminButtonTableComponent,
		YunoFormsSpanComponent,
		YunoFormsSelectComponent,
		YunoFormsTitleComponent,
		AnnotationComponent
	],
	templateUrl: './style-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipationStyleListComponent extends AppDataComponent {
	@Input() form: FormGroup<ParticipationModelForm>;

	@Output() openStyleEditor = new EventEmitter<number>();
	@Output() openRulesEditor = new EventEmitter<boolean>();

	private facade = inject(ParticipationModelFacade);
	private appFacade = inject(AppFacade);

	service = inject(ParticipationSettingsEditorService);
	language$ = this.appFacade.language$;

	onAddStyle() {
		this.service.addStyle();
		this.openStyleEditor.emit(this.service.styles.controls.length - 1);
	}

	onRemoveStyle(index: number) {
		this.service.styles.removeAt(index);
	}

	onSelectStyle(index: number) {
		this.openStyleEditor.emit(index);
	}

	onSetRules(bool: boolean) {
		this.openRulesEditor.emit(bool);
	}
}
