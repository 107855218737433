import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { EventsFormsComponent } from '@yuno/admin/features/events';
import { AdminItemListComponent, YunoAdminButtonsModule } from '@yuno/admin/ui';
import { moveItemInFormArray } from '@yuno/angular/forms';
import {
	YunoFormsDividerComponent,
	YunoFormsSelectComponent,
	YunoFormsSpanComponent,
	YunoFormsTitleComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';
import { LanguageAll } from '@yuno/api/interface';

import { ThemeEditorComponentService } from '../../theme-editor.component.service';
import { ThemeEditorFormService, ThemeForm } from '../../theme-editor.form.service';

@Component({
	imports: [
		ReactiveFormsModule,
		YunoFormsDividerComponent,
		YunoFormsTitleComponent,
		YunoFormsSpanComponent,
		YunoFormsSelectComponent,
		YunoFormsToggleComponent,
		YunoAdminButtonsModule,
		DragDropModule,
		EventsFormsComponent,
		AsyncPipe,
		AdminItemListComponent
	],
	selector: 'yuno-admin-theme-map-ui',
	templateUrl: './theme-map-ui.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemeMapUiComponent {
	readonly service = inject(ThemeEditorFormService);
	readonly componentService = inject(ThemeEditorComponentService);

	@Input() form: FormGroup<ThemeForm>;

	events$ = this.componentService.events$;

	removeItemAt(i: number): void {
		this.service.removeAvailableState(i);
	}

	addItem(): void {
		this.service.addAvailableState();
	}

	protected readonly languages = LanguageAll;

	/**
	 * Moves or adds a FormControl between two positions within a FormArray when a key is dropped onto it.
	 * @param event The CdkDragDrop event object.
	 */
	dragDropMapButtons(event: CdkDragDrop<Event[]>): void {
		moveItemInFormArray(this.service.mapButtons, event.previousIndex, event.currentIndex);
	}

	/**
	 * Adds a new MapStyle selector to the Array
	 */
	addMapButton(): void {
		this.service.addMapButton();
	}

	/**
	 * Removes an item from the FormArray instance of the current component's Service.
	 * @param index The index of the item to be removed.
	 */
	removeMapButton(index: number): void {
		this.service.mapButtons.removeAt(index);
	}

	onToggleEvents(index: number) {
		this.componentService.onToggleService(index);
	}
}
