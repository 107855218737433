import { JsonPipe, NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DoCheck,
	Input,
	inject
} from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'yuno-validator-messages',
    templateUrl: './validator-messages.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, JsonPipe]
})
export class YunoFormsValidatorMessagesComponent implements DoCheck {
	private readonly cdr = inject(ChangeDetectorRef);
	@Input({ required: true }) control: AbstractControl<unknown, unknown> | null;
	@Input() top = false;

	ngDoCheck(): void {
		this.markTouched();
	}

	markTouched(): void {
		if (this.control?.touched || this.control?.dirty) {
			this.cdr.markForCheck();
		}
	}
}

@Component({
    selector: 'yuno-validator-messages-template',
    template: `
		<div class="invalid-message-template">
			<div class="invalid-message-wrapper grid grid-cols-1 gap-2">
				<ng-content></ng-content>
			</div>
		</div>
	`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: []
})
export class YunoFormsValidatorMessagesTemplateComponent {}
