import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { YunoAdminButtonsModule } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import {
	YunoFormsSelectComponent,
	YunoFormsTitleComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';

import { InternalViewService } from '../../internal-view/internal-view.service';

@Component({
	imports: [
		ReactiveFormsModule,
		YunoFormsTitleComponent,
		YunoFormsSelectComponent,
		YunoFormsToggleComponent,
		YunoAdminButtonsModule
	],
	selector: 'yuno-admin-atlas-help-settings',
	templateUrl: './atlas-help-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppAtlasHelpSettingsComponent extends AppDataComponent {
	readonly service = inject(InternalViewService);
}
