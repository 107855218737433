/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";


@Component({
	selector: 'yuno-atlas-button-group-item',
	standalone: true,
	template: `<ng-content></ng-content>`,
	styleUrls: ['./button-group-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonGroupItemComponent {
	@Input() @HostBinding('class') style: 'square' | 'rounded' | 'round' = 'round';
	@Input() @HostBinding('style.color') activeColor: string | undefined;
}
