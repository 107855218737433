import { Injectable, inject } from '@angular/core';
import bbox from '@turf/bbox';
import { lineString } from '@turf/helpers';
import { Polygon, Position } from 'geojson';
import { DrawMapService } from 'libs/angular/draw-map/src/lib/services/draw-map.service';
import { isEqual } from 'lodash';
import { LngLatBoundsLike, Map } from 'maplibre-gl';
import {
	BehaviorSubject,
	combineLatest,
	distinctUntilChanged,
	map,
	shareReplay,
	startWith,
	tap
} from 'rxjs';

import { ENVIRONMENT } from '@yuno/admin/core';
import { AppFacade } from '@yuno/admin/features/apps';
import { MapFacade } from '@yuno/admin/features/map';
import { MapOnMissingImage, round } from '@yuno/shared/helpers';

import { ThemeFacade } from '../../../data-access';
import {
	DefaultMapBounds,
	DefaultMapBoundsLngLat,
	DefaultMapStyle,
	ThemeEditorFormService
} from '../theme-editor.form.service';

@Injectable()
export class ThemesMapService {
	readonly environment = inject(ENVIRONMENT);
	private readonly appFacade = inject(AppFacade);
	private readonly mapFacade = inject(MapFacade);
	private readonly themeFacade = inject(ThemeFacade);
	private readonly drawService = inject(DrawMapService);
	readonly formService = inject(ThemeEditorFormService);

	private _map = new BehaviorSubject<Map | null>(null);
	map$ = this._map.asObservable();

	boundsSet = false;
	theme$ = this.themeFacade.selectedTheme$.pipe(shareReplay(1));

	data$ = combineLatest({
		appId: this.appFacade.appId$.pipe(startWith(null)),
		theme: this.theme$.pipe(
			tap(data => {
				if (data) {
					const styleId = data?.mapStyles?.[0]?.style?._id;
					if (styleId) {
						this.mapFacade.getStyle(styleId);
					}
				}
			}),
			distinctUntilChanged((prev, curr) => {
				if (!prev || !curr) {
					return false;
				}

				return prev?.view?.style?._id === curr?.view?.style?._id;
			})
		),
		bounds: this.theme$.pipe(
			distinctUntilChanged((prev, curr) => {
				if (!prev && !curr) {
					return false;
				}

				return isEqual(prev?.view?.bounds, curr?.view?.bounds);
			}),
			map(data => {
				if (!data?.view?.bounds) {
					return DefaultMapBoundsLngLat;
				}

				return this.getMapBounds(data.view.bounds) as LngLatBoundsLike;
			})
		),
		drawUpdate: this.drawService.drawUpdate$.pipe(
			startWith(null),
			tap(data => {
				if (data?.features && data.features.length === 1) {
					const polygon = data.features[0].geometry as Polygon;
					this.updatePolygon(polygon.coordinates[0]);
				}
			})
		),
		map: combineLatest({
			map: this.map$,
			theme: this.theme$
		}).pipe(
			startWith(null),
			tap(data => {
				const map = data?.map;
				const theme = data?.theme;
				if (map && theme) {
					this.drawService.addDrawFeatures(map);
					if (theme?.view?.bounds) {
						const bounds = this.getMapBounds(theme.view.bounds);
						const polygon: Position[] = [
							bounds[1],
							[bounds[0][0], bounds[1][1]],
							bounds[0],
							[bounds[1][0], bounds[0][1]],
							bounds[1]
						];
						if (!this.boundsSet) {
							this.drawService.addPolygon('data.id', polygon);
							this.boundsSet = true;
						}
					} else {
						if (!this.boundsSet) {
							this.drawService.addPolygon('data.id', DefaultMapBounds);
							this.boundsSet = true;
						}
					}
				}
			})
		),
		mapStyle: this.mapFacade.style$.pipe(
			startWith(undefined),
			distinctUntilChanged((prev, curr) => {
				if (!prev && !curr) {
					// when no style is selected, load the default mapstyle
					this.mapFacade.getStyle(DefaultMapStyle);
					return false;
				}

				const prevMetadata: { [key: string]: unknown } = prev?.metadata as {
					[key: string]: unknown;
				};
				const currMetadata: { [key: string]: unknown } = curr?.metadata as {
					[key: string]: unknown;
				};

				if (!prevMetadata || !currMetadata) {
					return false;
				}

				return isEqual(prev?.metadata, curr?.metadata);
			})
		)
	});

	async mapLoad(map: Map): Promise<void> {
		this._map.next(map);
	}

	getMapBounds(bounds?: [[number, number], [number, number]]): [number, number][] {
		if (bounds) {
			return bounds as [number, number][];
		} else {
			return DefaultMapBoundsLngLat as [number, number][];
		}
	}

	updatePolygon(polygon: number[][]) {
		const line = lineString(polygon);
		const bb = bbox(line);
		const newBounds: [[number, number], [number, number]] = [
			[round(bb[0], 6), round(bb[1], 6)],
			[round(bb[2], 6), round(bb[3], 6)]
		];
		this.formService.bounds.patchValue(newBounds);
	}

	mapOnMissingImage(image: { id: string; target?: Map }): void {
		MapOnMissingImage(image, this.environment['yuno-cdn']);
	}
}
