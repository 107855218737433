import { AsyncPipe, CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	EventEmitter,
	HostBinding,
	Input,
	OnInit,
	Output,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Map } from 'maplibre-gl';
import { combineLatest, startWith, tap, withLatestFrom } from 'rxjs';

import { LanguagePipe } from '@yuno/angular/pipes';
import { TextToggleItem } from '@yuno/api/interface';

import { EventsService } from '../../../services/events.service';
import { TextListItemComponent } from '../../text-list';

@Component({
	selector: 'yuno-textfield-toggles-toggle',
	template: `
		@if (data$ | async) {
			@switch (listlike) {
				@case (true) {
					<yuno-textfield-list-item
						[color]="color"
						[backgroundColor]="backgroundColor"
						[title]="title | languageSelect: language"
						[active]="!!checked"
						(activeChange)="checkedOutput($event)"></yuno-textfield-list-item>
				}
				@default {
					<label class="yuno-form toggle">
						@if (addColor) {
							<div
								[ngStyle]="{ 'background-color': color }"
								class="mr-4 block h-6 w-6 rounded-full"></div>
						}
						@if (title) {
							<span>{{ title | languageSelect: language }}</span>
						}
						<input
							class="toggle my-auto"
							type="checkbox"
							[ngModel]="checked"
							(ngModelChange)="checkedOutput($event)" />
					</label>
				}
			}
		}
	`,
	styleUrls: ['./toggle.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [TextListItemComponent, CommonModule, FormsModule, AsyncPipe, LanguagePipe]
})
export class TextTogglesToggleComponent implements OnInit {
	@HostBinding('class.yuno__textfield__styling') private textStyling = true;

	private readonly event = inject(EventsService);
	private readonly route = inject(ActivatedRoute);
	private readonly destroyRef = inject(DestroyRef);
	private readonly cdr = inject(ChangeDetectorRef);

	// Only trigger the QueryParam check once for Active on Startup
	private triggered = false;

	_data: TextToggleItem;

	@Input() addColor: boolean;
	@Input() checked: boolean;
	@Input() title: string;
	@Input() language?: string = 'nl';

	@Input() set data(data: TextToggleItem) {
		this._data = data;
		this.checkDataset();
	}

	get data(): TextToggleItem {
		return this._data;
	}

	@Input() @HostBinding('class.list-like') listlike = false;

	@Input() color: string | undefined;
	@Input() backgroundColor: string | undefined;

	@Output() checkedChange = new EventEmitter<boolean>();

	private routeDatasets: string[] = [];

	data$ = combineLatest({
		update: this.event.mapStyleUpdate$.pipe(
			startWith(undefined),
			withLatestFrom(this.event.map$.pipe(startWith(undefined))),
			tap(
				([, map]) =>
					map &&
					this.checkLayerVisibility(
						map,
						this.data.layers?.map(e => e.id)
					)
			)
		)
	});

	ngOnInit(): void {
		this.route.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(e => {
			if (e['ds']) {
				this.routeDatasets = e['ds'].split(',');
				this.checkDataset();
				return;
			}

			// Only trigger the QueryParam check once for Active on Startup
			if (!this.triggered && !e['ds']) {
				this.data.active && this.checkedOutput(this.data.active);
			}

			this.triggered = true;
			this.routeDatasets = [];
		});
	}

	checkedOutput(event: boolean): void {
		this.checked = event;
		this.checkedChange.emit(this.checked);
	}

	checkDataset(): void {
		if (!this.data || !this.data?.dataset || this.data.dataset.length <= 0) {
			return;
		}

		let visibility = false;

		for (const ds of this.data.dataset) {
			if (this.routeDatasets.includes(ds)) {
				visibility = true;
				break;
			}
		}

		this.checked = visibility;
		this.cdr.markForCheck();
	}

	checkLayerVisibility(map?: Map, layers?: string[]): void {
		// When it is a dataset toggle
		// ignore this function
		if (this.data?.dataset && this.data?.dataset?.length > 0) {
			return;
		}

		if (!layers || !map) {
			return;
		}

		let visibility = false;

		for (const layer of layers) {
			if (!visibility && map.getLayer(layer)) {
				visibility = map.getLayoutProperty(layer, 'visibility') === 'visible';
			}
		}

		this.checked = visibility;
		this.cdr.markForCheck();
	}
}
