module.exports = {
  A: {
    A: {
      "2": "K D E F A B iC"
    },
    B: {
      "1": "6 7 8 9 a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I",
      "2": "C L M G",
      "1028": "Q H R S T U V W X Y Z",
      "4100": "N O P"
    },
    C: {
      "1": "6 7 8 9 JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC kC lC",
      "2": "0 1 2 3 jC IC J MB K D E F A B C L M G N O P NB y z mC nC",
      "194": "4 5 OB PB QB RB",
      "516": "SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB"
    },
    D: {
      "1": "6 7 8 9 a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC",
      "2": "0 J MB K D E F A B C L M G N O P NB y z XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB",
      "322": "1 2 3 4 5 OB PB QB RB SB TB UB VB WB mB nB oB pB",
      "1028": "qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z"
    },
    E: {
      "1": "L M G tC uC vC RC SC EC wC FC TC UC VC WC XC xC GC YC ZC aC bC cC yC HC dC eC fC zC",
      "2": "J MB K oC PC pC",
      "33": "E F A B C rC sC QC CC DC",
      "2084": "D qC"
    },
    F: {
      "1": "AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x",
      "2": "0 1 2 3 4 5 F B C G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB 0C 1C 2C 3C CC gC 4C DC",
      "322": "ZB aB bB",
      "1028": "cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B"
    },
    G: {
      "1": "ID JD KD LD MD ND OD RC SC EC PD FC TC UC VC WC XC QD GC YC ZC aC bC cC RD HC dC eC fC",
      "2": "PC 5C hC 6C",
      "33": "E 9C AD BD CD DD ED FD GD HD",
      "2084": "7C 8C"
    },
    H: {
      "2": "SD"
    },
    I: {
      "1": "I",
      "2": "IC J TD UD VD WD hC XD YD"
    },
    J: {
      "2": "D A"
    },
    K: {
      "1": "H",
      "2": "A B C CC gC DC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "EC"
    },
    P: {
      "1": "0 1 2 3 4 5 y z aD bD cD dD QC eD fD gD hD iD FC GC HC jD",
      "2": "J ZD"
    },
    Q: {
      "1028": "kD"
    },
    R: {
      "1": "lD"
    },
    S: {
      "1": "nD",
      "516": "mD"
    }
  },
  B: 5,
  C: "CSS position:sticky",
  D: true
};