import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	Output
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { RippleDirectiveModule } from '../../../../../../../project-atlas/ui/src/lib/ripple/ripple.directive';
import { colspan } from '../../../helpers';
import { JsonFormArrayControl, JsonFormControl } from '../../../json-forms.models';
import { ControlsSelectorComponent } from '../../controls/controls.selector.component';

@Component({
    selector: 'yuno-json-forms-types-array-control',
    templateUrl: './array-control.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ControlsSelectorComponent, RippleDirectiveModule]
})
export class ControlArrayComponent {
	_control: UntypedFormControl | undefined;

	@Input() item: JsonFormArrayControl;
	@Input() key: string;

	@Input()
	set control(control: UntypedFormControl | undefined) {
		this._control = control;
		this.className = this.colspan();
	}
	get control(): UntypedFormControl | undefined {
		return this._control;
	}

	@Input() form: UntypedFormGroup;
	@Output() removeFromParent = new EventEmitter<string>();

	@HostBinding('className') className: string;

	colspan(): string {
		return colspan(this.item.colspan);
	}

	removeItem(): void {
		this.removeFromParent.emit(this.key);
	}

	getItem(): JsonFormControl {
		return new JsonFormControl({
			...this.item,
			controlType: 'control',
			key: this.key
		});
	}
}
