import { ChangeDetectionStrategy, Component } from '@angular/core';


@Component({
    selector: 'yuno-admin-navbar',
    templateUrl: './navbar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NavbarComponent {}
