import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	inject
} from '@angular/core';
import { combineLatest, tap } from 'rxjs';

import { PagesFacade, TextfieldFacade } from '@yuno/admin/features/textfield-pages';
import { YunoSelectFieldModule } from '@yuno/admin/ui';
import { AngularPipesModule } from '@yuno/angular/pipes';
import { TextfieldComponents } from '@yuno/api/interface';

import { ContentOptions } from '../types';

@Component({
	selector: 'yuno-admin-textfield-pages-add',
	imports: [YunoSelectFieldModule, AngularPipesModule, AsyncPipe],
	templateUrl: './add-items.component.html',
	styleUrls: ['./add-items.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddItemsComponent {
	@Input() editor: 'textfield' | 'page' = 'textfield';
	@Output() selected = new EventEmitter<{
		item: ContentOptions;
		component?: TextfieldComponents;
	}>();

	list: ContentOptions[] = [
		{ key: 'h1', label: '<h1> Head Title' },
		{ key: 'paragraph', label: '<p> Paragraph' },
		{ key: 'h2', label: '<h2> Title' },
		{ key: 'summary', label: '<li> Summary' },
		{ key: 'h3', label: '<h3> Sub Title' },
		{ key: 'dateSummary', label: '<li> Date Summary' },
		{ key: 'blockquote', label: '<blockquote> Quote' },
		{ key: 'divider', label: 'Divider' },
		{ key: 'file', label: 'File' }
	];

	dataList: ContentOptions[] = [
		{ key: 'textblock', label: 'Textblock' },
		{ key: 'custom', label: 'Custom Textblock' },
		{ key: 'button', label: 'Button' },
		{ key: 'imageButton', label: 'Image Button' },
		{ key: 'link', label: 'Link' },
		{ key: 'news', label: 'News Item' },
		{ key: 'legend', label: 'Legend' },
		{ key: 'notification', label: 'Notification' },
		{ key: 'image', label: 'Image' },
		{ key: 'video', label: 'Video' }
	];
	pages: ContentOptions[] = [
		{ key: 'item', label: 'Page Item' },
		{ key: 'header', label: 'Header' }
	];
	textfields: ContentOptions[] = [
		{ key: 'toggles', label: 'Toggle' },
		{ key: 'list', label: 'List' },
		{ key: 'category', label: 'Place Marker Category' },
		{ key: 'container', label: 'Embed-Container' }
	];

	pageList = [...this.dataList, ...this.pages];
	textfieldList = [...this.dataList, ...this.textfields];

	private readonly textfieldFacade = inject(TextfieldFacade);
	private readonly pageFacade = inject(PagesFacade);

	data$ = combineLatest({
		textfield: this.textfieldFacade.selectedTextfield$.pipe(
			tap(data => {
				if (data?.components && data.components.length >= 1) {
					for (const c of data.components) {
						if (c?.predefined?.selector === 'h1') {
							this.list[0].disabled = true;
							break;
						}
					}
				}
			})
		),
		page: this.pageFacade.selectedPages$.pipe(
			tap(data => {
				if (data?.components && data.components.length >= 1) {
					for (const c of data.components) {
						if (c?.predefined?.selector === 'h1') {
							this.list[0].disabled = true;
							break;
						}
					}
				}
			})
		)
	});

	getList() {
		return this.editor === 'textfield' ? this.textfieldList : this.pageList;
	}

	onAdd(item: ContentOptions) {
		this.selected.next({ item });
	}
}
