import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';

import { YunoButtonComponent } from '@yuno/project-atlas/ui';

import { EventsService } from '../../services/events.service';

@Component({
    selector: 'yuno-text-no-cookie',
    template: `
		<ng-container *transloco="let t">
			<div class="aspect-h-9 aspect-w-16 rounded-md bg-[#e1e3e6]">
				<div class="flex h-full w-full flex-col items-center justify-center">
					<p class="px-16 py-4 text-center">{{ t('textfields.noCookieMessage') }}</p>

					<yuno-button (click)="sendRequest()">{{
						t('textfields.noCookieButton')
					}}</yuno-button>
				</div>
			</div>
		</ng-container>
	`,
    styleUrls: ['./text-no-cookie.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [TranslocoDirective, YunoButtonComponent]
})
export class TextNoCookieComponent {
	@HostBinding('class.yuno__textfield__styling') private textStyling = true;

	constructor(private service: EventsService) {}

	sendRequest() {
		this.service.acceptThirdPartyRequest();
	}
}
