import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import {
	YunoFormsColorComponent,
	YunoFormsTextareaComponent
} from '@yuno/angular/forms/components';

import { PredefinedDefaultEditorComponent } from './_default-editor.component';

@Component({
	selector: 'yuno-admin-predefined-blockquote-editor',
	imports: [
		ReactiveFormsModule,
		YunoFormsTextareaComponent,
		YunoFormsColorComponent,
		NgTemplateOutlet
	],
	template: `
		<form [formGroup]="form">
			<div formGroupName="options" class="flex flex-col gap-4">
				<section formGroupName="content">
					@for (lang of languages; track lang) {
						@if (lang === language) {
							<yuno-forms-textarea
								[formControlName]="lang"
								[language]="lang"
								[rows]="3"
								placeholder="Placeholder text"
								label="Paragraph content" />
						}
					}
				</section>

				<ng-container [ngTemplateOutlet]="template" />

				<yuno-forms-color
					[colorDirection]="'bottom'"
					formControlName="color"
					placeholder="#31AACC"
					label="Quote color" />
			</div>
		</form>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PredefinedBlockQuoteEditorComponent extends PredefinedDefaultEditorComponent {}
