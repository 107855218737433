import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Input,
	OnInit,
	inject
} from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { take } from 'rxjs';

import { LanguagePipe } from '@yuno/angular/pipes';
import { LanguageObjectModel, LanguageStringsModel, TextLink } from '@yuno/api/interface';

import { TextfieldToken } from '../../textfield.injection.token';

@Component({
    selector: 'yuno-textfield-link',
    template: `
		<ng-template #contents>
			<ng-content></ng-content>
			{{ content | languageSelect: language }}
		</ng-template>

		@switch (absolute) {
			@case (true) {
				<a
					[href]="url | languageSelect: language"
					target="_blank"
					[ngStyle]="{ color: color }">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						class="pointer-events-none mr-2 inline-block h-6 w-6 shrink-0"
						viewBox="0 0 20 20"
						fill="currentColor">
						<path
							fill-rule="evenodd"
							d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
							clip-rule="evenodd" />
					</svg>
					<ng-container *ngTemplateOutlet="contents"></ng-container>
				</a>
			}
			@default {
				<a [routerLink]="link" [ngStyle]="{ color: color }">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						class="pointer-events-none mr-2 inline-block h-6 w-6 shrink-0"
						viewBox="0 0 20 20"
						fill="currentColor">
						<path
							fill-rule="evenodd"
							d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
							clip-rule="evenodd" />
					</svg>
					<ng-container *ngTemplateOutlet="contents"></ng-container>
				</a>
			}
		}
	`,
    styleUrls: ['./text-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [LanguagePipe, CommonModule, RouterLink]
})
export class TextLinkComponent implements OnInit {
	@HostBinding('class.yuno__textfield__styling') private textStyling = true;

	private readonly injectValue = inject(TextfieldToken);
	private route = inject(ActivatedRoute);

	private _url: string | undefined;

	private appName: string;
	private intern = false;

	type: string | undefined;
	absolute: boolean;
	link: string[];

	@Input() language?: string;
	@Input() disableInjection = false;
	@Input() content?: LanguageStringsModel;

	@Input() @HostBinding('style.color') color? = '#3498DB';

	@Input()
	set url(urls: LanguageStringsModel | undefined) {
		if (!urls) {
			this._url = undefined;
			return;
		}

		let url: string | undefined;

		if (typeof urls === 'string') {
			url = urls;
		} else {
			if (!this.language) {
				this._url = undefined;
				return;
			}
			url = (urls as LanguageObjectModel)[this.language];
		}

		if (!url) {
			this._url = undefined;
			return;
		}

		this._url = url;

		if (this.absoluteUrl(url)) {
			this.absolute = true;
			return;
		}

		this.absolute = false;

		const link = [];
		if (this.appName) {
			link.push(this.appName);
		}

		if (this.intern) {
			link.push('intern');
		}

		this.link = ['/', ...link, ...url.split('/')];
	}

	get url(): string {
		return this._url as string;
	}

	@Input() set page(page: string) {
		let url = page;
		if (this.absoluteUrl(url)) {
			this.absolute = true;
			return;
		}

		this.absolute = false;

		if (!url.includes('page/') && this.type !== 'navigation') {
			url = `page/${page}`;
		}

		const link = [];
		if (this.appName) {
			link.push(this.appName);
		}

		if (this.intern) {
			link.push('intern');
		}

		this.link = ['/', ...link, ...url.split('/')];
	}

	constructor() {
		// This needs to be in the constructor
		// Because it needs to have the appName at launch before
		// any of the values are parsed
		this.route.params.pipe(take(1)).subscribe(e => {
			this.appName = e['appname'] || 'app';
		});
	}

	ngOnInit(): void {
		this.intern = !!this.route.snapshot.data['intern'];

		!this.disableInjection &&
			this.injectValue.data &&
			this.handleInjectionData(this.injectValue.data as TextLink, this.injectValue.language);
	}

	/**
	 * Setup the Component by using the Injection Method
	 *
	 * @param {TextLink} data
	 */
	handleInjectionData(data: TextLink, language?: string) {
		this.type = data.type;
		this.language = language;
		this.url = data.url;
		this.color = data.color || '#3498DB';
		this.content = data.display;

		if (data.page) {
			this.page = data.page;
		}

		if (data.link) {
			this.page = data.link;
		}
	}

	/* Check if absolute url or application url */
	absoluteUrl(str: string): boolean {
		const absolute = new RegExp('^(?:[a-z]+:)?//', 'i');
		return absolute.test(str);
	}
}
