import { NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Input,
	OnInit,
	inject
} from '@angular/core';

import { LanguagePipe } from '@yuno/angular/pipes';
import { MediaNotification } from '@yuno/api/interface';

import { TextfieldToken } from '../../textfield.injection.token';

@Component({
    selector: 'yuno-textfields-notification',
    template: ` <div class="yuno-text-notification" [ngClass]="className">
		<section class="pt-0.5">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 20 20"
				fill="currentColor"
				class="h-5 w-5">
				@if (data.type === 'success' || data.type === 'info') {
					<path
						fill-rule="evenodd"
						d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
						clip-rule="evenodd" />
				}

				@if (data.type === 'warning') {
					<path
						fill-rule="evenodd"
						d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
						clip-rule="evenodd" />
				}

				@if (data.type === 'disclaimer') {
					<path
						fill-rule="evenodd"
						d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
						clip-rule="evenodd" />
				}
			</svg>
		</section>
		<section class="flex flex-col">
			@if (data.title) {
				<h4>{{ data.title | languageSelect: language }}</h4>
			}
			<p>{{ data.content | languageSelect: language }}</p>
		</section>
	</div>`,
    styleUrl: './text-notification.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, LanguagePipe]
})
export class TextNotificationComponent implements OnInit {
	@HostBinding('class.yuno__textfield__styling') private textStyling = true;

	private readonly injectValue = inject(TextfieldToken);
	@Input() disableInjection = false;

	_data: MediaNotification;
	@Input() set data(data: MediaNotification) {
		this._data = data;
	}

	get data(): MediaNotification {
		return this._data;
	}

	@Input() language?: string = 'nl';

	ngOnInit(): void {
		!this.disableInjection &&
			this.injectValue?.data &&
			this.handleInjectionData(
				this.injectValue.data as MediaNotification,
				this.injectValue.language
			);
	}

	handleInjectionData(data: MediaNotification, language?: string): void {
		this.data = data;
		this.language = language;
	}

	get className(): string {
		switch (this.data.type) {
			case 'warning':
				return 'text-red-500 bg-red-100 border-red-300';
			case 'success':
				return 'text-emerald-500 bg-emerald-50 border-emerald-300';
			case 'disclaimer':
				return 'text-amber-500 bg-amber-50 border-amber-300';
			case 'info':
			default:
				return 'text-sky-500 bg-sky-50 border-sky-300';
		}
	}
}
