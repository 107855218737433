import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { YunoFormsLabelRequired } from '../../pipes/label-required/label-required.pipe';
import {
	YunoFormsDefaultComponent,
	YunoFormsDefaultTemplateComponent
} from '../_default/default.component';
import { YunoFormsValidatorMessagesComponent } from '../_validator-messages/validator-messages.component';

@Component({
    selector: 'yuno-forms-checkbox',
    template: `
		<label class="yuno-form">
			<input
				class="truncate"
				type="checkbox"
				[id]="ngControl.name"
				[name]="ngControl.name"
				[placeholder]="placeholder"
				[formControl]="getFormControl()" />
			@if (label) {
				<span class="select-none" [innerHtml]="label | labelRequired: required"> </span>
			}

			<yuno-validator-messages [control]="ngControl.control">
				<ng-content></ng-content>
			</yuno-validator-messages>
		</label>
	`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./checkbox.component.scss'],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        YunoFormsValidatorMessagesComponent,
        YunoFormsLabelRequired
    ]
})
export class YunoFormsCheckboxComponent extends YunoFormsDefaultComponent {}

@Component({
    selector: 'yuno-forms-template-checkbox',
    template: `
		<label class="yuno-form">
			<input
				class="truncate"
				type="checkbox"
				[ngModel]="value"
				(ngModelChange)="valueChange($event)"
				[placeholder]="placeholder" />
			@if (label) {
				<span class="select-none" [innerHtml]="label | labelRequired: required"> </span>
			}
		</label>
	`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./checkbox.component.scss'],
    imports: [FormsModule, YunoFormsLabelRequired],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => YunoFormsCheckboxTemplateComponent),
            multi: true
        }
    ]
})
export class YunoFormsCheckboxTemplateComponent extends YunoFormsDefaultTemplateComponent {}
