import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, inject } from '@angular/core';



import { LanguagePipe } from '@yuno/angular/pipes';
import { TextfieldToken } from '@yuno/angular/textfield';
import { Legend } from '@yuno/api/interface';
import { LegendaItemContentComponent } from '@yuno/project-atlas/ui';


@Component({
	selector: 'yuno-textfield-legend',
	templateUrl: './text-legend.component.html',
	styleUrls: ['./text-legend.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [LegendaItemContentComponent, LanguagePipe]
})
export class TextLegendComponent implements OnInit {
	@HostBinding('class.yuno__textfield__styling') private textStyling = true;

	private readonly injectValue = inject(TextfieldToken);
	@Input() disableInjection = false;

	_data: Legend;
	@Input() set data(data: Legend) {
		this._data = data;
	}

	get data(): Legend {
		return this._data;
	}

	@Input() language?: string | undefined = 'nl';

	ngOnInit(): void {
		!this.disableInjection &&
			this.injectValue?.data &&
			this.handleInjectionData(this.injectValue.data as Legend, this.injectValue.language);
	}

	handleInjectionData(data: Legend, language?: string): void {
		this.data = data;
		this.language = language;
	}
}
