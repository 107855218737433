import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { NgClass, NgStyle } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	OnDestroy,
	Output,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
	inject
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { LanguagePipe } from '@yuno/angular/pipes';
import { LanguageStringsModel, TextListItemComponents } from '@yuno/api/interface';

@Component({
    selector: 'yuno-textfield-list-dropdown',
    template: `
		<button
			#button
			type="button"
			(click)="toggleDropdown()"
			[ngStyle]="{
				color: active ? color : null,
				backgroundColor: active ? backgroundColor : null
			}">
			<span class="sr-only">Open a Dropdown</span>
			<span class="truncate">
				{{ currentValue || defaultValue | languageSelect: language }}
			</span>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="h-4 w-4"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				[ngClass]="{ '-scale-y-100': isDropdownOpen }">
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="M19 9l-7 7-7-7" />
			</svg>
		</button>

		<ng-template #dropdownContainer>
			<ul class="h-auto w-full overflow-auto rounded-md bg-gray-100 shadow-md">
				@if (resettable) {
					<li>
						<button
							type="button"
							(click)="onSelect('')"
							class="h-10 w-full truncate bg-gray-100 px-4 py-2 text-left transition-colors hover:bg-gray-200">
							{{ resetValue }}
						</button>
					</li>
				}
				@for (option of options; track option) {
					<li>
						<button
							type="button"
							(click)="onSelect(option.id)"
							class="w-full truncate bg-gray-100 px-4 py-2 text-left transition-colors hover:bg-gray-200">
							{{ option.title | languageSelect: language }}
						</button>
					</li>
				}
			</ul>
		</ng-template>
	`,
    styleUrls: ['./dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgStyle, NgClass, LanguagePipe]
})
export class TextListDropdownComponent implements OnDestroy {
	@HostBinding('class.yuno__textfield__styling') private textStyling = true;

	private overlay = inject(Overlay);
	private viewContainerRef = inject(ViewContainerRef);
	private cdr = inject(ChangeDetectorRef);

	@ViewChild('button', { read: ElementRef }) button: ElementRef;
	@ViewChild('dropdownContainer', { read: TemplateRef }) dropdownContainer: TemplateRef<unknown>;

	private overlayRef: OverlayRef;
	private _activeItem: string;

	_selectOptions: string[];
	_currentValue: string;

	active = false;

	isDropdownOpen = false;

	private destroyed$ = new Subject<boolean>();

	@Input() language?: string;

	private _color = '#fff';
	@Input() set color(color: string | undefined) {
		this._color = color || '#fff';
	}

	get color(): string {
		return this._color;
	}

	private _backgroundColor = '#00ADF1';
	@Input() set backgroundColor(color: string | undefined) {
		this._backgroundColor = color || '#00ADF1';
	}

	get backgroundColor(): string {
		return this._backgroundColor;
	}

	@Input() resettable = true;
	@Input() resetValue?: string;
	@Input() defaultValue?: string;

	@Input()
	set currentValue(val: string) {
		this._currentValue = val;
	}

	get currentValue(): string {
		return this._currentValue;
	}

	options: {
		id?: string;
		title?: LanguageStringsModel;
	}[];

	_items?: TextListItemComponents[];
	@Input() set items(items: TextListItemComponents[] | undefined) {
		this._items = items;
		items && this.setItems(items);
	}

	get items(): TextListItemComponents[] | undefined {
		return this._items;
	}

	@Output() activeValue = new EventEmitter<string>();

	setItems(items: TextListItemComponents[]): void {
		this.options = items?.map(item => {
			return {
				id: item.id,
				title: item.title
			};
		});

		const active = items.find(item => item.active);
		if (active && this._activeItem !== active.id) {
			this._activeItem = active.id as string;
			this.onSelect(this._activeItem);
		}
	}

	onSelect(val?: string) {
		if (!val) {
			return;
		}

		// Only fire once
		if (this.currentValue === val) {
			return;
		}

		this.active = val.length >= 1;

		this.currentValue = val;
		this.activeValue.emit(val);

		this.cdr.markForCheck();

		this.destroyDropdown();
	}

	// DROPDOWN OPTIONS
	toggleDropdown(): void {
		this.isDropdownOpen ? this.destroyDropdown() : this.openDropdown();
	}

	openDropdown(): void {
		this.isDropdownOpen = true;

		this.overlayRef = this.overlay.create({
			width: this.button.nativeElement.clientWidth,
			hasBackdrop: true,
			maxHeight: '96%',
			backdropClass: 'cdk-overlay-transparent-backdrop',
			scrollStrategy: this.overlay.scrollStrategies.close(),
			positionStrategy: this.overlay
				.position()
				.flexibleConnectedTo(this.button)
				.withPositions([
					{
						originX: 'start',
						originY: 'bottom',
						overlayX: 'start',
						overlayY: 'top',
						offsetY: -8
					}
				])
		});

		const templatePortal = new TemplatePortal(this.dropdownContainer, this.viewContainerRef);
		this.overlayRef.attach(templatePortal);

		this.destroyed$.next(false);
		this.overlayRef
			.backdropClick()
			.pipe(takeUntil(this.destroyed$))
			.subscribe(() => this.destroyDropdown());
	}

	destroyDropdown(): void {
		if (!this.overlayRef || !this.isDropdownOpen) {
			return;
		}

		this.isDropdownOpen = false;
		this.overlayRef.detach();

		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	ngOnDestroy(): void {
		this.destroyDropdown();
		this.destroyed$.complete();
	}
}
