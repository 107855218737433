import { Injectable, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store, select } from '@ngrx/store';
import { Feature } from 'geojson';
import { MapGeoJSONFeature } from 'maplibre-gl';
import { merge } from 'rxjs';

import { OpenTextfield, Textfield } from '@yuno/api/interface';

import { textfieldOverlayActions } from './textfield-overlays.actions';
import { OverlayTypes, textfieldOverlayFeature } from './textfield-overlays.state';

@Injectable()
export class TextfieldOverlayFacade {
	private readonly store = inject(Store);

	rightOverlay$ = this.store.pipe(select(textfieldOverlayFeature.getTextfieldOverlay('right')));
	rightFullOverlay$ = this.store.pipe(
		select(textfieldOverlayFeature.getTextfieldOverlay('right-full'))
	);
	leftOverlay$ = this.store.pipe(select(textfieldOverlayFeature.getTextfieldOverlay('left')));
	leftBottomOverlay$ = this.store.pipe(
		select(textfieldOverlayFeature.getTextfieldOverlay('left-bottom'))
	);
	leftFullOverlay$ = this.store.pipe(
		select(textfieldOverlayFeature.getTextfieldOverlay('left-full'))
	);
	fullOverlay$ = this.store.pipe(select(textfieldOverlayFeature.getTextfieldOverlay('full')));

	$mobileOverlay = toSignal(
		merge(
			this.rightOverlay$,
			this.rightFullOverlay$,
			this.leftOverlay$,
			this.leftBottomOverlay$,
			this.leftFullOverlay$,
			this.fullOverlay$
		)
	);

	setAppLanguage(language: string) {
		this.store.dispatch(textfieldOverlayActions.setLanguage({ language }));
	}

	closeAllOverlays(): void {
		this.store.dispatch(textfieldOverlayActions.closeAllOverlays());
	}

	openOverlay(
		overlay: OverlayTypes,
		textfield: string,
		appId: string,
		data?: Feature | MapGeoJSONFeature
	): void {
		this.store.dispatch(
			textfieldOverlayActions.loadTextfieldOverlay({
				overlay,
				textfield,
				appId,
				data
			})
		);
	}

	openOverlayEvent(
		options: OpenTextfield,
		appId: string,
		feature?: Feature | MapGeoJSONFeature
	): void {
		this.store.dispatch(
			textfieldOverlayActions.loadTextfieldOverlay({
				overlay: options.overlay || 'left',
				textfield: options.id as string,
				data: feature,
				appId
			})
		);
	}

	openFakeTextfieldOverlay(textfield: Textfield, feature?: Feature | MapGeoJSONFeature) {
		this.store.dispatch(
			textfieldOverlayActions.loadTextfieldOverlaySucces({
				overlay: 'left',
				textfield: textfield,
				data: feature
			})
		);
	}

	closeOverlay(overlay: OverlayTypes, timeout: number): void {
		this.store.dispatch(textfieldOverlayActions.closeOverlay({ overlay, timeout }));
	}
}
