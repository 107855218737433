import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
    selector: 'yuno-select-field',
    templateUrl: './select-field.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SelectFieldComponent {
	@Input() display: string;
	@Input() draggable = false;
	@Input() disabled = false;
	@Output() clicked = new EventEmitter<boolean>();

	selectField() {
		this.clicked.emit(true);
	}
}
