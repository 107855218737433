import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
	ViewChild,
	inject
} from '@angular/core';
import { Feature, Point } from 'geojson';
import { GeoJSONSource, LngLat, LngLatLike, Map, Marker, PointLike } from 'maplibre-gl';

import {
	LanguageType,
	MapMarker,
	MapMarkerData,
	MarkerEvent,
	MarkerIconStyle,
	MarkerLabelStyle,
	MarkerPanoramaStyle,
	MarkerPhotoStyle,
	MarkerType,
	instanceOfMarkerIconStyle,
	instanceOfMarkerLabelStyle,
	instanceOfMarkerPanoramaStyle,
	instanceOfMarkerPhotoStyle
} from '@yuno/api/interface';

import { ClusterClickEvent } from '../cluster/cluster.component';

export interface MapboxMarkerEvent {
	type: string;
	options: {
		name: string;
		zoom: number;
		view: 'min' | 'max';
		coordinates: PointLike;
	};
}

export interface MapboxMarker {
	public: boolean;
	selected: boolean;
	coordinates: PointLike;
	_id: string;
	name: string;
	style: {
		visibility: {
			visibility: 'visible' | 'none';
			zoom: {
				minzoom: number;
				maxzoom: number;
			};
		};
		html: string;
		class: string;
		marker: true;
		style: {
			backgroundColor: string;
			color: string;
			borderColor: string;
		};
	};
	events: {
		mouseover: MapboxMarkerEvent[];
		click: MapboxMarkerEvent[];
	};
}

export interface MapView {
	center?: number[];
	zoom?: number;
	bearing?: number;
	pitch?: number;
}

@Component({
    selector: 'yuno-project-atlas-marker',
    template: `
		@switch (getMarkerType()) {
			@case ('cluster') {
				<yuno-marker-cluster
					#marker
					[ngStyle]="{ zIndex: markerData.style.zIndex || 10 }"
					(clusterClick)="clusterClick($event)"
					[data]="markerData"
					[style]="markerIconStyle"></yuno-marker-cluster>
			}
			@case ('icon') {
				<yuno-marker-icon
					#marker
					[draggable]="draggable"
					(clickEvent)="onMouseClickEvent($event)"
					(moveEvent)="onMouseHoverEvent($event)"
					(mouseleave)="onMouseLeave()"
					[ngStyle]="{ zIndex: markerData.style.zIndex || 10 }"
					[data]="markerData"
					[placeMarker]="placeMarker"
					[selectEnabled]="selectEnabled"
					[selected]="selected"
					[style]="markerIconStyle"></yuno-marker-icon>
			}
			@case ('photo') {
				<yuno-marker-photo
					#marker
					[mapRotation]="mapBearing"
					[draggable]="draggable"
					(clickEvent)="onMouseClickEvent($event)"
					(moveEvent)="onMouseHoverEvent($event)"
					(mouseleave)="onMouseLeave()"
					[ngStyle]="{ zIndex: markerData.style.zIndex || 10 }"
					[data]="markerData"
					[placeMarker]="placeMarker"
					[selectEnabled]="selectEnabled"
					[selected]="selected"
					[style]="markerPhotoStyle"></yuno-marker-photo>
			}
			@case ('label') {
				<yuno-marker-label
					#marker
					[draggable]="draggable"
					(clickEvent)="onMouseClickEvent($event)"
					(moveEvent)="onMouseHoverEvent($event)"
					(mouseleave)="onMouseLeave()"
					[ngStyle]="{ zIndex: markerData.style.zIndex || 10 }"
					[data]="markerData"
					[language]="language"
					[placeMarker]="placeMarker"
					[style]="markerLabelStyle"></yuno-marker-label>
			}
			@case ('panorama') {
				<yuno-marker-panorama
					#marker
					[draggable]="draggable"
					(clickEvent)="onMouseClickEvent($event)"
					(moveEvent)="onMouseHoverEvent($event)"
					(mouseleave)="onMouseLeave()"
					[ngStyle]="{ zIndex: markerData.style.zIndex || 10 }"
					[data]="markerData"
					[language]="language"
					[style]="markerPanoramaStyle"></yuno-marker-panorama>
			}
		}
	`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class YunoMarkerComponent implements OnDestroy, AfterViewInit {
	private readonly cdr = inject(ChangeDetectorRef);
	private _language: LanguageType = 'nl';
	private _map: Map;

	mapBearing = 0;

	markerData: MapMarkerData;
	markerType: MarkerType;

	// Reference to the Map Marker
	markerRef: Marker;

	// Marker Styling
	markerIconStyle: MarkerIconStyle;
	markerPhotoStyle: MarkerPhotoStyle;
	markerLabelStyle: MarkerLabelStyle;
	markerPanoramaStyle: MarkerPanoramaStyle;

	@Input() set map(map: Map) {
		this._map = map;
		this.updateMapBearing();
	}

	get map(): Map {
		return this._map;
	}

	@Input() set data(data: MapMarker) {
		this.markerData = new MapMarkerData(data);
		this.createMarker();
	}

	@Input() set dataObj(data: MapMarkerData) {
		this.markerData = data;
		this.createMarker();
	}

	@Input() selectEnabled = false;
	@Input() selected: string;
	@Input() placeMarker = false;

	private _draggable = false;
	@Input() set draggable(bool: boolean) {
		this._draggable = bool;
		this.setDraggable();
	}

	get draggable(): boolean {
		return this._draggable;
	}

	private _setMarkerType: MarkerType | undefined;
	@Input() set setMarkerType(type: MarkerType | undefined) {
		this._setMarkerType = type;
	}

	get setMarkerType(): MarkerType | undefined {
		return this._setMarkerType;
	}

	@Input() geometry: Point;

	@Input() set language(lang: string) {
		this._language = lang;
	}

	get language(): LanguageType {
		return this._language;
	}

	@ViewChild('marker', { static: false, read: ElementRef }) marker: ElementRef;
	@ViewChild('markerIcon', { static: false, read: ElementRef }) markerIcon: ElementRef;

	@Output() mouseHoverEvent = new EventEmitter<MarkerEvent>();
	@Output() mouseClickEvent = new EventEmitter<MarkerEvent>();
	@Output() mouseLeaveEvent = new EventEmitter<boolean>();
	@Output() dragEnd = new EventEmitter<LngLat>();

	/**
	 * Creates the Marker After initialization
	 */
	ngAfterViewInit(): void {
		this.createMarker();
	}

	updateMapBearing(): void {
		if (!this.map) {
			return;
		}

		this.mapBearing = this.map.getBearing();
		this.map.on('rotate', () => {
			this.mapBearing = this.map.getBearing();
			// We only need to update Photos when rotation changes
			if (this.markerType === 'photo') {
				this.cdr.detectChanges();
			}
		});
	}

	private createMarker() {
		const el = this.marker?.nativeElement;
		if (!el) {
			console.warn('MapMarker > no element is found');
			return;
		}

		if (this.markerRef) {
			this.markerRef.remove();
		}

		this.markerRef = new Marker({
			element: el,
			draggable: this.draggable,
			pitchAlignment: ['panorama'].includes(this.markerType) ? 'map' : 'auto',
			rotationAlignment: ['panorama'].includes(this.markerType) ? 'map' : 'auto'
		})
			.setLngLat(this.geometry.coordinates as LngLatLike)
			.addTo(this.map);

		this.markerRef.on('dragend', () => this.dragEnd.emit(this.markerRef.getLngLat()));
	}

	setDraggable(): void {
		this.markerRef?.setDraggable(this.draggable);
	}

	/**
	 * Expands the Map view to the clicked Cluster Marker
	 * Using a native Mapbox/Maplibre function
	 *
	 * @param {ClusterClickEvent} event
	 */
	async clusterClick(event: ClusterClickEvent): Promise<void> {
		const source = this.map.getSource(event.source) as GeoJSONSource;
		const zoom = await source?.getClusterExpansionZoom(event.id);
		if (zoom) {
			this.map.easeTo({
				center: this.geometry.coordinates as LngLatLike,
				zoom: (zoom as number) || this.map.getZoom() + 1
			});
		}
	}

	/**
	 * Determines the Type of marker needed to display
	 * Then sets the needed style data
	 *
	 * @returns {MarkerType | null}
	 */
	getMarkerType(): MarkerType | null {
		if (!this.markerData || !this.markerData.style) {
			return null;
		}

		const style = this.markerData.style;

		// clusters need a cluster_source. If they do not
		// have a source they will not function.
		// All other Markers should not have this type of data
		//
		// Clusters use a MarkerIconStyle to display
		if (this.markerData.cluster?.cluster_source) {
			this.markerIconStyle = style as MarkerIconStyle;
			this.markerType = 'cluster';
			return this.markerType;
		}

		// Check if the Marker should be a Panorama Marker
		if (
			this.setMarkerType === 'panorama' ||
			(!this.setMarkerType && instanceOfMarkerPanoramaStyle(style))
		) {
			this.markerPanoramaStyle = style as MarkerPanoramaStyle;
			this.markerType = 'panorama';
			return this.markerType;
		}

		// Check if the Marker should be of a Icon type
		if (
			this.setMarkerType === 'icon' ||
			(!this.setMarkerType &&
				instanceOfMarkerIconStyle(style) &&
				style.icon &&
				style.icon !== 'icon')
		) {
			this.markerIconStyle = style as MarkerIconStyle;
			this.markerType = 'icon';
			return this.markerType;
		}

		// Check if the Marker should be of a Photo type
		if (
			this.setMarkerType === 'photo' ||
			(!this.setMarkerType && instanceOfMarkerPhotoStyle(style))
		) {
			this.markerPhotoStyle = style as MarkerPhotoStyle;
			this.markerType = 'photo';
			return this.markerType;
		}

		// Check if the Marker should be a Label
		if (
			this.setMarkerType === 'label' ||
			(!this.setMarkerType && instanceOfMarkerLabelStyle(style))
		) {
			this.markerLabelStyle = style as MarkerLabelStyle;
			this.markerType = 'label';
			return this.markerType;
		}

		// When no markerType is found we return null
		// this stops the generation of a unstyled Marker on the Map
		return null;
	}

	onMouseClickEvent(events: MarkerEvent) {
		const feature: Feature = {
			type: 'Feature',
			properties: this.markerData,
			geometry: this.geometry
		};

		this.mouseClickEvent.emit({ ...events, feature });
	}

	onMouseHoverEvent(events: MarkerEvent) {
		const feature: Feature = {
			type: 'Feature',
			properties: this.markerData,
			geometry: this.geometry
		};

		this.mouseHoverEvent.emit({ ...events, feature });
	}

	onMouseLeave(): void {
		this.mouseLeaveEvent.emit(true);
	}

	/**
	 * After the Marker is not needed anymore,
	 * we have to remove it from the Map
	 */
	ngOnDestroy(): void {
		this.markerRef?.remove();
	}
}
