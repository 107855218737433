import { DragDropModule } from '@angular/cdk/drag-drop';
import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { combineLatest, startWith, tap } from 'rxjs';

import { YunoAdminButtonComponent } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import {
	YunoFormsColorComponent,
	YunoFormsDividerComponent,
	YunoFormsSpanComponent,
	YunoFormsTextComponent,
	YunoFormsTitleComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';

import { TextEditorsService } from '../text-editors.service';

@Component({
	selector: 'yuno-admin-list-editor',
	imports: [
		ReactiveFormsModule,
		YunoFormsDividerComponent,
		YunoFormsTitleComponent,
		YunoFormsTextComponent,
		YunoFormsSpanComponent,
		YunoFormsColorComponent,
		YunoFormsToggleComponent,
		DragDropModule,
		AsyncPipe,
		YunoAdminButtonComponent
	],
	templateUrl: './list-editor.component.html',
	styleUrls: ['./list-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	private readonly cdr = inject(ChangeDetectorRef);
	readonly editService = inject(TextEditorsService);

	service$ = this.editService.getActiveService().pipe(
		tap(service => {
			this.editService.service = service;
		})
	);
	data$ = combineLatest({
		toggle: this.editService.eventToggle$.pipe(
			startWith(false),
			tap(bool => {
				if (!bool) {
					this.cdr.markForCheck();
				}
			})
		),
		language: this.editService.language$
	});

	ngOnInit() {
		this.languageSelector();
	}

	ngOnDestroy() {
		this.languageService.destroyLanguageSelector();
		this.editService.service = undefined;
	}
}
