import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	NgZone,
	OnInit,
	Optional,
	Self,
	inject
} from '@angular/core';
import {
	ControlValueAccessor,
	FormControl,
	FormGroup,
	NgControl,
	UntypedFormControl
} from '@angular/forms';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { tap } from 'rxjs';
import { v4 as uuid } from 'uuid';

import {
	FileLibraryEditContainerComponent,
	FileLibraryService
} from '@yuno/admin/features/file-library';

const TinyMceInit: EditorComponent['init'] = {
	content_css: ['/assets/tiny/modern-normalize.css', '/assets/tiny/tinymce.styles.css'],
	promotion: false,
	suffix: '.min',
	height: 400,
	plugins:
		'searchreplace autolink directionality code visualblocks visualchars link codesample table nonbreaking anchor insertdatetime advlist lists wordcount quickbars autoresize',
	paste_as_text: true,
	branding: false,
	menubar: 'edit insert format table',
	quickbars_insert_toolbar: false,
	contextmenu: 'copy paste | link image inserttable | cell row column deletetable',
	table_style_by_css: true,
	skin: 'oxide',
	toolbar:
		'undo redo | blocks | bold italic blockquote | fontsizeselect formatselect |  numlist bullist | removeformat link codesample | code'
};

@Component({
	selector: 'yuno-tinymce-editor-image-library',
	template: `
		@if (init && controlValue && id) {
			<editor
				[id]="id"
				[init]="init"
				[ngModel]="controlValue"
				(ngModelChange)="onModelChange($event)"></editor>
		}
	`,
	styleUrls: ['./tinymce-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false
})
export class TinymceEditorComponent implements OnInit {
	readonly id = uuid();

	tinymceEditor: EditorComponent['init'] = TinyMceInit;
	init: EditorComponent['init'];

	@Input() control: UntypedFormControl = new UntypedFormControl('');
	@Input() validate = false;
	@Input() validationMessage: string;
	@Input() height: 400;

	get controlValue(): string {
		return this.control.value || '';
	}

	ngOnInit(): void {
		this.init = {
			...this.tinymceEditor,
			...{ height: this.height }
		};
	}

	onModelChange(val: string): void {
		this.control.patchValue(val);
	}
}

const NOOP_VALUE_ACCESSOR: ControlValueAccessor = {
	writeValue(): void {
		//
	},
	registerOnChange(): void {
		//
	},
	registerOnTouched(): void {
		//
	}
};

@Component({
	selector: 'yuno-tinymce-editor-image-library-controlname',
	template: `
		<ng-container [formGroup]="getFormGroup()">
			@if (!!ngControl.disabled) {
				<div
					class="absolute bottom-0 left-0 right-0 top-6 z-100 cursor-default rounded-lg border-2 border-gray-400 bg-gray-400/30"></div>
			}
			<editor [id]="id" [init]="init" [formControlName]="ngControl.name"></editor>

			@if ({ img: selectImage$ | async, close: close$ | async }; as data) {}
		</ng-container>
	`,
	styleUrls: ['./tinymce-editor.component.scss'],
	standalone: false
})
export class TinymceEditorFormControlNameComponent implements OnInit {
	private readonly imageService = inject(FileLibraryService);
	private readonly cdr = inject(ChangeDetectorRef);
	private readonly zone = inject(NgZone);
	private overlay = inject(Overlay);
	private overlayRef?: OverlayRef;
	private browse = false;

	readonly id = uuid();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private editor: any;
	tinymceEditor: EditorComponent['init'] = TinyMceInit;
	init: EditorComponent['init'];

	@Input() validationMessage: string;
	@Input() height: 400;
	@Input() disabled = false;

	selectImage$ = this.imageService.activeFile$.pipe(
		tap(file => {
			if (this.browse) {
				this.editor?.insertContent(`<img src="${file.data?.url}"></img>`);
			}
		})
	);

	close$ = this.imageService.close$.pipe(
		tap(() => {
			if (this.browse) {
				this.closeOverlay();
			}
		})
	);

	constructor(
		@Self()
		@Optional()
		public ngControl: NgControl
	) {
		if (this.ngControl) {
			this.ngControl.valueAccessor = NOOP_VALUE_ACCESSOR;
		}
	}

	ngOnInit(): void {
		this.init = {
			...this.tinymceEditor,
			...{ height: this.height },
			...{ toolbar: this.tinymceEditor?.toolbar + ' | imageLibrary' },
			...{
				quickbars_image_toolbar: 'alignleft aligncenter alignright | replaceImage'
			},
			...{
				setup: editor =>
					this.zone.runOutsideAngular(() => {
						this.setup(editor);
						this.cdr.detectChanges();
					})
			}
		};
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setup(editor: any) {
		this.editor = editor;
		editor.ui.registry.addButton('imageLibrary', {
			text: 'Add Image from Library',
			onAction: () => {
				this.openImageLibrary();
			}
		});

		// @ts-ignore - tinymce types are not up to date
		editor.ui.registry.addButton('replaceImage', {
			text: 'Replace Image',
			onAction: () => {
				this.openImageLibrary();
			}
		});
	}

	openImageLibrary(): void {
		this.overlayRef = this.overlay.create({
			positionStrategy: this.overlay.position().global(),
			scrollStrategy: this.overlay.scrollStrategies.reposition(),
			hasBackdrop: false,
			// tinymce uses high z-indexes
			panelClass: 'z-10000'
		});

		const imageEditor = new ComponentPortal(FileLibraryEditContainerComponent);
		const t = this.overlayRef?.attach(imageEditor);
		t?.changeDetectorRef?.detectChanges();
		this.browse = true;
	}

	closeOverlay(): void {
		this.overlayRef?.dispose();
		this.overlayRef = undefined;
		this.browse = false;
	}

	getFormControl(): FormControl {
		return this.ngControl.control as FormControl;
	}

	getFormGroup(): FormGroup {
		return this.ngControl.control?.parent as FormGroup;
	}
}
