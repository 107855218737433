module.exports = {
  A: {
    A: {
      "2": "K D E F A B iC"
    },
    B: {
      "2": "6 7 8 9 C L M G N O P Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I"
    },
    C: {
      "33": "0 1 2 3 4 5 6 7 8 9 J MB K D E F A B C L M G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC kC lC",
      "164": "jC IC mC nC"
    },
    D: {
      "2": "0 1 2 3 4 5 6 7 8 9 J MB K D E F A B C L M G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC"
    },
    E: {
      "2": "J MB K D E F A B C L M G oC PC pC qC rC sC QC CC DC tC uC vC RC SC EC wC FC TC UC VC WC XC xC GC YC ZC aC bC cC yC HC dC eC fC zC"
    },
    F: {
      "2": "0 1 2 3 4 5 F B C G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x 0C 1C 2C 3C CC gC 4C DC"
    },
    G: {
      "2": "E PC 5C hC 6C 7C 8C 9C AD BD CD DD ED FD GD HD ID JD KD LD MD ND OD RC SC EC PD FC TC UC VC WC XC QD GC YC ZC aC bC cC RD HC dC eC fC"
    },
    H: {
      "2": "SD"
    },
    I: {
      "2": "IC J I TD UD VD WD hC XD YD"
    },
    J: {
      "2": "D A"
    },
    K: {
      "2": "A B C H CC gC DC"
    },
    L: {
      "2": "I"
    },
    M: {
      "33": "BC"
    },
    N: {
      "2": "A B"
    },
    O: {
      "2": "EC"
    },
    P: {
      "2": "0 1 2 3 4 5 J y z ZD aD bD cD dD QC eD fD gD hD iD FC GC HC jD"
    },
    Q: {
      "2": "kD"
    },
    R: {
      "2": "lD"
    },
    S: {
      "33": "mD nD"
    }
  },
  B: 5,
  C: "CSS element() function",
  D: true
};