import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	HostBinding,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { isEqual } from 'lodash';
import { combineLatest, debounceTime, startWith, take, tap } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import { DatasetsFacade } from '@yuno/admin/features/datasets';
import { FencesFacade } from '@yuno/admin/features/fences';
import { MapFacade } from '@yuno/admin/features/map';
import { ThemeFacade } from '@yuno/admin/features/themes';
import {
	EditContainerContentDirective,
	YunoAdminCloseButtonComponent,
	YunoAdminSaveButtonComponent,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { AppDataComponent, redirectTo } from '@yuno/admin/utils';
import { AuthFacade } from '@yuno/angular-auth';
import {
	DialogItem,
	MessageDialogComponent,
	MessageService,
	ToastItem,
	ToastType
} from '@yuno/angular/notifications';
import { MarkerCategory, YunoUserRolesEnum } from '@yuno/api/interface';

import { MarkerCategoriesFacade } from '../../../data-access';
import { CategoryEditorService } from './category-editor.service';
import { CategoryClusterComponent } from './tabs/cluster-view/category-cluster/category-cluster.component';
import { ListCategoryClusterComponent } from './tabs/cluster-view/list-category-cluster/list-category-cluster.component';
import { CategoryInputEditorComponent } from './tabs/inputs-view/category-input-editor/category-input-editor.component';
import { CategoryInputFieldEditorComponent } from './tabs/inputs-view/category-input-field-editor/category-input-field-editor.component';
import { ListCategoryInputsComponent } from './tabs/inputs-view/list-category-inputs/list-category-inputs.component';
import { CategorySettingsComponent } from './tabs/settings-view/category-settings.component';
import { CategoryStyleEditorComponent } from './tabs/styling-view/category-style-editor/category-style-editor.component';
import { CategoryStylingRulesComponent } from './tabs/styling-view/category-styling-rules/category-styling-rules.component';
import { CategoryStylingComponent } from './tabs/styling-view/category-styling/category-styling.component';
import { ListCategoryStyleComponent } from './tabs/styling-view/list-category-style/list-category-style.component';

export type categoryTabOptions = 'Settings' | 'Inputs' | 'Styling' | 'Cluster';

@Component({
    selector: 'yuno-admin-category-editor',
    imports: [
        YunoEditContainerModule,
        CategorySettingsComponent,
        ListCategoryStyleComponent,
        CategoryStylingComponent,
        CategoryStylingRulesComponent,
        CategoryStyleEditorComponent,
        ListCategoryInputsComponent,
        CategoryInputEditorComponent,
        CategoryInputFieldEditorComponent,
        ListCategoryClusterComponent,
        CategoryClusterComponent,
        AsyncPipe,
        YunoAdminSaveButtonComponent,
        YunoAdminCloseButtonComponent,
        EditContainerContentDirective
    ],
    providers: [CategoryEditorService],
    templateUrl: './category-editor.component.html',
    styleUrls: ['./category-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	@HostBinding('class.fixed') isPopup = false;

	@HostBinding('style.zIndex') get zIndex() {
		return this.isPopup ? 201 : 150;
	}

	private readonly destroyRef = inject(DestroyRef);
	private readonly dialog = inject(MatDialog);

	private message = inject(MessageService);
	private dialogRef: MatDialogRef<MessageDialogComponent>;

	private readonly authFacade = inject(AuthFacade);
	private readonly fenceFacade = inject(FencesFacade);
	private readonly themeFacade = inject(ThemeFacade);
	private readonly mapFacade = inject(MapFacade);
	private readonly categoryFacade = inject(MarkerCategoriesFacade);
	private readonly appFacade = inject(AppFacade);
	private readonly datasetFacade = inject(DatasetsFacade);

	private targetRoute: string[] | undefined;

	service = inject(CategoryEditorService);

	originalData?: Partial<MarkerCategory>;

	tabValue: categoryTabOptions = 'Settings';

	overlay = false;
	inputList = false;
	inputEditor = false;
	styleEditor = false;
	rulesEditor = false;

	data$ = combineLatest({
		userRole: this.authFacade.appRole$.pipe(
			tap(role => {
				if (this.authFacade.userHasMinimalAppRole(YunoUserRolesEnum.EDITOR, role)) {
					this.service.disabled = false;
					this.service.form.enable();
				} else {
					// When no Editor rights disable the form
					this.service.disabled = true;
					this.service.form.disable();
				}
			})
		),
		selected: this.categoryFacade.selectedMarkerCategory$.pipe(
			tap(data => {
				if (!data || (!data._id && !data.id) || isEqual(this.originalData, data)) {
					return;
				}

				this.editContainerBreadcrumbsService.addRoute('data', data?.id);

				this.service.changesMade = 0;
				this.originalData = data;

				this.service.createInputs(this.originalData?.inputs);
				this.service.createStyles(this.originalData?.styles);

				this.service.createEvents('onClick', data.events?.onClick);
				this.service.createEvents('onMouseMove', data.events?.onMouseMove);

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				this.service.form.patchValue(this.originalData as any);

				// createLayoutOptions is used after patching the original formData
				// so we can get the type of the options needed to populate the array
				this.service.createLayoutOptions(this.originalData?.layout?.options);

				// Adds the layers to the form
				// to keep older applications working
				this.service.addLayers(data.layers);

				if (data?._id && this.router.url.includes('create')) {
					redirectTo(
						this.route,
						this.router,
						['view', `${data._id}`, 'map', 'edit'],
						this.targetRoute
					);
				}
			})
		),
		fences: this.fenceFacade.fences$.pipe(
			startWith(null),
			tap(data => {
				if (data && data.length >= 1) {
					this.service.fenceSelectValues = data.map(object => object._id) as string[];
					this.service.fenceSelectDisplay = data.map(object => object.id) as string[];
					this.service.form.updateValueAndValidity();
				}
			})
		),
		themes: this.themeFacade.themes$.pipe(
			startWith(null),
			tap(data => {
				if (data && data.length >= 1) {
					this.service.themeSelectValues = data.map(object => object._id) as string[];
					this.service.themeSelectDisplay = data.map(object => object.id) as string[];
					this.service.form.updateValueAndValidity();
				}
			})
		),
		mapStyles: this.mapFacade.mapStyles$.pipe(
			startWith(null),
			tap(data => {
				if (data && data.length >= 1) {
					this.service.styleSelectValues = data.map(object => object._id) as string[];
					this.service.styleSelectDisplay = data.map(object => object.id) as string[];
					this.service.form.updateValueAndValidity();
				}
			})
		),
		language: this.appFacade.language$,
		parent: this.datasetFacade.parent$
	});

	ngOnInit() {
		this.service.createFormGroup();
		this.onChanges();
		this.onRouting();

		this.languageSelector();
	}

	onChanges(): void {
		this.service.form.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(300))
			.subscribe(() => {
				this.service.changesMade++;
				if (this.isPopup) {
					this.datasetFacade.getParentRoute(this.allRouteParams);
				}
			});
	}

	onRouting(): void {
		this.route.data.pipe(take(1)).subscribe(data => {
			this.isPopup = data['popup'] || false;
		});

		/* 	Detects current url and select dataset from url	*/
		this.route.paramMap.pipe(take(1)).subscribe(data => {
			const id = data.get('id');
			const targetRouteArray = this.route?.parent?.snapshot.pathFromRoot
				.map(s => s.url)
				.reduce((a, e) => {
					return a.concat(e);
				})
				.map(s => s.path);
			this.targetRoute = targetRouteArray?.slice(0, -3);
			if (id === 'create') {
				this.service.addDefaultStyle();
			}

			this.categoryFacade.select(id);
		});
	}

	onSave(): void {
		this.service.form.markAllAsTouched();

		if (this.service.form.valid) {
			const category: Partial<MarkerCategory> =
				this.service.form.getRawValue() as Partial<MarkerCategory>;

			this.categoryFacade.updateSelect(category);
			this.categoryFacade.save();
		} else {
			this.sendToast('One or more of the required inputs are missing!', 'error');
		}
	}

	/* 	navigates back to the Dataset page */
	onClose(): void {
		if (this.service.disableClose) {
			return;
		}
		if (this.service.changesMade <= 1) {
			return this.doClose();
		}

		const data: DialogItem = {
			title: 'Confirm',
			message: 'Changes are made, want to Close without Saving?',
			buttons: [
				{
					key: 'Yes',
					type: 'danger',
					confirm: true
				},
				{
					key: 'Cancel',
					type: 'secondary'
				}
			],
			confirm: 'Confirmed'
		};

		this.dialogRef = this.dialog.open(MessageDialogComponent, {
			data: data
		});

		this.dialogRef
			.afterClosed()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((confirmed: boolean) => {
				if (confirmed) {
					this.doClose();
				}
			});
	}

	private doClose(): void {
		redirectTo(this.route, this.router);
	}

	onSwitchTab(key: string) {
		this.tabValue = key as categoryTabOptions;
		if (this.tabValue === 'Inputs') {
			this.inputEditor = false;
			this.inputList = true;
		}
		if (this.tabValue === 'Styling') {
			this.styleEditor = false;
			this.rulesEditor = false;
		}
	}

	sendToast(message: string, type: ToastType) {
		const item: ToastItem = {
			message: message
		};
		this.message.showToast(item, type);
	}

	ngOnDestroy() {
		this.editContainerBreadcrumbsService.removeRouteName(this.originalData?.id);
		this.languageService.destroyLanguageSelector();
	}
}
