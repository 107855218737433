import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AnnotationComponent } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import {
	YunoFormsSelectComponent,
	YunoFormsTitleComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';
import { YunoButtonComponent } from '@yuno/project-atlas/ui';

import { InternalViewService } from '../../internal-view/internal-view.service';

@Component({
	imports: [
		ReactiveFormsModule,
		YunoButtonComponent,
		YunoFormsTitleComponent,
		YunoFormsSelectComponent,
		YunoFormsToggleComponent,
		AnnotationComponent,
		YunoButtonComponent
	],
	selector: 'yuno-admin-atlas-ui-settings',
	templateUrl: './atlas-ui-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppAtlasUiSettingsComponent extends AppDataComponent {
	readonly service = inject(InternalViewService);
}
