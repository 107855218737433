export const contentEventTypes = [
	'loadDataset',
	'unloadDataset',
	'openPano',
	'openImage',
	'openUrl',
	'clickMarker',
	'setFeatureState',
	'setLayerStyle'
];

export const textEventTypes = [
	'popupText',
	'popupCustomText',
	'openText',
	'openTextfield',
	'closeTextfieldOverlay',
	'embedText'
];

export const zoomEventTypes = [
	'zoomTo',
	'zoomToMarker',
	'zoomToFence',
	'zoomToBounds',
	'zoomToPolygon',
	'zoomToExtend'
];

export const legacyEventTypes = [
	'popupHoverCustomText',
	'customDataPopup',
	'customDataTextPopup',
	'openTextPopup',
	'zoomToFeature',
	'hoverPopup'
];

export function getDisplayNames(e: string[]) {
	const displayArr: string[] = [];
	e.forEach(item => {
		displayArr.push(getEventNames(item));
	});
	return displayArr;
}

export function getEventNames(e: string): string {
	switch (e) {
		case 'popupText':
			return '(Map) Popup a Text Label';
		case 'popupCustomText':
			return '(Map) Popup a Custom Text';
		case 'setFeatureState':
			return 'Set Feature State (Change the GeoJson properties of a Layer)';
		case 'setLayerStyle':
			return 'Set Layer Style';
		case 'popupHoverCustomText':
			return '(legacy) Popup Hover Custom Text';
		case 'customDataPopup':
			return '(legacy) Popup Custom Data';
		case 'customDataTextPopup':
			return '(legacy) Popup Custom Data Text';
		// case 'setLayerState':
		// 	return '(deprecated) Change the GeoJson properties of a Layer (click)';

		case 'loadDataset':
			return 'Load a Dataset';
		case 'unloadDataset':
			return 'Unload a specific Dataset';
		// case 'setFilter':
		// 	return 'Set a Filter';
		case 'clickMarker':
			return 'Emulate Click Event of Map Marker';
		case 'openPano':
		case 'loadPano':
			return 'Open a Panorama';
		case 'openImage':
			return 'Open an Image';
		case 'openUrl':
			return 'Open an url link';
		case 'openText':
		case 'loadText':
			return 'Open a Textfield';
		case 'openTextfield':
			return 'Open a Textfield in an overlay';
		case 'closeTextfieldOverlay':
			return 'Close all Textfield overlays';
		case 'embedText':
			return 'Embed a Textfield';
		// case 'toggleTextfield':
		// 	return '(Legacy) Toggle a Textfield';
		case 'openTextPopup':
		case 'loadTextPopup':
			return '(legacy) Open a Textfield in an overlay';

		case 'zoomTo':
			return 'Zoom to a Coordinate';
		case 'zoomToMarker':
			return 'Zoom to a Marker';
		case 'zoomToFence':
			return 'Zoom to a Fence';
		case 'zoomToBounds':
			return 'Zoom to Bounding Box';
		case 'zoomToFeature':
			return '(legacy) Zoom to a Mapbox Feature';
		case 'zoomToExtend':
			return 'Zoom to the theme extends';
		case 'zoomToPolygon':
			return 'Zoom to a Polygon';
		// case 'clusterExpansionZoom':
		// 	return 'Zoom to the expand of the cluster';
		case 'hoverPopup':
			// same as popupText
			return '(legacy) Show a Text Label on Hover';
		default:
			return '';
	}
}
