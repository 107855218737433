import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList } from '@angular/cdk/drag-drop';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { tap } from 'rxjs';

import { YunoAdminButtonComponent } from '@yuno/admin/ui';
import { moveItemInFormArray } from '@yuno/angular/forms';
import { YunoFormsSpanComponent, YunoFormsTextComponent } from '@yuno/angular/forms/components';
import { AngularPipesModule } from '@yuno/angular/pipes';

import { PredefinedListItemEditorComponent } from '../predefined-editor.component';
import { PredefinedEditorService } from '../predefined-editor.service';
import { PredefinedDefaultEditorComponent } from './_default-editor.component';

@Component({
	selector: 'yuno-admin-predefined-summary-editor',
	imports: [
		ReactiveFormsModule,
		YunoFormsTextComponent,
		YunoFormsSpanComponent,
		CdkDrag,
		CdkDragHandle,
		YunoAdminButtonComponent,
		AngularPipesModule,
		CdkDropList,
		AsyncPipe,
		NgTemplateOutlet
	],
	template: `
		@if (listItem$ | async; as listItem) {}
		@if (editor.getSelector(form) | async; as selector) {
			<form [formGroup]="form">
				<div formGroupName="options" class="flex flex-col gap-4">
					<div class="flex flex-col gap-4">
						<section formGroupName="title">
							@for (lang of languages; track lang) {
								@if (lang === language) {
									<yuno-forms-text
										[language]="lang"
										[formControlName]="lang"
										placeholder="Add title text here"
										label="Paragraph title (optional)" />
								}
							}
						</section>

						<ng-container [ngTemplateOutlet]="template" />

						<section>
							<yuno-forms-span class="font-semibold"> Summary items</yuno-forms-span>
							<div
								formArrayName="list"
								cdkDropList
								(cdkDropListDropped)="drop($event, editor.getList(form))">
								@for (
									alias of editor.getList(form).controls;
									track alias;
									let i = $index
								) {
									<div
										[formGroupName]="i"
										cdkDrag
										class="mb-2 flex w-full flex-row items-center justify-center gap-2 rounded-md border border-gray-300 bg-gray-200 p-2">
										<div cdkDragHandle class="hover:cursor-move">
											<svg
												viewBox="0 0 16 24"
												class="mx-2 h-6 w-4 text-gray-400 group-hover:text-yuno-blue-text"
												fill="currentColor">
												<use href="#drag-icon" />
											</svg>
										</div>
										<span>
											{{ alias.value.content | languageSelect: language }}
										</span>
										<div class="flex-1"></div>
										<div class="flex justify-end gap-2">
											<button
												yuno-admin-button
												color="secondary"
												(click)="
													openOverlay(i, editor.getList(form), selector)
												">
												edit
											</button>
											<button
												yuno-admin-button
												color="danger"
												(click)="
													editor.removeListAt(i, editor.getList(form))
												">
												remove
											</button>
										</div>
									</div>
								}
							</div>
						</section>
						<button
							yuno-admin-button
							color="primary"
							(click)="editor.addListItem(editor.getList(form))"
							[add]="true">
							Add Item
						</button>
					</div>
				</div>
			</form>
		}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PredefinedSummaryEditorComponent extends PredefinedDefaultEditorComponent {
	readonly editor = inject(PredefinedEditorService);

	private overlay = inject(Overlay);
	private overlayRef?: OverlayRef;

	listItem$ = this.editor._closeOverlay.asObservable().pipe(
		tap(toggle => {
			if (toggle) {
				this.closeOverlay();
			}
		})
	);

	async drop(event: CdkDragDrop<unknown>, array: FormArray): Promise<void> {
		moveItemInFormArray(array, event.previousIndex, event.currentIndex);
	}

	openOverlay(i: number, list: FormArray, selector: string): void {
		this.editor._listItem.next(list.controls[i] as FormGroup);
		this.editor._selector.next(selector);

		this.overlayRef = this.overlay.create({
			positionStrategy: this.overlay.position().global(),
			scrollStrategy: this.overlay.scrollStrategies.reposition(),
			hasBackdrop: false,
			// tinymce uses high z-indexes
			panelClass: 'z-10000'
		});

		const itemEditor = new ComponentPortal(PredefinedListItemEditorComponent);
		const t = this.overlayRef?.attach(itemEditor);
		t?.changeDetectorRef?.detectChanges();
	}

	closeOverlay(): void {
		this.overlayRef?.dispose();
		this.overlayRef = undefined;
		this.editor._listItem.next(null);
		this.editor._closeOverlay.next(false);
	}
}
