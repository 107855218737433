@if (service.form) {
	<form [formGroup]="service.form" class="grid grid-cols-2 gap-4">
		<yuno-forms-title class="col-span-2">Marker Styling</yuno-forms-title>
		<yuno-forms-span class="col-span-2 mb-4">
			Define the marker styles to be used for this categories. <br />
			Don't forget to set a default style when using multiple styles. This style will be
			applied when the input field that determines which style is used is empty.
		</yuno-forms-span>
		<yuno-forms-span
			class="col-span-2 rounded-md border border-red-200 bg-red-100 px-2 font-semibold text-red-500">
			Changing the Marker Type to "label" clears all icon images.
		</yuno-forms-span>
		<yuno-forms-select
			(changes)="service.onTypeChange($event)"
			formControlName="type"
			[display]="service.styleType"
			[selectValues]="service.styleType"
			label="Marker type">

		</yuno-forms-select>
		<yuno-forms-number formControlName="zIndex" label="z-index"></yuno-forms-number>
	</form>
}
