import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import {
	EditContainerComponent,
	EditContainerContentDirective,
	YunoAdminButtonComponent,
	YunoAdminCloseButtonComponent
} from '@yuno/admin/ui';
import { JsonFormsModule } from '@yuno/angular-json-forms';
import {
	YunoFormsNumberComponent,
	YunoFormsSelectComponent,
	YunoFormsTextComponent,
	YunoFormsTitleComponent
} from '@yuno/angular/forms/components';

import { ParticipationModelEditorsDefaultComponent } from '../../default.component';

@Component({
	selector: 'yuno-admin-category-styling-rules',
	imports: [
		CommonModule,
		ReactiveFormsModule,
		JsonFormsModule,
		YunoAdminButtonComponent,
		EditContainerComponent,
		EditContainerContentDirective,
		YunoAdminCloseButtonComponent,
		YunoFormsNumberComponent,
		YunoFormsSelectComponent,
		YunoFormsTextComponent,
		YunoFormsTitleComponent
	],
	templateUrl: './style-rules.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipationStylingRulesComponent extends ParticipationModelEditorsDefaultComponent {
	@Output() closePage = new EventEmitter<void>();

	onClosePage(): void {
		this.closePage.emit();
	}

	addRule(): void {
		this.service.addRuleToLayoutOptions();
	}

	removeRule(index: number): void {
		this.service.removeRuleFromLayoutOptions(index);
	}
}
