@if (form) {
	<form [formGroup]="form" class="flex flex-col gap-8">
		@if (language$ | async; as language) {
			<section class="grid grid-cols-2 gap-2" formGroupName="reaction">
				<yuno-forms-title class="col-span-2">Reaction display settings</yuno-forms-title>
				<section formGroupName="reactionTitle">
					@for (lang of languages; track lang) {
						@if (lang === language) {
							<yuno-forms-text
								class="col-span-1"
								[language]="lang"
								[formControlName]="lang"
								placeholder="Question"
								label="Reaction Title" />
						}
					}
				</section>
				<section formGroupName="answerTitle">
					@for (lang of languages; track lang) {
						@if (lang === language) {
							<yuno-forms-text
								class="col-span-1"
								[language]="lang"
								[formControlName]="lang"
								label="Answer Title"
								placeholder="Answer" />
						}
					}
				</section>
			</section>
		}

		<section class="flex flex-col gap-4">
			<span class="text-sm font-semibold">Textfield</span>
			<yuno-admin-search-bar
				[debounceTime]="100"
				[minimumLength]="0"
				(searches)="applyFilter($event)" />

			<yuno-forms-select
				formControlName="textfield"
				[placeholder]="'none'"
				[selectValues]="$filteredKeys()"
				[display]="$filteredValues()" />

			<yuno-admin-annotation>
				<p>
					When using a Textfield you will be able to provide the Keys as options to
					display. Like this:
					<span class="overflow inline-block">
						{{ 'properties.content' | curly }}
					</span>
					or
					<span class="overflow inline-block">
						{{ 'properties.answer' | curly }}
					</span>
				</p>
				<p>
					Excluding these keys:
					<span class="font-semibold">postalcode, postcode, name, email</span>
				</p>
				<p>
					All Keys are selectable by using
					<span class="overflow inline-block">
						{{ 'properties.key' | curly }}
					</span>
					, the question is available using
					<span class="overflow inline-block">
						{{ 'properties.content' | curly }}
					</span>
					, and the answer given is available using
					<span class="overflow inline-block">{{ 'properties.answer' | curly }}</span>
				</p>
				<p>
					If you want to use the Answer Title or Reaction Title from the settings you can
					use
					<span class="overflow inline-block">
						{{ 'properties.answerTitle' | curly }}
					</span>
					or
					<span class="overflow inline-block">
						{{ 'properties.reactionTitle' | curly }}
					</span>
				</p>
			</yuno-admin-annotation>
		</section>
	</form>
}
