import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { LanguageAll } from '@yuno/api/interface';

@Component({
	selector: 'yuno-admin-predefined-default-editor',
	standalone: true,
	template: ``,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PredefinedDefaultEditorComponent {
	@Input() template: TemplateRef<HTMLElement> | null;

	private _language = 'nl';

	languages = LanguageAll;
	@Input() form: FormGroup;

	@Input() set language(language: string | undefined) {
		if (language) {
			this._language = language;
		}
	}

	get language(): string {
		return this._language;
	}
}
