import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	Renderer2,
	inject
} from '@angular/core';

import { LanguageType, MarkerPanoramaStyle } from '@yuno/api/interface';

import { DefaultComponent } from '../default/default.component';

@Component({
	selector: 'yuno-marker-panorama',
	template: `
		<div
			class="marker-panorama"
			[ngStyle]="{ color: style.color }"
			(click)="onClick($event)"
			(mouseenter)="onMove($event, true)"
			(mouseleave)="onMove($event, false)">
			@switch (style.type) {
				@case ('vp') {
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 44 44"
						class="h-[2.75rem] w-[2.75rem]">
						<path fill="#FFF" d="M30.5 14 22 5.5 13.5 14c4.7-4.6 12.3-4.6 17 0z" />
						<circle
							cx="22"
							cy="22"
							r="10"
							fill="rgba(0,0,0,0)"
							stroke="currentColor"
							stroke-width="4" />
					</svg>
				}
				@case ('mp') {
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 44 44"
						class="h-[2.75rem] w-[2.75rem]">
						<path fill="#FFF" d="M30.5 14 22 5.5 13.5 14c4.7-4.6 12.3-4.6 17 0z" />
						<circle cx="22" cy="22" r="11.8" fill="currentColor" />
					</svg>
				}
				@default {
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 44 44"
						class="h-[2.75rem] w-[2.75rem]">
						<path fill="#FFF" d="M30.5 14 22 5.5 13.5 14c4.7-4.6 12.3-4.6 17 0z" />
						<circle cx="22" cy="22" r="11.8" fill="currentColor" />
					</svg>
				}
			}
		</div>

		<!-- draggable marker -->
		@if (draggable) {
			<yuno-marker-crosshair></yuno-marker-crosshair>
		}
	`,
	styleUrls: ['./panorama.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false
})
export class PanoramaComponent extends DefaultComponent {
	private el = inject(ElementRef);
	private renderer = inject(Renderer2);

	private _className: string;
	private _style: MarkerPanoramaStyle;
	private _language: LanguageType = 'nl';

	@Input() set language(lang: string) {
		this._language = lang;
	}
	get language(): LanguageType {
		return this._language;
	}

	@Input() set style(style: MarkerPanoramaStyle) {
		this._style = style;

		this.renderer.setStyle(
			this.el.nativeElement,
			'--icon-rotation',
			`${style?.rotation || 0}deg`,
			2
		);
	}
	get style(): MarkerPanoramaStyle {
		return this._style;
	}

	@Input() set class(className: string) {
		this._className = className;
	}
	get class(): string {
		return this._className;
	}
}
