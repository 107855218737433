import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Directive,
	EventEmitter,
	HostBinding,
	HostListener,
	Input,
	OnInit,
	Output
} from '@angular/core';

import { YunoAdminButtonColors } from '../button';
import { EditContainerBreadcrumbsComponent } from './edit-container-breadcrumbs.component';

interface ContainerButtonDisplay {
	key: string;
	add?: boolean;
	type?: YunoAdminButtonColors;
}

interface ContainerTabDisplay {
	key: string;
	active: boolean;
}

@Component({
	selector: 'yuno-edit-container',
	imports: [CommonModule, EditContainerBreadcrumbsComponent],
	templateUrl: './edit-container.component.html',
	styleUrls: ['./edit-container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditContainerComponent implements OnInit {
	private _tabs: string[] = ['edit'];
	private _startTab: string;
	private _backdrop = true;
	private _overlay = false;
	private _popup = false;
	containerTabs: ContainerTabDisplay[] = [];

	@Input() buttons: ContainerButtonDisplay[];

	@Input()
	set tabs(tabs: string[]) {
		this._tabs = tabs;
		this.containerTabs = [];
		tabs.forEach((tab, index) => {
			this.containerTabs.push({ key: tab, active: index === 0 ? true : false });
		});
	}

	get tabs(): string[] {
		return this._tabs;
	}

	@Input()
	set startTab(tab: string) {
		this._startTab = tab;
	}

	@Input()
	set backdrop(bool: boolean) {
		this._backdrop = bool;
	}

	get backdrop(): boolean {
		return this._backdrop;
	}

	@Input()
	set popup(bool: boolean) {
		this._popup = bool;
	}

	get popup(): boolean {
		return this._popup;
	}

	@Input() autoHeight = false;

	@HostBinding('class') private _className: string;

	@Input()
	set overlay(active: boolean) {
		this._overlay = active;
		this._className = 'overlay';
	}

	get overlay(): boolean {
		return this._overlay;
	}

	/**
	 * How many columns in total (1-12)
	 */
	@Input() col = 2;
	/**
	 * edit-container starting position
	 */
	@Input() pos = 2;
	/**
	 * edit-container column-width
	 * counted from starting position
	 */
	@Input() span = 1;

	activeTab = 0;

	@Output() outputTab = new EventEmitter<ContainerTabDisplay>();

	innerWidth: number;

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.innerWidth = window.innerWidth;
	}

	get containerClass(): string {
		let str = '';

		if (!this.autoHeight) {
			str += 'h-full ';
		}

		if (this.popup) {
			str += 'pt-12 ';
		}

		str += `grid-cols-12 lg:grid-cols-${this.col}`;
		return str;
	}

	get backgroundClass(): string {
		return `col-start-1 col-span-12 lg:col-start-${this.pos} lg:col-span-${this.span}`;
	}

	onTabClick(event: ContainerTabDisplay, index: number) {
		this.containerTabs[this.activeTab].active = false;
		this.activeTab = index;
		this.containerTabs[index].active = true;
		this.outputTab.next({ key: event.key, active: event.active });
	}

	ngOnInit(): void {
		this.innerWidth = window.innerWidth;

		let index = null;
		if (this.containerTabs && this.containerTabs.length) {
			index = this.containerTabs.findIndex(x => x.key === this._startTab);
			if (index >= 1) {
				this.activeTab = index;
				this.containerTabs[0].active = false;
				this.containerTabs[index].active = true;
			}
		}
	}
}

@Directive({
	standalone: true,
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[edit-container-content]'
})
export class EditContainerContentDirective {
	@HostBinding('class') classNames =
		'relative h-full overflow-y-auto p-3 md:p-6 2xl:px-6 bg-gray-100 rounded-md';
	@HostBinding('class.rounded-tl-none') @Input() hasTabs = false;
}
