import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { combineLatest } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import { SpritesheetModule } from '@yuno/admin/features/spritesheets/feature/module/spritesheet.module';
import { YunoFormsSpanComponent, YunoFormsTextComponent } from '@yuno/angular/forms/components';

import { ContentForm, LegendEditorService, legendList } from '../legend-editor.service';

@Component({
	selector: 'yuno-admin-legend-svg-editor',
	imports: [
		ReactiveFormsModule,
		SpritesheetModule,
		YunoFormsTextComponent,
		YunoFormsSpanComponent,
		AsyncPipe
	],
	templateUrl: './legend-svg-editor.component.html',
	styleUrls: ['./legend-svg-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegendSvgEditorComponent {
	private _form: FormGroup<ContentForm> | FormGroup<legendList>;

	data$ = combineLatest({
		appId: this.appFacade.appId$,
		clientId: this.appFacade.clientId$
	});

	@Input() set form(content: FormGroup<ContentForm> | FormGroup<legendList>) {
		this._form = content;
	}

	get form(): FormGroup<ContentForm> | FormGroup<legendList> {
		return this._form;
	}

	constructor(
		public service: LegendEditorService,
		private appFacade: AppFacade
	) {}

	setSvg(svg: string) {
		if (this._form.controls['svg']) {
			this._form.controls['svg'].setValue(svg);
		}
	}
}
