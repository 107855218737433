import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	Renderer2,
	inject
} from '@angular/core';

import { MarkerPhotoStyle } from '@yuno/api/interface';

import { DefaultComponent } from '../default/default.component';

@Component({
	selector: 'yuno-marker-photo',
	template: `
		@if (data) {
			<div
				class="photo-icon"
				(click)="onClick($event)"
				(mouseenter)="onMove($event, true)"
				(mouseleave)="onMove($event, false)">
				<img
					class="photo-icon-image"
					width="48px"
					height="48px"
					src="https://cdn.projectatlas.app/sprites/public/photo-dark.svg"
					alt="photo marker icon" />
				<img
					class="photo-icon-background"
					width="48px"
					height="48px"
					src="https://cdn.projectatlas.app/sprites/public/photo-marker.svg"
					alt="photo marker rotation icon" />

				<!-- embed cluster count -->
				<ng-content></ng-content>
			</div>
			<!-- draggable marker -->
			@if (draggable) {
				<yuno-marker-crosshair class="z-0"></yuno-marker-crosshair>
			}
		}
	`,
	styleUrls: ['./photos.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false
})
export class PhotosComponent extends DefaultComponent {
	private el = inject(ElementRef);
	private renderer = inject(Renderer2);

	private _style: MarkerPhotoStyle;
	private _mapRotation = 0;

	@Input() set mapRotation(rotation: number) {
		this._mapRotation = rotation;
		this.updatePhotoPointer();
	}

	get mapRotation(): number {
		return this._mapRotation;
	}

	@Input() markerRotation: number;

	@Input() set style(style: MarkerPhotoStyle) {
		this._style = style;
		this.updatePhotoPointer();
	}

	get style(): MarkerPhotoStyle {
		return this._style;
	}

	@Input() selectEnabled = false;
	@Input() selected: string;

	updatePhotoPointer(): void {
		const photoRotation = this._style?.rotation || this.markerRotation || 0;

		this.renderer.setStyle(
			this.el.nativeElement,
			'--icon-rotation',
			`${photoRotation - this.mapRotation}deg`,
			2
		);
	}
}
