import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventData, NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';
import { LngLat, Map, MapLibreEvent } from 'maplibre-gl';
import { combineLatest, startWith, tap } from 'rxjs';

import { MarkerEvent } from '@yuno/api/interface';
import { MarkersModule } from '@yuno/project-atlas/ui';
import { waitFor } from '@yuno/shared/helpers';

import { MarkersFacade } from '../../../../../index';
import { MarkersMapViewerService } from './map-viewer.service';

@Component({
	selector: 'yuno-admin-markers-map-viewer',
	imports: [CommonModule, NgxMapLibreGLModule, MarkersModule],
	templateUrl: './map-viewer.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkersMapViewerComponent {
	readonly service = inject(MarkersMapViewerService);

	private markerFacade = inject(MarkersFacade);
	private readonly route = inject(ActivatedRoute);
	private readonly router = inject(Router);

	zoom = this.service.zoom;
	center = this.service.center;
	pitch = this.service.pitch;
	bearing = this.service.bearing;

	data$ = combineLatest({
		language: this.service.language$,
		style: this.service.data$,
		bounds: this.service.bounds$,
		styleBounds: this.markerFacade.mapStyleBounds$.pipe(
			startWith(undefined),
			tap(data => {
				data && !this.service.hasBounds && this.service.setBounds(data);
			})
		),
		boundsOptions: this.service.boundsOptions$,
		draggable: this.service.draggable$
	});

	mapStyle$ = this.markerFacade.mapStyle$;

	async mapLoad(map: Map): Promise<void> {
		this.service.setMap(map);

		await waitFor(1500);
		this.service.animateBounds();
	}

	moveEnd(
		event: MapLibreEvent<MouseEvent | TouchEvent | WheelEvent | undefined> & EventData
	): void {
		this.service.setMapUrl(event);
	}

	dragEnd(lngLat: LngLat): void {
		this.service.setDragend(lngLat);
	}

	markerMouseClickEvent(events: MarkerEvent): void {
		const id = events.feature?.properties?.['id'] as string;
		if (!id) {
			return;
		}

		this.markerFacade.select(id);

		this.router.navigate(['./', 'map', 'edit', id], { relativeTo: this.route });
	}
}
