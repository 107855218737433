import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AnnotationComponent } from '@yuno/admin/ui';
import { YunoFormsSelectComponent, YunoFormsSpanComponent } from '@yuno/angular/forms/components';

import { EventsDefaultFormItemComponent } from '../_default/default-form-item.component';

@Component({
	selector: 'yuno-admin-popup-custom-text',
	imports: [
		ReactiveFormsModule,
		YunoFormsSpanComponent,
		YunoFormsSelectComponent,
		AnnotationComponent,
		AsyncPipe
	],
	templateUrl: './popup-custom-text.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupCustomTextComponent extends EventsDefaultFormItemComponent implements OnInit {
	ngOnInit(): void {
		this.service.getCustomTextfields();
	}
}
