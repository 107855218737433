import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AnnotationComponent } from '@yuno/admin/ui';
import { YunoFormsSpanComponent, YunoFormsTextComponent } from '@yuno/angular/forms/components';

import { EventsDefaultFormItemComponent } from '../_default/default-form-item.component';

@Component({
	selector: 'yuno-admin-open-url',
	imports: [
		ReactiveFormsModule,
		YunoFormsTextComponent,
		YunoFormsSpanComponent,
		AnnotationComponent
	],
	templateUrl: './open-url.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenUrlComponent extends EventsDefaultFormItemComponent {}
