import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { EventsDefaultFormItemComponent } from '../_default/default-form-item.component';

@Component({
	selector: 'yuno-admin-zoom-to-extend',
	imports: [ReactiveFormsModule],
	templateUrl: './zoom-to-extend.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZoomToExtendComponent extends EventsDefaultFormItemComponent {}
