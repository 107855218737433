import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { YunoFormsTextComponent } from '@yuno/angular/forms/components';

import { PredefinedDefaultEditorComponent } from './_default-editor.component';

@Component({
	selector: 'yuno-admin-predefined-heading-editor',
	imports: [ReactiveFormsModule, YunoFormsTextComponent, NgTemplateOutlet],
	template: `
		<form [formGroup]="form">
			<div formGroupName="options" class="flex flex-col gap-4">
				<section formGroupName="title">
					@for (lang of languages; track lang) {
						@if (lang === language) {
							<yuno-forms-text
								[formControlName]="lang"
								[language]="lang"
								placeholder="Add title text here"
								label="Title" />
						}
					}
				</section>
				<ng-container [ngTemplateOutlet]="template" />
			</div>
		</form>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PredefinedHeidingEditorComponent extends PredefinedDefaultEditorComponent {}
