import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
	YunoFormsColorComponent,
	YunoFormsDividerComponent,
	YunoFormsNumberComponent,
	YunoFormsSelectComponent,
	YunoFormsTitleComponent
} from '@yuno/angular/forms/components';

import { CategoryEditorService } from '../../../category-editor.service';

@Component({
	selector: 'yuno-admin-category-cluster',
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		YunoFormsColorComponent,
		YunoFormsDividerComponent,
		YunoFormsNumberComponent,
		YunoFormsSelectComponent,
		YunoFormsTitleComponent
	],
	templateUrl: './category-cluster.component.html',
	styleUrls: ['./category-cluster.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryClusterComponent {
	service = inject(CategoryEditorService);
	counterValue = 2;
}
