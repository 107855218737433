import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { YunoAdminButtonComponent } from '@yuno/admin/ui';
import {
	YunoFormsColorComponent,
	YunoFormsNumberComponent,
	YunoFormsTextComponent,
	YunoFormsTitleComponent
} from '@yuno/angular/forms/components';

import { LegendEditorService, legendList } from '../legend-editor.service';

@Component({
	selector: 'yuno-admin-legend-gradient-editor',
	imports: [
		ReactiveFormsModule,
		YunoAdminButtonComponent,
		YunoFormsColorComponent,
		YunoFormsNumberComponent,
		YunoFormsTextComponent,
		YunoFormsTitleComponent
	],
	templateUrl: './legend-gradient-editor.component.html',
	styleUrls: ['./legend-gradient-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegendGradientEditorComponent {
	private _form: FormGroup<legendList>;

	@Input() set form(content: FormGroup<legendList>) {
		this._form = content;
	}

	get form(): FormGroup<legendList> {
		return this._form;
	}

	constructor(public service: LegendEditorService) {}

	removeGradiantAt(index: number) {
		this.service.removeGradiant(this.service.gradiants, index);
	}

	addGradiant() {
		this.service.addGradiant(this.service.gradiants);
	}
}
