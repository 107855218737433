import { CommonModule, NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	EventEmitter,
	Input,
	Output,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { ReplaySubject, first } from 'rxjs';

import { LanguageType } from '@yuno/api/interface';

import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import {
	JsonButtonEventForm,
	JsonFormData,
	JsonFormErrors,
	JsonFormEvent,
	JsonFormSuccess,
	JsonFormValue
} from './json-forms.models';
import { ActionsService } from './services/actions.service';
import { FormCreatorService } from './services/form-creator.service';
import { LanguageService } from './services/language.service';
import { ValidationService } from './services/validation.service';

@Component({
    selector: 'yuno-json-forms',
    templateUrl: './json-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, DynamicFormComponent, NgClass, FormsModule, ReactiveFormsModule]
})
export class JsonFormComponent {
	private readonly destroyRef = inject(DestroyRef);
	private readonly validationService = inject(ValidationService);
	private readonly formCreator = inject(FormCreatorService);
	private readonly languageService = inject(LanguageService);
	private readonly actionsService = inject(ActionsService);

	private ready$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	private _data?: JsonFormData;
	private _formIndex = 0;
	private _formValue?: JsonFormValue;
	private _noMargin = false;
	private _readOnly = false;

	masterForm: UntypedFormGroup[] = [];

	@Input() multiForm: boolean;

	@Input() set readOnly(bool: boolean) {
		this._readOnly = bool;

		for (const form of this.masterForm) {
			form.disable();
		}
	}

	get readOnly(): boolean {
		return this._readOnly;
	}

	@Input() set language(lang: LanguageType | undefined) {
		this.languageService.setLanguage(lang || 'nl');
	}

	@Input()
	set data(data: JsonFormData | undefined) {
		this.masterForm = [];
		this._data = data;
		this.setupForm();
	}

	get data(): JsonFormData | undefined {
		return this._data;
	}

	@Input()
	set formValue(formValue: JsonFormValue | undefined) {
		if (!formValue) {
			return;
		}

		this._formValue = formValue;
		this.ready$.pipe(first()).subscribe(() => {
			this.formPatcher();
		});
	}

	get formValue(): JsonFormValue | undefined {
		return this._formValue;
	}

	@Input()
	set formIndex(num: number) {
		this._formIndex = num || 0;
	}

	get formIndex(): number {
		return this._formIndex;
	}

	@Input()
	set submitForm(bool: boolean) {
		!!bool && this.onFormSubmit();
	}

	@Input()
	set noMargin(bool: boolean) {
		this._noMargin = bool || false;
	}

	get noMargin(): boolean {
		return this._noMargin;
	}

	@Output() readonly formSubmit: EventEmitter<JsonFormSuccess> =
		new EventEmitter<JsonFormSuccess>();

	@Output() readonly formErrors: EventEmitter<JsonFormErrors> =
		new EventEmitter<JsonFormErrors>();

	@Output() readonly computedForm: EventEmitter<UntypedFormGroup[]> = new EventEmitter<
		UntypedFormGroup[]
	>();

	@Output() readonly formEvent: EventEmitter<JsonFormEvent> = new EventEmitter<JsonFormEvent>();
	@Output() readonly formChanges: EventEmitter<UntypedFormGroup> =
		new EventEmitter<UntypedFormGroup>();

	@Output() readonly buttonAction: EventEmitter<JsonButtonEventForm> =
		new EventEmitter<JsonButtonEventForm>();

	constructor() {
		this.actionsService.action$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(val => this.buttonAction.emit(val));
	}

	setupForm(): void {
		if (!this.data) {
			return;
		}

		for (const controls of this.data.data) {
			const form = this.formCreator.createJsonFormGroup(controls.controls);

			if (this.readOnly) {
				form.disable();
			}

			this.masterForm.push(form);
		}

		this.ready$.next(true);
		this.computedForm.emit(this.masterForm);
	}

	formPatcher(): void {
		for (const [index, form] of this.masterForm.entries()) {
			if (!this.formValue) {
				return;
			}

			const formValue = this.formValue.data[index];
			form.patchValue(formValue);
		}
	}

	onFormSubmit(): void {
		this.validationService.setValidation(true);

		const invalidForms: number[] = [];
		for (const [index, form] of this.masterForm.entries()) {
			if (!form.valid) {
				invalidForms.push(index);
			}
		}

		/* When al forms are valid */
		if (invalidForms.length <= 0) {
			this.emitValidForms();
			this.validationService.setValidation(false);
			return;
		}

		this.emitErrors(invalidForms);
	}

	emitValidForms(): void {
		if (this.multiForm) {
			const formArrayData = this.masterForm.map(form => form.value);
			this.formSubmit.emit({ data: formArrayData });

			return;
		}

		const formData = this.masterForm.reduce(
			(masterForm, currentForm) => ({
				...masterForm,
				...currentForm.value
			}),
			{}
		);
		this.formSubmit.emit({ data: formData });
	}

	emitErrors(arr: number[]): void {
		this.formErrors.emit({
			errors: true,
			formErrorIndices: arr,
			data: this.masterForm
		});
	}
}
