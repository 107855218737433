import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AnnotationComponent } from '@yuno/admin/ui';
import {
	YunoFormsSelectComponent,
	YunoFormsSpanComponent,
	YunoFormsTextComponent
} from '@yuno/angular/forms/components';
import { LoadPanoEvent } from '@yuno/api/interface';

import { EventsDefaultFormItemComponent } from '../_default/default-form-item.component';

@Component({
	selector: 'yuno-admin-load-pano',
	imports: [
		ReactiveFormsModule,
		YunoFormsTextComponent,
		YunoFormsSpanComponent,
		YunoFormsSelectComponent,
		AnnotationComponent,
		AsyncPipe
	],
	templateUrl: './load-pano.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadPanoComponent extends EventsDefaultFormItemComponent implements OnInit {
	ngOnInit(): void {
		this.service.getPanoramas();
		this.onValueChanged((this.form.value.options as LoadPanoEvent['options']).id);
	}
}
