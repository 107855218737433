import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
	FormsModule,
	ReactiveFormsModule,
	UntypedFormControl,
	UntypedFormGroup
} from '@angular/forms';

import { LanguagePipe } from '@yuno/angular/pipes';
import { LanguageStringsModel } from '@yuno/api/interface';

import { JsonFormControl } from '../../../json-forms.models';
import { SafeHtmlPipe } from '../../../pipes/safe-html/safe-html.pipe';

@Component({
    selector: 'yuno-json-forms-select',
    templateUrl: './select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [FormsModule, ReactiveFormsModule, SafeHtmlPipe, LanguagePipe]
})
export class SelectComponent {
	@Input() form: UntypedFormGroup;
	@Input() control: UntypedFormControl;
	@Input() item: JsonFormControl;
	@Input() display?: LanguageStringsModel[];
	@Input() language = 'nl';

	returnDisplay(index: number, select: string) {
		if (this.display && this.display[index]) {
			return this.display[index];
		} else {
			return select;
		}
	}
}
