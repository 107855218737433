module.exports = {
  A: {
    A: {
      "2": "iC",
      "8": "K D E",
      "129": "A B",
      "161": "F"
    },
    B: {
      "1": "6 7 8 9 O P Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I",
      "129": "C L M G N"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC kC lC",
      "2": "jC IC",
      "33": "J MB K D E F A B C L M G mC nC"
    },
    D: {
      "1": "6 7 8 9 WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC",
      "33": "0 1 2 3 4 5 J MB K D E F A B C L M G N O P NB y z OB PB QB RB SB TB UB VB"
    },
    E: {
      "1": "F A B C L M G sC QC CC DC tC uC vC RC SC EC wC FC TC UC VC WC XC xC GC YC ZC aC bC cC yC HC dC eC fC zC",
      "33": "J MB K D E oC PC pC qC rC"
    },
    F: {
      "1": "1 2 3 4 5 OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x DC",
      "2": "F 0C 1C",
      "33": "0 B C G N O P NB y z 2C 3C CC gC 4C"
    },
    G: {
      "1": "AD BD CD DD ED FD GD HD ID JD KD LD MD ND OD RC SC EC PD FC TC UC VC WC XC QD GC YC ZC aC bC cC RD HC dC eC fC",
      "33": "E PC 5C hC 6C 7C 8C 9C"
    },
    H: {
      "2": "SD"
    },
    I: {
      "1": "I",
      "33": "IC J TD UD VD WD hC XD YD"
    },
    J: {
      "33": "D A"
    },
    K: {
      "1": "B C H CC gC DC",
      "2": "A"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "1": "A B"
    },
    O: {
      "1": "EC"
    },
    P: {
      "1": "0 1 2 3 4 5 J y z ZD aD bD cD dD QC eD fD gD hD iD FC GC HC jD"
    },
    Q: {
      "1": "kD"
    },
    R: {
      "1": "lD"
    },
    S: {
      "1": "mD nD"
    }
  },
  B: 4,
  C: "CSS3 2D Transforms",
  D: true
};