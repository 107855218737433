module.exports = {
  A: {
    A: {
      "2": "K D E F A B iC"
    },
    B: {
      "2": "C L M G N O P",
      "1025": "6 7 8 9 d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I",
      "1537": "Q H R S T U V W X Y Z a b c"
    },
    C: {
      "2": "jC",
      "932": "0 1 2 3 4 5 IC J MB K D E F A B C L M G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB mC nC",
      "2308": "6 7 8 9 yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC kC lC"
    },
    D: {
      "2": "J MB K D E F A B C L M G N O P NB y z",
      "545": "0 1 2 3 4 5 OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB",
      "1025": "6 7 8 9 d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC",
      "1537": "gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c"
    },
    E: {
      "1": "FC TC UC VC WC XC xC GC YC ZC aC bC cC yC HC dC eC fC zC",
      "2": "J MB K oC PC pC",
      "516": "B C L M G CC DC tC uC vC RC SC EC wC",
      "548": "F A sC QC",
      "676": "D E qC rC"
    },
    F: {
      "2": "F B C 0C 1C 2C 3C CC gC 4C DC",
      "513": "UB",
      "545": "0 1 2 3 4 5 G N O P NB y z OB PB QB RB SB",
      "1025": "e f g h i j k l m n o p q r s t u v w x",
      "1537": "TB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d"
    },
    G: {
      "1": "FC TC UC VC WC XC QD GC YC ZC aC bC cC RD HC dC eC fC",
      "2": "PC 5C hC 6C 7C",
      "516": "MD ND OD RC SC EC PD",
      "548": "AD BD CD DD ED FD GD HD ID JD KD LD",
      "676": "E 8C 9C"
    },
    H: {
      "2": "SD"
    },
    I: {
      "2": "IC J TD UD VD WD hC",
      "545": "XD YD",
      "1025": "I"
    },
    J: {
      "2": "D",
      "545": "A"
    },
    K: {
      "2": "A B C CC gC DC",
      "1025": "H"
    },
    L: {
      "1025": "I"
    },
    M: {
      "2308": "BC"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1537": "EC"
    },
    P: {
      "545": "J",
      "1025": "0 1 2 3 4 5 y z GC HC jD",
      "1537": "ZD aD bD cD dD QC eD fD gD hD iD FC"
    },
    Q: {
      "1537": "kD"
    },
    R: {
      "1537": "lD"
    },
    S: {
      "932": "mD",
      "2308": "nD"
    }
  },
  B: 5,
  C: "Intrinsic & Extrinsic Sizing",
  D: true
};