import { AsyncPipe } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
	inject
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AsyncSubject, BehaviorSubject, combineLatest, map, startWith, tap } from 'rxjs';

import { LayoutFacade } from '@yuno/admin/features/layout';
import { ChooseTemplateComponent } from '@yuno/admin/features/templates';
import {
	SideOptionsButtonComponent,
	SideOptionsContainerComponent,
	TableChanged,
	YunoAdminButtonsModule,
	YunoAdminTableComponent,
	YunoAdminUiSelectorDropdownItemCounterComponent
} from '@yuno/admin/ui';
import { SideOptionsMenuService } from '@yuno/admin/ui/side-options-menu/side-options-menu.service';
import { AppDataComponent } from '@yuno/admin/utils';
import { DialogItem, MessageService } from '@yuno/angular/notifications';
import {
	Textfield,
	TextfieldComponents,
	UserRolesEnum,
	YunoUserRolesEnum,
	minimalUserRole
} from '@yuno/api/interface';

import { TextfieldFacade } from '../../../data-access';

export type TextfieldFilter = 'all' | 'public' | 'non-public';

@Component({
	imports: [
		YunoAdminButtonsModule,
		RouterModule,
		YunoAdminTableComponent,
		AsyncPipe,
		ChooseTemplateComponent,
		ReactiveFormsModule,
		YunoAdminUiSelectorDropdownItemCounterComponent,
		SideOptionsButtonComponent,
		SideOptionsContainerComponent
	],
	selector: 'yuno-admin-textfield',
	templateUrl: './textfields.component.html',
	styleUrls: ['./textfields.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextfieldsComponent
	extends AppDataComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	private readonly messageService = inject(MessageService);
	private readonly textfieldFacade = inject(TextfieldFacade);
	private readonly layoutFacade = inject(LayoutFacade);
	private readonly sideMenuService = inject(SideOptionsMenuService);
	@ViewChild('sideOptions') sideOptionsTemplate!: TemplateRef<HTMLElement>;

	private _filter = new BehaviorSubject<TextfieldFilter>('all');
	filter$ = this._filter.asObservable();
	$filter = toSignal(this.filter$);
	$allCount = toSignal(this.textfieldFacade.getCount('all'));
	$publicCount = toSignal(this.textfieldFacade.getCount('public'));
	$nonPublicCount = toSignal(this.textfieldFacade.getCount('non-public'));
	$clientCount = toSignal(this.textfieldFacade.getCount('client'));
	$nonClientCount = toSignal(this.textfieldFacade.getCount('non-client'));

	$presets = toSignal(this.textfieldFacade.getPresets());

	/* 	an observerable that checks when legends are collected 	*/
	private _textfieldsChecked = new AsyncSubject<boolean>();

	data$ = combineLatest({
		textfields: combineLatest({
			data: this.textfieldFacade.allTextfields$,
			filter: this.filter$
		}).pipe(
			tap(({ data }) => {
				if (data && data.length >= 1) {
					this.masterToggle = this.getMasterToggle(data);
					if (this.masterToggle) {
						this._textfieldsChecked.next(true);
						this._textfieldsChecked.complete();
					}
				}
			}),
			map(({ data, filter }) => {
				if (data) {
					let textfields = data;
					if (this.disableUserAccess() || filter === 'public') {
						textfields = data
							.filter(text => text.public)
							.map(item => {
								return item;
							});
					}
					if (filter === 'non-public') {
						textfields = data
							.filter(text => !text.public)
							.map(item => {
								return item;
							});
					}

					return textfields as { [key: string]: unknown }[];
				}
				return [] as { [key: string]: unknown }[];
			})
		),
		dialog: this.messageService.dialog$.pipe(
			startWith(undefined),
			tap(confirmed => {
				if (confirmed) {
					this.onToggleAllConfirm();
				}
			})
		),
		selected: this.textfieldFacade.selectedTextfield$,
		checked: this._textfieldsChecked.asObservable().pipe(startWith(false))
	});

	texts: { [key: string]: unknown }[];
	selected: Textfield | undefined;
	masterToggle: 'true' | 'indeterminate' | 'false';
	masterTogglePreset: 'false';

	toggles: {
		checked: boolean;
		filter: string;
	} = {
		checked: false,
		filter: ''
	};
	toggleTemplates = false;

	ngOnInit(): void {
		this.textfieldFacade.get();
	}

	ngAfterViewInit() {
		this.sideMenuService.updateTemplate(this.sideOptionsTemplate);
		this.layoutFacade.activateSideoptions(true);
		this.layoutFacade.toggleSideoptions(true);
	}

	getMasterToggle(data: Partial<Textfield>[]): 'true' | 'indeterminate' | 'false' {
		if (data.every(text => text.public === true)) {
			return 'true';
		}
		if (data.every(text => text.public === false)) {
			return 'false';
		}
		if (data.some(text => text.public === true) && data.some(text => text.public === false)) {
			return 'indeterminate';
		}
		return 'indeterminate';
	}

	onSelectTemplate(bool: boolean) {
		this.toggleTemplates = bool;
	}

	onCreate(components?: TextfieldComponents[]) {
		this.textfieldFacade.clearSelect();
		this.router.navigate(['create'], {
			relativeTo: this.route
		});
		this.textfieldFacade.setTemplate(components);
		this.toggleTemplates = false;
	}

	onCreateFromPreset(id?: string) {
		this.textfieldFacade.clearSelect();
		this.router.navigate(['create'], {
			relativeTo: this.route
		});
		this.textfieldFacade.setPreset(id);
		this.toggleTemplates = false;
	}

	onSelectTextfield(row: Partial<Textfield>): void {
		this.router.navigate(['edit', row._id], {
			relativeTo: this.route
		});
	}

	onDeleteTextfield(row: Partial<Textfield>): void {
		this.textfieldFacade.delete(row._id as string);
	}

	onUpdateToggles(e: TableChanged) {
		// The User should be a XKP member with EDITOR rights
		// and has a EDITOR role inside the application
		if (
			!minimalUserRole(UserRolesEnum.EDITOR) ||
			!this.minimalAppRole(YunoUserRolesEnum.EDITOR)
		) {
			return;
		}

		const textfield = {
			public: e.key === 'public' ? e.checked : e.item['public'],
			preset: e.key === 'preset' ? e.checked : e.item['preset'],
			id: e.item['id'],
			_id: e.item['_id']
		} as Partial<Textfield>;
		this.textfieldFacade.updateTextfield(textfield, e.index);
	}

	onToggleAllPublic(checked: boolean) {
		this.toggles.checked = checked;
		const data: DialogItem = {
			title: 'Confirm toggling textfields',
			message: 'Are you sure you want to toggle the visibility of these textfields?',
			buttons: [
				{
					key: 'Yes',
					type: 'primary',
					confirm: true
				},
				{
					key: 'Cancel',
					type: 'muted'
				}
			],
			confirm: 'Confirmed'
		};
		this.messageService.openDialog(data);
	}

	onToggleAllConfirm() {
		this.textfieldFacade.updateAllTextfields(this.toggles.checked, this.toggles?.filter || '');
	}

	disableUserAccess(): boolean {
		const hasMinimalUserRole = this.minimalRole(this.roles.EDITOR);
		const hasMinimalAppRole = this.minimalAppRole(this.userRoles.EDITOR);
		return !(hasMinimalUserRole && hasMinimalAppRole);
	}

	setFilter(filter: TextfieldFilter): void {
		this._filter.next(filter);
	}

	ngOnDestroy() {
		this.sideMenuService.updateTemplate(null);
		this.layoutFacade.toggleSideoptions(false);
		this.layoutFacade.activateSideoptions(false);
	}
}
