import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { combineLatest, map, take, withLatestFrom } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import { LayoutFacade } from '@yuno/admin/features/layout';
import { NavbarFacade } from '@yuno/admin/features/navbar';
import {
	PillItem,
	SidemenuContainerComponent,
	YunoAdminButtonComponent,
	YunoAdminNavbarButtonsComponent
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { ButtonGroupModule, YunoButtonModule } from '@yuno/project-atlas/ui';

import { MarkerCategoriesFacade } from '../../data-access';
import { PlaceMarkersMapViewerComponent } from './components/map-viewer/map-viewer.component';
import { MapViewerService } from './components/map-viewer/map-viewer.service';

interface PillItemUrl extends PillItem {
	url: string;
	exact: boolean;
}

@Component({
	selector: 'yuno-admin-place-marker-view',
	imports: [
		CommonModule,
		YunoAdminButtonComponent,
		YunoButtonModule,
		ButtonGroupModule,
		RouterModule,
		YunoAdminNavbarButtonsComponent,
		PlaceMarkersMapViewerComponent,
		SidemenuContainerComponent
	],
	templateUrl: './view.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlaceMarkersViewComponent extends AppDataComponent implements OnInit {
	private readonly appFacade = inject(AppFacade);
	private readonly categoryFacade = inject(MarkerCategoriesFacade);
	private readonly mapService = inject(MapViewerService);
	private readonly navbarFacade = inject(NavbarFacade);
	private readonly layoutFacade = inject(LayoutFacade);

	selector: PillItemUrl[] = [
		{
			key: 'Map',
			url: 'map',
			exact: false,
			selected: true
		},
		{
			key: 'List',
			url: 'list',
			exact: false,
			selected: false
		}
	];

	data$ = combineLatest({
		filter: this.mapService.filter$,
		jsonUrl: this.categoryFacade.selectedMarkerCategory$.pipe(
			withLatestFrom(this.appFacade.app$),
			map(([model, app]) => {
				if (!app) {
					return {
						url: undefined,
						fileName: undefined
					};
				}

				this.navbarFacade.addRoute(
					{
						display: model?.id || (Math.random() + 1).toString(36).substring(7),
						index: 3,
						exact: false
					},
					this.route.snapshot
				);

				return {
					url: '',
					fileName: model?.id
				};
			})
		),
		sideoptions: this.layoutFacade.sideoptions$
	});

	ngOnInit(): void {
		this.route.queryParamMap.pipe(take(1)).subscribe(data => {
			const map = data.get('map')?.split(',');
			if (!map) {
				return;
			}

			this.mapService.basedOnRoute = true;
			this.mapService.zoom = Number(map[2]);
			this.mapService.center = [Number(map[1]), Number(map[0])];
			this.mapService.bearing = Number(map[3]);
			this.mapService.pitch = Number(map[4]);
		});

		/* 	Detects current url and select dataset from url	*/
		this.route.paramMap.pipe(take(1)).subscribe(data => {
			const id = data.get('id');
			if (!id) {
				return;
			}
			this.categoryFacade.select(id);
		});
	}

	openEditor(url: string[]): void {
		// An array of route segments to navigate to.
		let navigateTo = ['./'];

		// Get the first child route segment, if it exists.
		const child = this.route.firstChild?.snapshot.url[0].path;
		// Add the child route segment to the navigation array, if it exists.
		if (child) {
			navigateTo.push(child);
		}
		// Add the 'edit' route segment to the navigation array.
		navigateTo = [...navigateTo, ...url];

		// Clear the selected item from the facade.
		this.categoryFacade.clearMarker();

		// Navigate to the specified route segments relative to the current route.
		this.router.navigate(navigateTo, { relativeTo: this.route });
	}
}
