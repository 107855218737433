import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	inject
} from '@angular/core';

import { MessageService, ToastItem } from '@yuno/angular/notifications';
import { Sprites } from '@yuno/api/interface';

import { SpritesheetService } from '../spritesheet.service';

@Component({
	selector: 'yuno-admin-spritesheet-manager',
	templateUrl: './spritesheet-manager.component.html',
	styleUrls: ['./spritesheet-manager.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false
})
export class SpritesheetManagerComponent implements OnInit {
	private readonly service = inject(SpritesheetService);
	private readonly message = inject(MessageService);
	private cdr = inject(ChangeDetectorRef);

	private _clientId: string;
	private _appId: string;

	@Input() disableCopy = false;

	@Input()
	set clientId(value: string) {
		this._clientId = value;
	}

	get clientId(): string {
		return this._clientId;
	}

	@Input()
	set appId(value: string) {
		this._appId = value;
	}

	get appId(): string {
		return this._appId;
	}

	@Output() addSprite = new EventEmitter<boolean>();
	@Output() selectIcon = new EventEmitter<string>();

	sprites: Sprites;
	clientSprites: Sprites;
	yunoSprites: Sprites;

	file: File[];
	images: string[];
	isSdf = false;

	loading = false;

	ngOnInit(): void {
		this.getSprites(this.clientId, this.appId);
	}

	getUrl(url: string): string {
		if (this.clientId) {
			url += `/${this.clientId}`;
		}
		if (this.appId) {
			url += `/${this.appId}`;
		}
		return url;
	}

	async getSprites(clientId: string, appId?: string): Promise<void> {
		try {
			if (appId) {
				/* Load App Sprites */
				this.sprites = await this.service.get(this.getUrl('icon'));
				this.sprites.files = this.sprites.files?.map(e => e + '?' + Date.now());
				this.sprites.sdf = this.sprites.sdf?.map(e => e + '?' + Date.now());
			}

			if (clientId) {
				/* Load Client Sprites */
				this.clientSprites = await this.service.get(`icon/${clientId}`);
			}

			/* Load Yuno Sprites */
			this.yunoSprites = await this.service.get('icon');

			this.loading = false;
			this.cdr.markForCheck();
		} catch (error) {
			this.loading = false;
			this.cdr.markForCheck();
		}
	}

	addSprites() {
		this.addSprite.emit(true);
	}

	async onAddSprite() {
		try {
			const uploading: ToastItem = {
				message: `uploading file...`
			};
			this.message.showToast(uploading, 'info');

			let url = 'icon/';
			if (this.isSdf) {
				url += '/sdf/';
			}

			await this.service.postFile(`${url}/${this.getUrl('icon/')}`, this.file);

			const success: ToastItem = {
				message: `uploaded the sprite!`
			};
			this.message.showToast(success, 'success');
		} catch (error) {
			console.log(error);
			const data: ToastItem = {
				message: `error uploading file!`
			};
			this.message.showToast(data, 'warning');
		}
	}

	copyToClipboard(e: string) {
		this.selectIcon.emit(e);
		if (this.disableCopy) {
			return;
		}

		navigator.clipboard.writeText(e);
		const data: ToastItem = {
			message: `copied to clipboard`
		};
		this.message.showToast(data, 'success');
	}

	viewSpritesheet(): void {
		if (this._clientId) {
			window.open(
				`${this.service.spriteview}${this.getUrl('/clients')}/sprites/sprite.png`,
				'_blank'
			);
		} else {
			window.open(`${this.service.spriteview}/public/sprites/sprite.png`, '_blank');
		}
	}

	async generateSpritesheet(): Promise<void> {
		try {
			await this.service.post(`generate${this.getUrl('/')}`);
			const data: ToastItem = {
				message: `generated spritesheet!`
			};
			this.message.showToast(data, 'success');
		} catch (error) {
			const data: ToastItem = {
				message: `error generating spritesheet!`
			};
			this.message.showToast(data, 'warning');
		}
	}

	async zipit(): Promise<void> {
		try {
			await this.service.downloadFile(`zip/${this.getUrl('/')}`).then((blob: Blob) => {
				const a = document.createElement('a');
				const objectUrl = URL.createObjectURL(blob);
				a.href = objectUrl;
				a.download = `${this.appId || this.clientId || 'yuno'}-sprites.zip`;
				a.click();
				URL.revokeObjectURL(objectUrl);
			});
		} catch (error) {
			const data: ToastItem = {
				message: `error downloading zip!`
			};
			this.message.showToast(data, 'warning');
		}
	}
}
