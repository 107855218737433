import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import {
	YunoFormsSpanComponent,
	YunoFormsTitleComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';

import { CategoryEditorService } from '../../../category-editor.service';

@Component({
	selector: 'yuno-admin-list-category-cluster',
	imports: [
		ReactiveFormsModule,
		YunoFormsTitleComponent,
		YunoFormsSpanComponent,
		YunoFormsToggleComponent
	],
	templateUrl: './list-category-cluster.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListCategoryClusterComponent {
	service = inject(CategoryEditorService);
}
