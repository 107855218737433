import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';

import { AppFacade } from '@yuno/admin/features/apps';
import {
	EditContainerContentDirective,
	YunoAdminButtonsModule,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import {
	YunoFormsDividerComponent,
	YunoFormsNumberComponent,
	YunoFormsTextComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';
import { AngularPipesModule } from '@yuno/angular/pipes';
import { AngularSvgLoaderModule } from '@yuno/angular/svg-loader';
import { LanguageAll } from '@yuno/api/interface';

import { LegendActiveLayerEditorComponent } from '../legend-active-layer-editor/legend-active-layer-editor.component';
import { LegendEditorService, tabLegendContent } from '../legend-editor.service';
import { LegendGradientEditorComponent } from '../legend-gradient-editor/legend-gradient-editor.component';
import { LegendIconEditorComponent } from '../legend-icon-editor/legend-icon-editor.component';
import { LegendImageEditorComponent } from '../legend-image-editor/legend-image-editor.component';
import { LegendSvgEditorComponent } from '../legend-svg-editor/legend-svg-editor.component';

@Component({
	selector: 'yuno-admin-legend-list-item-container',
	imports: [
		CommonModule,
		RouterModule,
		TranslocoModule,
		AngularPipesModule,
		ReactiveFormsModule,
		DragDropModule,
		YunoAdminButtonsModule,
		YunoEditContainerModule,
		YunoFormsDividerComponent,
		YunoFormsTextComponent,
		YunoFormsNumberComponent,
		YunoFormsToggleComponent,
		AngularSvgLoaderModule,
		LegendIconEditorComponent,
		LegendImageEditorComponent,
		LegendSvgEditorComponent,
		LegendGradientEditorComponent,
		LegendActiveLayerEditorComponent,
		EditContainerContentDirective
	],
	template: `
		<yuno-edit-container
			[col]="8"
			[pos]="3"
			[span]="4"
			[style.zIndex]="210"
			[tabs]="['Icon', 'SVG', 'Image', 'Gradient']"
			[startTab]="service.getStartTab(service.activeList)"
			(outputTab)="onSwitchTabList($event.key)">
			<ng-container buttons>
				<button yuno-admin-close-button (click)="service.editList = false"></button>
			</ng-container>
			<div edit-container-content [hasTabs]="true">
				@if (service.activeList) {
					<form [formGroup]="service.activeList" class="grid grid-cols-1 gap-4">
						<yuno-forms-toggle
							labelPos="side"
							formControlName="public"
							label="Public" />
						<section formGroupName="title">
							@for (lang of languages; track lang) {
								@if (lang === $language()) {
									<yuno-forms-text
										[language]="$language()"
										[formControlName]="$language()"
										label="Legend item name" />
								}
							}
						</section>

						<yuno-forms-number
							label="Icon container height"
							formControlName="iconHeight" />
						<yuno-forms-toggle
							labelPos="side"
							formControlName="bold"
							label="Bold Text" />
						<yuno-forms-divider />
					</form>
				}
				@if (service.tabListValue === 'Icon') {
					<yuno-admin-legend-icon-editor [form]="service.activeList" />
				}
				@if (service.tabListValue === 'SVG') {
					<yuno-admin-legend-svg-editor [form]="service.activeList" />
				}
				@if (service.tabListValue === 'Image') {
					<yuno-admin-legend-image-editor [form]="service.activeList" />
				}
				@if (service.tabListValue === 'Gradient') {
					<yuno-admin-legend-gradient-editor [form]="service.activeList" />
				}
				<yuno-admin-legend-active-layer-editor [form]="service.activeList" />
			</div>
		</yuno-edit-container>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegendListItemEditorContainerComponent extends AppDataComponent {
	protected readonly languages = LanguageAll;
	readonly appFacade = inject(AppFacade);
	readonly service = inject(LegendEditorService);

	$language = this.appFacade.$language;

	onSwitchTabList(key: string) {
		this.service.tabListValue = key as tabLegendContent;
	}
}
