import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	Input,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { tap } from 'rxjs';

import { MarkerCategoriesFacade } from '@yuno/admin/features/place-markers/data-access';
import {
	DragRowComponent,
	YunoAdminButtonComponent,
	YunoAdminDeleteAdminButtonComponent
} from '@yuno/admin/ui';
import { moveItemInFormArray, newLanguageFormGroup } from '@yuno/angular/forms';
import {
	YunoFormsSpanComponent,
	YunoFormsTextComponent,
	YunoFormsTitleComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';
import { LanguageAll } from '@yuno/api/interface';

import {
	CategoryEditorService,
	MarkerInputOptionsListForm
} from '../../../category-editor.service';

@Component({
	selector: 'yuno-admin-category-input-field-editor',
	imports: [
		ReactiveFormsModule,
		YunoFormsTitleComponent,
		YunoFormsTextComponent,
		YunoFormsSpanComponent,
		YunoFormsToggleComponent,
		YunoAdminButtonComponent,
		DragDropModule,
		DragRowComponent,
		AsyncPipe,
		YunoAdminDeleteAdminButtonComponent
	],
	templateUrl: './category-input-field-editor.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryInputFieldEditorComponent implements OnInit {
	private readonly destroyRef = inject(DestroyRef);
	private readonly cdr = inject(ChangeDetectorRef);
	private readonly categoryFacade = inject(MarkerCategoriesFacade);
	service = inject(CategoryEditorService);

	update = false;
	hover: number | null = null;

	@Input() language: string | undefined = 'nl';

	selected$ = this.categoryFacade.selectedInput$.pipe(
		tap(() => {
			this.update = false;
			setTimeout(() => {
				this.update = true;
				this.cdr.markForCheck();
			}, 0);
		})
	);

	hasOptions(index: number): boolean {
		const type =
			this.service.inputs.controls[index].controls.preset?.controls?.type?.getRawValue();
		const bool = !(
			!type || !['type', 'theme', 'status', 'category', 'impact', 'size'].includes(type)
		);
		return bool;
	}

	hasCustomOptions(index: number): boolean {
		const type =
			this.service.inputs.controls[index].controls.custom?.controls?.type?.getRawValue();
		const bool = !(!type || !['fence'].includes(type));
		return bool;
	}

	addOption(index: number): void {
		this.service.inputs.controls[
			index
		].controls.preset?.controls?.options?.controls?.list?.push(
			new FormGroup<MarkerInputOptionsListForm>({
				display: newLanguageFormGroup(),
				value: new FormControl<string>('', { nonNullable: true })
			})
		);
	}

	getInputOptions(index: number): FormArray<FormControl<string>> {
		return this.service.inputs.controls[index].controls.preset?.controls?.options?.controls
			?.list as unknown as FormArray<FormControl<string>>;
	}

	onRemove(input: number, index: number): void {
		this.getInputOptions(input).removeAt(index);
	}

	ngOnInit(): void {
		this.onChanges();
	}

	onChanges(): void {
		this.service.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
			this.cdr.markForCheck();
		});
	}

	isCustom(index: number): boolean {
		const value = this.service.inputs.controls[index]?.value;
		if (value?.custom && !value.custom.key) {
			// if you empty the custom key field the form fields will destroy themselves.
			// to counter this this check will make sure to return true if there is a custom form active
			return true;
		}
		return !!value.custom?.key;
	}

	async drop(event: CdkDragDrop<unknown>, index: number): Promise<void> {
		moveItemInFormArray(this.getInputOptions(index), event.previousIndex, event.currentIndex);
	}

	protected readonly languages = LanguageAll;
}
