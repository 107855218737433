import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { combineLatest } from 'rxjs';

import { YunoAdminButtonsModule } from '@yuno/admin/ui';
import { moveItemInFormArray } from '@yuno/angular/forms';
import { LanguagePipe } from '@yuno/angular/pipes';
import { AngularSvgLoaderModule } from '@yuno/angular/svg-loader';
import { LegendOptionsDefault, SVGItemModel } from '@yuno/api/interface';

import { ContentForm, LegendEditorService, legendList } from '../legend-editor.service';

@Component({
	selector: 'yuno-admin-legend-list-editor',
	imports: [
		ReactiveFormsModule,
		AngularSvgLoaderModule,
		YunoAdminButtonsModule,
		DragDropModule,
		AsyncPipe,
		LanguagePipe
	],
	templateUrl: './legend-list-editor.component.html',
	styleUrls: ['./legend-list-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegendListEditorComponent {
	private _form: FormGroup<ContentForm>;

	data$ = combineLatest({
		icons: this.service.get<SVGItemModel[]>('svg-item/type/legend')
	});

	@Input() set form(content: FormGroup<ContentForm>) {
		this._form = content;
	}

	get form(): FormGroup<ContentForm> {
		return this._form;
	}

	@Input() language: string | undefined = 'nl';

	@Output() list = new EventEmitter<boolean>();

	constructor(public service: LegendEditorService) {}

	getIconUrl(icons: SVGItemModel[], selection: SVGItemModel['id']): string {
		const index = icons.findIndex(obj => obj.id === selection);
		return icons[index].url;
	}

	/* CDK DragList Drop Event */
	drop(event: CdkDragDrop<FormArray<FormGroup<legendList>>>): void {
		moveItemInFormArray(this.service.list, event.previousIndex, event.currentIndex);
	}

	addList(content?: LegendOptionsDefault): void {
		this.service.addContent(content);
	}

	removeListAt(i: number): void {
		this.service.removeList(this.service.list, i);
	}

	editListAt(index: number): void {
		this.service.listIndex = index;
		if (this.service.activeList.value.image) {
			this.service.tabListValue = 'Image';
		}
		if (this.service.activeList.value.class) {
			this.service.tabListValue = 'Icon';
		}
		if (
			this.service.activeList.value.gradiant &&
			this.service.activeList.value.gradiant.length >= 1
		) {
			this.service.tabListValue = 'Gradient';
		}
		this.list.emit(true);
	}
}
