<yuno-edit-container [col]="12" [pos]="4" [span]="6">
	<ng-container buttons>
		<button yuno-admin-close-button (click)="onClosePage()"></button>
	</ng-container>
	<div class="flex flex-col gap-2 md:grid md:h-full">
		<div edit-container-content>
			@if (form) {
				<form [formGroup]="form">
					<section formArrayName="styles">
						<div
							[formGroupName]="service.currentStyleIndex"
							class="grid grid-cols-1 gap-4">
							<yuno-forms-title>Marker Styling</yuno-forms-title>
							<yuno-forms-span>
								Define the marker styles to be used for this categories. <br />
								Don't forget to set a default style when using multiple styles. This
								style will be applied when the input field that determines which
								style is used is empty.
							</yuno-forms-span>
							<div formGroupName="style" class="grid grid-cols-2 gap-4">
								<yuno-forms-select
									formControlName="type"
									[display]="service.styleType"
									[selectValues]="service.styleType"
									label="Marker type" />
								<yuno-forms-number formControlName="zIndex" label="z-index" />
							</div>
							<yuno-forms-divider />
							<yuno-forms-title>Style Editor</yuno-forms-title>

							<yuno-forms-text formControlName="id" label="Style / Category name" />

							<h4>Visibility</h4>
							<div formGroupName="style" class="grid grid-cols-2 gap-4">
								<yuno-forms-number
									formControlName="minZoom"
									label="Marker min. Zoom" />
								<yuno-forms-number
									formControlName="maxZoom"
									label="Marker max. Zoom" />
							</div>

							<div formGroupName="style">
								<yuno-forms-select
									formControlName="visibility"
									[selectValues]="service.visibilityType"
									[display]="service.visibilityType"
									label="Marker Visibility" />
							</div>
							<yuno-forms-divider [useGap]="true" />

							@if (service.type.value === 'label') {
								<div class="flex flex-col gap-4">
									<h4>Label Settings</h4>
									<div formGroupName="style" class="grid grid-cols-2 gap-4">
										<yuno-forms-select
											class="coll-span-1"
											formControlName="class"
											[selectValues]="service.classSelect"
											label="Style" />
										<yuno-forms-select
											class="coll-span-1"
											formControlName="eventStyle"
											[selectValues]="service.eventSelect"
											[display]="service.eventDisplay"
											label="Event Type" />

										<yuno-forms-color
											formControlName="backgroundColor"
											label="Background color" />
										<yuno-forms-color
											formControlName="color"
											label="Text color" />
									</div>
								</div>
							}
							@if (service.type.value === 'icon') {
								<div class="flex flex-col gap-4">
									<h4>Icon Settings</h4>
									<section class="grid grid-cols-2 gap-4">
										<yuno-admin-participation-svg-select-container
											[form]="
												service.styles.controls[service.currentStyleIndex]
											"
											[controlName]="'icon'"
											type="default"
											[(selected)]="selectSprite"
											[src]="getIcon(service.currentStyleIndex)">
											Default Sprite
										</yuno-admin-participation-svg-select-container>
										<yuno-admin-participation-svg-select-container
											[form]="
												service.styles.controls[service.currentStyleIndex]
											"
											type="selected"
											[controlName]="'icon'"
											[(selected)]="selectSprite"
											[src]="getIconSelect(service.currentStyleIndex)">
											Selected Sprite
										</yuno-admin-participation-svg-select-container>
									</section>
									@if (clientId && appId) {
										<div
											class="block max-h-96 overflow-y-auto rounded-lg border border-gray-300 bg-gray-100 p-2">
											<yuno-admin-spritesheet-manager
												[disableCopy]="true"
												[appId]="appId"
												[clientId]="clientId"
												(selectIcon)="
													setIcon($event, service.currentStyleIndex)
												"></yuno-admin-spritesheet-manager>
										</div>
									}
									<div formGroupName="style" class="grid grid-cols-2 gap-4">
										<yuno-forms-number
											formControlName="rotation"
											label="Icon Rotation" />
										<yuno-forms-number
											formControlName="scale"
											label="Icon Scale"
											[step]="0.1" />
									</div>
								</div>
							}
							<yuno-forms-divider [useGap]="true" />

							<div formGroupName="style" class="grid grid-cols-2 gap-4">
								<div class="col-span-2 flex flex-row justify-start">
									<yuno-admin-participation-style-alignment
										class="max-w-xs"
										[alignment]="
											service.getStyleAlignment(service.currentStyleIndex)
										"
										(alignmentChange)="
											service.setStyleAlignment(
												service.currentStyleIndex,
												$event
											)
										">
									</yuno-admin-participation-style-alignment>
								</div>
							</div>
						</div>
					</section>
				</form>
			}
		</div>
	</div>
</yuno-edit-container>
