import { Injectable, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormArray, Validators } from '@angular/forms';

import { EventForm, EventFormsService, EventsForm } from '@yuno/admin/features/events';
import { ControlsOf, LanguageFormType, newLanguageFormGroup } from '@yuno/angular/forms';
import {
	DateOptionsArray,
	Event,
	LanguageObjectModel,
	MarkerAlignmentKeys,
	MarkerCategoryCluster,
	MarkerCategoryClusterCountStyle,
	MarkerCategoryClusterOptions,
	MarkerCategoryInputs,
	MarkerCategoryLayers,
	MarkerCategoryLayout,
	MarkerCategoryStyles,
	MarkerClassKeys,
	MarkerCustomOptions,
	MarkerCustomOptionsType,
	MarkerEventDisplayKeys,
	MarkerEventKeys,
	MarkerFixedProperties,
	MarkerIconStyle,
	MarkerLabelStyle,
	MarkerPhotoStyle,
	MarkerPresetOptions,
	MarkerPresetOptionsType,
	MarkerType,
	OperatorArray,
	VisibilityEnum
} from '@yuno/api/interface';

export interface MarkerCategoryForm {
	order?: FormControl<number | undefined>;
	_id?: FormControl<string | undefined>;
	id: FormControl<string>;
	type: FormControl<MarkerType>;
	public: FormControl<boolean>;
	intern?: FormControl<boolean>;
	zIndex: FormControl<number>;
	styles: FormArray<FormGroup<MarkerCategoryStylesForm>>;
	cluster?: FormGroup<ControlsOf<MarkerCategoryCluster>>;
	layout: FormGroup<MarkerCategoryLayoutForm>;
	inputs: FormArray<FormGroup<MarkerCategoryInputsForm>>;
	fence?: FormControl<string | undefined>;
	mapStyle?: FormControl<string | undefined>;
	theme?: FormControl<string | undefined>;
	layers: FormArray<FormGroup<ControlsOf<MarkerCategoryLayers>>>;
	events: FormGroup<EventsForm>;
	customEvents: FormControl<boolean>;
}

export interface MarkerCategoryInputsForm {
	custom?: FormGroup<MarkerCategoryInputsCustomForm>;
	preset?: FormGroup<MarkerCategoryInputsPresetForm>;
}

export interface MarkerInputOptionsForm {
	list: FormArray<FormGroup<MarkerInputOptionsListForm>>;
	listById: FormControl<boolean>;
	value: FormControl<string>;
	linkText: FormControl<boolean>;
	showArea: FormControl<boolean>;
}

export interface MarkerInputOptionsListForm {
	display: FormGroup<LanguageFormType>;
	value: FormControl<string>;
}

export type MarkerCategoryInputsCustomForm = ControlsOf<
	Omit<Partial<MarkerCustomOptions>, 'options' | 'label' | 'placeholder'>
> & {
	options?: FormGroup<MarkerInputOptionsForm>;
	label?: FormGroup<LanguageFormType>;
	placeholder?: FormGroup<LanguageFormType>;
};
export type MarkerCategoryInputsPresetForm = ControlsOf<
	Omit<Partial<MarkerPresetOptions>, 'options' | 'label' | 'placeholder'>
> & {
	options?: FormGroup<MarkerInputOptionsForm>;
	label?: FormGroup<LanguageFormType>;
	placeholder?: FormGroup<LanguageFormType>;
};
export type MarkerCategoryLayoutForm = ControlsOf<
	Omit<Partial<MarkerCategoryLayout>, 'options'>
> & {
	// We need Untyped FormArray because the use of multiple types:
	// https://angular.io/guide/typed-forms
	//DateLayoutOption | StringLayoutOption | NumberLayoutOption
	options?: UntypedFormArray;
};

export type MarkerCategoryStylesForm = ControlsOf<Omit<Partial<MarkerCategoryStyles>, 'style'>> & {
	style?: FormGroup<ControlsOf<MarkerIconStyle | MarkerLabelStyle>>;
};

@Injectable({
	providedIn: 'root'
})
export class CategoryEditorService {
	private readonly eventsForm = inject(EventFormsService);

	changesMade = 0;
	disabled = true;
	disableClose = false;
	form: FormGroup<MarkerCategoryForm>;

	fenceSelectValues: string[] = [];
	fenceSelectDisplay: string[] = [];
	styleSelectValues: string[] = [];
	styleSelectDisplay: string[] = [];
	themeSelectValues: string[] = [];
	themeSelectDisplay: string[] = [];

	visibilityType = [VisibilityEnum.none, VisibilityEnum.visible];
	styleType = ['icon', 'label'];

	alignmentSelect = MarkerAlignmentKeys;
	eventSelect = MarkerEventKeys;
	eventDisplay = MarkerEventDisplayKeys;
	classSelect = MarkerClassKeys;

	optionsOperator = OperatorArray;
	optionsDateOptions = DateOptionsArray;

	language = 'nl';

	get styleRuleSelectValues(): string[] {
		const objArray = this.inputs.getRawValue();
		return objArray
			.flatMap(obj => obj.preset)
			.reduce((acc: string[], form) => {
				if (form && form.type) {
					acc.push(form.type);
				}
				return acc;
			}, []);
	}

	get styleRuleSelectDisplay(): string[] {
		const objArray = this.inputs.getRawValue();
		return objArray
			.flatMap(obj => obj.preset)
			.reduce((acc: string[], form) => {
				if (form && form.label) {
					const labelLang: LanguageObjectModel =
						form.label as unknown as LanguageObjectModel;
					const label = labelLang[this.language];
					if (label) {
						acc.push(label);
					}
				}
				return acc;
			}, []);
	}

	get formEvents() {
		return this.form.get('events') as FormGroup<EventsForm>;
	}

	get onClickEvents() {
		return this.form.controls.events.get('onClick') as FormArray<FormGroup<EventForm>>;
	}

	get onMouseMoveEvents() {
		return this.form.controls.events.get('onMouseMove') as FormArray<FormGroup<EventForm>>;
	}

	get type() {
		return this.form.get('type') as FormControl<MarkerType>;
	}

	get inputs() {
		return this.form.get('inputs') as FormArray<FormGroup<MarkerCategoryInputsForm>>;
	}

	get layoutOptions() {
		return this.form.controls.layout.get('options') as UntypedFormArray;
	}

	get styles() {
		return this.form.get('styles') as FormArray<FormGroup<ControlsOf<MarkerCategoryStyles>>>;
	}

	get cluster() {
		return this.form.get('cluster') as FormGroup<ControlsOf<MarkerCategoryCluster>>;
	}

	get layers(): FormArray<FormGroup<ControlsOf<MarkerCategoryLayers>>> {
		return this.form.get('layers') as FormArray<FormGroup<ControlsOf<MarkerCategoryLayers>>>;
	}

	/**
		@returns An array of string keys corresponding to the Styles inside the form.
	 */
	get stylesKeys(): string[] {
		const objArray = this.styles.value;

		return objArray
			.flatMap(obj => obj.id)
			.reduce((acc: string[], form) => {
				if (form) {
					acc.push(form);
				}
				return acc;
			}, []);
	}

	/**
	 * Generates the FormGroup required by the MarkerCategoryForm
	 * then enables change detection on the Form
	 */
	createFormGroup(): void {
		this.form = new FormGroup<MarkerCategoryForm>({
			order: new FormControl(1, { nonNullable: true }),
			_id: new FormControl({ value: undefined, disabled: true }, { nonNullable: true }),
			id: new FormControl('', { nonNullable: true, validators: Validators.required }),
			type: new FormControl('icon', { nonNullable: true, validators: Validators.required }),
			public: new FormControl(false, { nonNullable: true }),
			intern: new FormControl(false, { nonNullable: true }),
			zIndex: new FormControl(1, { nonNullable: true }),
			styles: new FormArray<FormGroup<MarkerCategoryStylesForm>>([]),
			cluster: new FormGroup<ControlsOf<MarkerCategoryCluster>>({
				active: new FormControl<boolean>(false, { nonNullable: true }),
				countStyle: new FormGroup<ControlsOf<MarkerCategoryClusterCountStyle>>({
					alignment: new FormControl<string>('top', { nonNullable: true }),
					backgroundColor: new FormControl<string>('#394551', { nonNullable: true }),
					color: new FormControl<string>('rgba(255,255,255,0.85)', { nonNullable: true })
				}),
				options: new FormGroup<ControlsOf<MarkerCategoryClusterOptions>>({
					maxZoom: new FormControl<number>(24, { nonNullable: true }),
					minPoints: new FormControl<number>(2, { nonNullable: true }),
					minZoom: new FormControl<number>(1, { nonNullable: true }),
					radius: new FormControl<number>(120, { nonNullable: true })
				}),
				style: new FormGroup<ControlsOf<Partial<MarkerIconStyle>>>({
					visibility: new FormControl<VisibilityEnum>(VisibilityEnum.visible, {
						nonNullable: true
					}),
					minZoom: new FormControl<number>(1, { nonNullable: true }),
					maxZoom: new FormControl<number>(24, { nonNullable: true }),
					zIndex: new FormControl<number>(1, { nonNullable: true }),
					alignment: new FormControl<string>('center', { nonNullable: true }),
					icon: new FormControl<string>('', { nonNullable: true }),
					iconSelect: new FormControl<string>('', { nonNullable: true }),
					rotation: new FormControl<number>(0, { nonNullable: true }),
					scale: new FormControl<number>(1, { nonNullable: true })
				})
			}),
			layout: new FormGroup<MarkerCategoryLayoutForm>({
				fallback: new FormControl<string | undefined>('', {
					nonNullable: true,
					validators: Validators.required
				}),
				filter: new FormControl<keyof MarkerFixedProperties | undefined>(undefined, {
					nonNullable: true
				}),
				options: this.createLayoutOptionsForm()
			}),
			inputs: new FormArray<FormGroup<MarkerCategoryInputsForm>>([]),
			fence: new FormControl('', { nonNullable: true }),
			mapStyle: new FormControl('', { nonNullable: true }),
			theme: new FormControl('', { nonNullable: true }),
			layers: new FormArray<FormGroup<ControlsOf<MarkerCategoryLayers>>>([]),
			events: this.eventsForm.createEventsForm(),
			customEvents: new FormControl(false, {
				nonNullable: true
			})
		});

		this.disabled && this.form.disable();
	}

	createStyles(styles?: MarkerCategoryStyles[]): void {
		if (!styles) {
			return;
		}

		this.form.controls.styles.clear();

		styles.forEach(() => {
			const formGroup = new FormGroup<MarkerCategoryStylesForm>({
				id: new FormControl<string>('', { nonNullable: true }),
				overwriteZoom: new FormControl<boolean>(false, { nonNullable: true })
			});

			const formStyle = this.createFormStyle();
			formGroup.setControl('style', formStyle);

			// Add Input to the Main form
			this.form.controls.styles.push(formGroup);
		});
	}

	addStyle(): void {
		const formGroup = new FormGroup<MarkerCategoryStylesForm>({
			id: new FormControl<string>('new style', { nonNullable: true }),
			overwriteZoom: new FormControl<boolean>(false, { nonNullable: true })
		});

		const formStyle = this.createFormStyle();
		formGroup.setControl('style', formStyle);

		// Add Input to the Main form
		this.form.controls.styles.push(formGroup);
	}

	addDefaultStyle(): void {
		const formGroup = new FormGroup<MarkerCategoryStylesForm>({
			id: new FormControl<string>('Default', { nonNullable: true }),
			overwriteZoom: new FormControl<boolean>(false, { nonNullable: true })
		});
		const formStyle = this.createFormStyle();
		formGroup.setControl('style', formStyle);
		this.form.controls.styles.push(formGroup);
		this.form.controls.layout.setControl('fallback', new FormControl('Default'));
	}

	getStyleAlignment(index: number): string {
		const styles = this.form.get('styles') as FormArray<
			FormGroup<ControlsOf<MarkerCategoryStyles>>
		>;
		return styles.controls[index].controls.style?.get('alignment')?.getRawValue() || 'center';
	}

	setStyleAlignment(index: number, value: string): void {
		const styles = this.form.get('styles') as FormArray<
			FormGroup<ControlsOf<MarkerCategoryStyles>>
		>;
		styles.controls[index].controls.style?.get('alignment')?.patchValue(value);
	}

	onTypeChange(str: string | null) {
		if (str === 'label') {
			for (const style of this.styles.controls) {
				style.get('style')?.get('icon')?.reset();
			}
		}
	}

	private createFormStyle(): FormGroup<
		ControlsOf<MarkerIconStyle | MarkerPhotoStyle | MarkerLabelStyle>
	> {
		return new FormGroup<ControlsOf<MarkerIconStyle | MarkerPhotoStyle | MarkerLabelStyle>>({
			type: new FormControl('icon', { nonNullable: true }),
			visibility: new FormControl<VisibilityEnum>(VisibilityEnum.visible, {
				nonNullable: true
			}),
			minZoom: new FormControl<number>(1, { nonNullable: true }),
			maxZoom: new FormControl<number>(24, { nonNullable: true }),
			minFence: new FormControl<number>(1, { nonNullable: true }),
			maxFence: new FormControl<number>(24, { nonNullable: true }),
			areaColor: new FormControl<string>('#fff', { nonNullable: true }),
			areaOpacity: new FormControl<number>(0.75, { nonNullable: true }),
			zIndex: new FormControl<number>(1, { nonNullable: true }),
			alignment: new FormControl<string>('bottom', { nonNullable: true }),
			eventStyle: new FormControl<string>('chevron-event', { nonNullable: true }),
			icon: new FormControl<string>('icon', { nonNullable: true }),
			iconSelect: new FormControl<string>('', { nonNullable: true }),
			rotation: new FormControl<number>(0, { nonNullable: true }),
			scale: new FormControl<number>(1, { nonNullable: true }),
			class: new FormControl<string>('label pointer', { nonNullable: true }),
			color: new FormControl<string>('#394551', { nonNullable: true }),
			backgroundColor: new FormControl<string>('rgba(255,255,255,0.85)', {
				nonNullable: true
			})
		});
	}

	createEvents(type: 'onClick' | 'onMouseMove', events?: Event[]): void {
		if (!events) {
			return;
		}

		const form = this.eventsForm.createEventForm();

		for (const event of events) {
			if (!event) {
				continue;
			}

			const eventForm = this.eventsForm.createEvent(event.type, event.options);
			form.push(eventForm);
		}

		this.form.controls.events.controls[type].clear();
		this.form.controls.events.setControl(type, form);
	}

	createInputs(inputs?: MarkerCategoryInputs[]): void {
		if (!inputs) {
			return;
		}
		this.form.controls.inputs.clear();

		for (const input of inputs) {
			const formGroup = new FormGroup<MarkerCategoryInputsForm>({});

			// Creates the Custom Input Form
			if (input.custom) {
				formGroup.setControl('custom', new FormGroup<MarkerCategoryInputsCustomForm>({}));
				const customGroup = this.createMarkerCategoryInputsCustomForm();

				if (input.custom.options?.list) {
					const list = customGroup.controls?.options?.get('list') as FormArray<
						FormGroup<MarkerInputOptionsListForm>
					>;
					for (const option of input.custom.options.list) {
						const group = new FormGroup({
							display: newLanguageFormGroup(),
							value: new FormControl<string>('', { nonNullable: true })
						});
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						group.patchValue(option as any);
						list.push(group);
					}
				}

				formGroup.setControl('custom', customGroup);
				this.form.controls.inputs.push(formGroup);

				continue;
			}

			// Creates the Preset Input Form
			if (input.preset) {
				formGroup.setControl('preset', new FormGroup<MarkerCategoryInputsPresetForm>({}));
				const presetGroup = this.createMarkerCategoryInputsPresetForm();

				if (input.preset.options?.list) {
					const list = presetGroup.controls?.options?.get('list') as FormArray<
						FormGroup<MarkerInputOptionsListForm>
					>;
					for (const option of input.preset.options.list) {
						const group = new FormGroup({
							display: newLanguageFormGroup(),
							value: new FormControl<string>('', { nonNullable: true })
						});
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						group.patchValue(option as any);
						list.push(group);
					}
				}

				formGroup.setControl('preset', presetGroup);
			}

			this.form.controls.inputs.push(formGroup);
		}
	}

	addPresetInput(input: MarkerCategoryInputs) {
		const formGroup = new FormGroup<MarkerCategoryInputsForm>({
			preset: new FormGroup<MarkerCategoryInputsPresetForm>({})
		});

		const presetGroup = this.createMarkerCategoryInputsPresetForm();
		formGroup.setControl('preset', presetGroup);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		formGroup.patchValue(input as any);

		// Add Input to the Main form
		this.form.controls.inputs.push(formGroup);
	}

	addCustomInput(input: MarkerCategoryInputs) {
		const formGroup = new FormGroup<MarkerCategoryInputsForm>({
			custom: new FormGroup<MarkerCategoryInputsCustomForm>({})
		});

		const customGroup = this.createMarkerCategoryInputsCustomForm();
		formGroup.setControl('custom', customGroup);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		formGroup.patchValue(input as any);

		// Add Input to the Main form
		this.form.controls.inputs.push(formGroup);
	}

	private createMarkerCategoryInputsCustomForm(): FormGroup<MarkerCategoryInputsCustomForm> {
		return new FormGroup<MarkerCategoryInputsCustomForm>({
			key: new FormControl<string>('', {
				nonNullable: true,
				validators: [Validators.required, Validators.minLength(3)]
			}),
			label: newLanguageFormGroup(),
			listView: new FormControl<boolean>(false, { nonNullable: true }),
			options: new FormGroup<MarkerInputOptionsForm>({
				list: new FormArray<FormGroup<MarkerInputOptionsListForm>>([]),
				listById: new FormControl<boolean>(false, {
					nonNullable: true
				}),
				value: new FormControl<string>('', { nonNullable: true }),
				linkText: new FormControl<boolean>(false, {
					nonNullable: true
				}),
				showArea: new FormControl<boolean>(false, {
					nonNullable: true
				})
			}),
			placeholder: newLanguageFormGroup(),
			required: new FormControl<boolean>(false, { nonNullable: true }),
			type: new FormControl<MarkerCustomOptionsType>(
				{ value: 'textarea', disabled: true },
				{ nonNullable: true }
			)
		});
	}

	private createMarkerCategoryInputsPresetForm(): FormGroup<MarkerCategoryInputsPresetForm> {
		return new FormGroup<MarkerCategoryInputsPresetForm>({
			label: newLanguageFormGroup(),
			listView: new FormControl<boolean>(false, { nonNullable: true }),
			options: new FormGroup<MarkerInputOptionsForm>({
				list: new FormArray<FormGroup<MarkerInputOptionsListForm>>([]),
				listById: new FormControl<boolean>(false, {
					nonNullable: true
				}),
				value: new FormControl<string>('', { nonNullable: true }),
				linkText: new FormControl<boolean>(false, {
					nonNullable: true
				}),
				showArea: new FormControl<boolean>(false, {
					nonNullable: true
				})
			}),
			placeholder: newLanguageFormGroup(),
			required: new FormControl<boolean>(false, { nonNullable: true }),
			type: new FormControl<MarkerPresetOptionsType>(
				{ value: 'title', disabled: true },
				{ nonNullable: true }
			)
		});
	}

	getStyleRuleType(): 'date' | 'number' | 'string' | undefined {
		const filter = this.form.controls?.layout?.get('filter')?.value;

		if (!filter) {
			return;
		}

		if (['endDate', 'startDate'].includes(filter)) {
			return 'date';
		}

		if (['maxZoom', 'minZoom', 'number', 'rotation'].includes(filter)) {
			return 'number';
		}

		return 'string';
	}

	addRuleToLayoutOptions(): void {
		const type = this.getStyleRuleType();
		let optionForm: UntypedFormArray;
		if (type === 'date') {
			optionForm = this.createOptionsFormDate();
		} else if (type === 'number') {
			optionForm = this.createOptionsFormNumber();
		} else {
			optionForm = this.createOptionsFormString();
		}

		this.form.controls.layout.controls.options?.push(optionForm);
	}

	removeRuleFromLayoutOptions(index: number): void {
		this.form.controls.layout.controls.options?.removeAt(index);
	}

	createLayoutOptions(options: MarkerCategoryLayout['options']): void {
		this.form.controls?.layout.controls?.options?.clear();

		if (!options) {
			return;
		}

		const type = this.getStyleRuleType();
		for (const option of options) {
			let optionForm: UntypedFormArray;
			if (type === 'date') {
				optionForm = this.createOptionsFormDate();
			} else if (type === 'number') {
				optionForm = this.createOptionsFormNumber();
			} else {
				optionForm = this.createOptionsFormString();
			}

			optionForm.patchValue(option);
			this.form.controls.layout.controls.options?.push(optionForm);
		}
	}

	/**
	 * Creates the Layers FormArray
	 * Only here to be backwards compatible with older Place Marker Categories
	 * that did not have a Theme selection
	 * @deprecated
	 */
	addLayers(layers?: MarkerCategoryLayers[]): void {
		if (!layers || !layers.length) {
			this.layers.reset();
			return;
		}

		for (const layer of layers) {
			const formGroup = new FormGroup<ControlsOf<MarkerCategoryLayers>>({
				_id: new FormControl<string | undefined>(layer._id, { nonNullable: true }),
				id: new FormControl<string>(layer.id, { nonNullable: true })
			});

			this.layers.push(formGroup);
		}
	}

	private createOptionsFormDate(): UntypedFormArray {
		return new UntypedFormArray([
			new FormArray([
				new FormControl<string>('<', { nonNullable: true }),
				new FormControl<number>(1, { nonNullable: true }),
				new FormControl<string>('years', { nonNullable: true })
			]),
			new FormControl<string>(this.stylesKeys[0] || '', { nonNullable: true })
		]);
	}

	private createOptionsFormNumber(): UntypedFormArray {
		return new UntypedFormArray([
			new FormArray([
				new FormControl<string>('<', { nonNullable: true }),
				new FormControl<number>(1, { nonNullable: true })
			]),
			new FormControl<string>(this.stylesKeys[0] || '', { nonNullable: true })
		]);
	}

	private createOptionsFormString(): UntypedFormArray {
		return new UntypedFormArray([
			new FormControl<string>('', {
				nonNullable: true,
				validators: [Validators.required, Validators.minLength(3)]
			}),
			new FormControl<string>(this.stylesKeys[0] || '', { nonNullable: true })
		]);
	}

	resetLayoutOptions(): void {
		this.form.controls?.layout.controls?.options?.clear();
		this.addRuleToLayoutOptions();
	}

	/**
	 * Returns a FormArray that should be one of these Layout Options
	 * DateLayoutOption = [[Operator, number, DateOptions], string];
	 * NumberLayoutOption = [[Operator, number], string];
	 * StringLayoutOption = [string, string];
	 *
	 * @private
	 * @return {*}  {(FormArray<FormGroup<ControlsOf<DateLayoutOption | StringLayoutOption | NumberLayoutOption>>>)}
	 * @memberof CategoryEditorService
	 */
	private createLayoutOptionsForm(): UntypedFormArray {
		return new UntypedFormArray([]);
	}
}
