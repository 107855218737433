import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { YunoFormsSelectComponent, YunoFormsTextComponent } from '@yuno/angular/forms/components';

import { EventsDefaultFormItemComponent } from '../_default/default-form-item.component';

@Component({
	selector: 'yuno-admin-popup-custom-data',
	imports: [ReactiveFormsModule, YunoFormsTextComponent, YunoFormsSelectComponent, AsyncPipe],
	templateUrl: './popup-custom-data.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupCustomDataComponent extends EventsDefaultFormItemComponent implements OnInit {
	ngOnInit(): void {
		this.service.getTextfields();
	}
}
