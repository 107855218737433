import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';

import { colspan, columns } from '../../../helpers';
import {
	JsonArrayForm,
	JsonButtonForm,
	JsonFormControl,
	JsonFormKeyType,
	JsonGroupForm,
	JsonLabel,
	JsonLanguageForm
} from '../../../json-forms.models';
import { FormCreatorService } from '../../../services/form-creator.service';
import { ValidationService } from '../../../services/validation.service';
import { ArrayComponent } from '../array/array.component';
import { ButtonComponent } from '../button/button.component';
import { ControlComponent } from '../control/control.component';
import { DividerComponent } from '../divider/divider.component';
import { LabelComponent } from '../label/label.component';
import { LanguageComponent } from '../language/language.component';

@Component({
    selector: 'yuno-json-forms-types-group',
    templateUrl: './group.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgClass,
        ButtonComponent,
        LabelComponent,
        DividerComponent,
        ArrayComponent,
        ControlComponent,
        LanguageComponent
    ]
})
export class GroupComponent {
	private _control: UntypedFormGroup | undefined;
	private _item: JsonGroupForm;
	validationMessage: string;

	@Input()
	set control(control: UntypedFormGroup | undefined) {
		this._control = control;
		this.hostClass = this.colspan();
	}
	get control(): UntypedFormGroup | undefined {
		return this._control;
	}

	@Input()
	set item(item: JsonGroupForm) {
		this._item = item;
		this.className = this.columns();

		if (item.validationMessage) {
			this.validationMessage = item.validationMessage;
			return;
		}

		this.generateValidationMessage();
	}
	get item(): JsonGroupForm {
		return this._item;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() formValue: any;
	@Input() form: UntypedFormGroup;

	@HostBinding('className') hostClass = 'col-span-full';
	className: string;

	constructor(
		private formCreator: FormCreatorService,
		public validationService: ValidationService
	) {}

	columns(): string {
		return columns(this.item.columns || 1);
	}

	colspan(): string {
		return colspan(this.item.colspan);
	}

	asGroup(obj: JsonFormKeyType): JsonGroupForm {
		return obj as JsonGroupForm;
	}

	asButton(obj: JsonFormKeyType): JsonButtonForm {
		return obj as JsonButtonForm;
	}

	asLabel(obj: JsonFormKeyType): JsonLabel {
		return obj as JsonLabel;
	}

	asArray(obj: JsonFormKeyType): JsonArrayForm {
		return obj as JsonArrayForm;
	}

	asFormControl(obj: JsonFormKeyType): JsonFormControl {
		return obj as JsonFormControl;
	}

	asFormLanguage(obj: JsonFormKeyType): JsonLanguageForm {
		return obj as JsonLanguageForm;
	}

	getControlKey(key: string): string {
		const str = `${this.item.key}.${key}`;
		return str;
	}

	generateValidationMessage(): void {
		this.validationMessage = this.formCreator.formValidationMessages(this.item);
	}
}
