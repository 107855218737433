import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { debounceTime, map } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import {
	YunoAdminCardComponent,
	YunoAdminNavbarButtonsComponent,
	YunoAdminSaveButtonComponent
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { YunoFormsSelectComponent } from '@yuno/angular/forms/components';
import { App } from '@yuno/api/interface';

import { InternalViewService } from '../internal-view/internal-view.service';

@Component({
	imports: [
		ReactiveFormsModule,
		YunoFormsSelectComponent,
		AsyncPipe,
		YunoAdminNavbarButtonsComponent,
		YunoAdminCardComponent,
		YunoAdminSaveButtonComponent
	],
	selector: 'yuno-admin-production-team',
	templateUrl: './production-team.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppProductionTeamComponent extends AppDataComponent implements OnInit {
	readonly service = inject(InternalViewService);
	private readonly appFacade = inject(AppFacade);
	private readonly destroyRef = inject(DestroyRef);

	users$ = this.appFacade.users$.pipe(
		map(data => {
			const xkp = data?.filter(user => user.user?.email.includes('@xkp.nl'));
			const xkpCms = xkp?.filter(
				user => user.role && !['ATLAS', 'ATLASINTERNAL'].includes(user?.role)
			);
			let xkpDisplay: string[] = [];
			let xkpValue: string[] = [];

			if (xkpCms && xkpCms.length >= 1) {
				xkpDisplay = xkpCms.map(user => user.user?.displayName || '');
				xkpValue = xkpCms.map(user => user.id || '');
			}

			const clients = data?.filter(user => !user.user?.email.includes('@xkp.nl'));
			const clientCms = clients?.filter(
				user => user.role && !['ATLAS', 'ATLASINTERNAL'].includes(user?.role)
			);

			let clientDisplay: string[] = [];
			let clientValue: string[] = [];

			if (clientCms && clientCms.length >= 1) {
				clientDisplay = clientCms.map(user => user.user?.displayName || '');
				clientValue = clientCms.map(user => user.id || '');
			}

			return {
				xkpDisplay: xkpDisplay,
				xkpValue: xkpValue,
				clientDisplay: [...xkpDisplay, ...clientDisplay],
				clientValue: [...xkpValue, ...clientValue]
			};
		})
	);

	ngOnInit() {
		this.onChanges();

		const app = this.appFacade.$app();
		if (!app) {
			return;
		}
		this.appFacade.getAppUsers();
		this.service.appForm.patchValue(app);
	}

	onChanges(): void {
		this.service.appForm.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(300))
			.subscribe(() => this.appFacade.updateApp(this.service.appForm.getRawValue() as App));
	}

	onSave() {
		this.appFacade.saveSettings();
	}
}
