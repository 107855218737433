import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	inject
} from '@angular/core';

import { ENVIRONMENT } from '@yuno/admin/core';
import { YunoFormsSpanComponent } from '@yuno/angular/forms/components';
import { MarkerAlignmentKeysType } from '@yuno/api/interface';

@Component({
	selector: 'yuno-admin-place-marker-alignment-selector',
	imports: [CommonModule, YunoFormsSpanComponent],
	styleUrls: ['alignment-selector.component.scss'],
	template: `
		<yuno-forms-span class="font-semibold">Alignment:</yuno-forms-span>
		<div class="container">
			<div
				class="icon-container"
				(click)="setAlignment('top-left')"
				[ngClass]="{ active: alignment === 'top-left' }">
				<img
					src="{{
						environment['yuno-cdn']
					}}/sprites/public/marker-indicator-top-left.svg" />
			</div>
			<div
				class="icon-container"
				(click)="setAlignment('top')"
				[ngClass]="{ active: alignment === 'top' }">
				<img src="{{ environment['yuno-cdn'] }}/sprites/public/marker-indicator-top.svg" />
			</div>
			<div
				class="icon-container"
				(click)="setAlignment('top-right')"
				[ngClass]="{ active: alignment === 'top-right' }">
				<img
					src="{{
						environment['yuno-cdn']
					}}/sprites/public/marker-indicator-top-right.svg" />
			</div>
			<div
				class="icon-container"
				(click)="setAlignment('top-left-left')"
				[ngClass]="{ active: alignment === 'top-left-left' }">
				<img
					src="{{
						environment['yuno-cdn']
					}}/sprites/public/marker-indicator-top-left-left.svg" />
			</div>
			<div
				class="icon-container"
				(click)="setAlignment('top-right-right')"
				[ngClass]="{ active: alignment === 'top-right-right' }">
				<img
					src="{{
						environment['yuno-cdn']
					}}/sprites/public/marker-indicator-top-right-right.svg" />
			</div>

			<div
				class="icon-container"
				(click)="setAlignment('left')"
				[ngClass]="{ active: alignment === 'left' }">
				<img src="{{ environment['yuno-cdn'] }}/sprites/public/marker-indicator-left.svg" />
			</div>
			<div
				class="align-center icon-container"
				(click)="setAlignment('center')"
				[ngClass]="{ active: alignment === 'center' }">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="h-6 w-6"
					fill="none"
					viewBox="0 0 24 24"
					stroke="#229FEC">
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
				</svg>
			</div>
			<div
				class="icon-container"
				(click)="setAlignment('right')"
				[ngClass]="{ active: alignment === 'right' }">
				<img
					src="{{ environment['yuno-cdn'] }}/sprites/public/marker-indicator-right.svg" />
			</div>
			<div
				class="icon-container"
				(click)="setAlignment('bottom-left-left')"
				[ngClass]="{ active: alignment === 'bottom-left-left' }">
				<img
					src="{{
						environment['yuno-cdn']
					}}/sprites/public/marker-indicator-bottom-left-left.svg" />
			</div>
			<div
				class="icon-container"
				(click)="setAlignment('bottom-right-right')"
				[ngClass]="{ active: alignment === 'bottom-right-right' }">
				<img
					src="{{
						environment['yuno-cdn']
					}}/sprites/public/marker-indicator-bottom-right-right.svg" />
			</div>
			<div
				class="icon-container"
				(click)="setAlignment('bottom-left')"
				[ngClass]="{ active: alignment === 'bottom-left' }">
				<img
					src="{{
						environment['yuno-cdn']
					}}/sprites/public/marker-indicator-bottom-left.svg" />
			</div>
			<div
				class="icon-container"
				(click)="setAlignment('bottom')"
				[ngClass]="{ active: alignment === 'bottom' }">
				<img
					src="{{
						environment['yuno-cdn']
					}}/sprites/public/marker-indicator-bottom.svg" />
			</div>

			<div
				class="icon-container"
				(click)="setAlignment('bottom-right')"
				[ngClass]="{ active: alignment === 'bottom-right' }">
				<img
					src="{{
						environment['yuno-cdn']
					}}/sprites/public/marker-indicator-bottom-right.svg" />
			</div>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlaceMarkerAlignmentSelectorComponent {
	readonly environment = inject(ENVIRONMENT);

	@Input() alignment: MarkerAlignmentKeysType = 'center';
	@Output() alignmentChange = new EventEmitter<MarkerAlignmentKeysType>();

	setAlignment(str: MarkerAlignmentKeysType): void {
		this.alignment = str;
		this.alignmentChange.emit(this.alignment);
	}
}
