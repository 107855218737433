import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormsModule, UntypedFormControl } from '@angular/forms';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { v4 as uuid } from 'uuid';

@Component({
    selector: 'yuno-tinymce-inline',
    templateUrl: './tinymce-inline.component.html',
    styleUrls: ['./tinymce-inline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [EditorComponent, FormsModule]
})
export class TinymceInlineComponent implements OnInit {
	readonly id = uuid();

	tinymceEditor = {
		suffix: '.min',
		promotion: false,
		menubar: false,
		inline: true,
		plugins: ['autolink'],
		content_css: ['/assets/tinymce-admin.css']
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	init: any;

	@Input() inlineType: 'h1' | 'h2' | 'p' = 'p';
	@Input() control: UntypedFormControl = new UntypedFormControl('');

	get controlValue(): string {
		return this.control.value;
	}

	preventEnter = {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		setup: function (limit: any) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			limit.on('keydown', (e: any) => {
				if (e.keyCode == 13 || (e.ctrlKey && e.keyCode == 13)) {
					e.preventDefault();
				}
			});
		}
	};

	h1Options = {
		forced_root_block: 'h1',
		toolbar:
			'undo redo | bold italic underline | alignleft aligncenter alignright alignjustify',
		...this.preventEnter
	};

	h2Options = {
		forced_root_block: 'h2',
		toolbar:
			'undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent',
		...this.preventEnter
	};

	pOptions = {
		toolbar:
			'undo redo | bold italic underline strikethrough blockquote | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | removeformat | table link codesample'
	};

	quoteOptions = {
		forced_root_block: 'blockquote',
		toolbar: 'undo redo | bold italic underline'
	};

	ngOnInit(): void {
		this.init = {
			...this.tinymceEditor,
			...(this.inlineType === 'h1' && this.h1Options),
			...(this.inlineType === 'h2' && this.h2Options),
			...(this.inlineType === 'p' && this.pOptions)
		};
	}

	onModelChange(val: string): void {
		this.control.patchValue(val);
	}
}
