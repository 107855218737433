import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostListener,
	ViewChild,
	signal
} from '@angular/core';

@Component({
	selector: 'yuno-admin-ui-selector-dropdown',
	imports: [CommonModule, CdkConnectedOverlay, CdkOverlayOrigin],
	template: `
		<span class="px-2 italic empty:hidden">
			<ng-content select="[description]"></ng-content>
		</span>
		<span class="relative" #activeContent cdkOverlayOrigin #trigger="cdkOverlayOrigin">
			<ng-content select="[active]"></ng-content>
		</span>

		<!-- Dropdown menu -->
		<ng-template
			cdkConnectedOverlay
			[cdkConnectedOverlayOrigin]="trigger"
			[cdkConnectedOverlayPositions]="position"
			[cdkConnectedOverlayOpen]="$isOpen()"
			[cdkConnectedOverlayHasBackdrop]="true"
			[cdkConnectedOverlayBackdropClass]="'opacity-0'"
			(backdropClick)="close()">
			<div
				id="selector-dropdown"
				(click)="close()"
				[ngStyle]="{
					'min-width.px': getWidth()
				}"
				class="mt-2 overflow-hidden rounded-lg border border-yuno-blue-gray-400 bg-yuno-blue-gray-100 px-1 shadow">
				<div class="flex flex-col gap-2 py-3 text-sm text-yuno-gray-100">
					<ng-content></ng-content>
				</div>
			</div>
		</ng-template>
	`,
	styleUrls: ['./selector-dropdown.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminUiSelectorDropdownComponent {
	@HostListener('click') compClick() {
		this.toggle();
	}

	@ViewChild('activeContent', { static: false, read: ElementRef }) activeContent: ElementRef;

	$isOpen = signal<boolean>(false);

	position: ConnectedPosition[] = [
		{
			originX: 'end',
			originY: 'bottom',
			overlayX: 'end',
			overlayY: 'top'
		}
	];

	getWidth(): number {
		return this.activeContent.nativeElement.offsetWidth || 200;
	}

	toggle(): void {
		this.$isOpen.set(!this.$isOpen());
	}

	close(): void {
		this.activeContent.nativeElement.offsetWidth;
		this.$isOpen.set(false);
	}
}

@Component({
	selector: 'yuno-admin-ui-selector-dropdown-item',
	standalone: true,
	template: ` <ng-content></ng-content>`,
	styleUrls: ['./selector-dropdown-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminUiSelectorDropdownItemComponent {}

@Component({
	selector: 'yuno-admin-ui-selector-dropdown-item-counter',
	standalone: true,
	template: ` <ng-content></ng-content> `,
	styleUrls: ['./selector-dropdown-item-counter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminUiSelectorDropdownItemCounterComponent {}
