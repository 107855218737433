import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';

import { ThemesMapService } from './map.service';

@Component({
	imports: [NgxMapLibreGLModule, AsyncPipe],
	providers: [ThemesMapService],
	selector: 'yuno-admin-map-viewer',
	templateUrl: './map-viewer.component.html',
	styleUrls: ['./map-viewer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapViewerComponent {
	readonly service = inject(ThemesMapService);
}
