import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import {
	YunoFormsDividerComponent,
	YunoFormsNumberComponent,
	YunoFormsSelectComponent,
	YunoFormsSpanComponent,
	YunoFormsTitleComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';

import { ThemeEditorFormService, ThemeForm } from '../../theme-editor.form.service';

@Component({
	imports: [
		ReactiveFormsModule,
		YunoFormsDividerComponent,
		YunoFormsNumberComponent,
		YunoFormsSelectComponent,
		YunoFormsSpanComponent,
		YunoFormsTitleComponent,
		YunoFormsToggleComponent
	],
	selector: 'yuno-admin-theme-panorama',
	templateUrl: './theme-panorama.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemePanoramaComponent {
	readonly service = inject(ThemeEditorFormService);

	@Input() form: FormGroup<ThemeForm>;
}
