import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	HostBinding,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterLink, RouterOutlet } from '@angular/router';
import { combineLatest, debounceTime, startWith, take, tap } from 'rxjs';

import { EventsFacade } from '@yuno/admin/features/events';
import { LegendFacade } from '@yuno/admin/features/legends';
import { StatesFacade } from '@yuno/admin/features/states';
import {
	EditContainerComponent,
	EditContainerContentDirective,
	YunoAdminButtonsModule,
	YunoAdminCardDraggableComponent
} from '@yuno/admin/ui';
import { AppDataComponent, redirectTo } from '@yuno/admin/utils';
import { YunoFormsSelectComponent, YunoFormsTextComponent } from '@yuno/angular/forms/components';
import {
	Dataset,
	DatasetData,
	DatasetDataFences,
	DatasetDataLayers,
	DatasetDataMarkerCategories,
	DatasetDataMarkers,
	DatasetDataObjects,
	DatasetDataPanoramas,
	DatasetDataParticipates,
	DatasetDataPhotoCategories,
	DatasetDataShapes
} from '@yuno/api/interface';

import { DatasetsFacade } from '../../../data-access';
import { DatasetEditorService } from '../dataset-editor.service';

export type Keys =
	| 'fences'
	| 'layers'
	| 'markers'
	| 'markerCategories'
	| 'panoramas'
	| 'objects'
	| 'participates'
	| 'photoCategories'
	| 'shapes';

@Component({
    selector: 'yuno-admin-dataset-editor',
    imports: [
        RouterOutlet,
        RouterLink,
        YunoFormsTextComponent,
        YunoFormsSelectComponent,
        ReactiveFormsModule,
        EditContainerComponent,
        YunoAdminButtonsModule,
        YunoAdminCardDraggableComponent,
        AsyncPipe,
        EditContainerContentDirective
    ],
    templateUrl: './dataset-editor.component.html',
    styleUrls: ['./dataset-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatasetEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	private readonly destroyRef = inject(DestroyRef);
	private readonly datasetFacade = inject(DatasetsFacade);
	private readonly eventsFacade = inject(EventsFacade);
	private readonly stateFacade = inject(StatesFacade);
	private readonly legendFacade = inject(LegendFacade);
	readonly service = inject(DatasetEditorService);

	originalData: Partial<Dataset>;
	url: string;
	@HostBinding('class.popup') isPopup = false;

	/*
		Selected Dataset
		On startup:
			- Makes a copy so you have a backup of original data
			- Setups the form
			- Because of Array, initialize states separately
	*/
	data$ = combineLatest({
		dataset: this.datasetFacade.selectedDataset$.pipe(
			tap(data => {
				if (!this.originalData && data && data._id) {
					this.originalData = data as Dataset;
					if (data.data) {
						this.editContainerBreadcrumbsService.addRoute(
							'dataset',
							data.id || 'no name dataset'
						);

						if (data.data.fences && data.data.fences.length >= 1) {
							this.service.addFences(data.data.fences);
						}
						if (data.data.layers && data.data.layers.length >= 1) {
							this.service.addLayers(data.data.layers);
						}
						if (data.data.markers && data.data.markers.length >= 1) {
							this.service.addMarkers(data.data.markers);
						}
						if (data.data.markerCategories && data.data.markerCategories.length >= 1) {
							this.service.addMarkerCategories(data.data.markerCategories);
						}
						if (data.data.panoramas && data.data.panoramas.length >= 1) {
							this.service.addPanoramas(data.data.panoramas);
						}
						if (data.data.participates && data.data.participates.length >= 1) {
							this.service.addParticipates(data.data.participates);
						}
						if (data.data.objects && data.data.objects.length >= 1) {
							this.service.addObjects(data.data.objects);
						}
						if (data.data.photoCategories && data.data.photoCategories.length >= 1) {
							this.service.addGeoPhotos(data.data.photoCategories);
						}
						if (data.data.shapes && data.data.shapes.length >= 1) {
							this.service.addShapes(data.data.shapes);
						}
					}

					if (data.states && data.states.length >= 1) {
						this.service.addStates(data.states);
					}

					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					this.service.form.patchValue(data as any);
				}

				if (data?._id && this.url === 'create') {
					this.route.paramMap.pipe(take(1)).subscribe(url => {
						const id = url.get('id');
						if (!id) {
							redirectTo(this.route, this.router, ['edit', `${data._id}`]);
						}
					});
				}
			})
		),
		toggle: this.eventsFacade.toggle$.pipe(startWith(false)),
		legends: this.legendFacade.legends$.pipe(
			tap(data => {
				if (data && data.length >= 1) {
					this.service.legendDisplay = [
						'none',
						...data.map(object => object.id)
					] as string[];
					this.service.legendValues = ['', ...data.map(object => object._id)] as string[];
				}
			})
		),
		states: this.stateFacade.states$.pipe(
			tap(data => {
				if (data && data.length >= 1) {
					this.service.stateDisplay = data.map(object => object.state) as string[];
					this.service.stateValues = data.map(object => object._id) as string[];
				}
			})
		),
		parent: this.datasetFacade.parent$
	});

	listData: { key: Keys; display: string; displayCount?: string }[] = [
		{
			key: 'fences',
			display: 'Fences'
		},
		{
			key: 'layers',
			display: 'Layers'
		},
		{
			key: 'markers',
			display: 'Markers'
		},
		{
			key: 'markerCategories',
			display: 'Marker Categories',
			displayCount: 'categories'
		},
		{
			key: 'panoramas',
			display: 'Panoramas'
		},
		{
			key: 'objects',
			display: '3D Panorama Objects',
			displayCount: 'objects'
		},
		{
			key: 'participates',
			display: 'Participates'
		},
		{
			key: 'photoCategories',
			display: 'GeoPhotos'
		},
		{
			key: 'shapes',
			display: 'Shapes'
		}
	];

	ngOnInit(): void {
		this.service.route = this.route;

		/* 	Detects current url and select dataset from url	*/
		this.route.paramMap.pipe(take(1)).subscribe(data => {
			const id = data.get('id');
			if (!id) {
				this.url = 'create';
				return;
			}
			this.datasetFacade.select(id);
			this.url = 'edit';
		});

		this.service.createFormGroup();
		this.onChanges();
		this.onRouting();

		this.legendFacade.getLegendsByAppId();
		this.stateFacade.get();
	}

	onChanges(): void {
		this.service.form.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
			.subscribe(() => {
				this.datasetFacade.updateSelect(
					this.service.form.getRawValue() as Partial<Dataset>
				);
				this.service.rawForm$.next(this.service.form.getRawValue() as Partial<Dataset>);
				this.datasetFacade.getParentRoute(this.allRouteParams);
			});
	}

	onRouting(): void {
		this.route.data.pipe(take(1)).subscribe(data => {
			this.isPopup = data['popup'] || false;
		});
	}

	/* 	Return the length of the data within each of the items	*/
	onListLength(key: Keys): number {
		let length = 0;
		const data = this.service.data.value as DatasetData;

		if (data && data[key]) {
			const list:
				| DatasetDataFences[]
				| DatasetDataParticipates[]
				| DatasetDataLayers[]
				| DatasetDataMarkers[]
				| DatasetDataMarkerCategories[]
				| DatasetDataPanoramas[]
				| DatasetDataObjects[]
				| DatasetDataPhotoCategories[]
				| DatasetDataShapes[]
				| undefined = data[key];

			if (list && list.length >= 1) {
				length = list.length;
			}
		}

		return length;
	}

	onSave() {
		this.datasetFacade.save();
	}

	/* 	navigates back to the Dataset page */
	onClose(): void {
		redirectTo(this.route, this.router);
		this.datasetFacade.clearSelect();
	}

	removeState(index: number) {
		this.service.removeState(index);
	}

	addState(): void {
		this.service.addState();
	}

	ngOnDestroy() {
		this.editContainerBreadcrumbsService.removeRouteByType('dataset');

		// reset the raw form data
		this.service.rawForm$.next({});
		this.service.routing$.next({ id: '', type: '' });
		this.datasetFacade.clearSelect();
	}
}
