import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import {
	EditContainerContentDirective,
	UserRightsMessageComponent,
	YunoAdminButtonsModule,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import {
	YunoFormsDividerComponent,
	YunoFormsSelectComponent,
	YunoFormsTextComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';
import { ButtonCardModule } from '@yuno/project-atlas/ui';

import { ImageButtonEditorComponent } from './image-button-editor.component';

@NgModule({
	declarations: [ImageButtonEditorComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		YunoEditContainerModule,
		YunoAdminButtonsModule,
		ButtonCardModule,
		UserRightsMessageComponent,
		EditContainerContentDirective,
		YunoFormsTextComponent,
		YunoFormsDividerComponent,
		YunoFormsSelectComponent,
		YunoFormsToggleComponent
	]
})
export class ImageButtonEditorModule {}
