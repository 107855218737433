import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';

import { inIframe } from '@yuno/shared/helpers';


@Component({
    selector: 'control-zoom-handler',
    imports: [CommonModule, TranslocoDirective],
    template: `
		<ng-container *transloco="let t">
			<div class="m-10 text-center" [ngStyle]="{ 'marginLeft.px': leftOffset + 40 }">
				<p *ngIf="type === 'map' && !moveMessage && zoomMessage">
					{{ t('embed.zoomMap') }}
				</p>
				<p *ngIf="type === 'pano' && !moveMessage && zoomMessage">
					{{ t('embed.zoomPano') }}
				</p>
				<p *ngIf="moveMessage">{{ t('embed.touchMap') }}</p>
			</div>
		</ng-container>
	`,
    styleUrls: ['./control-zoom-handler.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlZoomHandlerComponent {
	private timer: number;

	zoomMessage = false;
	moveMessage = false;

	@HostBinding('class.show-ctrlzoom-handler') showHandler: boolean = false;

	@Input() type: 'map' | 'pano' = 'map';
	@Input() leftOffset: number = 0;

	@Input() set wheelEvent(event: WheelEvent) {
		this.ctrlKeyHandler(event);
	}

	@Input() set touchEvent(event: TouchEvent) {
		this.touchEventHandler(event);
	}

	private touchEventHandler(event: TouchEvent) {
		if (!event || !inIframe()) {
			return;
		}

		this.timer && clearTimeout(this.timer);

		if ('touches' in event && event.touches.length <= 1) {
			this.showHandler = true;
			this.moveMessage = true;

			this.timer = window.setTimeout(() => {
				this.showHandler = false;
				this.moveMessage = false;
			}, 1000);

			return;
		}

		this.showHandler = false;
		this.moveMessage = false;
	}

	private ctrlKeyHandler(event: KeyboardEvent | WheelEvent) {
		if (!event || !inIframe()) {
			return;
		}
		event.preventDefault();

		this.timer && clearTimeout(this.timer);

		if (!event.ctrlKey) {
			this.showHandler = true;
			this.zoomMessage = true;

			this.timer = window.setTimeout(() => {
				this.showHandler = false;
				this.zoomMessage = false;
			}, 1000);

			return;
		}

		this.showHandler = false;
		this.zoomMessage = false;
	}
}
