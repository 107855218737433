import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DoCheck,
	Input,
	inject
} from '@angular/core';
import { FormArray } from '@angular/forms';

import { YunoFormsValidatorMessagesComponent } from '../_validator-messages/validator-messages.component';

@Component({
    selector: 'yuno-forms-array-container',
    templateUrl: 'array-container.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [YunoFormsValidatorMessagesComponent]
})
export class YunoFormsArrayContainerComponent implements DoCheck {
	private readonly cdr = inject(ChangeDetectorRef);

	@Input({ required: true }) control: FormArray;

	ngDoCheck(): void {
		this.markTouched();
	}

	markTouched(): void {
		if (!this.control) {
			return;
		}

		if (this.control.touched || this.control.dirty) {
			this.cdr.markForCheck();
		}
	}
}
