import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	Output,
	inject
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor-v2';
import { AsyncSubject, lastValueFrom } from 'rxjs';

import { MessageService } from '@yuno/angular/notifications';
import { NunjucksService } from '@yuno/angular/nunjucks';
import { waitFor } from '@yuno/shared/helpers';

import { YunoAdminButtonComponent } from '../button';

@Component({
	selector: 'yuno-code-editor',
	imports: [FormsModule, ReactiveFormsModule, MonacoEditorModule, YunoAdminButtonComponent],
	template: `
		<form>
			<ngx-monaco-editor
				[style.height.px]="height"
				[options]="editorOptions"
				[formControl]="control"
				(onInit)="onMonacoInit($event)"
				(ngModelChange)="onChange($event)"></ngx-monaco-editor>
			<div class="flex w-full">
				<div class="ml-auto mr-0 flex gap-2">
					@if (validate) {
						<button yuno-admin-button color="secondary" (click)="runValidation()">
							Validate HTML
						</button>
					}

					@if (format) {
						<button yuno-admin-button color="secondary" (click)="onFormat()">
							Format
						</button>
					}
				</div>
			</div>
		</form>
	`,
	styleUrls: ['./code-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CodeEditorComponent {
	private readonly cdr = inject(ChangeDetectorRef);
	private readonly nunjucks = inject(NunjucksService);
	private readonly message = inject(MessageService);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private _editor = new AsyncSubject<void>();
	editor$ = this._editor.asObservable();

	private _control: UntypedFormControl = new UntypedFormControl('');
	private _language: 'json' | 'html' | 'xml' | 'plaintext' = 'json';
	private _readOnly = false;

	@Input()
	set control(form: UntypedFormControl) {
		this._control = form;
	}

	get control() {
		return this._control;
	}

	@Input() format = true;
	@Input() validate = false;
	@Input() height = 300;

	@Input()
	set language(lang: 'json' | 'html' | 'xml' | 'plaintext') {
		this._language = lang;
		this.updateOptions();
	}

	get language() {
		return this._language;
	}

	@Input()
	set readOnly(bool: boolean) {
		this._readOnly = bool;
		this.updateOptions();
	}

	get readOnly() {
		return this._readOnly;
	}

	@Output() changed = new EventEmitter<string>();

	editorOptions: NgxMonacoEditorConfig['defaultOptions'] = {
		theme: 'vs-dark',
		language: this.language,
		scrollBeyondLastLine: false,
		lineNumbers: 'off',
		contextmenu: false,
		snippetSuggestions: 'none',
		codeLens: false,
		automaticLayout: true,
		minimap: {
			enabled: false
		},
		readOnly: this.readOnly
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	editor: any;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onMonacoInit(editor: any): void {
		this.editor = editor;

		this._editor.next(undefined);
		this._editor.complete();

		this.onFormat();
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async getEditor(): Promise<any> {
		await lastValueFrom(this.editor$);
		return this.editor;
	}

	async onFormat(): Promise<void> {
		await this.getEditor();

		// adds a additional wait
		// to let the editor populate its data
		await waitFor(500);

		// perform the format action
		this.editor.getAction('editor.action.formatDocument').run();
		this.cdr.detectChanges();
	}

	async updateOptions(): Promise<void> {
		await this.getEditor();
		this.editorOptions = {
			...this.editorOptions,
			language: this.language,
			readOnly: this.readOnly
		};
		this.cdr.detectChanges();
	}

	onChange(event: string): void {
		this.changed.emit(event);
		this.onFormat();
	}

	runValidation(): void {
		try {
			this.nunjucks.renderString(this.control.value, {});
			this.message.sendToast(`It's all good!`, 'success');
		} catch (error) {
			if (!error) {
				this.message.sendToast('undefined template error', 'error');
			}

			if (typeof error === 'string') {
				this.filterErrorMessage(error);
			}

			if (typeof error === 'object') {
				const err = error?.toString() || '';
				this.filterErrorMessage(err);
			}
		}
	}

	filterErrorMessage(errorMessage: string): void {
		const match = errorMessage.match(/unknown block tag: \w+/);
		this.message.sendToast(match ? match[0] : errorMessage, 'error');
	}
}
