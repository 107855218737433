import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';


@Component({
	selector: 'yuno-ui-floating-list-container',
	standalone: true,
	template: `
		<div
			id="dropdown"
			class="mt-1 w-44 overflow-hidden rounded-lg border border-yuno-gray-400 bg-yuno-gray-600/90 shadow backdrop-blur-sm ">
			<ul class="divide-y divide-yuno-gray-400/60 text-sm text-yuno-gray-100">
				<ng-content></ng-content>
			</ul>
		</div>
	`,
	styleUrls: ['./floating-list.component.scss'],
	// Adds styles to Global CSS
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FloatingListComponent {}
