import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { DragRowComponent, YunoAdminButtonsModule } from '@yuno/admin/ui';
import { moveItemInFormArray } from '@yuno/angular/forms';
import {
	YunoFormsSelectComponent,
	YunoFormsTextComponent,
	YunoFormsTextareaComponent,
	YunoFormsTitleComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';
import { LanguageAll, LanguageType } from '@yuno/api/interface';

import {
	ThemeEditorFormService,
	ThemeForm,
	themeParticipationData
} from '../../theme-editor.form.service';

@Component({
	imports: [
		ReactiveFormsModule,
		YunoFormsTitleComponent,
		YunoFormsTextComponent,
		YunoFormsSelectComponent,
		YunoFormsTextareaComponent,
		YunoFormsToggleComponent,
		YunoAdminButtonsModule,
		DragDropModule,
		DragRowComponent
	],
	selector: 'yuno-admin-theme-participate',
	templateUrl: './theme-participate.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemeParticipateComponent {
	readonly service = inject(ThemeEditorFormService);

	@Input() form: FormGroup<ThemeForm>;
	@Input() language?: LanguageType;

	languages = LanguageAll;

	addItem(): void {
		this.service.addModuleParticipationData();
	}

	removeItem(i: number): void {
		this.service.removeModuleParticipationData(i);
	}

	drop(event: CdkDragDrop<FormArray<FormGroup<themeParticipationData>>>): void {
		moveItemInFormArray(
			this.service.themeParticipation,
			event.previousIndex,
			event.currentIndex
		);
	}
}
