import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PlaceMarkerViewerUiSelectorsComponent } from '../ui-selectors/ui-selectors.component';

@Component({
	selector: 'yuno-admin-place-markers-routing',
	imports: [RouterModule, PlaceMarkerViewerUiSelectorsComponent],
	template: `
		<yuno-admin-place-marker-viewer-ui-selectors />
		<router-outlet />
	`,
	styleUrls: ['routing.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminPlaceMarkersViewerRoutingComponent {}
