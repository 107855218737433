import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Input,
	OnInit,
	inject
} from '@angular/core';

import { LanguagePipe } from '@yuno/angular/pipes';
import { PredefinedItem } from '@yuno/api/interface';

import { TextfieldToken } from '../../textfield.injection.token';
import { TextFileComponent } from '../text-file/text-file.component';
import { PredefinedBlockQuoteEditorComponent } from './components/blockquote.component';
import { PredefinedDividerComponent } from './components/divider.component';
import {
	PredefinedHeading1Component,
	PredefinedHeading2Component,
	PredefinedHeading3Component
} from './components/heading.component';
import { PredefinedParagraphComponent } from './components/paragraph.component';
import {
	PredefinedDateSummaryComponent,
	PredefinedSummaryComponent
} from './components/summary.component';

@Component({
	selector: 'yuno-text-predefined',
	templateUrl: './text-predefined.component.html',
	styleUrl: './text-predefined.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		PredefinedHeading1Component,
		PredefinedHeading2Component,
		PredefinedHeading3Component,
		PredefinedParagraphComponent,
		PredefinedDividerComponent,
		PredefinedBlockQuoteEditorComponent,
		PredefinedDateSummaryComponent,
		PredefinedSummaryComponent,
		LanguagePipe,
		TextFileComponent
	]
})
export class TextPredefinedComponent implements OnInit {
	@HostBinding('class.yuno__textfield__styling') private textStyling = true;
	@HostBinding('class.divider') private isDivider = false;

	private readonly injectValue = inject(TextfieldToken);

	// these selectors should remove the margin-top from the host element
	private noMarginTop = ['h1', 'h2', 'h3'];

	@HostBinding('class') class: string | undefined;

	@Input() disableInjection = false;
	@Input() language?: string;

	_data: PredefinedItem;
	@Input() set data(data: PredefinedItem) {
		this._data = data;
		this.setClass();
	}

	get data(): PredefinedItem {
		return this._data;
	}

	ngOnInit(): void {
		!this.disableInjection &&
			this.injectValue?.data &&
			this.handleInjectionData(
				this.injectValue.data as PredefinedItem,
				this.injectValue.language
			);
	}

	setClass(): void {
		if (this.data.selector.includes('divider')) {
			this.isDivider = true;
			return;
		}

		if (this.noMarginTop.includes(this.data.selector)) {
			this.class = 'no-margin-top';
			return;
		}

		this.class = undefined;
	}

	handleInjectionData(data: PredefinedItem, language?: string): void {
		this.language = language;
		this.data = data;
		this.setClass();
	}
}
