import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	EventEmitter,
	OnInit,
	Output,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { startWith, tap } from 'rxjs';

import { EventFormType, EventsFacade } from '@yuno/admin/features/events';
import { EventsFormsComponent } from '@yuno/admin/features/events/feature/events.forms.component';
import { FencesFacade } from '@yuno/admin/features/fences';
import { MapFacade } from '@yuno/admin/features/map';
import { ThemeFacade } from '@yuno/admin/features/themes';
import { YunoAdminButtonComponent } from '@yuno/admin/ui';
import { JsonFormsModule } from '@yuno/angular-json-forms';
import {
	YunoFormsDividerComponent,
	YunoFormsNumberComponent,
	YunoFormsSelectComponent,
	YunoFormsTextComponent,
	YunoFormsTitleComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';
import { Event } from '@yuno/api/interface';

import { CategoryEditorService } from '../../category-editor.service';

@Component({
	selector: 'yuno-admin-category-settings',
	imports: [
		ReactiveFormsModule,
		YunoFormsDividerComponent,
		YunoFormsTitleComponent,
		YunoFormsTextComponent,
		YunoFormsNumberComponent,
		YunoFormsSelectComponent,
		YunoFormsToggleComponent,
		JsonFormsModule,
		YunoAdminButtonComponent,
		AsyncPipe,
		EventsFormsComponent
	],
	templateUrl: './category-settings.component.html',
	styleUrls: ['./category-settings.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategorySettingsComponent implements OnInit {
	private readonly fenceFacade = inject(FencesFacade);
	private readonly themeFacade = inject(ThemeFacade);
	private readonly eventsFacade = inject(EventsFacade);
	private readonly mapFacade = inject(MapFacade);
	private readonly destroyRef = inject(DestroyRef);
	private readonly cdr = inject(ChangeDetectorRef);

	service = inject(CategoryEditorService);

	toggle$ = this.eventsFacade.toggle$.pipe(
		startWith(false),
		tap(toggle => {
			if (toggle) {
				this.service.disableClose = true;
			}
			if (!toggle) {
				setTimeout(() => {
					this.service.disableClose = false;
				}, 500);
			}
		})
	);
	active: EventFormType;

	@Output() openInputEditor = new EventEmitter<boolean>();
	@Output() openInputList = new EventEmitter<boolean>();

	constructor() {
		this.mapFacade.getMapStyles();
		this.fenceFacade.get();
		this.themeFacade.get();
	}

	ngOnInit(): void {
		this.onChanges();
	}

	onChanges(): void {
		this.service.form?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
			this.cdr.markForCheck();
		});
	}

	onToggleEvents(key: 'onClick' | 'onMouseMove') {
		let events: Event[];
		if (key === 'onClick') {
			events = this.service.onClickEvents.getRawValue() as Event[];
		} else {
			events = this.service.onMouseMoveEvents.getRawValue() as Event[];
		}

		this.active = key;
		this.eventsFacade.toggleEvents(true);
		this.eventsFacade.updateEvents(events);
		this.eventsFacade.setKey(key);
	}
}
