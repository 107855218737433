import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	Output,
	signal
} from '@angular/core';
import { isEqual } from 'lodash';

import { LanguagePipe } from '@yuno/angular/pipes';
import { LanguageTypeAll } from '@yuno/api/interface';

import { YunoButtonComponent } from '../button';
import { YunoAtlasButtonGroupItem } from '../button-group';

@Component({
	selector: 'yuno-mobile-states',
	imports: [
		CdkOverlayOrigin,
		CommonModule,
		CdkConnectedOverlay,
		LanguagePipe,
		YunoButtonComponent
	],
	template: `
		<ng-template
			cdkConnectedOverlay
			[cdkConnectedOverlayOrigin]="stateButton"
			[cdkConnectedOverlayOpen]="$open()"
			[cdkConnectedOverlayHasBackdrop]="true"
			[cdkConnectedOverlayPositions]="[connectedPosition]"
			[cdkConnectedOverlayBackdropClass]="'transparent'"
			(backdropClick)="toggle()">
			<div
				class="mb-2 flex flex-col items-end justify-end gap-4 rounded-md bg-yuno-gray-500 p-2 text-yuno-gray-100 shadow-md">
				@for (option of options; track option.id) {
					<button
						class="px-4 hover:opacity-60"
						(click)="toggleState(option)"
						[ngClass]="{
							'pointer-events-none opacity-40': activeValue?.id === option.id
						}">
						{{ option.display | languageSelect: language }}
					</button>
				}
			</div>
		</ng-template>

		<button yuno-button #stateButton="cdkOverlayOrigin" cdkOverlayOrigin (click)="toggle()">
			<span
				class="inline-flex transform flex-row items-center justify-center gap-2 rounded-full">
				{{ activeValue?.display | languageSelect: language }}
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke-width="3"
					stroke="currentColor"
					[ngClass]="{
						'-scale-y-100': $open()
					}"
					class="h-4 w-4">
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						d="m4.5 15.75 7.5-7.5 7.5 7.5" />
				</svg>
			</span>
		</button>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoMobileStatesComponent {
	@Input()
	@HostBinding('class')
	style: 'square' | 'rounded' | 'round' = 'round';

	@Input() language: LanguageTypeAll = 'nl';

	activeValue?: YunoAtlasButtonGroupItem;

	// Keep track of the current active Value
	private _value: string;
	@Input()
	set value(val: string | undefined) {
		if (!val) {
			return;
		}

		this._value = val;
		this.checkActive();
	}

	get value(): string {
		return this._value;
	}

	@Output() valueChange = new EventEmitter<string>();

	private _options: YunoAtlasButtonGroupItem[] = [];
	@Input() set options(options: YunoAtlasButtonGroupItem[]) {
		if (isEqual(options, this.options)) {
			return;
		}

		this._options = options || [];
		this.checkActive();
	}

	get options(): YunoAtlasButtonGroupItem[] {
		return this._options;
	}

	$open = signal<boolean>(false);
	connectedPosition: ConnectedPosition = {
		originX: 'end',
		originY: 'top',
		overlayX: 'end',
		overlayY: 'bottom'
	};

	toggle(): void {
		this.$open.set(!this.$open());
	}

	toggleState(option: YunoAtlasButtonGroupItem): void {
		this.value = option.id;
		this.valueChange.emit(this.value);
		this.toggle();
	}

	checkActive(): void {
		this.activeValue = this.options.find(e => e.id === this.value);
	}
}
