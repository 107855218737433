import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { AdminItemListComponent, YunoAdminButtonsModule } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import {
	YunoFormsSelectComponent,
	YunoFormsSpanComponent,
	YunoFormsTitleComponent
} from '@yuno/angular/forms/components';

import { ThemeEditorFormService, ThemeForm } from '../../theme-editor.form.service';

@Component({
	imports: [
		ReactiveFormsModule,
		YunoFormsTitleComponent,
		YunoFormsSpanComponent,
		YunoFormsSelectComponent,
		YunoAdminButtonsModule,
		AdminItemListComponent
	],
	selector: 'yuno-admin-theme-datasets',
	templateUrl: './theme-datasets.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemeDatasetsComponent extends AppDataComponent {
	readonly service = inject(ThemeEditorFormService);

	@Input() form: FormGroup<ThemeForm>;

	removeItemAt(i: number): void {
		this.service.removeDataset(i);
	}

	addItem(): void {
		this.service.addDataset();
	}

	editDatasetAt(id: string) {
		console.log(id);
		this.router.navigate(['dataset', id], {
			relativeTo: this.route
		});
	}
}
