import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { EventForm, EventFormsService } from '@yuno/admin/features/events';
import { YunoFormsSelectComponent, YunoFormsTitleComponent } from '@yuno/angular/forms/components';
import { Event } from '@yuno/api/interface';

import { ClickMarkerComponent } from '../form-items/click-marker/click-marker.component';
import { EmbedTextComponent } from '../form-items/embed-text/embed-text.component';
import { LoadDatasetComponent } from '../form-items/load-dataset/load-dataset.component';
import { LoadPanoComponent } from '../form-items/load-pano/load-pano.component';
import { MapPopupComponent } from '../form-items/map-popup/map-popup.component';
import { OpenImageComponent } from '../form-items/open-image/open-image.component';
import { OpenTextPopupComponent } from '../form-items/open-text-popup/open-text-popup.component';
import { OpenTextComponent } from '../form-items/open-text/open-text.component';
import { OpenUrlComponent } from '../form-items/open-url/open-url.component';
import { PopupCustomDataComponent } from '../form-items/popup-custom-data/popup-custom-data.component';
import { PopupCustomTextComponent } from '../form-items/popup-custom-text/popup-custom-text.component';
import { SetFeatureStateComponent } from '../form-items/set-feature-state/set-feature-state.component';
import { SetLayerStyleComponent } from '../form-items/set-layer-style/set-layer-style.component';
import { ZoomToBoundsComponent } from '../form-items/zoom-to-bounds/zoom-to-bounds.component';
import { ZoomToExtendComponent } from '../form-items/zoom-to-extend/zoom-to-extend.component';
import { ZoomToFeatureComponent } from '../form-items/zoom-to-feature/zoom-to-feature.component';
import { ZoomToFenceComponent } from '../form-items/zoom-to-fence/zoom-to-fence.component';
import { ZoomToMarkerComponent } from '../form-items/zoom-to-marker/zoom-to-marker.component';
import { ZoomToPolygonComponent } from '../form-items/zoom-to-polygon/zoom-to-polygon.component';
import { ZoomToComponent } from '../form-items/zoom-to/zoom-to.component';
import {
	contentEventTypes,
	getDisplayNames,
	legacyEventTypes,
	textEventTypes,
	zoomEventTypes
} from './types';

@Component({
	selector: 'yuno-admin-event-item-form',
	imports: [
		ZoomToFenceComponent,
		ZoomToBoundsComponent,
		ZoomToFeatureComponent,
		ZoomToPolygonComponent,
		MapPopupComponent,
		PopupCustomTextComponent,
		OpenTextComponent,
		OpenTextPopupComponent,
		EmbedTextComponent,
		ZoomToMarkerComponent,
		SetFeatureStateComponent,
		LoadDatasetComponent,
		LoadPanoComponent,
		OpenImageComponent,
		ZoomToExtendComponent,
		YunoFormsTitleComponent,
		YunoFormsSelectComponent,
		ZoomToComponent,
		PopupCustomDataComponent,
		OpenUrlComponent,
		ClickMarkerComponent,
		ReactiveFormsModule,
		SetLayerStyleComponent
	],
	templateUrl: './event-item-form.component.html',
	styleUrls: ['./event-item-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventItemFormComponent {
	private readonly service = inject(EventFormsService);

	@Input({ required: true }) group: FormGroup<EventForm>;

	eventGroupSelect = [
		{
			optgroup: 'Content',
			options: contentEventTypes
		},
		{
			optgroup: 'Text',
			options: textEventTypes
		},
		{
			optgroup: 'Zoom',
			options: zoomEventTypes
		},
		{
			optgroup: 'Legacy (Do not use!)',
			options: legacyEventTypes
		}
	];
	eventGroupDisplay = [
		{
			optgroup: 'Content',
			options: getDisplayNames(contentEventTypes)
		},
		{
			optgroup: 'Text',
			options: getDisplayNames(textEventTypes)
		},
		{
			optgroup: 'Zoom',
			options: getDisplayNames(zoomEventTypes)
		},
		{
			optgroup: 'Legacy',
			options: getDisplayNames(legacyEventTypes)
		}
	];

	generateOptions(type: string | null): void {
		if (!type) {
			return;
		}

		const options = this.service.generateOptions(type as Event['type']);
		if (!options) {
			return;
		}

		this.group.setControl('options', options);
	}
}
