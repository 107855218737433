import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { AnnotationComponent } from '@yuno/admin/ui';
import {
	YunoFormsSelectComponent,
	YunoFormsSpanComponent,
	YunoFormsTextComponent
} from '@yuno/angular/forms/components';
import { LoadDatasetEvent } from '@yuno/api/interface';

import { EventsDefaultFormItemComponent } from '../_default/default-form-item.component';

@Component({
	selector: 'yuno-admin-load-dataset',
	imports: [
		ReactiveFormsModule,
		YunoFormsTextComponent,
		YunoFormsSpanComponent,
		YunoFormsSelectComponent,
		AnnotationComponent,
		AsyncPipe
	],
	templateUrl: './load-dataset.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadDatasetComponent extends EventsDefaultFormItemComponent implements OnInit {
	@Input() unload: boolean = false;

	ngOnInit(): void {
		this.service.getDataset();
		this.onValueChanged((this.form.value.options as LoadDatasetEvent['options']).id);
	}
}
