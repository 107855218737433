module.exports = {
  A: {
    A: {
      "2": "K iC",
      "2340": "D E F A B"
    },
    B: {
      "2": "C L M G N O P",
      "1025": "6 7 8 9 Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I"
    },
    C: {
      "1": "6 7 8 9 c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC kC lC",
      "2": "jC IC mC",
      "513": "xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b",
      "545": "0 1 2 3 4 5 J MB K D E F A B C L M G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB nC"
    },
    D: {
      "2": "0 1 2 3 4 5 J MB K D E F A B C L M G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB",
      "1025": "6 7 8 9 bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC"
    },
    E: {
      "1": "A B C L M G QC CC DC tC uC vC RC SC EC wC FC TC UC VC WC XC xC GC YC ZC aC bC cC yC HC dC eC fC zC",
      "2": "J MB oC PC pC",
      "164": "K",
      "4644": "D E F qC rC sC"
    },
    F: {
      "2": "0 1 2 3 4 5 F B G N O P NB y z 0C 1C 2C 3C CC gC",
      "545": "C 4C DC",
      "1025": "OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x"
    },
    G: {
      "1": "CD DD ED FD GD HD ID JD KD LD MD ND OD RC SC EC PD FC TC UC VC WC XC QD GC YC ZC aC bC cC RD HC dC eC fC",
      "2": "PC 5C hC",
      "4260": "6C 7C",
      "4644": "E 8C 9C AD BD"
    },
    H: {
      "2": "SD"
    },
    I: {
      "2": "IC J TD UD VD WD hC XD YD",
      "1025": "I"
    },
    J: {
      "2": "D",
      "4260": "A"
    },
    K: {
      "2": "A B CC gC",
      "545": "C DC",
      "1025": "H"
    },
    L: {
      "1025": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "2340": "A B"
    },
    O: {
      "1025": "EC"
    },
    P: {
      "1025": "0 1 2 3 4 5 J y z ZD aD bD cD dD QC eD fD gD hD iD FC GC HC jD"
    },
    Q: {
      "1025": "kD"
    },
    R: {
      "1025": "lD"
    },
    S: {
      "1": "nD",
      "4097": "mD"
    }
  },
  B: 4,
  C: "Crisp edges/pixelated images",
  D: true
};