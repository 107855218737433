import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import {
	YunoFormsNumberComponent,
	YunoFormsSelectComponent,
	YunoFormsSpanComponent,
	YunoFormsTitleComponent
} from '@yuno/angular/forms/components';

import { CategoryEditorService } from '../../../category-editor.service';

@Component({
	selector: 'yuno-admin-category-styling',
	imports: [
		YunoFormsTitleComponent,
		YunoFormsSpanComponent,
		YunoFormsNumberComponent,
		YunoFormsSelectComponent,
		ReactiveFormsModule
	],
	templateUrl: './category-styling.component.html',
	styleUrls: ['./category-styling.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryStylingComponent {
	service = inject(CategoryEditorService);
}
