import { Location } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	HostBinding,
	Input,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Params, Router, RoutesRecognized } from '@angular/router';
import { debounceTime, filter, map, pairwise } from 'rxjs';

import { LanguagePipe } from '@yuno/angular/pipes';
import { Event, LanguageStringsModel, TextList, TextListItemComponents } from '@yuno/api/interface';
import { waitFor } from '@yuno/libs/shared/helpers';

import { EventsService } from '../../services/events.service';
import { TextfieldToken } from '../../textfield.injection.token';
import { TextListDropdownComponent } from './dropdown/dropdown.component';
import { TextListItemComponent } from './item/item.component';

@Component({
    selector: 'yuno-textfield-list',
    template: `
		@if (title | languageSelect: language; as titleLanguage) {
			@if (titleLanguage) {
				<span class="font-semibold italic">{{ titleLanguage }}</span>
			}
		}
		<ng-content></ng-content>

		@if (data && routeDataComplete) {
			@switch (select && !multiselect) {
				@case (true) {
					<yuno-textfield-list-dropdown
						[language]="language"
						[items]="items"
						[color]="data.active?.color"
						[backgroundColor]="data.active?.backgroundColor"
						[resettable]="!!data.resettable"
						[defaultValue]="data.defaultValue"
						(activeValue)="sendDropdownEvent($event)" />
				}
				@default {
					@for (item of items; track trackByFn($index, item)) {
						<yuno-textfield-list-item
							[color]="data.active?.color"
							[backgroundColor]="data.active?.backgroundColor"
							[disableActiveState]="!!data.disableActiveState"
							[title]="item.title | languageSelect: language"
							[multiselect]="multiselect"
							[active]="!!item.active"
							(activeChange)="sendEvent(item, $event)" />
					}
				}
			}
		}
	`,
    styleUrls: ['./text-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [TextListDropdownComponent, TextListItemComponent, LanguagePipe]
})
export class TextListComponent implements OnInit {
	@HostBinding('class.yuno__textfield__styling') private textStyling = true;

	private location = inject(Location);
	private router = inject(Router);
	private destroyRef = inject(DestroyRef);
	private event = inject(EventsService);
	private route = inject(ActivatedRoute);
	private cdr = inject(ChangeDetectorRef);
	injectValue = inject(TextfieldToken);

	@Input() title?: LanguageStringsModel;

	@Input() language?: string;
	@Input() disableInjection = false;

	// the items in the view
	private _items: TextListItemComponents[] = [];

	// the items in memory, when changing routes between pages and themes
	private _originalItems: TextListItemComponents[] = [];

	@Input() set items(data: TextListItemComponents[]) {
		this._items = data;
		this._originalItems = data;
	}

	get items(): TextListItemComponents[] {
		return this._items;
	}

	@Input() select = false;
	@Input() data: TextList;

	@Input() multiselect = false;

	routeDataComplete = false;

	ngOnInit(): void {
		this.subscribeToRouterEvents();

		if (!this.disableInjection && this.injectValue?.data) {
			this.handleInjectionData();
			return;
		}

		// Do not use when injection data is provided
		// else we trigger the route data twice
		this.activateRouteData();
	}

	activateRouteData(): void {
		if (this.data.disableActiveState) {
			this.routeDataComplete = true;
			this.cdr.markForCheck();
			return;
		}

		this.route.queryParamMap
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				debounceTime(500),
				map((params: Params) => params['params'])
			)
			.subscribe(params => {
				if (params['list']) {
					const list: string[] = params['list']?.split(',');

					if (this.items) {
						const items: TextListItemComponents[] = JSON.parse(
							JSON.stringify(this.items)
						);
						for (const item of items) {
							if (!item.id) {
								item.active = false;
								continue;
							}

							if (!list.includes(item.id)) {
								item.active = false;
								continue;
							}

							item.active = true;
						}

						this.items = items;
					}

					this.routeDataComplete = true;
					this.cdr.markForCheck();

					return;
				}

				this.routeDataComplete = true;
				this.cdr.markForCheck();
			});
	}

	subscribeToRouterEvents(): void {
		this.router.events
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				filter((evt: any) => evt instanceof RoutesRecognized),
				pairwise()
			)
			.subscribe((events: RoutesRecognized[]) => {
				if (
					events[0].urlAfterRedirects.includes('/page') &&
					!events[1].urlAfterRedirects.includes('/page')
				) {
					this.resetItems();
					this.cdr.detectChanges();

					if (!this.disableInjection && this.injectValue?.data) {
						this.handleInjectionData();
						return;
					}

					this.setItems();
				}
			});
	}

	/**
	 * Setup the Component by using the Injection Method
	 */
	handleInjectionData() {
		const data = this.injectValue.data as TextList;

		this.language = this.injectValue.language;
		this.data = data;
		this.title = data.title;
		this.items = data.items || ([] as TextListItemComponents[]);
		this.select = !!data.select;
		this.multiselect = !!data.multiselect;

		this.activateRouteData();
	}

	resetItems(): void {
		this.items = [];
	}

	setItems(): void {
		this.items = this._originalItems;
	}

	async sendDropdownEvent(id: string): Promise<void> {
		const found = this.items?.find(item => item.id === id);
		if (found) {
			await this.sendEvent(found, true);
		}
	}

	async sendEvent(item: TextListItemComponents, active?: boolean): Promise<void> {
		// When inside a Page, the first page when entering the application
		// we need to delay triggering the events. This can cause the Home Page
		// to dissapear when the events are triggered too fast.
		const path = this.location.path();
		if (path.includes('/page')) {
			// await waitFor(500);
			return;
		}

		if (!item.events || !item.events.length) {
			return;
		}

		if (!active) {
			await this.deactivateEvents(item);
			return;
		}
		//
		// When disableActiveState is true, we don't want to change the route params
		if (!this.data.disableActiveState) {
			const options: { list?: string | null; ds?: string | null } = {
				list: item.id,
				ds: null
			};

			if (item.events.find(e => e.type === 'loadDataset') || this.data.keepActiveDataset) {
				delete options.ds;
			}

			if (this.multiselect) {
				const list = this.items?.filter(item => item.active).map(item => item.id);
				options.list = list?.filter(e => !!e).join(',') || null;
			}

			this.changeActiveItems(item.id, active);
			this.event.sendEvent([
				{
					type: 'navigateToQueryParams',
					options: {
						params: options
					}
				}
			]);
		}

		// Wait so the route params can change
		await waitFor(10);

		const events: Event[] = [];
		for (const event of item.events) {
			const newEvent = JSON.parse(JSON.stringify({ ...event }));
			if (newEvent.type === 'loadDataset') {
				newEvent.options = {
					...newEvent.options,
					multi: this.multiselect
				};

				events.push(newEvent);
				continue;
			}

			events.push(event);
		}
		this.event.sendEvent(events);
	}

	async deactivateEvents(item: TextListItemComponents): Promise<void> {
		const options: { list?: string | null; ds?: string | null } = { list: null, ds: null };

		if (this.multiselect) {
			const list = this.items?.filter(i => i.active).map(i => i.id);
			options.list = list?.filter(e => !!e).join(',') || null;
		}

		this.event.sendEvent([
			{
				type: 'navigateToQueryParams',
				options: {
					params: options
				}
			}
		]);

		if (!item.events || !item.events.length) {
			return;
		}

		// Wait so the route params can change
		await waitFor(10);

		const events: Event[] = [];
		for (const event of item.events) {
			const newEvent = JSON.parse(JSON.stringify({ ...event }));
			if (newEvent.type === 'loadDataset') {
				newEvent.options = {
					...newEvent.options,
					multi: this.multiselect,
					remove: true
				};
				events.push(newEvent);
			}
		}
		this.event.sendEvent(events);
	}

	changeActiveItems(id: string | undefined, active: boolean): void {
		if (!this.items) {
			console.warn('TextList: No Items');
			return;
		}

		const items = JSON.parse(JSON.stringify(this.items));

		if (!id) {
			for (const item of items) {
				item.active = false;
			}

			this.items = items;
			return;
		}

		for (const item of items) {
			if (this.multiselect) {
				if (item.id === id) {
					item.active = active;
				}
				continue;
			}

			if (item.id !== id) {
				item.active = false;
			} else {
				item.active = active;
			}
		}

		this.items = items;
		this.cdr.markForCheck();
	}

	trackByFn(index: number, item: TextListItemComponents) {
		return item.id;
	}
}
