import { NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs';

import { columns } from '../../helpers';
import {
	JsonArrayForm,
	JsonButtonForm,
	JsonFormControl,
	JsonFormEvent,
	JsonFormKeyType,
	JsonFormPageData,
	JsonGroupForm,
	JsonLabel,
	JsonLanguageForm
} from '../../json-forms.models';
import { ArrayComponent } from '../types/array/array.component';
import { ButtonComponent } from '../types/button/button.component';
import { ControlComponent } from '../types/control/control.component';
import { DividerComponent } from '../types/divider/divider.component';
import { GroupComponent } from '../types/group/group.component';
import { LabelComponent } from '../types/label/label.component';
import { LanguageComponent } from '../types/language/language.component';

@Component({
    selector: 'yuno-json-forms-dynamic-form',
    templateUrl: './dynamic-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgClass,
        ButtonComponent,
        LabelComponent,
        DividerComponent,
        ArrayComponent,
        GroupComponent,
        LanguageComponent,
        ControlComponent
    ]
})
export class DynamicFormComponent implements OnInit {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() formValue: any;
	@Input() formGroup: UntypedFormGroup;
	@Input() formData: JsonFormPageData;
	@Input() noMargin = false;
	@Output() formEvent: EventEmitter<JsonFormEvent> = new EventEmitter<JsonFormEvent>();
	@Output() formChanges: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();

	ngOnInit(): void {
		this.onFormChanges();
	}

	onFormChanges(): void {
		this.formGroup.valueChanges.pipe(debounceTime(100)).subscribe(val => {
			this.formChanges.emit(val);
		});
	}

	asGroup(obj: JsonFormKeyType): JsonGroupForm {
		return obj as JsonGroupForm;
	}

	asButton(obj: JsonFormKeyType): JsonButtonForm {
		return obj as JsonButtonForm;
	}

	asLabel(obj: JsonFormKeyType): JsonLabel {
		return obj as JsonLabel;
	}

	asArray(obj: JsonFormKeyType): JsonArrayForm {
		return obj as JsonArrayForm;
	}

	asFormControl(obj: JsonFormKeyType): JsonFormControl {
		return obj as JsonFormControl;
	}

	asFormLanguage(obj: JsonFormKeyType): JsonLanguageForm {
		return obj as JsonLanguageForm;
	}

	columns(cols: number | undefined): string {
		return columns(cols);
	}
}
