module.exports = {
  A: {
    A: {
      "2": "K D E F A iC",
      "548": "B"
    },
    B: {
      "1": "6 7 8 9 Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I",
      "516": "C L M G N O P"
    },
    C: {
      "1": "6 7 8 9 wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC kC lC",
      "2": "jC IC J MB K D E F mC nC",
      "676": "0 1 2 3 4 5 A B C L M G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB",
      "1700": "hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB"
    },
    D: {
      "1": "6 7 8 9 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC",
      "2": "J MB K D E F A B C L M",
      "676": "G N O P NB",
      "804": "0 1 2 3 4 5 y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B"
    },
    E: {
      "1": "WC XC xC GC YC ZC aC bC cC yC HC dC eC fC zC",
      "2": "J MB oC PC",
      "548": "SC EC wC FC TC UC VC",
      "676": "pC",
      "804": "K D E F A B C L M G qC rC sC QC CC DC tC uC vC RC"
    },
    F: {
      "1": "wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x DC",
      "2": "F B C 0C 1C 2C 3C CC gC 4C",
      "804": "0 1 2 3 4 5 G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB"
    },
    G: {
      "2": "E PC 5C hC 6C 7C 8C 9C AD BD CD DD ED FD",
      "2052": "GD HD ID JD KD LD MD ND OD RC SC EC PD FC TC UC VC WC XC QD GC YC ZC aC bC cC RD HC dC eC fC"
    },
    H: {
      "2": "SD"
    },
    I: {
      "2": "IC J I TD UD VD WD hC XD YD"
    },
    J: {
      "2": "D",
      "292": "A"
    },
    K: {
      "1": "H",
      "2": "A B C CC gC DC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "2": "A",
      "548": "B"
    },
    O: {
      "1": "EC"
    },
    P: {
      "1": "0 1 2 3 4 5 y z QC eD fD gD hD iD FC GC HC jD",
      "804": "J ZD aD bD cD dD"
    },
    Q: {
      "1": "kD"
    },
    R: {
      "1": "lD"
    },
    S: {
      "1": "mD nD"
    }
  },
  B: 1,
  C: "Fullscreen API",
  D: true
};