import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostListener,
	Input,
	Output
} from '@angular/core';

import { MapMarkerData, MarkerFixedProperties, MarkerIconStyle } from '@yuno/api/interface';

import { DefaultComponent } from '../default/default.component';

export interface ClusterClickEvent {
	source: string;
	id: number;
}

@Component({
	selector: 'yuno-marker-cluster',
	template: `
		@if (style) {
			<yuno-marker-icon [data]="data" [style]="style">
				@if (counter) {
					<div
						class="yuno-marker-cluster-count !rounded-full px-2 py-1"
						[ngStyle]="{
							backgroundColor: counter.backgroundColor,
							color: counter.color
						}"
						[ngClass]="alignment">
						{{ cluster?.point_count || 0 }}
					</div>
				}
			</yuno-marker-icon>
		}
	`,
	styleUrls: ['./cluster.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false
})
export class ClusterComponent extends DefaultComponent {
	@Input() style: MarkerIconStyle;

	override _data: MapMarkerData | null = null;

	alignment = '';
	counter: MarkerFixedProperties['counter'];
	cluster: MapMarkerData['cluster'];

	@Input() override set data(data: MapMarkerData | null) {
		this._data = data;
		this.cluster = data?.cluster;
		this.counter = data?.properties.counter;
		this.alignment = `count-${this.counter?.alignment || 'top-right'}`;
		this.setEvents();
	}

	override get data(): MapMarkerData | null {
		return this._data;
	}

	@Output() clusterClick = new EventEmitter<ClusterClickEvent>();

	@HostListener('click') clicked() {
		this.clickCluster();
	}

	clickCluster(): void {
		if (this.data?.cluster) {
			this.clusterClick.emit({
				source: this.data.cluster.cluster_source,
				id: this.data.cluster.cluster_id
			});
		}
	}
}
