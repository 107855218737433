module.exports = {
  A: {
    A: {
      "2": "K D E F A B iC"
    },
    B: {
      "1": "6 7 8 9 x AB BB CB DB EB FB GB HB IB JB KB LB I",
      "2": "C L M G N O P",
      "164": "Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v",
      "2049": "w"
    },
    C: {
      "1": "6 7 8 9 w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC kC lC",
      "2": "0 1 2 3 4 5 jC IC J MB K D E F A B C L M G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U mC nC",
      "66": "V W",
      "2305": "Y Z a b c d e f g h i j k l m n o p q r s t u v",
      "2820": "X"
    },
    D: {
      "1": "6 7 8 9 x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC",
      "2": "J MB K D E F A B C L M G N O P NB y",
      "164": "0 1 2 3 4 5 z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v",
      "2049": "w"
    },
    E: {
      "1": "GC YC ZC aC bC cC yC HC dC eC fC zC",
      "2": "J MB oC PC pC",
      "132": "A B C L QC CC DC tC",
      "164": "K D E F qC rC sC",
      "1540": "M G uC vC RC SC EC wC FC TC UC VC WC XC xC"
    },
    F: {
      "1": "j k l m n o p q r s t u v w x",
      "2": "F B C 0C 1C 2C 3C CC gC 4C DC",
      "164": "0 1 2 3 4 5 G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h",
      "2049": "i"
    },
    G: {
      "1": "GC YC ZC aC bC cC RD HC dC eC fC",
      "2": "PC 5C hC 6C",
      "132": "CD DD ED FD GD HD ID JD KD LD",
      "164": "E 7C 8C 9C AD BD",
      "1540": "MD ND OD RC SC EC PD FC TC UC VC WC XC QD"
    },
    H: {
      "2": "SD"
    },
    I: {
      "1": "I",
      "2": "IC J TD UD VD WD hC",
      "164": "XD YD"
    },
    J: {
      "2": "D",
      "164": "A"
    },
    K: {
      "1": "H",
      "2": "A B C CC gC DC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "2": "A B"
    },
    O: {
      "164": "EC"
    },
    P: {
      "1": "1 2 3 4 5",
      "164": "0 J y z ZD aD bD cD dD QC eD fD gD hD iD FC GC HC jD"
    },
    Q: {
      "164": "kD"
    },
    R: {
      "164": "lD"
    },
    S: {
      "2": "mD nD"
    }
  },
  B: 5,
  C: "CSS image-set",
  D: true
};