import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';

import { AppFacade } from '@yuno/admin/features/apps';
import { YunoAdminCardComponent } from '@yuno/admin/ui';

@Component({
	imports: [YunoAdminCardComponent],
	selector: 'yuno-client-production-team',
	templateUrl: './client-team.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppClientTeamComponent implements OnInit {
	private readonly appFacade = inject(AppFacade);

	engineer: string | undefined;
	lead: string | undefined;
	contact: string | undefined;

	ngOnInit() {
		const app = this.appFacade.$app();

		this.engineer = app?.data?.engineer;
		this.lead = app?.data?.lead;
		this.contact = app?.data?.contact;
	}
}
