module.exports = {
  A: {
    A: {
      "1": "B",
      "2": "K D E F A iC"
    },
    B: {
      "1": "6 7 8 9 M G N O P Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I",
      "129": "C L"
    },
    C: {
      "1": "6 7 8 9 kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC kC lC",
      "2": "jC IC",
      "260": "0 1 2 3 4 5 G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB",
      "804": "J MB K D E F A B C L M mC nC"
    },
    D: {
      "1": "6 7 8 9 qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC",
      "260": "lB mB nB oB pB",
      "388": "QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB",
      "1412": "0 1 2 3 4 5 G N O P NB y z OB PB",
      "1956": "J MB K D E F A B C L M"
    },
    E: {
      "1": "SC EC wC FC TC UC VC WC XC xC GC YC ZC aC bC cC yC HC dC eC fC zC",
      "129": "A B C L M G sC QC CC DC tC uC vC RC",
      "1412": "K D E F qC rC",
      "1956": "J MB oC PC pC"
    },
    F: {
      "1": "dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x",
      "2": "F 0C 1C",
      "260": "YB ZB aB bB cB",
      "388": "0 1 2 3 4 5 G N O P NB y z OB PB QB RB SB TB UB VB WB XB",
      "1796": "2C 3C",
      "1828": "B C CC gC 4C DC"
    },
    G: {
      "1": "SC EC PD FC TC UC VC WC XC QD GC YC ZC aC bC cC RD HC dC eC fC",
      "129": "BD CD DD ED FD GD HD ID JD KD LD MD ND OD RC",
      "1412": "E 7C 8C 9C AD",
      "1956": "PC 5C hC 6C"
    },
    H: {
      "1828": "SD"
    },
    I: {
      "1": "I",
      "388": "XD YD",
      "1956": "IC J TD UD VD WD hC"
    },
    J: {
      "1412": "A",
      "1924": "D"
    },
    K: {
      "1": "H",
      "2": "A",
      "1828": "B C CC gC DC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "1": "B",
      "2": "A"
    },
    O: {
      "1": "EC"
    },
    P: {
      "1": "0 1 2 3 4 5 y z bD cD dD QC eD fD gD hD iD FC GC HC jD",
      "260": "ZD aD",
      "388": "J"
    },
    Q: {
      "1": "kD"
    },
    R: {
      "1": "lD"
    },
    S: {
      "1": "nD",
      "260": "mD"
    }
  },
  B: 4,
  C: "CSS3 Border images",
  D: true
};