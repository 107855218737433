@if (routerData | async) {
	@switch (data.type) {
		@case ('iframe') {
			<ng-container *ngTemplateOutlet="iframe"></ng-container>
		}
		@case ('iframe-split') {
			<ng-container *ngTemplateOutlet="iframeSplit"></ng-container>
		}
		@case ('default') {
			<ng-container *ngTemplateOutlet="defaultPage"></ng-container>
		}
		@case ('side-by-side') {
			<ng-container *ngTemplateOutlet="defaultPage"></ng-container>
		}
		@default {
			<ng-container *ngTemplateOutlet="defaultPage"></ng-container>
		}
	}
	<!-- IFRAME PAGE -->
	<ng-template #iframe>
		@switch (!!data.iframeSrc) {
			@case (true) {
				<iframe [src]="data.iframeSrc | safeIframe" width="100%" height="100%"></iframe>
			}
			@default {
				<p>NO IFRAME SRC</p>
			}
		}
	</ng-template>

	<!-- IFRAME SPLITSCREEN PAGE -->
	<ng-template #iframeSplit>
		<div class="flex h-full w-full flex-col justify-start md:flex-row">
			<div
				class="split-left-container wide-text max-h-[300px] md:max-h-full"
				[ngStyle]="{ 'background-color': data.iframeOptions?.backgroundColor || '#fff' }">
				<div
					class="flex h-full flex-col justify-start overflow-y-auto overflow-x-hidden rounded-lg bg-white px-4 py-2 pt-0 shadow-md md:p-4 md:pt-0 lg:p-6 lg:pt-0">
					<ng-container *ngTemplateOutlet="defaultPage"></ng-container>
				</div>
			</div>
			<ng-container *ngTemplateOutlet="iframe"></ng-container>
		</div>
	</ng-template>
	<!-- Default Page -->
	<ng-template #defaultPage>
		<div
			*transloco="let t"
			class="flex h-full w-full flex-col items-center justify-start gap-6 px-6 sm:pt-8 xl:px-2 2xl:px-0"
			[ngClass]="{
				'max-w-3xl': !sideBySide,
				'max-w-6xl': sideBySide,
				'center-logo': data.header?.logo?.position === 'top-center'
			}">
			<!-- Menu Item -->
			@if (disableNavbar) {
				<div
					class="absolute left-0 top-0 h-14 cursor-pointer flex-row items-center justify-center divide-purple-300 p-4 transition-opacity hover:opacity-80 sm:flex"
					(click)="activateSideMenu()">
					<yuno-hamburger [active]="sidemenuActive" />
					{{ t('buttons.menu') }}
				</div>
			}

			<!-- LOGO TOP-CENTER ONLY -->
			@if (data.header && data.header.logo.position === 'top-center' && logo) {
				<div class="page-header-logo centered" [ngClass]="data.header.logo.position">
					<img
						class="logo-img"
						[src]="logo.src"
						[ngStyle]="{ 'maxHeight.px': logo.height, 'maxWidth.px': logo.width }"
						loading="lazy" />
				</div>
			}

			<!-- HEADER -->
			@if (
				data.header &&
				(data.header.logo.active ||
					(data.header.title.projectTitle | languageSelect: language))
			) {
				<section
					class="relative flex w-full flex-col items-start justify-start"
					[ngClass]="{
						'sm:flex-row-reverse sm:items-end': data.header.logo.position === 'right'
					}">
					<!-- LOGO LEFT / RIGHT -->
					@if (
						data.header.logo.active &&
						logo &&
						(data.header.logo.position === 'left' ||
							data.header.logo.position === 'right')
					) {
						<div
							class="flex flex-row"
							[ngClass]="{
								'w-full justify-end sm:w-auto':
									data.header.logo.position === 'right'
							}">
							<img
								class="logo-img"
								[src]="logo.src"
								[ngStyle]="{
									'maxHeight.px': logo.height,
									'maxWidth.px': logo.width
								}"
								loading="lazy" />
						</div>
					}

					<div class="flex flex-1 flex-col">
						<h3 class="text-lg font-semibold text-yuno-gray-400">
							{{ data.header.title.description | languageSelect: language }}
						</h3>
						@if (data.header.logo.position !== 'project') {
							<h1
								class="text-[1.75rem] font-semibold"
								[ngStyle]="{ color: data.header.title.color }">
								{{ data.header.title.projectTitle | languageSelect: language }}
							</h1>
						}

						<!-- LOGO PROJECT -->
						@if (
							data.header.logo.active &&
							logo &&
							data.header.logo.position === 'project'
						) {
							<div>
								<img
									class="logo-img"
									[src]="logo.src"
									[ngStyle]="{
										'maxHeight.px': logo.height,
										'maxWidth.px': logo.width
									}"
									loading="lazy" />
							</div>
						}
					</div>
				</section>
			}

			<!-- BANNER -->
			@if (data.banner?.active && !data.banner?.imageBanner?.reactive) {
				<section
					class="no-select yuno-banner-container h-52 min-h-52 w-full overflow-hidden">
					<div class="relative flex h-full w-full flex-col items-center justify-center">
						@if (data.banner?.imageBanner?.split?.active) {
							<div
								class="split-screen-banner absolute -right-4 top-0 z-10 flex h-full w-1/2 flex-col items-start justify-center px-8"
								[ngStyle]="{
									backgroundColor:
										data.banner?.imageBanner?.split?.backgroundColor ||
										'transparent',
									color: data.banner?.imageBanner?.split?.fontColor || '#fff'
								}">
								{{
									data.banner?.imageBanner?.split?.description
										| languageSelect: language
								}}
							</div>
						}
						@if (data.banner?.imageBanner?.mobileSrc || data.banner?.imageBanner?.src) {
							<picture>
								@if (data.banner?.imageBanner?.src) {
									<source
										media="(min-width: 640px)"
										[srcset]="data.banner?.imageBanner?.src" />
								}
								<img
									class="absolute left-1/2 top-1/2 z-1 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover"
									[src]="
										data.banner?.imageBanner?.mobileSrc ||
										data.banner?.imageBanner?.src
									" />
							</picture>
						}
					</div>
				</section>
			}

			<!-- REACTIVE BANNER -->
			@if (
				((data.banner?.active && data.banner?.imageBanner?.mobileSrc) ||
					data.banner?.imageBanner?.src) &&
				data.banner?.imageBanner?.reactive
			) {
				<section class="no-select relative flex w-full rounded">
					<picture class="flex-1">
						@if (data.banner?.imageBanner?.src) {
							<source
								media="(min-width: 640px)"
								[srcset]="data.banner?.imageBanner?.src" />
						}
						<img
							class="relative left-1/2 top-1/2 z-1 h-auto w-full -translate-x-1/2 -translate-y-1/2 object-cover"
							[src]="
								data.banner?.imageBanner?.mobileSrc || data.banner?.imageBanner?.src
							" />
					</picture>
				</section>
			}

			<!-- CONTENT -->
			<section
				[ngClass]="{
					'mt-0 pt-0': !data.banner?.active,
					'flex-1': !sideBySide
				}"
				class="w-full pb-20">
				<div
					class="page-content-container"
					[ngClass]="{
						'sm:grid sm:grid-cols-2 sm:gap-6': sideBySide
					}">
					<!-- Mobile  -->
					@if (isMobile()) {
						<div>
							<!--
				              When using the defaul view (single column) we render the same order of components
				              as on the desktop. Otherwise we render the components in the order they are defined
				              for Mobile.
				              -->
							<yuno-textfield-injector
								[data]="{ components: !sideBySide ? components : componentsMobile }"
								[textLinkColor]="textLinkColor"
								[textHeadingColor]="textHeadingColor"
								[buttonColor]="buttonColor"
								[buttonBgColor]="buttonBgColor"
								[language]="language"
								[injector]="injector" />
						</div>
					}

					<!-- DESKTOP SINGLE COLUMN -->
					@if (!sideBySide && !isMobile()) {
						<div class="hidden sm:block">
							<yuno-textfield-injector
								[data]="{ components: components }"
								[textLinkColor]="textLinkColor"
								[textHeadingColor]="textHeadingColor"
								[buttonColor]="buttonColor"
								[buttonBgColor]="buttonBgColor"
								[language]="language"
								[injector]="injector" />
						</div>
					}

					<!-- DESKTOP SIDE-BY-SIDE -->
					@if (sideBySide && !isMobile()) {
						<div class="page-content-left hidden sm:block">
							<yuno-textfield-injector
								[data]="{ components: componentsLeft }"
								[textLinkColor]="textLinkColor"
								[textHeadingColor]="textHeadingColor"
								[buttonColor]="buttonColor"
								[buttonBgColor]="buttonBgColor"
								[language]="language"
								[injector]="injector" />
						</div>
						<div class="page-content-right hidden sm:block">
							<yuno-textfield-injector
								[data]="{ components: componentsRight }"
								[textLinkColor]="textLinkColor"
								[textHeadingColor]="textHeadingColor"
								[buttonColor]="buttonColor"
								[buttonBgColor]="buttonBgColor"
								[language]="language"
								[injector]="injector" />
						</div>
					}
				</div>
			</section>
		</div>
	</ng-template>
}
