import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SpriteEditorComponent } from '../module/editor/sprite-editor.component';

const routes: Routes = [
	{
		path: '',
		loadComponent: () => import('./spritesheets.component').then(m => m.SpritesheetsComponent),
		children: [
			{ path: 'create', component: SpriteEditorComponent },
			{ path: '**', redirectTo: '' }
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SpritesheetsRoutingModule {}
