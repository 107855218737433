import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { SpritesheetModule } from '@yuno/admin/features/spritesheets/feature/module/spritesheet.module';
import {
	EditContainerContentDirective,
	YunoAdminButtonsModule,
	YunoAdminCloseButtonComponent,
	YunoAdminCodeEditorModule,
	YunoAdminSaveButtonComponent,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import {
	YunoFormsDividerComponent,
	YunoFormsSpanComponent,
	YunoFormsTextComponent,
	YunoFormsTitleComponent
} from '@yuno/angular/forms/components';

import { ClientsEditorComponent } from './clients-editor.component';

@NgModule({
	declarations: [ClientsEditorComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		YunoAdminButtonsModule,
		YunoEditContainerModule,
		YunoAdminCodeEditorModule,
		SpritesheetModule,
		EditContainerContentDirective,
		YunoAdminCloseButtonComponent,
		YunoAdminSaveButtonComponent,
		YunoFormsDividerComponent,
		YunoFormsTitleComponent,
		YunoFormsSpanComponent,
		YunoFormsTextComponent
	]
})
export class ClientsModule {}
