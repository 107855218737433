import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { YunoAdminButtonComponent } from '@yuno/admin/ui';
import { YunoFormsSpanComponent, YunoFormsTextComponent } from '@yuno/angular/forms/components';
import { AngularSvgLoaderModule } from '@yuno/angular/svg-loader';

type svgType = 'default' | 'selected';

@Component({
	selector: 'yuno-admin-participation-svg-select-container',
	imports: [
		CommonModule,
		YunoFormsTextComponent,
		YunoFormsSpanComponent,
		ReactiveFormsModule,
		AngularSvgLoaderModule,
		YunoAdminButtonComponent
	],
	templateUrl: './svg-select-container.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgSelectContainerComponent {
	@Input() form: FormGroup;
	@Input() controlName = 'sprite';
	@Input() controlNameSelect = 'Select';


	@Input() selected: svgType;
	@Output() selectedChange = new EventEmitter<svgType>();

	@Input() type: svgType = 'default';
	@Input() src: string | undefined;

	select(): void {
		this.selected = this.type;
		this.selectedChange.emit(this.selected);
	}
}
