import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { AngularPipesModule } from '@yuno/angular/pipes';
import { YunoSharedUiButtonColors, YunoSharedUiButtonComponent } from '@yuno/shared/ui';

import { DialogItem } from '../../utils';

@Component({
    imports: [MatDialogModule, YunoSharedUiButtonComponent, AngularPipesModule],
    selector: 'yuno-notification-message-dialog',
    templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageDialogComponent {
	dialog: DialogItem = {
		title: 'Confirm',
		message: 'Confirm the changes?',
		buttons: [
			{
				key: 'Save',
				type: 'primary',
				confirm: true
			},
			{
				key: 'Cancel'
			}
		]
	};

	primaryColor: YunoSharedUiButtonColors = 'primary';

	constructor(
		@Inject(MAT_DIALOG_DATA) private data: DialogItem,
		private dialogRef: MatDialogRef<MessageDialogComponent>
	) {
		if (data) {
			this.dialog.title = data.title || this.dialog.title;
			this.dialog.message = data.message || this.dialog.message;
			this.dialog.buttons = data.buttons || this.dialog.buttons;
		}
	}

	onConfirmClick() {
		this.dialogRef.close(true);
	}
}
