import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';

import {
	ParticipationModelForm,
	ParticipationSettingsEditorService
} from '../settings-editor.service';

@Component({
	selector: 'yuno-admin-participation-model-default',
	imports: [],
	template: ``,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipationModelEditorsDefaultComponent {
	@Input() form: FormGroup<ParticipationModelForm>;
	public readonly service: ParticipationSettingsEditorService = inject(
		ParticipationSettingsEditorService
	);
}
