import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import { combineLatest, map, startWith, tap } from 'rxjs';

import { TooltipDirective, YunoButtonComponent } from '@yuno/project-atlas/ui';

import { SvgComponent } from '../../../../svg-loader/src/lib/svg/svg.component';
import { CustomDrawMode, DrawMapService } from '../services/draw-map.service';

export type toolbarSettings = {
	polyArea: boolean;
	polyRect: boolean;
	polyCircle: boolean;
	line: boolean;
	point: boolean;
	limit: number;
};

@Component({
    selector: 'yuno-draw-tool-bar',
    templateUrl: './tool-bar.component.html',
    styleUrls: ['./tool-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [YunoButtonComponent, TooltipDirective, SvgComponent, AsyncPipe]
})
export class ToolBarComponent {
	@Input() settings: toolbarSettings = {
		polyArea: true,
		polyRect: false,
		polyCircle: false,
		line: true,
		point: true,
		limit: 0
	};
	features: FeatureCollection<Geometry, GeoJsonProperties>;

	data$ = combineLatest({
		drawMode: this.service.drawMode$.pipe(
			startWith(null),
			tap(val => {
				if (val) {
					this.setDrawType(val);
				}
			})
		),
		selection: this.service.drawSelect$.pipe(
			startWith(null),
			map(data => {
				let bool = false;
				if (data?.features && data.features.length >= 1) {
					bool = true;
				} else {
					bool = false;
				}
				return bool;
			})
		)
	});

	constructor(public service: DrawMapService) {}

	checkFeatures() {
		const all = this.service.draw?.getAll();
		if (!all) {
			return;
		}

		this.features = all;
	}

	setDrawType(mode: CustomDrawMode) {
		this.checkFeatures();
		if (this.settings.limit === 0) {
			this.confirmDrawType(mode);
		}
		if (mode !== 'simple_select' && this.settings.limit <= this.features.features.length) {
			return;
		} else {
			this.confirmDrawType(mode);
		}
	}

	confirmDrawType(mode: CustomDrawMode) {
		setTimeout(() => {
			this.service.draw?.changeMode(mode as string);
		}, 250);
	}

	deleteDrawing() {
		this.service.draw?.trash();
	}
}
