import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatest, map, startWith } from 'rxjs';

import { GeoPhotoCategoryFacade } from '@yuno/admin/features/geophotos';
import {
	TableColumnDisplay,
	TableRow,
	TableSelectionOutput,
	TableSelectionOutputNew,
	YunoAdminTableComponent
} from '@yuno/admin/ui';

import { DatasetEditorService } from '../../dataset-editor.service';

@Component({
	selector: 'yuno-admin-dataset-listview-geo-photos',
	imports: [CommonModule, YunoAdminTableComponent],
	templateUrl: './geo-photos.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatasetListviewGeoPhotosComponent implements OnInit {
	private readonly destroyRef = inject(DestroyRef);
	private readonly facade = inject(GeoPhotoCategoryFacade);
	readonly service = inject(DatasetEditorService);

	// Combine observables to create a data stream for the component.
	data$ = combineLatest({
		changes: this.service.rawForm$.pipe(
			startWith(undefined),
			takeUntilDestroyed(this.destroyRef),
			map(data => data?.data?.photoCategories?.map(data => data.category as TableRow) || [])
		),
		// provide a clean set of data to the table
		geoPhotos: this.facade.data$.pipe(startWith(null))
	});

	// Initialize table column configuration.
	tableColumns: TableColumnDisplay[] = [{ key: 'id', label: 'GeoPhotos' }];

	// Angular lifecycle hook, called when the component is initialized.
	ngOnInit(): void {
		this.facade.get();
	}

	// Event handler for selecting all items in the table.
	onSelectAll(e: TableSelectionOutput) {
		this.service.selectAll(
			'GeoPhotos',
			e.selection.map(data => ({ category: data }))
		);
	}

	/*
		Triggers when selecting or deselecting a row in the table
		when adding an item, also adds an event key to the object
	*/
	onSelect(e: TableSelectionOutputNew): void {
		if (!e.checked) {
			this.service.remove('GeoPhotos', 'category', e.row);
			return;
		}

		this.service.add('GeoPhotos', { category: e.row });
	}
}
