import { CdkDrag, CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { combineLatest, debounceTime, map, startWith, take, tap } from 'rxjs';

import { TemplatesFacade } from '@yuno/admin/features/templates';
import {
	AnnotationComponent,
	DragRowComponent,
	EditContainerComponent,
	EditContainerContentDirective,
	TableColumnDisplay,
	TableRow,
	TableSelectionOutput,
	TableSelectionOutputNew,
	YunoAdminButtonComponent,
	YunoAdminButtonTableComponent,
	YunoAdminCloseButtonComponent,
	YunoAdminSaveButtonComponent,
	YunoAdminTableComponent
} from '@yuno/admin/ui';
import { AppDataComponent, redirectTo } from '@yuno/admin/utils';
import { moveItemInFormArray } from '@yuno/angular/forms';
import { YunoFormsImageComponent } from '@yuno/angular/forms/admin-components';
import {
	YunoFormsArrayContainerComponent,
	YunoFormsSelectComponent,
	YunoFormsSpanComponent,
	YunoFormsTextComponent,
	YunoFormsTitleComponent
} from '@yuno/angular/forms/components';
import { Layer, LayerTemplate, Template } from '@yuno/api/interface';

import { LayersFacade } from '../../data-access';
import { LayerTemplateEditorService } from './template-editor.service';

@Component({
	imports: [
		ReactiveFormsModule,
		AsyncPipe,
		CdkDrag,
		CdkDropList,
		DragRowComponent,
		EditContainerComponent,
		EditContainerContentDirective,
		YunoAdminCloseButtonComponent,
		YunoAdminSaveButtonComponent,
		YunoAdminButtonComponent,
		YunoAdminTableComponent,
		YunoAdminButtonTableComponent,
		YunoFormsTextComponent,
		YunoFormsImageComponent,
		YunoFormsArrayContainerComponent,
		YunoFormsTitleComponent,
		YunoFormsSpanComponent,
		YunoFormsSelectComponent,
		AnnotationComponent
	],
	providers: [LayerTemplateEditorService],
	selector: 'yuno-admin-layer-template-editor',
	templateUrl: './template-editor.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayerTemplateEditorComponent extends AppDataComponent implements OnInit {
	private readonly layerFacade = inject(LayersFacade);
	private readonly templateFacade = inject(TemplatesFacade);

	private readonly destroyRef = inject(DestroyRef);
	readonly service = inject(LayerTemplateEditorService);

	originalData: Partial<Template>;
	notFoundCount = 0;

	data$ = combineLatest({
		layers: this.layerFacade.layers$.pipe(map(data => data as { [key: string]: unknown }[])),
		template: this.templateFacade.selected$.pipe(
			tap(data => {
				if (!this.originalData) {
					this.originalData = data as Template;
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					this.service.form.patchValue(this.originalData as any);
					if (this.originalData?.tags && this.originalData?.tags.length > 0) {
						for (const tag of this.originalData.tags) {
							this.service.addTag(tag);
						}
					}

					const layerOptions = this.originalData?.options as {
						layers: string[];
						layerType: string;
					};
					if (layerOptions?.layers && layerOptions?.layers.length > 0) {
						for (const layer of layerOptions.layers) {
							const control = new FormControl<string>(layer, {
								nonNullable: true
							});
							this.service.layers.push(control);
						}
					}
				}
			})
		),
		changes: combineLatest({
			form: this.service.rawForm$,
			layers: this.layerFacade.layers$
		}).pipe(
			startWith(undefined),
			takeUntilDestroyed(this.destroyRef),
			map(data => {
				const ids = (data?.form as LayerTemplate)?.options?.layers.map(data => data) || [];
				const changes: TableRow[] = [];
				this.notFoundCount = 0;
				for (const id of ids) {
					const row = data?.layers?.find(row => row._id === id);
					if (row) {
						changes.push(row);
					} else {
						this.notFoundCount++;
					}
				}
				return changes;
			})
		)
	});

	// Initialize table column configuration.
	tableColumns: TableColumnDisplay[] = [{ key: 'id', label: 'Layer' }];

	ngOnInit(): void {
		this.service.createFormGroup();
		this.layerFacade.get();
		this.onChanges();

		/* 	Detects current url and select dataset from url	*/
		this.route.paramMap.pipe(take(1)).subscribe(data => {
			this.templateFacade.select(data.get('id'));
		});
	}

	onChanges(): void {
		this.service.form.valueChanges
			.pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: () => {
					this.templateFacade.updateSelect(
						this.service.form.getRawValue() as Partial<Template>
					);
					this.service.rawForm$.next(this.service.form.getRawValue());
				},
				complete: () => {
					this.templateFacade.clearSelect();
				}
			});
	}

	drop(event: CdkDragDrop<string[]>): void {
		moveItemInFormArray(this.service.tags, event.previousIndex, event.currentIndex);
	}

	onSave() {
		this.templateFacade.save();
	}

	/* 	navigates back to the Layer page */
	onClose(): void {
		redirectTo(this.route, this.router);
	}

	// Event handler for selecting all items in the table.
	onSelectAll(e: TableSelectionOutput) {
		this.service.selectAll(e.selection.map((data: Partial<Layer>) => data._id as string));
	}

	// Triggers when selecting or deselecting a row in the table
	// when adding an item, also adds an event key to the object
	onSelect(e: TableSelectionOutputNew): void {
		if (!e.checked) {
			this.service.remove(e.row);
			return;
		}

		this.service.add(e.row);
	}

	onAddTag(): void {
		this.service.addTag();
	}

	onRemoveTag(index: number): void {
		this.service.removeTag(index);
	}
}
