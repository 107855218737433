import { DragDropModule } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import {
	YunoFormsSpanComponent,
	YunoFormsTextComponent,
	YunoFormsTitleComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';

import {
	ParticipationModelForm,
	ParticipationSettingsEditorService
} from '../../settings-editor.service';

@Component({
	selector: 'yuno-admin-participation-crm',
	imports: [
		YunoFormsTitleComponent,
		YunoFormsTextComponent,
		YunoFormsSpanComponent,
		YunoFormsToggleComponent,
		ReactiveFormsModule,
		DragDropModule
	],
	templateUrl: './crm.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipationModelCRMComponent {
	service = inject(ParticipationSettingsEditorService);
	@Input() form: FormGroup<ParticipationModelForm>;
}
