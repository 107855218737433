import { DragDropModule } from '@angular/cdk/drag-drop';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { tap } from 'rxjs';

import {
	FileLibraryEditContainerComponent,
	FileLibraryService
} from '@yuno/admin/features/file-library';
import { AnnotationComponent, YunoAdminButtonComponent } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import {
	YunoFormsColorComponent,
	YunoFormsDividerComponent,
	YunoFormsHtmlTextComponent,
	YunoFormsSelectComponent,
	YunoFormsSpanComponent,
	YunoFormsTextComponent,
	YunoFormsTitleComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';

import { TextEditorsService } from '../text-editors.service';

@Component({
	selector: 'yuno-admin-text-news-editor',
	imports: [
		DragDropModule,
		ReactiveFormsModule,
		YunoFormsDividerComponent,
		YunoFormsTitleComponent,
		YunoFormsTextComponent,
		YunoFormsSpanComponent,
		YunoFormsColorComponent,
		YunoFormsHtmlTextComponent,
		YunoFormsSelectComponent,
		YunoFormsToggleComponent,
		AnnotationComponent,
		YunoAdminButtonComponent,
		NgClass,
		AsyncPipe
	],
	templateUrl: './text-news.component.html',
	styleUrls: ['./text-news.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextNewsEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	readonly editService = inject(TextEditorsService);
	readonly imageService = inject(FileLibraryService);

	private overlay = inject(Overlay);
	private overlayRef?: OverlayRef;

	alignValues: string[] = ['top', 'bottom', 'left', 'right'];
	sizeValues: string[] = ['1/2', '1/3', '1/4'];
	linkTypes: string[] = ['page', 'routes', 'url'];
	videoTypes: string[] = ['vimeo', 'youtube'];
	videoDisplay: string[] = ['Vimeo', 'Youtube'];

	imageLocation: 0 | 1 = 0;
	imageOrder = ['row', 'column'];
	imageOrderDisplay = ['left - right', 'top - bottom'];
	browseImageLibrary = false;

	service$ = this.editService.getActiveService().pipe(
		tap(service => {
			this.editService.service = service;
			this.editService.observeForm();
		})
	);

	selectImage$ = this.imageService.activeFile$.pipe(
		tap(file => {
			if (this.browseImageLibrary) {
				this.imageLocation
					? this.editService.patchSecondaryImageValue(file)
					: this.editService.patchImageValue(file);
			}
		})
	);

	close$ = this.imageService.close$.pipe(
		tap(() => {
			if (this.browseImageLibrary) {
				this.closeImageOverlay();
			}
		})
	);

	ngOnInit() {
		this.languageSelector();
		this.editService.onInitNav();
		this.editService.onInitPages();
	}

	ngOnDestroy() {
		this.languageService.destroyLanguageSelector();
		this.editService.service = undefined;
	}

	/**
	 * Opens the image library inside a
	 * CDK Overlay
	 */
	openImageLibrary(imageLocation: 0 | 1): void {
		this.imageLocation = imageLocation;
		this.overlayRef = this.overlay.create({
			positionStrategy: this.overlay.position().global(),
			scrollStrategy: this.overlay.scrollStrategies.reposition(),
			hasBackdrop: false,
			// tinymce uses high z-indexes
			panelClass: 'z-10000'
		});

		const imageEditor = new ComponentPortal(FileLibraryEditContainerComponent);
		const imageLibrary = this.overlayRef?.attach(imageEditor);
		imageLibrary?.changeDetectorRef?.detectChanges();
		this.browseImageLibrary = true;
	}

	/**
	 * Removes the image overlay from the DOM
	 */
	closeImageOverlay(): void {
		this.overlayRef?.dispose();
		this.overlayRef = undefined;
		this.browseImageLibrary = false;
	}
}
