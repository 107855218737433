import { ChangeDetectionStrategy, Component, Input } from '@angular/core';


@Component({
    selector: 'yuno-gif',
    imports: [],
    templateUrl: './gif-play.component.html',
    styleUrls: ['./gif-play.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GifPlayComponent {
	@Input() preview: string;
	@Input() gif: string;

	play = false;
}
