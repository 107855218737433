import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';


@Component({
    selector: 'yuno-admin-navbar-buttons-container',
    imports: [],
    template: `
		<div class="flex w-full flex-row justify-end gap-2">
			<ng-content></ng-content>
		</div>
	`,
    styleUrls: ['./navbar-buttons.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminNavbarButtonsComponent {
	@HostBinding('className') className = 'pointer-events-none';
}
