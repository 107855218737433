import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	OnInit,
	inject,
	signal
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs';

import { YunoFormsNumberComponent, YunoFormsRadioComponent } from '@yuno/angular/forms/components';
import { ZoomToEvent, zoomToOptionsModeRadioMap } from '@yuno/api/interface';

import { EventsDefaultFormItemComponent } from '../_default/default-form-item.component';

@Component({
	selector: 'yuno-admin-zoom-to',
	imports: [ReactiveFormsModule, YunoFormsNumberComponent, YunoFormsRadioComponent],
	templateUrl: './zoom-to.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZoomToComponent extends EventsDefaultFormItemComponent implements OnInit {
	private destroyRef = inject(DestroyRef);
	zoomToRadioMap = zoomToOptionsModeRadioMap;

	$others = signal(false);

	ngOnInit(): void {
		this.setOptions(this.form.value.options as ZoomToEvent['options']);
		this.form.valueChanges
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				// check if modes change, else we keep in a loop for toggling the disable/enabled values
				distinctUntilChanged((prev, curr) => {
					const prevOptions = prev.options as ZoomToEvent['options'];
					const currOptions = curr.options as ZoomToEvent['options'];

					return prevOptions?.mode === currOptions?.mode;
				})
			)
			.subscribe(val => {
				const options = val.options as ZoomToEvent['options'];
				this.setOptions(options);
			});
	}

	setOptions(options: ZoomToEvent['options']): void {
		const optionsForm = this.form.get('options');
		if (!optionsForm) {
			return;
		}

		if (options.mode === 'map') {
			optionsForm.get('bearing')?.disable();
			optionsForm.get('pitch')?.disable();
			this.$others.set(false);
		}

		if (options.mode === 'custom') {
			optionsForm.get('bearing')?.enable();
			optionsForm.get('pitch')?.enable();
			this.$others.set(true);
		}
	}
}
