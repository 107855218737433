import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { HtmlEditorImageLibraryComponent } from '@yuno/admin/features/html-form';
import { AppDataComponent } from '@yuno/admin/utils';
import {
	YunoFormsSpanComponent,
	YunoFormsTextComponent,
	YunoFormsTitleComponent
} from '@yuno/angular/forms/components';

import { TextEditorsService } from '../text-editors.service';

@Component({
	selector: 'yuno-admin-textblock-editor',
	imports: [
		AsyncPipe,
		ReactiveFormsModule,
		YunoFormsTitleComponent,
		YunoFormsTextComponent,
		YunoFormsSpanComponent,
		HtmlEditorImageLibraryComponent
	],
	templateUrl: './textblock-editor.component.html',
	styleUrls: ['./textblock-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextblockEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	readonly editService = inject(TextEditorsService);

	service$ = this.editService.getActiveService();

	ngOnInit() {
		this.languageSelector();
	}

	ngOnDestroy() {
		this.languageService.destroyLanguageSelector();
	}
}
