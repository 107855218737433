module.exports = {
  A: {
    A: {
      "2": "K D E F A B iC"
    },
    B: {
      "2": "C L M G N O P",
      "33": "6 7 8 9 Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I"
    },
    C: {
      "2": "0 1 2 3 4 5 6 7 8 9 jC IC J MB K D E F A B C L M G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC kC lC mC nC"
    },
    D: {
      "2": "J MB K D E F A B C L M G N",
      "33": "0 1 2 3 4 5 6 7 8 9 O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC"
    },
    E: {
      "1": "A B C L M G QC CC DC tC uC vC RC SC EC wC FC TC UC VC WC XC xC GC YC ZC aC bC cC yC HC dC eC fC zC",
      "2": "J MB oC PC",
      "33": "K D E F pC qC rC sC"
    },
    F: {
      "2": "F B C 0C 1C 2C 3C CC gC 4C DC",
      "33": "0 1 2 3 4 5 G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x"
    },
    G: {
      "1": "CD DD ED FD GD HD ID JD KD LD MD ND OD RC SC EC PD FC TC UC VC WC XC QD GC YC ZC aC bC cC RD HC dC eC fC",
      "2": "PC 5C hC",
      "33": "E 6C 7C 8C 9C AD BD"
    },
    H: {
      "2": "SD"
    },
    I: {
      "2": "IC J TD UD VD WD hC",
      "33": "I XD YD"
    },
    J: {
      "2": "D A"
    },
    K: {
      "2": "A B C CC gC DC",
      "33": "H"
    },
    L: {
      "33": "I"
    },
    M: {
      "2": "BC"
    },
    N: {
      "2": "A B"
    },
    O: {
      "33": "EC"
    },
    P: {
      "33": "0 1 2 3 4 5 J y z ZD aD bD cD dD QC eD fD gD hD iD FC GC HC jD"
    },
    Q: {
      "33": "kD"
    },
    R: {
      "33": "lD"
    },
    S: {
      "2": "mD nD"
    }
  },
  B: 4,
  C: "CSS Cross-Fade Function",
  D: true
};