import { inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { StyleSpecification } from "maplibre-gl";
import { catchError, map, of, switchMap, take, tap, withLatestFrom } from "rxjs";



import { appFeature } from "@yuno/admin/features/apps";
import { GraphQLService } from "@yuno/angular-graphql";
import { ApiService } from "@yuno/angular/api";
import { MessageService } from "@yuno/angular/notifications";



import { DELETE_GEOPHOTO_CATEGORY, DeleteMutation, GET_GEOPHOTO_CATEGORIES, GET_GEOPHOTO_CATEGORY, GetQuery, SAVE_GEOPHOTO_CATEGORY, SaveMutation, SelectQuery } from "../../../utils";
import { categoriesActions } from "./categories.actions";
import { categoriesFeature } from "./categories.state";


// export const createCategory = createEffect(
// 	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
// 		actions$.pipe(
// 			ofType(categoriesActions.create),
// 			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
// 			switchMap(([, appId]) =>
// 				graphql
// 					.mutate<CreateMutation>({
// 						mutation: CREATE_GEOPHOTO_CATEGORY,
// 						variables: {
// 							appId
// 						}
// 					})
// 					.pipe(
// 						map(data => {
// 							if (!data.data?.createGeoPhotoCategory) {
// 								throw new Error('Failed creating');
// 							}
//
// 							return categoriesActions.createSuccess({
// 								id: data.data.createGeoPhotoCategory._id
// 							});
// 						}),
// 						take(1),
// 						catchError(error => of(categoriesActions.createFailure({ error })))
// 					)
// 			)
// 		),
// 	{ functional: true }
// );

export const createCategorySuccess = createEffect(
	(actions$ = inject(Actions), store = inject(Store)) =>
		actions$.pipe(
			ofType(categoriesActions.createSuccess),
			tap(data => {
				store.dispatch(categoriesActions.select({ _id: data.id }));
			})
		),
	{ dispatch: false, functional: true }
);

export const createCategoryFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(categoriesActions.createFailure),
			tap(() => {
				message.sendToast(`Error creating Category!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const loadCategories = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(categoriesActions.load, categoriesActions.reload),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<GetQuery>({
						query: GET_GEOPHOTO_CATEGORIES,
						variables: {
							appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.geoPhotoCategories) {
								throw new Error('Not found');
							}

							return categoriesActions.loadSuccess({
								data: data.data.geoPhotoCategories
							});
						}),
						take(1),
						catchError(error => of(categoriesActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectCategory = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(categoriesActions.select),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([action, appId]) =>
				graphql
					.query<SelectQuery>({
						query: GET_GEOPHOTO_CATEGORY,
						variables: {
							id: action._id,
							appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.getGeoPhotoCategory) {
								throw new Error('Not found');
							}

							return categoriesActions.selectSuccess({
								data: data.data.getGeoPhotoCategory
							});
						}),
						take(1),
						catchError(error => of(categoriesActions.selectFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectCategoryFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(categoriesActions.selectFailure),
			tap(() => {
				message.sendToast(`Error selecting Category!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const updateCategory = createEffect(
	(actions$ = inject(Actions)) =>
		actions$.pipe(
			ofType(categoriesActions.updateSelect),
			map(action => {
				return categoriesActions.updateSelectSuccess({
					data: action.data
				});
			}),
			catchError(error => of(categoriesActions.updateSelectFailure({ error })))
		),
	{ functional: true }
);

export const saveCategory = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(categoriesActions.save),
			withLatestFrom(
				store.pipe(select(categoriesFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) => {
				const data = { ...selected };
				delete data?.fenceData;

				return graphql
					.mutate<SaveMutation>({
						mutation: SAVE_GEOPHOTO_CATEGORY,
						variables: {
							data: { ...data, updatedAt: undefined },
							appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.saveGeoPhotoCategory) {
								throw new Error('error saving to the database');
							}

							store.dispatch(
								categoriesActions.selectSuccess({
									data: data.data.saveGeoPhotoCategory
								})
							);

							return categoriesActions.saveSuccess();
						}),
						take(1),
						catchError(error => {
							return of(categoriesActions.saveFailure({ error }));
						})
					);
			})
		),
	{ functional: true }
);

// export const duplicateCategory = createEffect(
// 	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
// 		actions$.pipe(
// 			ofType(categoriesActions.duplicate),
// 			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
// 			switchMap(([categorie, appId]) =>
// 				graphql
// 					.mutate<DuplicateMutation>({
// 						mutation: DUPLICATE_GEOPHOTO_CATEGORY,
// 						variables: {
// 							_id: categorie._id,
// 							appId: appId
// 						}
// 					})
// 					.pipe(
// 						map(data => {
// 							if (!data.data?.duplicateGeoPhotoCategoryById) {
// 								throw new Error(
// 									'error duplicating Participation Category to the database'
// 								);
// 							}
// 							store.dispatch(categoriesActions.reload());
// 							return categoriesActions.duplicateSuccess({
// 								data: data.data.duplicateGeoPhotoCategoryById
// 							});
// 						}),
// 						take(1),
// 						catchError(error => {
// 							return of(categoriesActions.duplicateFailure({ error }));
// 						})
// 					)
// 			)
// 		),
// 	{ functional: true }
// );

export const deleteCategory = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(categoriesActions.delete),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([data, appId]) =>
				graphql
					.mutate<DeleteMutation>({
						mutation: DELETE_GEOPHOTO_CATEGORY,
						variables: {
							_id: data._id,
							appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.deleteGeoPhotoCategoryById) {
								throw new Error('error deleting Category from the database');
							}
							store.dispatch(categoriesActions.reload());
							return categoriesActions.deleteSuccess({
								_id: data.data?.deleteGeoPhotoCategoryById
							});
						}),
						take(1),
						catchError(error => {
							return of(categoriesActions.deleteFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const saveCategorySuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(categoriesActions.saveSuccess),
			tap(() => {
				message.sendToast(`Category successfully saved!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const saveCategoryFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(categoriesActions.saveFailure),
			tap(() => {
				message.sendToast(`Error saving Category!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateCategorySuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(categoriesActions.duplicateSuccess),
			tap(() => {
				message.sendToast(`Category successfully duplicated!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateCategoryFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(categoriesActions.duplicateFailure),
			tap(() => {
				message.sendToast(`Error duplicating Category!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteCategorySuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(categoriesActions.deleteSuccess),
			tap(() => {
				message.sendToast(`Category successfully deleted!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteCategoryFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(categoriesActions.deleteFailure),
			tap(() => {
				message.sendToast(`Error deleting Category!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const loadMapStyle = createEffect(
	(actions$ = inject(Actions), api = inject(ApiService)) =>
		actions$.pipe(
			ofType(categoriesActions.loadMapStyle),
			switchMap(action =>
				api.getObservable<StyleSpecification>(action.style).pipe(
					map(data => {
						return categoriesActions.loadMapStyleSuccess({ data });
					}),
					take(1),
					catchError(error => of(categoriesActions.loadMapStyleFailure({ error })))
				)
			)
		),
	{ functional: true }
);
