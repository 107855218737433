import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	EventEmitter,
	Input,
	Output,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HtmlEditorImageLibraryComponent } from '@yuno/admin/features/html-form';
import {
	CardButtonDisplay,
	EditContainerContentDirective,
	YunoAdminButtonsModule,
	YunoAdminCardDraggableComponent,
	YunoEditContainerModule,
	YunoSelectFieldModule
} from '@yuno/admin/ui';
import { moveItemInFormArray } from '@yuno/angular/forms';
import {
	YunoFormsColorComponent,
	YunoFormsDividerComponent,
	YunoFormsFormControlSelectorComponent,
	YunoFormsSelectComponent,
	YunoFormsSpanComponent,
	YunoFormsTextComponent,
	YunoFormsTitleComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';
import { AngularPipesModule } from '@yuno/angular/pipes';
import { LanguageAll, LanguageObjectModel, ParticipationPageForm } from '@yuno/api/interface';
import { generateUniqueValue } from '@yuno/libs/shared/helpers';
import { YunoProjectAtlasLinkModule } from '@yuno/project-atlas/ui';

import {
	ParticipationForm,
	ParticipationModelForm,
	ParticipationSettingsEditorService,
	ParticipationSettingsSelectItems
} from '../../../settings-editor.service';
import { ParticipationPageInputEditorComponent } from './input-editor/input-editor.component';

@Component({
	selector: 'yuno-admin-participation-steps-page-editor',
	imports: [
		YunoFormsDividerComponent,
		YunoFormsTitleComponent,
		YunoFormsTextComponent,
		YunoFormsSpanComponent,
		YunoFormsColorComponent,
		YunoFormsFormControlSelectorComponent,
		YunoFormsSelectComponent,
		YunoFormsToggleComponent,
		FormsModule,
		ReactiveFormsModule,
		YunoAdminButtonsModule,
		DragDropModule,
		YunoEditContainerModule,
		YunoAdminCardDraggableComponent,
		YunoSelectFieldModule,
		AngularPipesModule,
		ParticipationPageInputEditorComponent,
		YunoProjectAtlasLinkModule,
		HtmlEditorImageLibraryComponent,
		EditContainerContentDirective
	],
	styleUrls: ['./../cdk-drag-settings.scss'],
	templateUrl: './steps-page-editor.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepsPageEditorComponent {
	private readonly destroyRef = inject(DestroyRef);

	@Input() form: FormGroup<ParticipationModelForm>;
	@Input() language = 'nl';
	@Input() selectedPages: number;
	@Output() savePage = new EventEmitter<void>();
	@Output() closePage = new EventEmitter<void>();

	editInput: FormGroup<ParticipationForm> | null = null;

	buttons: CardButtonDisplay[] = [{ key: 'delete', type: 'danger' }];

	get formDataValues(): ParticipationPageForm[] {
		return this.service.pages.controls[this.selectedPages].value
			.form as ParticipationPageForm[];
	}

	get currentType(): string {
		return this.service.pages.controls[this.selectedPages].value.type || '';
	}

	constructor(
		public service: ParticipationSettingsEditorService,
		private cdr: ChangeDetectorRef
	) {
		// Change Detection for HTML editor
		this.service.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
			this.cdr.detectChanges();
		});
	}

	onClosePage(): void {
		this.closePage.emit();
	}

	onSavePage(): void {
		this.savePage.emit();
	}

	addNewPageInput(): void {
		this.editInput = null;
	}

	getFormDataFormGroup(index: number): FormGroup<ParticipationForm> {
		return this.service.pages.controls[this.selectedPages].controls.form.controls[index];
	}

	drop(event: CdkDragDrop<ParticipationPageForm[]>): void {
		moveItemInFormArray(
			this.service.pages.controls[this.selectedPages].controls.form,
			event.previousIndex,
			event.currentIndex
		);
	}

	onEditControl(data: FormGroup<ParticipationForm>): void {
		this.cdr.markForCheck();
		this.editInput = null;

		this.cdr.markForCheck();

		this.editInput = data;
	}

	// Adds new item to the FormPage Array
	onAddInputField(item: ParticipationSettingsSelectItems) {
		this.service.addItemToPageArray(
			this.service.pages.controls[this.selectedPages].controls,
			item.formType,
			generateUniqueValue(this.service.listOfKeys, item.type),
			item.label
		);
	}

	compareInput(val: string | undefined): boolean {
		if (!val) {
			return false;
		}

		return this.service.listOfKeys.includes(val);
	}

	removeItem(index: number): void {
		this.service.removeItemFromPageArra(
			this.service.pages.controls[this.selectedPages].controls,
			index
		);
	}

	getActivateParticipate(index: number): boolean {
		return this.service.getActivateParticipate(index);
	}

	selectValuesLanguage(lang: string, value?: LanguageObjectModel[]): string[] {
		if (value && value.length > 0) {
			return value.map(obj => obj[lang] as string) || [];
		}
		return [];
	}

	protected readonly languages = LanguageAll;
}
