import { ChangeDetectionStrategy, Component, Injector, OnInit, inject } from '@angular/core';

import { PageItem } from '@yuno/api/interface';

import { injectClass, token } from '../token';

@Component({
	selector: 'yuno-admin-page-item',
	template: ` @if (data) {
		<div class="mb-4" [innerHtml]="data.content! | safeHtml"></div>
	}`,
	styleUrls: ['./page-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false
})
export class PageItemComponent implements OnInit {
	private readonly inject = inject(Injector);

	injectValue: injectClass;
	data: PageItem;

	ngOnInit() {
		this.injectValue = this.inject.get(token);
		this.data = this.injectValue.data as PageItem;
	}
}
