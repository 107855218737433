module.exports = {
  A: {
    A: {
      "132": "K D E F A B iC"
    },
    B: {
      "1": "6 7 8 9 C L M G N O P Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I"
    },
    C: {
      "1": "6 7 8 9 bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC kC lC",
      "2": "0 1 2 3 4 5 jC IC J MB K D E F A B C L M G N O P NB y z OB PB QB RB SB TB UB VB mC nC",
      "322": "WB XB YB ZB aB"
    },
    D: {
      "1": "6 7 8 9 iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC",
      "2": "J MB K",
      "16": "D",
      "33": "0 1 2 3 4 5 E F A B C L M G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB"
    },
    E: {
      "1": "B C L M G CC DC tC uC vC RC SC EC wC FC TC UC VC WC XC xC GC YC ZC aC bC cC yC HC dC eC fC zC",
      "2": "J oC PC",
      "16": "MB",
      "33": "K D E F A pC qC rC sC QC"
    },
    F: {
      "1": "VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x",
      "2": "F B C 0C 1C 2C 3C CC gC 4C DC",
      "33": "0 1 2 3 4 5 G N O P NB y z OB PB QB RB SB TB UB"
    },
    G: {
      "1": "ED FD GD HD ID JD KD LD MD ND OD RC SC EC PD FC TC UC VC WC XC QD GC YC ZC aC bC cC RD HC dC eC fC",
      "16": "PC 5C hC",
      "33": "E 6C 7C 8C 9C AD BD CD DD"
    },
    H: {
      "2": "SD"
    },
    I: {
      "1": "I",
      "2": "TD UD VD",
      "33": "IC J WD hC XD YD"
    },
    J: {
      "33": "D A"
    },
    K: {
      "1": "H",
      "2": "A B C CC gC DC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "36": "A B"
    },
    O: {
      "1": "EC"
    },
    P: {
      "1": "0 1 2 3 4 5 y z ZD aD bD cD dD QC eD fD gD hD iD FC GC HC jD",
      "33": "J"
    },
    Q: {
      "1": "kD"
    },
    R: {
      "1": "lD"
    },
    S: {
      "1": "mD nD"
    }
  },
  B: 2,
  C: "CSS writing-mode property",
  D: true
};