import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { combineLatest, map, startWith } from 'rxjs';

import { ObjectsFacade } from '@yuno/admin/features/objects';
import {
	TableColumnDisplay,
	TableRow,
	TableSelectionOutput,
	TableSelectionOutputNew,
	YunoAdminTableComponent
} from '@yuno/admin/ui';
import { DatasetDataObjects } from '@yuno/api/interface';
import { LoadersModule } from '@yuno/project-atlas/ui';

import { DatasetEditorService, ObjectDataForm } from '../../dataset-editor.service';

@Component({
	selector: 'yuno-admin-dataset-listview-objects',
	imports: [YunoAdminTableComponent, LoadersModule, AsyncPipe],
	templateUrl: './objects.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatasetListviewObjectsComponent implements OnInit {
	private readonly destroyRef = inject(DestroyRef);
	private readonly facade = inject(ObjectsFacade);
	readonly service = inject(DatasetEditorService);

	// Combine observables to create a data stream for the component.
	data$ = combineLatest({
		changes: this.service.rawForm$.pipe(
			startWith(undefined),
			takeUntilDestroyed(this.destroyRef),
			map(
				data =>
					data?.data?.objects?.map(
						(data: DatasetDataObjects) => data.data as unknown as TableRow
					) || []
			)
		),
		// provide a clean set of data to the table
		objects: this.facade.objects$.pipe(
			startWith(null),
			map(data => {
				return (
					(data
						?.map(item => {
							if (!item.data) {
								return;
							}
							return {
								_id: item.data._id,
								id: item.data.id,
								type: item.type
							} as TableRow;
						})
						.filter(e => e !== undefined) as TableRow[]) || []
				);
			})
		)
	});

	// Initialize table column configuration.
	tableColumns: TableColumnDisplay[] = [
		{ key: 'id', label: 'Object' },
		{ key: 'type', label: 'type' }
	];

	// Angular lifecycle hook, called when the component is initialized.
	ngOnInit(): void {
		this.facade.get();
	}

	// Event handler for selecting all items in the table.
	onSelectAll(e: TableSelectionOutput) {
		this.service.selectAll(
			'Objects',
			e.selection.map(item => {
				return { data: item, type: item?.['type'] || 'data-object' };
			})
		);
	}

	/*
		Triggers when selecting or deselecting a row in the table
		when adding an item, also adds an event key to the object
	*/
	onSelect(e: TableSelectionOutputNew): void {
		if (!e.checked) {
			const formGroups = this.service.dataObjects.controls as FormGroup<ObjectDataForm>[];
			const foundIndex = formGroups.findIndex(
				group => group.controls.data.get('_id')?.value === e.row['_id']
			);

			if (foundIndex < 0) {
				return;
			}

			this.service.dataObjects.removeAt(foundIndex);
			return;
		}

		this.service.add('Objects', {
			data: e.row || {},
			type: e.row?.['type'] || 'data-object'
		});
	}
}
