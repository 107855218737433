import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



import { LanguagePipe } from '@yuno/angular/pipes';
import { TextfieldToken } from '@yuno/angular/textfield';
import { MarkerIconStyle, MarkerLabelStyle, TextCategory } from '@yuno/api/interface';



import { EventsService } from '../../services/events.service';


@Component({
	selector: 'yuno-textfield-category',
	imports: [NgClass, LanguagePipe, FormsModule, ReactiveFormsModule],
	templateUrl: './text-category.component.html',
	styleUrls: ['./text-category.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextCategoryComponent implements OnInit {
	@HostBinding('class.yuno__textfield__styling') private textStyling = true;

	private event = inject(EventsService);

	private readonly injectValue = inject(TextfieldToken);
	@Input() disableInjection = false;

	_data: TextCategory;
	@Input() set data(data: TextCategory) {
		this._data = data;
	}

	get data(): TextCategory {
		return this._data;
	}

	@Input() language?: string | undefined = 'nl';

	dropdownActive = false;

	ngOnInit(): void {
		!this.disableInjection &&
			this.injectValue?.data &&
			this.handleInjectionData(
				this.injectValue.data as TextCategory,
				this.injectValue.language
			);
	}

	handleInjectionData(data: TextCategory, language?: string): void {
		this.data = data;
		this.language = language;
	}

	returnSvg(style: MarkerIconStyle | MarkerLabelStyle): string {
		return (style as MarkerIconStyle).icon || '';
	}

	returnColor(style: MarkerIconStyle | MarkerLabelStyle): string {
		return (style as MarkerLabelStyle).color || '#0EA5E9';
	}

	isActive(id: string): boolean {
		return true;
	}

	toggleChange(event: boolean, style: string) {
		const layoutOptions = this.data.category.layout.options;
		const result = layoutOptions?.find(item => item[1] === style);
		const filter = this.data.category.layout.filter;

		if (result && filter) {
			this.event.sendEvent([
				{
					type: 'toggleCategory',
					options: {
						style: result[0],
						filter: filter,
						category: this.data.category.id,
						visible: event
					}
				}
			]);
		}
	}
}
