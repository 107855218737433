import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { LanguageType, MarkerLabelStyle } from '@yuno/api/interface';

import { DefaultComponent } from '../default/default.component';

@Component({
	selector: 'yuno-marker-label',
	template: `
		<div
			[class]="class"
			(click)="onClick($event)"
			[ngStyle]="{
				borderColor: solid ? '#fff' : style.backgroundColor
			}"
			(mouseenter)="onMove($event, true)"
			(mouseleave)="onMove($event, false)">
			<div
				class="content-container flex items-baseline gap-2"
				[ngClass]="{
					'flex-row': !class.includes('left'),
					'flex-row-reverse': class.includes('left')
				}"
				[ngStyle]="{
					color: solid ? 'inherit' : style.color,
					backgroundColor: solid ? '#fff' : style.backgroundColor,
					borderColor: solid ? '#fff' : style.backgroundColor
				}">
				<div [innerHtml]="data?.properties?.display | languageSelect: language"></div>

				@if (hasClickEvents && eventStyle !== 'no-event') {
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 6 10"
						class="inline-block h-2.5 w-2"
						[ngClass]="{
							'rotate-180 transform': class.includes('left')
						}">
						<path
							fill="currentColor"
							d="M0 8.994a.993.993 0 0 1 .293-.707l3.293-3.293L.293 1.701A1 1 0 0 1 1.707.287l4 4a1 1 0 0 1 0 1.414l-4 4A1 1 0 0 1 0 8.994Z" />
					</svg>
				}
			</div>

			@if (class.includes('underlined')) {
				<div
					class="marker_underline"
					[ngStyle]="{
						backgroundColor: style.backgroundColor
					}"></div>
			}
		</div>

		<!-- draggable marker -->
		@if (draggable) {
			<yuno-marker-crosshair></yuno-marker-crosshair>
		}
	`,
	styleUrls: ['label.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false
})
export class LabelComponent extends DefaultComponent {
	private _className: string;
	private _style: MarkerLabelStyle;
	private _language: LanguageType = 'nl';

	solid = false;
	eventStyle: MarkerLabelStyle['eventStyle'] = 'chevron-event';

	@Input() set language(lang: string) {
		this._language = lang;
	}

	get language(): LanguageType {
		return this._language;
	}

	@Input() set style(style: MarkerLabelStyle) {
		this._style = style;
		this.class = `${style.class || 'label pointer'} ${style.alignment || 'center'}`;
		this.eventStyle = style.eventStyle;
		this.solid = this.class?.includes('underlined');
	}

	get style(): MarkerLabelStyle {
		return this._style;
	}

	@Input() set class(className: string) {
		this._className = className;
	}

	get class(): string {
		return this._className;
	}
}
