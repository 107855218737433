module.exports = {
  A: {
    D: {
      "1": "6 7 8 9 rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC",
      "2": "0 1 2 3 4 5 J MB K D E F A B C L M G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB"
    },
    L: {
      "1": "I"
    },
    B: {
      "1": "6 7 8 9 Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I",
      "2": "C L M G N O P"
    },
    C: {
      "1": "6 7 8 9 WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB LB I BC MC NC OC kC lC",
      "2": "jC IC J MB mC nC",
      "33": "0 1 2 3 4 5 K D E F A B C L M G N O P NB y z OB PB QB RB SB TB UB VB"
    },
    M: {
      "1": "BC"
    },
    A: {
      "2": "K D E F A B iC"
    },
    F: {
      "1": "eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x",
      "2": "0 1 2 3 4 5 F B C G N O P NB y z OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB 0C 1C 2C 3C CC gC 4C DC"
    },
    K: {
      "1": "H",
      "2": "A B C CC gC DC"
    },
    E: {
      "1": "L M G DC tC uC vC RC SC EC wC FC TC UC VC WC XC xC GC YC ZC aC bC cC yC HC dC eC fC",
      "2": "J MB K D oC PC pC qC rC zC",
      "33": "E F A B C sC QC CC"
    },
    G: {
      "1": "HD ID JD KD LD MD ND OD RC SC EC PD FC TC UC VC WC XC QD GC YC ZC aC bC cC RD HC dC eC fC",
      "2": "PC 5C hC 6C 7C 8C",
      "33": "E 9C AD BD CD DD ED FD GD"
    },
    P: {
      "1": "0 1 2 3 4 5 y z bD cD dD QC eD fD gD hD iD FC GC HC jD",
      "2": "J ZD aD"
    },
    I: {
      "1": "I",
      "2": "IC J TD UD VD WD hC XD YD"
    }
  },
  B: 6,
  C: "text-decoration-line property",
  D: undefined
};