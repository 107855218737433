@if (service.form) {
	<form [formGroup]="service.form" class="flex flex-col gap-16">
		<section class="flex flex-col gap-2">
			<yuno-forms-title>Styles</yuno-forms-title>
			<div class="mb-4 flex flex-col gap-1">
				@for (style of service.styles.controls; track style; let i = $index) {
					<div
						class="flex items-center justify-start gap-2 rounded-md border border-solid border-yuno-blue-gray-400 bg-yuno-blue-gray-100 px-4 text-yuno-blue-gray-700 hover:bg-yuno-blue-gray-200">
						<div class="flex-1 select-none truncate py-4">
							{{ style.value.id }}
						</div>
						<div class="flex">
							<button yuno-admin-button-table (click)="onSelectStyle(i)">Edit</button>
							<button
								yuno-admin-button-table
								(click)="onRemoveStyle(i)"
								color="danger">
								Delete
							</button>
						</div>
					</div>
				}
				@if (service.styles.controls.length <= 0) {
					<div
						class="flex items-center justify-start gap-2 rounded-md border border-solid border-yuno-blue-gray-400 bg-yuno-blue-gray-200 px-4 text-yuno-blue-gray-700">
						<div class="flex-1 select-none truncate py-4">
							no styles has been created
						</div>
					</div>
				}
				<button yuno-admin-button (click)="onAddStyle()" [add]="true">Add Style</button>
			</div>

			<div formGroupName="layout">
				<yuno-forms-select
					[display]="service.stylesKeys"
					[selectValues]="service.stylesKeys"
					label="Default Style"
					formControlName="fallback" />
			</div>
		</section>
		<section class="flex flex-col gap-2">
			<yuno-forms-title>Styling Rules</yuno-forms-title>
			<yuno-forms-span>
				Define the rules that determine which style is used for a marker.
			</yuno-forms-span>
			<div formGroupName="layout" class="mt-4 grid grid-cols-1 gap-4">
				<yuno-forms-select
					[selectValues]="service.listOfKeys"
					label="input field"
					formControlName="filter"
					(changes)="service.resetLayoutOptions()" />
				<yuno-admin-annotation type="warning">
					Changing this value clears all defined rules.
				</yuno-admin-annotation>
			</div>
			<button yuno-admin-button [add]="true" (click)="onSetRules(true)">Set Rules</button>
		</section>
	</form>
}
