import { DragDropModule } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormArray, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HtmlEditorImageLibraryComponent } from '@yuno/admin/features/html-form';
import {
	YunoAdminButtonComponent,
	YunoEditContainerModule,
	YunoSelectFieldModule
} from '@yuno/admin/ui';
import { LanguageFormType, newLanguageFormGroup } from '@yuno/angular/forms';
import {
	YunoFormsDividerComponent,
	YunoFormsTextComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';
import { AngularPipesModule } from '@yuno/angular/pipes';
import { LanguageAll } from '@yuno/api/interface';

import { ParticipationForm } from '../../../../settings-editor.service';

@Component({
	selector: 'yuno-admin-participation-steps-page-input-editor',
	imports: [
		YunoFormsDividerComponent,
		YunoFormsTextComponent,
		YunoFormsToggleComponent,
		FormsModule,
		ReactiveFormsModule,
		YunoAdminButtonComponent,
		DragDropModule,
		YunoEditContainerModule,
		YunoSelectFieldModule,
		AngularPipesModule,
		HtmlEditorImageLibraryComponent
	],
	templateUrl: './input-editor.component.html',
	styleUrls: ['./input-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipationPageInputEditorComponent {
	private _data: FormGroup<ParticipationForm> | null;
	@Input() set data(form: FormGroup<ParticipationForm> | null) {
		this._data = null;
		this.cdr.detectChanges();

		this.cdr.markForCheck();
		this._data = form;
	}

	get data(): FormGroup<ParticipationForm> | null {
		return this._data;
	}

	get selectValues(): FormArray<FormGroup<LanguageFormType>> {
		return this.data?.get('selectValues') as FormArray<FormGroup<LanguageFormType>>;
	}

	@Input() language = 'nl';
	protected readonly languages = LanguageAll;

	constructor(private cdr: ChangeDetectorRef) {}

	removeItemAt(index: number): void {
		this.selectValues.removeAt(index);
	}

	addItem(): void {
		// this.selectValues.push(new FormControl<string>('', { nonNullable: true }));
		this.selectValues.push(newLanguageFormGroup());
	}
}
