import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { LanguagePipe } from '@yuno/angular/pipes';
import { LanguageStringsModel } from '@yuno/api/interface';

import { JsonFormControl } from '../../json-forms.models';
import { FormCreatorService } from '../../services/form-creator.service';
import { LanguageService } from '../../services/language.service';
import { ValidationService } from '../../services/validation.service';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ColorComponent } from './color/color.component';
import { DateComponent } from './date/date.component';
import { EmailComponent } from './email/email.component';
import { FileComponent } from './file/file.component';
import { NumberComponent } from './number/number.component';
import { ParagraphComponent } from './paragraph/paragraph.component';
import { PasswordComponent } from './password/password.component';
import { RadioComponent } from './radio/radio.component';
import { RangeComponent } from './range/range.component';
import { SelectComponent } from './select/select.component';
import { TelephoneComponent } from './telephone/telephone.component';
import { TextComponent } from './text/text.component';
import { TextareaComponent } from './textarea/textarea.component';
import { ToggleComponent } from './toggle/toggle.component';
import { UrlComponent } from './url/url.component';

@Component({
    selector: 'yuno-json-forms-control-selector',
    templateUrl: './controls.selector.component.html',
    styleUrls: ['./controls.selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        ParagraphComponent,
        TextComponent,
        EmailComponent,
        PasswordComponent,
        TelephoneComponent,
        UrlComponent,
        DateComponent,
        NumberComponent,
        RangeComponent,
        SelectComponent,
        TextareaComponent,
        CheckboxComponent,
        RadioComponent,
        ToggleComponent,
        FileComponent,
        ColorComponent,
        AsyncPipe,
        LanguagePipe
    ]
})
export class ControlsSelectorComponent {
	_item: JsonFormControl;
	_control: UntypedFormControl;

	@Input() form: UntypedFormGroup;
	@Input()
	set control(form: UntypedFormControl) {
		if (!this._control) {
			this._control = form;
		}
	}
	get control(): UntypedFormControl {
		if (this.item?.options?.disabled) {
			this._control.disable();
		}
		return this._control;
	}
	@Input()
	set item(item: JsonFormControl) {
		this._item = item;
		if (item.validationMessage && Object.keys(item.validationMessage).length > 0) {
			this.validationMessage = item.validationMessage;
			return;
		}

		this.generateValidationMessage();
	}
	get item(): JsonFormControl {
		return this._item;
	}

	validate = false;
	validationMessage: LanguageStringsModel;

	language$ = this.languageService.language$;

	constructor(
		private formCreator: FormCreatorService,
		private languageService: LanguageService,
		public validationService: ValidationService
	) {}

	generateValidationMessage(): void {
		this.validationMessage = this.formCreator.formValidationMessages(this.item);
	}
}
