import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Injector, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { map } from 'rxjs';

import { HtmlEditorImageLibraryComponent } from '@yuno/admin/features/html-form';
import { AnnotationComponent, YunoAdminButtonComponent } from '@yuno/admin/ui';
import {
	YunoFormsColorComponent,
	YunoFormsDividerComponent,
	YunoFormsSelectComponent,
	YunoFormsSpanComponent,
	YunoFormsTextComponent,
	YunoFormsTitleComponent
} from '@yuno/angular/forms/components';
import { MediaImageButton } from '@yuno/api/interface';

import { TextEditorsService } from '../text-editors.service';
import { InjectClass, token } from '../token';

@Component({
	selector: 'yuno-admin-item-editor',
	imports: [
		AsyncPipe,
		ReactiveFormsModule,
		YunoFormsDividerComponent,
		YunoFormsTitleComponent,
		YunoFormsTextComponent,
		YunoFormsSpanComponent,
		YunoFormsColorComponent,
		YunoFormsSelectComponent,
		HtmlEditorImageLibraryComponent,
		AnnotationComponent,
		YunoAdminButtonComponent
	],
	templateUrl: './page-item-editor.component.html',
	styleUrls: ['./page-item-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageItemEditorComponent implements OnInit {
	private readonly inject = inject(Injector);
	readonly editService = inject(TextEditorsService);

	data$ = this.editService.data$;
	service$ = this.editService.getActiveService().pipe(
		map(service => {
			return this.editService.returnPagesService(service);
		})
	);

	imageButtons: MediaImageButton[];
	injectedData: InjectClass;

	ngOnInit(): void {
		this.injectedData = this.inject.get(token);
		this.editService.onInitImageButtons();
		this.editService.onInitNav();
	}
}
