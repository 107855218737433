import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	EventEmitter,
	HostBinding,
	Input,
	OnInit,
	Output,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

export const SearchDelays = {
	debounceTime: 500,
	minimumLength: 3
};

@Component({
    selector: 'yuno-admin-search-bar',
    imports: [FormsModule],
    templateUrl: './search-bar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminSearchBarComponent implements OnInit {
	private readonly destroyRef = inject(DestroyRef);

	@HostBinding('class') className = 'relative';

	private _enter = false;
	private _debounceTime = SearchDelays.debounceTime;
	private _minimumLength = SearchDelays.minimumLength;
	private _searchValue = new Subject<string>();
	searchValue$ = this._searchValue.asObservable();

	@Input() searchString: string;

	@Output() searches = new EventEmitter<string>();
	@Input() placeholder = 'Search...';

	/**
	 * Only searches when pressing the Enter/Return key
	 */
	@Input()
	set enter(bool: boolean) {
		this._enter = bool;
	}

	get enter(): boolean {
		return this._enter;
	}

	/**
	 * sets a custom minimal search length
	 * for the search to return a value
	 *
	 * defaults to 3
	 */

	@Input()
	set minimumLength(num: number) {
		this._minimumLength = num;
	}

	get minimumLength(): number {
		return this._minimumLength;
	}

	/**
	 * sets a custom DebounceTime in ms
	 * for the search to return a value
	 *
	 * defaults to 500ms
	 */
	@Input()
	set debounceTime(num: number) {
		this._debounceTime = num;
	}

	get debounceTime(): number {
		return this._debounceTime;
	}

	ngOnInit(): void {
		this.searchValue$
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				distinctUntilChanged(),

				// when the length is lower than the minimal input stop searching
				filter((query: string) => query?.length >= this.minimumLength),
				// only start the search when the input is not changed
				// for this duration
				debounceTime(this.debounceTime)
			)
			.subscribe(val => {
				this.emitSearch(val);
			});
	}

	onSearch(): void {
		this._searchValue.next(this.searchString);

		if (!this.searchString) {
			this.emitSearch('');
		}
	}

	emitSearch(val: string): void {
		this.searches.emit(val);
	}
}
