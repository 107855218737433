<ng-container [formGroup]="form">
	<label class="yuno-form">
		@if (item.label) {
			<span
				class="select-none"
				[innerHtml]="
					item.label
						| languageSelect: language
						| safeHtml: item.validators?.required || item.required
				">
			</span>
		}
		<select [name]="item.key" [formControl]="control">
			<option [value]="undefined" selected hidden [disabled]="item.validators?.required">
				{{ item.placeholder | languageSelect: language }}
			</option>
			@for (select of item.selectValues; track select; let i = $index) {
				<option [value]="select | languageSelect: language">
					{{ returnDisplay(i, select) | languageSelect: language }}
				</option>
			}
		</select>
	</label>
</ng-container>
