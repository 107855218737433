import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { RouterLink } from '@angular/router';

import { AuthFacade } from '@yuno/angular-auth';
import { FloatingListComponent } from '@yuno/yuno/features/ui';

@Component({
	selector: 'yuno-sidebar-user',
	template: `
		@if (user$ | async; as user) {
			<div class="flex h-16 items-center justify-start gap-4">
				<div
					(click)="toggle()"
					class="flex h-7 w-7 cursor-pointer items-center justify-center rounded-3xl bg-gray-100 p-1.5"
					cdkOverlayOrigin
					#trigger="cdkOverlayOrigin">
					<svg xmlns="http://www.w3.org/2000/svg" width="14" height="15">
						<path
							data-name="user_icon"
							d="M7 6a3 3 0 1 0-3-3 3 3 0 0 0 3 3Zm-7 9a7 7 0 0 1 14 0Z"
							fill="#394558"
							fill-rule="evenodd" />
					</svg>
				</div>
				@if (appRole$ | async; as role) {
					@if (role === 'atlas') {
						<button class="text-yuno-blue hover:cursor-pointer hover:underline">
							{{ user.email }}
						</button>
					}
					@if (role !== 'atlas') {
						<div class="flex flex-col">
							<button class="text-yuno-blue hover:cursor-pointer hover:underline">
								{{ user.email }}
							</button>
							<span class="text-sm text-gray-500">{{ role }} </span>
						</div>
					}
				}
			</div>

			<!-- Dropdown menu -->
			<ng-template
				cdkConnectedOverlay
				[cdkConnectedOverlayOrigin]="trigger"
				[cdkConnectedOverlayOpen]="$isOpen()"
				[cdkConnectedOverlayHasBackdrop]="true"
				[cdkConnectedOverlayBackdropClass]="'opacity-0'"
				(backdropClick)="close()">
				<yuno-ui-floating-list-container>
					<li>
						<a
							[routerLink]="['/', 'auth', 'logout']"
							class="block w-full p-4 hover:bg-yuno-gray-600">
							Sign out
						</a>
					</li>
				</yuno-ui-floating-list-container>
			</ng-template>
		}
	`,
	styleUrls: ['./sidebar-user.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CdkConnectedOverlay, FloatingListComponent, RouterLink, CdkOverlayOrigin, AsyncPipe]
})
export class SidebarUserComponent {
	private readonly authFacade = inject(AuthFacade);
	$isOpen = signal<boolean>(false);

	user$ = this.authFacade.user$;
	appRole$ = this.authFacade.appRole$;

	close(): void {
		this.$isOpen.set(false);
	}

	toggle(): void {
		this.$isOpen.set(!this.$isOpen());
	}
}
