import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import {
	AnnotationComponent,
	DragRowComponent,
	YunoAdminAddButtonComponent,
	YunoAdminDeleteAdminButtonComponent
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import {
	YunoFormsArrayContainerComponent,
	YunoFormsDividerComponent,
	YunoFormsSpanComponent,
	YunoFormsTextComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';

import { InternalViewService } from '../../internal-view/internal-view.service';

@Component({
	imports: [
		ReactiveFormsModule,
		YunoFormsDividerComponent,
		YunoFormsTextComponent,
		YunoFormsSpanComponent,
		YunoFormsToggleComponent,
		AnnotationComponent,
		DragRowComponent,
		YunoAdminDeleteAdminButtonComponent,
		YunoAdminAddButtonComponent,
		YunoFormsArrayContainerComponent
	],
	selector: 'yuno-admin-project-title-settings',
	templateUrl: './project-title-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppProjectTitleSettingsComponent extends AppDataComponent {
	readonly service = inject(InternalViewService);

	onAdd(): void {
		this.service.addNewUrl();
	}

	onRemove(index: number): void {
		this.service.removeUrl(index);
	}
}
