import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormGroup } from '@angular/forms';
import { RouterOutlet } from '@angular/router';
import { combineLatest, map, startWith } from 'rxjs';

import { EventForm, EventFormsService, EventsFacade } from '@yuno/admin/features/events';
import { EventsFormsComponent } from '@yuno/admin/features/events/feature/events.forms.component';
import { FencesFacade } from '@yuno/admin/features/fences';
import {
	TableColumnDisplay,
	TableRow,
	TableSelectionOutput,
	TableSelectionOutputNew,
	YunoAdminButtonComponent,
	YunoAdminButtonTableComponent,
	YunoAdminTableComponent
} from '@yuno/admin/ui';
import { Fence } from '@yuno/api/interface';

import { DatasetListviewBasicComponent } from '../basic-dataset-list-component.component';

@Component({
	selector: 'yuno-admin-dataset-listview-fences',
	imports: [
		YunoAdminTableComponent,
		YunoAdminButtonComponent,
		YunoAdminButtonTableComponent,
		EventsFormsComponent,
		AsyncPipe,
		RouterOutlet
	],
	templateUrl: './fences.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatasetListviewFencesComponent
	extends DatasetListviewBasicComponent
	implements OnInit
{
	private readonly eventsFacade = inject(EventsFacade);
	private readonly eventsForm = inject(EventFormsService);
	private readonly facade = inject(FencesFacade);

	// Initialize event index and a variable for copying event data.
	eventIndex = 0;
	copyEventData?: FormArray<FormGroup<EventForm>>;

	// Combine observables to create a data stream for the component.
	data$ = combineLatest({
		events: this.eventsFacade.toggle$.pipe(startWith(false)),
		changes: this.service.rawForm$.pipe(
			startWith(undefined),
			takeUntilDestroyed(this.destroyRef),
			map(data => data?.data?.fences.map(data => data.fence as TableRow) || [])
		),
		fences: this.facade.fences$.pipe(startWith([]))
	});

	// Initialize table column configuration.
	tableColumns: TableColumnDisplay[] = [{ key: 'id', label: 'Fence' }];

	// Getter to retrieve event data from the form array.
	get dataFencesEvents(): FormArray<FormGroup<EventForm>> | undefined {
		return this.eventIndex >= 0
			? (this.service.dataFences.controls[this.eventIndex].get('events') as FormArray<
					FormGroup<EventForm>
				>)
			: undefined;
	}

	// Angular lifecycle hook, called when the component is initialized.
	ngOnInit(): void {
		this.facade.get();
	}

	// Event handler for selecting all items in the table.
	onSelectAll(e: TableSelectionOutput) {
		this.service.selectAll(
			'Fences',
			e.selection.map(data => ({ fence: data }))
		);
	}

	// Triggers when selecting or deselecting a row in the table
	// when adding an item, also adds an event key to the object
	onSelect(e: TableSelectionOutputNew): void {
		if (!e.checked) {
			this.service.remove('Fences', 'fence', e.row);
			return;
		}

		this.service.add('Fences', { fence: e.row });
	}

	// Function to determine the enabled state and event count for a hovered item.
	eventButtonsData(row: Partial<Fence>): {
		enabled: boolean;
		eventCount: number;
	} {
		if (!row) {
			return { enabled: false, eventCount: 0 };
		}

		const formGroups = this.service.dataFences.controls as FormGroup[];
		const data = formGroups.find(
			group => group.controls?.['fence'].get('_id')?.value === row._id
		);

		if (!data) {
			return {
				enabled: false,
				eventCount: 0
			};
		}

		return {
			enabled: true,
			eventCount: data.value.events.length
		};
	}

	// Event handler for clicking on an event button.
	onClickEventButton(row: Partial<Fence>): void {
		const formGroups = this.service.dataFences.controls as FormGroup[];
		const foundIndex = formGroups.findIndex(
			group => group.controls?.['fence'].get('_id')?.value === row._id
		);

		if (foundIndex < 0) {
			return;
		}

		this.eventIndex = foundIndex;

		this.eventsFacade.setKey('events');
		this.eventsFacade.toggleEvents(true);
	}

	// Function to copy events data for a specific item.
	copyEvents(row: Partial<Fence>): void {
		const formGroups = this.service.dataFences.controls as FormGroup[];
		const foundIndex = formGroups.findIndex(
			group => group.controls?.['fence'].get('_id')?.value === row._id
		);

		if (foundIndex < 0) {
			return;
		}

		const control = this.service.dataFences.controls[foundIndex];
		this.copyEventData = control.controls.events;
	}

	// Function to paste copied events data to a specific item.
	pasteEvents(event: Partial<Fence>): void {
		if (!this.copyEventData) {
			return;
		}

		// All formgroups in the data fences form array.
		const formGroups = this.service.dataFences.controls as FormGroup[];
		// Get the index of the formgroup that matches the event id.
		const foundIndex = formGroups.findIndex(
			group => group.controls?.['fence'].get('_id')?.value === event['_id']
		);

		if (foundIndex < 0) {
			return;
		}

		// if the index if found, get the formgroup and the events form array.
		const control = this.service.dataFences.controls[foundIndex];

		// First we clear all current events
		// from the events form array
		control.controls.events?.clear();

		for (const eventData of this.copyEventData.value) {
			control.controls.events.push(this.eventsForm.createEvent(eventData.type));
		}
		control.controls.events.patchValue(this.copyEventData.value);
	}

	// Function to clear the clipboard (reset copied event data).
	clearClipboard(): void {
		this.copyEventData = undefined;
	}
}
