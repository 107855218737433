import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	inject
} from '@angular/core';
import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';
import { Polygon, Position } from 'geojson';
import { isEqual } from 'lodash';
import { LngLatBoundsLike, LngLatLike, Map, Marker } from 'maplibre-gl';
import { BehaviorSubject, combineLatest, distinctUntilChanged, startWith, tap } from 'rxjs';

import { ENVIRONMENT } from '@yuno/admin/core';
import { MapFacade } from '@yuno/admin/features/map';
import { DefaultMapBounds } from '@yuno/admin/features/themes';
import { MarkersModule } from '@yuno/project-atlas/ui';

import { DrawMapService } from '../../../../../../../../angular/draw-map/src/lib/services/draw-map.service';

@Component({
	imports: [CommonModule, NgxMapLibreGLModule, MarkersModule],
	selector: 'yuno-admin-config-map',
	templateUrl: './config-map.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigMapComponent implements OnInit {
	private readonly mapFacade = inject(MapFacade);
	private readonly drawService = inject(DrawMapService);

	readonly environment = inject(ENVIRONMENT);

	private vectorBasis = '5e42a2df5e53be02b5f37b00';
	private mapBoundsLngLat: LngLatBoundsLike = [
		[7.22, 53.7],
		[3.2, 50.75]
	];

	private _map = new BehaviorSubject<Map | null>(null);
	map$ = this._map.asObservable();

	data$ = combineLatest({
		mapStyle: this.mapFacade.style$.pipe(
			startWith(undefined),
			distinctUntilChanged((prev, curr) => {
				if (!prev && !curr) {
					// when no style is selected, load the default mapstyle
					this.mapFacade.getStyle(this.vectorBasis);
					return false;
				}

				const prevMetadata: { [key: string]: unknown } = prev?.metadata as {
					[key: string]: unknown;
				};
				const currMetadata: { [key: string]: unknown } = curr?.metadata as {
					[key: string]: unknown;
				};

				if (!prevMetadata || !currMetadata) {
					return false;
				}

				return isEqual(prev?.metadata, curr?.metadata);
			})
		),
		map: this.map$.pipe(
			startWith(null),
			tap(map => {
				if (map) {
					this.drawService.addDrawFeatures(map);
					if (this.extend) {
						const bounds = this.extend as [number, number][];
						const polygon: Position[] = [
							bounds[1],
							[bounds[0][0], bounds[1][1]],
							bounds[0],
							[bounds[1][0], bounds[0][1]],
							bounds[1]
						];
						// this.fitBounds(map, fence.polygon);
						this.drawService.addPolygon('data.id', polygon);
					} else {
						this.drawService.addPolygon('data.id', DefaultMapBounds);
					}
				}
			})
		),
		drawUpdate: this.drawService.drawUpdate$.pipe(
			startWith(null),
			tap(data => {
				if (data?.features && data.features.length === 1) {
					const polygon = data.features[0].geometry as Polygon;
					this.polygon.next(polygon.coordinates[0]);
					return;
				}

				this.polygon.next(null);
			})
		),
		drawEdit: this.drawService.drawSelect$.pipe(
			startWith(null),
			tap(data => {
				this.draggabble = !(data?.features && data.features.length >= 1);
			})
		)
	});

	draggabble = true;

	@Input() coordinates: number[] = [4.463, 51.912];
	@Input() extend: [number, number][] = [
		[3.2, 50.75],
		[7.22, 53.5]
	];

	@Output() pointer = new EventEmitter<Position>();
	@Output() polygon = new EventEmitter<Position[] | null>();

	ngOnInit() {
		this.mapFacade.getStyle(this.vectorBasis);
	}

	async mapLoad(map: Map): Promise<void> {
		this._map.next(map);
		map.fitBounds(this.extend as LngLatBoundsLike, { animate: false, padding: 40 });
	}

	getLngLat(point: Position | undefined): LngLatLike {
		return point as LngLatLike;
	}

	dragEnd(marker: Marker): void {
		const lngLat = marker.getLngLat();
		const position = [lngLat.lng, lngLat.lat] as Position;
		this.pointer.next(position);
	}

	mapOnMissingImage(image: { id: string; target?: Map }): void {
		if (!image.target) {
			return;
		}

		const map = image.target;
		const id = image.id;
		const url = `${this.environment['yuno-cdn']}/sprites/public/sdf/sprites/${image.id}.png`;

		if (!map.hasImage(id)) {
			map.loadImage(url).then(img => {
				if (!img) {
					throw new Error(`Image could not be loaded for, ${url}`);
				}

				// the Map fires the same missing image event multiple times
				// then throws an error: "An image named "{{id}}" already exists."
				if (!map.hasImage(id)) {
					map.addImage(id, img.data, {
						sdf: true,
						pixelRatio: 1
					});
				}
			});
		}
	}
}
